<template>
  <Modal
    v-model="isShow"
    title="发票信息"
    :footer-hide="true"
    :closable="false"
    class-name="invoice"
    :mask-closable="false"
    width="832">
    <div class="panel">
      <div class="title">发票类型</div>
      <div class="type">
        <div @click="toggleType(beforeType)" :class="['type-item', invoiceType!==4?'active':'']">{{proprietary !== 1?'':'电子'}}普通发票</div>
        <div @click="toggleType(4)" :class="['type-item', invoiceType===4?'active':'']">增值税专用发票</div>
      </div>
    </div>
    <div class="panel">
      <div class="title">发票抬头<div v-if="invoiceType === 4" @click="goInvoice()" class="invoice-btn">发票中心</div></div>
      <div class="rule">
        <p>发票须知</p>
        <p>一、发票金额不含员工券、满减、优惠券、银行支付有礼、格力明珠卡等优惠扣减金额。</p>
        <p>二、电子普通发票</p>
        <p>1.电子普通发票是税局认可的有效收付款凭证，其法律效力、基本用途及使用规定同纸质发票，如需纸质发票可自行下载打印；</p>
        <p>2.格力自营店铺全面启用电子普通发票，用户可点击（个人中心-全部订单-查看详情-下载发票）查询和下载；</p>
        <p>3.电子普通发票开票时限：不含优惠订单自货物签收完成后的3个工作日内开具，使用优惠订单7-15个工作日开具。</p>
        <p>三、第三方卖家销售的商品/服务的发票由卖家自行出具、提供，发票类型和内容由卖家根据实际商品、服务情况决定。</p>
        <p>四、增值税专用发票 </p>
        <p>1.增值税专用发票资质需要在（个人中心-发票资质）进行操作修改；</p>
        <p>2.有效增值税专用发票开票资质仅为一个；</p>
        <p>3.请按照税法规定使用发票；</p>
        <p>4.增值税专用发票开票时限：货物签收后的7-15个工作日内开具并按预留地址寄出。</p>
        <p>五、发票补开换开及修改规则 </p>
        <p>1.发票开具成功后，以订单日期为基础，1年内可支持一次发票换开。超过1年则不支持发票换开。</p>
        <p>2.更换增值税专用发票，需将原发票寄回格力电子商务公司。邮寄地址为：广东省珠海市香洲区金鸡西路珠海格力岐关办公室（红塔仁恒对面）格力电子商务有限公司财务部0756-8572102。</p>
      </div>
      <Form ref="dataForm" :model="form" :rules="allRules" :label-width="121">
        <FormItem v-if="invoiceType !== 4" label="发票抬头：">
          <div class="type" style="padding: 0;">
            <div @click="toggleType(2)" :class="['type-item', invoiceType===2?'active':'']">个人</div>
            <div @click="toggleType(3)" :class="['type-item', invoiceType===3?'active':'']">单位</div>
            <div @click="goInvoice()" class="invoice-center">发票中心</div>
          </div>
        </FormItem>
        <!-- 个人 -->
        <FormItem v-if="invoiceType === 2" prop="invoiceTitle" label="个人名称：" key="invoiceTitle">
          <Input v-model.trim="form.invoiceTitle" placeholder="请填写'个人'或您的姓名" style="width: 400px"></Input>
        </FormItem>
        <template v-else>
          <FormItem label="公司名称：" prop="invoiceName" key="invoiceName">
            <Input @on-blur="handleTitleBlur" v-model.trim="form.invoiceName" placeholder="请输入公司名称" style="width: 400px"></Input>
          </FormItem>
          <FormItem label="纳税人识别号：" prop="invoiceTaxPayer" key="invoiceTaxPayer">
            <Input v-model.trim="form.invoiceTaxPayer" placeholder="请输入纳税人识别号" style="width: 400px"></Input>
          </FormItem>
          <FormItem label="注册地址：" prop="invoiceAddress" :key="invoiceType === 3?'invoiceAddress':'invoiceAddress_required'">
            <Input v-model.trim="form.invoiceAddress" :placeholder="invoiceType === 3?'选填':'必填'" style="width: 400px"></Input>
          </FormItem>
          <FormItem label="公司电话：" prop="invoiceTel" :key="invoiceType === 3?'invoiceTel':'invoiceTel_required'">
            <Input v-model.trim="form.invoiceTel" :placeholder="invoiceType === 3?'选填':'必填'" style="width: 400px"></Input>
          </FormItem>
          <FormItem label="开户银行：" prop="invoiceBank" :key="invoiceType === 3?'invoiceBank':'invoiceBank_required'">
            <Input v-model.trim="form.invoiceBank" :placeholder="invoiceType === 3?'选填':'必填'" style="width: 400px"></Input>
          </FormItem>
          <FormItem label="银行账户：" prop="invoiceAccount" :key="invoiceType === 3?'invoiceAccount':'invoiceAccount_required'">
            <Input v-model.trim="form.invoiceAccount" :placeholder="invoiceType === 3?'选填':'必填'" style="width: 400px"></Input>
          </FormItem>
        </template>
        <FormItem label="发票内容：" key="invoiceContent">
          <div class="good-detail">
            <div class="txt">{{form.invoiceContent}}</div>
            <div class="tip">发票内容将显示详细商品名称与价格信息</div>
          </div>
        </FormItem>
        <template v-if="invoiceType === 4 || proprietary !== 1">
          <FormItem label="收件人：" prop="ticketName" key="ticketName">
            <Input v-model.trim="form.ticketName" placeholder="" style="width: 184px"></Input>
          </FormItem>
          <FormItem label="收票所在地区：" prop="address" key="address">
            <FormItem prop="provinceCode" key="provinceCode" style="display: inline-block;">
              <Select :value="form.provinceCode" @on-change="handleProvinceChange" label-in-value style="width:137px">
                <Option v-for="item in provinceList" :value="item.addressCode" :key="item.addressCode">{{ item.addressName }}</Option>
              </Select>
            </FormItem>
            <FormItem prop="cityCode" key="cityCode" style="display: inline-block;">
              <Select :value="form.cityCode" @on-change="handleCityChange" label-in-value class="gap" style="width:137px">
                <Option v-for="item in cityList" :value="item.addressCode" :key="item.addressCode">{{ item.addressName }}</Option>
              </Select>
            </FormItem>
            <FormItem prop="countryCode" key="countryCode" style="display: inline-block;">
              <Select :value="form.countryCode" @on-change="handleCountryChange" label-in-value class="gap" style="width:137px">
                <Option v-for="item in countryList" :value="item.addressCode" :key="item.addressCode">{{ item.addressName }}</Option>
              </Select>
            </FormItem>
            <FormItem prop="townCode" key="townCode" style="display: inline-block;">
              <Select :value="form.townCode" @on-change="handleTownChange" label-in-value class="gap" style="width:137px">
                <Option v-for="item in townList" :value="item.addressCode" :key="item.addressCode">{{ item.addressName }}</Option>
              </Select>
            </FormItem>
          </FormItem>
          <FormItem label="收票详细地址：" prop="ticketAddressDetail" key="ticketAddressDetail">
            <Input v-model.trim="form.ticketAddressDetail" placeholder="" style="width: 400px"></Input>
          </FormItem>
        </template>
        <FormItem label="收票人手机：" prop="ticketMobile" key="ticketMobile">
          <Input v-model.trim="form.ticketMobile" @on-focus="handleMobileFocus" @on-blur="handleMobileBlur" @on-change="handleMobileChange" placeholder="" style="width: 184px"></Input>
        </FormItem>
        <FormItem v-if="!(invoiceType === 4 || proprietary !== 1)" label="收票人邮箱：" prop="ticketEmail" key="ticketEmail">
          <Input v-model.trim="form.ticketEmail" placeholder="选填，用来接收电子发票" style="width: 184px"></Input>
        </FormItem>
        <div class="submit">
          <Button @click="submit" type="primary" class="sure" size="large">确定</Button>
          <Button @click="isShow = false" type="dashed" class="cancle" style="margin-left: 24px" size="large">取消</Button>
        </div>
        <div id="captcha"></div>
      </Form>
    </div>
  </Modal>
</template>

<script>
import { mapState } from 'vuex'
import {checkLoginConfig} from '@/utils/common'
import {captchaCheck} from '@/api/api'

import {
  queryAddress,
  checkInvoice,
  queryTaxCode,
  updateInvoice,
  getNewInvoiceInfo,
  getOrderInvoiceInfo,
} from '@/api/api'

export default {
  name: 'Invoice',
  props: {
    value: Boolean,
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
    proprietary: {
      type: Number,
      default: 1
    },
    shopId: {
      type: String,
      default: ''
    },
    invoiceRevoke: {
      type: Number,
      default: 0
    },
    address: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    const validateTrue = (rule, value, callback) => {
      callback();
    };
    const validateAddress = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请选择所在地区'));
      } else {
        callback();
      }
    };
    return {
      invoiceType: 2,
      beforeType: 2, // 记录去增值税专用发票之前的类型，方便跳转到对应的tab
      form: {
        invoiceTitle: '个人',
        invoiceContent: '商品明细',
        invoiceName: '',
        preInvoiceName: '',
        invoiceTaxPayer: '',
        invoiceAddress: '',
        invoiceTel: '',
        invoiceBank: '',
        invoiceAccount: '',
        ticketName: '',
        provinceCode: '',
        provinceName: '',
        cityCode: '',
        cityName: '',
        countryCode: '',
        countryName: '',
        townCode: '',
        townName: '',
        ticketAddressDetail: '',
        ticketMobile: '',
        ticketEmail: '',
      },
      rules: {
        invoiceTitle: [
          { required: true, message: "请填写'个人'或您的姓名", trigger: 'blur'},
          { type: 'string', min: 2, message: '个人名称限2-30位数', trigger: 'blur' },
          { type: 'string', max: 30, message: '个人名称限2-30位数', trigger: 'blur' },
        ],
        invoiceName: [
          { required: true, message: "请输入完整公司/单位名称", trigger: 'blur'},
          { type: 'string', min: 3, message: '公司名称限3-30个字', trigger: 'blur' },
          { type: 'string', max: 30, message: '公司名称限3-30个字', trigger: 'blur' },
        ],
        invoiceTaxPayer: [
          { required: true, message: "纳税人识别号不能为空", trigger: 'blur'},
          { type: 'string', min: 15, message: '纳税人识别号限15-20位数', trigger: 'blur' },
          { type: 'string', max: 20, message: '纳税人识别号限15-20位数', trigger: 'blur' },
        ],
        ticketName: [
          { required: true, message: "收件人不能为空", trigger: 'blur'},
          { type: 'string', min: 2, message: '收件人限2-30位数', trigger: 'blur' },
          { type: 'string', max: 30, message: '收件人限2-30位数', trigger: 'blur' },
        ],
        address: [{ required: true, validator: validateTrue }],
        provinceCode: [{ validator: validateAddress, trigger: 'change' }],
        cityCode: [{ validator: validateAddress, trigger: 'change' }],
        countryCode: [{ validator: validateAddress, trigger: 'change' }],
        townCode: [{ validator: validateAddress, trigger: 'change' }],
        ticketAddressDetail: [
          { required: true, message: "收票详细地址不能为空", trigger: 'blur'},
          { type: 'string', min: 1, message: '详细地址限1-50个字', trigger: 'blur' },
          { type: 'string', max: 50, message: '详细地址限1-50个字', trigger: 'blur' },
        ],
        ticketMobile: [
          { required: true, message: "请输入手机号码", trigger: 'blur'},
        ],
        ticketEmail: [
          { type: 'email', message: '请确认输入正确邮箱', trigger: 'blur' },
        ],
      },
      companyRules: {
        optional: {
          invoiceAddress: [
            { type: 'string', min: 8, message: '注册地址限8-80位数', trigger: 'blur' },
            { type: 'string', max: 80, message: '注册地址限8-80位数', trigger: 'blur' },
          ],
          invoiceTel: [
            { type: 'string', min: 10, message: '公司电话限10-15位数', trigger: 'blur' },
            { type: 'string', max: 15, message: '公司电话限10-15位数', trigger: 'blur' },
          ],
          invoiceBank: [
            { type: 'string', min: 4, message: '开户银行限4-80位数', trigger: 'blur' },
            { type: 'string', max: 80, message: '开户银行限4-80位数', trigger: 'blur' },
          ],
          invoiceAccount: [
            { type: 'string', min: 6, message: '银行账户限6-30位数', trigger: 'blur' },
            { type: 'string', max: 30, message: '银行账户限6-30位数', trigger: 'blur' },
          ],
        },
        required: {
          invoiceAddress: [
            { required: true, message: "注册地址不能为空", trigger: 'blur'},
            { type: 'string', min: 8, message: '注册地址限8-80位数', trigger: 'blur' },
            { type: 'string', max: 80, message: '注册地址限8-80位数', trigger: 'blur' },
          ],
          invoiceTel: [
            { required: true, message: "公司电话不能为空", trigger: 'blur'},
            { type: 'string', min: 10, message: '公司电话限10-15位数', trigger: 'blur' },
            { type: 'string', max: 15, message: '公司电话限10-15位数', trigger: 'blur' },
          ],
          invoiceBank: [
            { required: true, message: "开户银行不能为空", trigger: 'blur'},
            { type: 'string', min: 4, message: '开户银行限4-80位数', trigger: 'blur' },
            { type: 'string', max: 80, message: '开户银行限4-80位数', trigger: 'blur' },
          ],
          invoiceAccount: [
            { required: true, message: "银行账户不能为空", trigger: 'blur'},
            { type: 'string', min: 6, message: '银行账户限6-30位数', trigger: 'blur' },
            { type: 'string', max: 30, message: '银行账户限6-30位数', trigger: 'blur' },
          ],
        },
      },
      ticketMobile: '', // 备胎，form.ticketMobile，因为是共用，所以要保存上一个的数据
      provinceList: [],
      cityList: [],
      countryList: [],
      townList: [],
      captchaIns:null, //网易网盾插件实例
    }
  },
  computed: {
    ...mapState([
      'userInfo',
    ]),
    isShow: {
      get: function() {
        return this.value;
      },
      set: function(val) {
        this.$emit('input', val);
      },
    },
    allRules() {
      let keyName = this.invoiceType === 3?'optional':'required'
      this.rules.invoiceAddress = this.companyRules[keyName].invoiceAddress
      this.rules.invoiceTel = this.companyRules[keyName].invoiceTel
      this.rules.invoiceBank = this.companyRules[keyName].invoiceBank
      this.rules.invoiceAccount = this.companyRules[keyName].invoiceAccount
      return this.rules
    }
  },
  mounted() {
    // 网易网盾配置
    checkLoginConfig({
      init: (instance) => {
        // 初始化成功后得到验证实例instance，可以调用实例的方法
        console.log('初始化成功', instance)
        this.captchaIns = instance
      },
      afterCheck: (val) => {
        // 验证成功后，调用close方法关闭弹框（enableClose为true时调用）
        this.captchaIns.close()
        captchaCheck({
          captchaValue: val.validate,
        }).then((res) => {
          if(res.result === 0){
            this.preInvoiceName = ''
            this.captchaIns.refresh() 
            this.handleTitleBlur()
          }
        })
      },
    })
  },
  watch: {
    value(newValue) {
      if(newValue) {
        this.$nextTick(() => {
          if(Object.keys(this.info).length === 4){ // 新增
            this.getNewInvoiceData()
          } else { // 编辑
            this.$refs['dataForm'].resetFields()
            let reg = /^(\d{3})\d{4}(\d{4})$/
            let phone = this.address.id?this.address.contactPhone.replace(reg, '$1****$2'):''
            if(this.info.invoiceType === 4)
              this.beforeType = 2
            else
              this.beforeType = this.info.invoiceType
            this.invoiceType = this.info.invoiceType;
            this.form = {
              invoiceTitle: this.info.invoiceType === 2?this.info.invoiceTitle:'个人',
              invoiceContent: this.info.invoiceContent  || '商品明细',
              invoiceName: this.info.invoiceType === 2?'':this.info.invoiceTitle,
              invoiceTaxPayer: this.info.invoiceType === 2?'':this.info.invoiceTaxPayer,
              invoiceAddress: this.info.invoiceType === 2?'':this.info.invoiceAddress,
              invoiceTel: this.info.invoiceType === 2?'':this.info.invoiceTel,
              invoiceBank: this.info.invoiceType === 2?'':this.info.invoiceBank,
              invoiceAccount: this.info.invoiceType === 2?'':this.info.invoiceAccount,
              ticketName: this.proprietary !== 1 || this.info.invoiceType === 4?this.info.ticketName:this.address.id?this.address.contactPerson:'',
              provinceCode: this.proprietary !== 1 || this.info.invoiceType === 4?this.info.provinceCode:this.address.id?this.address.provinceCode:'',
              provinceName: this.proprietary !== 1 || this.info.invoiceType === 4?this.info.provinceName:this.address.id?this.address.provinceName:'',
              cityCode: this.proprietary !== 1 || this.info.invoiceType === 4?this.info.cityCode:this.address.id?this.address.cityCode:'',
              cityName: this.proprietary !== 1 || this.info.invoiceType === 4?this.info.cityName:this.address.id?this.address.cityName:'',
              countryCode: this.proprietary !== 1 || this.info.invoiceType === 4?this.info.countryCode:this.address.id?this.address.countryCode:'',
              countryName: this.proprietary !== 1 || this.info.invoiceType === 4?this.info.countryName:this.address.id?this.address.countryName:'',
              townCode: this.proprietary !== 1 || this.info.invoiceType === 4?this.info.townCode:this.address.id?this.address.townCode:'',
              townName: this.proprietary !== 1 || this.info.invoiceType === 4?this.info.townName:this.address.id?this.address.townName:'',
              ticketAddressDetail: this.proprietary !== 1 || this.info.invoiceType === 4?this.info.ticketAddressDetail:this.address.id?this.address.detailAddress:'',
              ticketMobile: this.info.ticketMobile,
              ticketEmail: this.proprietary !== 1 || this.info.invoiceType === 4?'':this.info.email,
            }
            this.ticketMobile = this.proprietary !== 1 || this.info.invoiceType === 4?this.userInfo.mobile.replace(reg, '$1****$2'):phone
            this.preInvoiceName = this.form.invoiceName
          }
        })
      }
    },
    'form.provinceCode': {
      handler: function(newVal, oldVal){
        if(newVal === '') {
          this.cityList = []
        } else {
          this.getData(newVal, 'cityList')
        }
      }
    },
    'form.cityCode': {
      handler: function(newVal, oldVal){
        if(newVal === '') {
          this.countryList = []
        } else {
          this.getData(newVal, 'countryList')
        }
      }
    },
    'form.countryCode': {
      handler: function(newVal, oldVal){
        if(newVal === '') {
          this.townList = []
        } else {
          this.getData(newVal, 'townList')
        }
      }
    },
  },
  methods: {
    goInvoice() {
      this.isShow = false
      this.$router.push({
        name: 'invoiceCenter'
      })
    },
    //校验发票抬头是否只包含中文和指定特殊字符
    isValidTitle(title) {
      return  /^[()（）\u4E00-\u9FA5]+$/.test(title)
    },

    checkInvoice() {
      if( this.invoiceType === 2) {
        if(!this.isValidTitle(this.form.invoiceTitle)) {
          this.$Message.warning('发票抬头名称仅支持中文和（）')
          return;
        }
      }
      checkInvoice({
        invoiceType: this.invoiceType,
        ...this.form,
        invoiceTitle: this.invoiceType === 2?this.form.invoiceTitle:this.form.invoiceName,
          ticketEmail: this.invoiceType === 4?"":this.form.ticketEmail,
        shopId: this.shopId,
        ticketAddressArea: this.form.provinceName + this.form.cityName + this.form.countryName + this.form.townName,
        ticketAddress: this.form.provinceName + this.form.cityName + this.form.countryName + this.form.townName + this.form.ticketAddressDetail,
      }).then((res) => {
        if (res.result === 0) {
          this.$Message.success('保存成功');
          this.isShow = false
          this.$emit('change', {
            invoiceType: this.invoiceType,
            ...this.form,
            invoiceTitle: this.invoiceType === 2?this.form.invoiceTitle:this.form.invoiceName,
            ticketAddressArea: this.form.provinceName + this.form.cityName + this.form.countryName + this.form.townName,
            ticketAddress: this.form.provinceName + this.form.cityName + this.form.countryName + this.form.townName + this.form.ticketAddressDetail,
          })
        } else {
        }
      }).catch((err) => {
      })
    },
    submit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.checkInvoice()
        }
      })
    },
    handleTownChange(val) {
      if(val) {
        this.form.townCode = val.value
        this.form.townName = val.label
      }
    },
    handleCountryChange(val) {
      if(val) {
        this.form.townCode = ''
        this.form.countryCode = val.value
        this.form.countryName = val.label
      }
    },
    handleCityChange(val) {
      if(val) {
        this.form.townCode = ''
        this.form.countryCode = ''
        this.form.cityCode = val.value
        this.form.cityName = val.label
      }
    },
    handleProvinceChange(val) {
      if(val) {
        this.form.townCode = ''
        this.form.countryCode = ''
        this.form.cityCode = ''
        this.form.provinceCode = val.value
        this.form.provinceName = val.label
      }
    },
    handleMobileBlur() {
      if(this.form.ticketMobile === '') {
        let reg = /^(\d{3})\d{4}(\d{4})$/
        let phone = this.address.id?this.address.contactPhone.replace(reg, '$1****$2'):''
        let defaultMobile = this.proprietary !== 1 || this.invoiceType === 4?
                            Object.keys(this.info).length !== 4&&(this.proprietary !== 1 || this.info.invoiceType === 4)?this.info.ticketMobile:phone:
                            Object.keys(this.info).length !== 4&&this.info.invoiceType !== 4?this.info.ticketMobile:this.userInfo.mobile.replace(reg, '$1****$2')
        this.form.ticketMobile = defaultMobile
      } else {
        let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
        if(!reg.test(this.form.ticketMobile)) {
          this.$Message.info('请输入正确手机号码');
        }
      }
    },
    handleMobileFocus() {
      let reg = /^(\d{3})\d{4}(\d{4})$/
      let phone = this.address.id?this.address.contactPhone.replace(reg, '$1****$2'):''
      let defaultMobile = this.proprietary !== 1 || this.invoiceType === 4?
                            Object.keys(this.info).length !== 4&&(this.proprietary !== 1 || this.info.invoiceType === 4)?this.info.ticketMobile:phone:
                            Object.keys(this.info).length !== 4&&this.info.invoiceType !== 4?this.info.ticketMobile:this.userInfo.mobile.replace(reg, '$1****$2')
      if(this.form.ticketMobile === defaultMobile) {
        this.form.ticketMobile = ''
      }
    },
    handleMobileChange() {
      // 在数据变化后要执行的某个操作，而这个操作需要使用随数据改变而改变的DOM结构的时候，这个操作都应该放进Vue.nextTick()的回调函数中
      this.$nextTick(() => {
        this.form.ticketMobile = this.form.ticketMobile && this.form.ticketMobile.replace(/[^\d]/g, '')
      })
    },
    handleTitleBlur() {
      console.log('视角视角了')
      this.$nextTick(() => {
          
        console.log(this.preInvoiceName, '-------', this.form.invoiceName)
          if(this.preInvoiceName === this.form.invoiceName) {
            //  名称没有改变，不请求
            return
          }
          this.preInvoiceName = this.form.invoiceName
          console.log('-====',this.form.invoiceTaxPayer)

          if(this.form.invoiceTaxPayer.length > 0 || this.form.invoiceName === '') {
            //  有识别码不请求
            return
          }
          queryTaxCode({
            companyName: this.form.invoiceName,
          }).then((res) => {
            console.log('进行人工校验res.data:::', res.data)
            if (res.result === 0 && res.data.taxCode) {
              this.form.invoiceTaxPayer = res.data.taxCode;
              this.$refs['dataForm'].validateField('invoiceTaxPayer')
            } else if (res.result === 2231){ // 进行人工校验：图形验证码
              console.log('进行人工校验啊啊啊啊啊22222', this.captchaIns)

              this.captchaIns.popUp();
            }
          }).catch((err) => {
          })
        }
      )
    },
    toggleType(type) {
      if(this.invoiceType !== type) {
        if(this.proprietary === 1 && (type === 4 || this.invoiceType === 4)) {
          let phone = this.form.ticketMobile
          this.form.ticketMobile = this.ticketMobile
          this.ticketMobile = phone // 存放当前的
        }
        if(type === 4)
          this.beforeType = this.invoiceType
        else
          this.beforeType = type
        this.invoiceType = type
      }
    },
    getNewInvoiceData() {
      getNewInvoiceInfo({
        userId: this.userInfo.userId,
      }).then((res) => {
        if (res.result === 0) {
          this.$refs['dataForm'].resetFields()
          let reg = /^(\d{3})\d{4}(\d{4})$/
          let phone = this.address.id?this.address.contactPhone.replace(reg, '$1****$2'):''
          if(res.data.invoiceType === 4)
            this.beforeType = 2
          else
            this.beforeType = res.data.invoiceType
          this.invoiceType = res.data.invoiceType;
          this.form = {
            invoiceTitle: res.data.invoiceType === 2?res.data.invoiceTitle:'个人',
            invoiceContent: res.data.invoiceContent || '商品明细',
            invoiceName: res.data.invoiceType === 2?'':res.data.invoiceTitle,
            invoiceTaxPayer: res.data.invoiceType === 2?'':res.data.invoiceTaxpayer,
            invoiceAddress: res.data.invoiceType === 2?'':res.data.registeredAddress,
            invoiceTel: res.data.invoiceType === 2?'':res.data.registeredTelephone,
            invoiceBank: res.data.invoiceType === 2?'':res.data.bank,
            invoiceAccount: res.data.invoiceType === 2?'':res.data.account,
            ticketName: this.address.id?this.address.contactPerson:'',
            provinceCode: this.address.id?this.address.provinceCode:'',
            provinceName: this.address.id?this.address.provinceName:'',
            cityCode: this.address.id?this.address.cityCode:'',
            cityName: this.address.id?this.address.cityName:'',
            countryCode: this.address.id?this.address.countryCode:'',
            countryName: this.address.id?this.address.countryName:'',
            townCode: this.address.id?this.address.townCode:'',
            townName: this.address.id?this.address.townName:'',
            ticketAddressDetail: this.address.id?this.address.detailAddress:'',
            ticketMobile: this.proprietary !== 1 || res.data.invoiceType === 4?phone:this.userInfo.mobile.replace(reg, '$1****$2'),
            ticketEmail: '',
          }
          this.ticketMobile = this.proprietary !== 1 || res.data.invoiceType === 4?this.userInfo.mobile.replace(reg, '$1****$2'):phone
        } else {
        }
      }).catch((err) => {
      })
    },
    getData(code, dataKey) {
      queryAddress({
        parentCode: code,
      }).then((res) => {
        if (res.result === 0) {
          this[dataKey] = res.data;
        } else {
        }
      }).catch((err) => {
      })
    },
  },
  created() {
    this.getData(0, 'provinceList')
  },
}
</script>

<style lang="less" scoped>
.invoice {
  .panel {
    margin-top: 12px;
    border: 1px solid rgba(153,153,153,0.20);
    padding: 12px 24px;
    .title {
      position: relative;
      margin-bottom: 12px;
      font-size: 18px;
      color: #333333;
      .invoice-btn {
        cursor: pointer;
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 14px;
        color: #0085ff;
      }
    }
    .type {
      padding: 0 18px;
      display: flex;
      align-items: center;
      line-height: 1.5;
      .type-item {
        cursor: pointer;
        margin-left: 18px;
        border: 1px solid #999999;
        padding: 5px 12px;
        font-size: 14px;
        color: #999999;
      }
      .type-item:first-child {
        margin-left: 0;
      }
      .type-item.active {
        border-color: #CC0000;
        color: #CC0000;
      }
      .invoice-center {
        cursor: pointer;
        margin-left: 18px;
        font-size: 14px;
        color: #0085ff;
      }
    }
    .rule {
      margin-bottom: 24px;
      padding: 0 5px;
      height: 128px;
      border: 1px solid #999999;
      overflow: auto;
      font-size: 14px;
      color: #333333;
    }
    .good-detail {
      position: relative;
      .txt {
        width: 184px;
        line-height: 30px;
        text-align: center;
        border: 1px solid #CC0000;
        font-size: 14px;
        color: #CC0000;
      }
      .tip {
        position: absolute;
        line-height: 26px;
        font-size: 12px;
        color: #999999;
      }
    }
    .gap {
      margin-left: 9px;
    }
    .submit {
      margin-top: 32px;
      text-align: center;
      .sure {
        width: 132px;
        background-color: #CC0000;
        border: none;
        font-size: 18px;
        color: white;
      }
      .cancle {
        width: 132px;
        border: 1px solid #999999;
        font-size: 18px;
        color: #999999;
      }
    }
  }
  .panel:first-child {
    margin-top: 0;
  }
}
</style>

<style lang="less">
.invoice {
  .ivu-modal-body {
    max-height: 700px;
    overflow: auto;
  }
}
</style>