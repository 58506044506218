import axios from 'axios'
import config from '@/config'
import router from '../router'
import store from '../store'
import ViewUI from 'view-design'
import { ERROR_CODE } from './errorCode'
import * as Sentry from "@sentry/vue";

let { STOREID } = config
const homeUrls = [
  '/mallv2/facade/non_commodity/query_manage_data',
  '/mallv2/facade/index/select_special_ad_v3',
  '/mallv2/facade/index/select_home_recommend_v212',
  '/mallv2/facade/index/home_recommend/query_page_v212'
]

// 请求超时时间
axios.defaults.timeout = 15000;
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use((config) => {
  if (config.method === 'post') {
    //处理storeid
    if(config.data){
      config.data.storeId=config.data.storeId?config.data.storeId:STOREID;
      config.data.shopId=config.data.shopId?config.data.shopId:STOREID;
    }else{
      config.data={
        storeId:STOREID,
        shopId:STOREID,
      };
    }
    if(homeUrls.includes(config.url)) {
      config.data.storeId = '-' + config.data.storeId;
      config.data.shopId = '-' + config.data.shopId;
    }
  }
  if (config.method === 'get') {
    //处理storeid
    if(config.params){
      config.params.storeId=config.params.storeId?config.params.storeId:STOREID;
      config.params.shopId=config.params.shopId?config.params.shopId:STOREID;
    }else{
      config.params={
        storeId:STOREID,
        shopId:STOREID,
      };
    }
    if(homeUrls.includes(config.url)) {
      config.params.storeId = '-' + config.params.storeId;
      config.data.shopId = '-' + config.data.shopId;
    }
  }
  try {
    if(window.location.href.includes('mall')) {
      Sentry.addBreadcrumb({  
        category: 'xhr',  
        message: `入参数 HTTP ${config.method.toUpperCase()} to ${config.url}`,  
        data: { 
          data: JSON.stringify(config.data)
        },  
        level: 'info',  
      }); 
    } 
  } catch (error) {
    console.log(error)
  }
  config.headers.Authorization = store.state.userInfo.token || '';

  return {
    ...config,
    headers: {
      ...config.headers,
      'mobile-login-token': store.state.userInfo.token || '',
      'x-flag': 'pc',
      'version': '3.0.0',
      'platform-flag': 'mall'
    }
  }
}, (error) => {
  return Promise.reject(error)
})

// 响应拦截
axios.interceptors.response.use((res) => {
  try {
    if(window.location.href.includes('mall')) {
      Sentry.addBreadcrumb({  
        category: 'xhr',  
        message: `出参数 HTTP ${res.config.method.toUpperCase()} to ${res.config.url}`,  
        data: {  
          data: JSON.stringify(res.data)
        },  
        level: 'info',  
      });
    } 
  } catch (error) {
    console.log(error)
  }
  if (res.data.result === 0) {
    return Promise.resolve(res.data);
  } else if(res.data.result === 35000){
    // token失效，重新登录
    ViewUI.Message.info(res.data.message);
    store.commit('SET_USER_INFO', {});
    store.commit('SET_USER_ADDRESS', {});
    store.commit('CLEAR_CACHE_LIST');
    router.push({name: 'Login'});
  } else if (res.data.result === 80009) {
    router.replace({name: 'closeShopPage'});
    return
  } else {
    // 特殊处理状态码（各个页面根据返回内容自行处理）
    if (!ERROR_CODE.includes(res.data.result)) {
      // 正常统一取后台错误提示
      ViewUI.Message.error(res.data.message);
    }

    return Promise.resolve(res.data);
  }
}, (error) => {
  errMsg(error)
  try {
    if(window.location.href.includes('mall')) {
      Sentry.withScope((scope) => {
        scope.setExtra('response', {  
          statusCode: error.response.status,  
          headers: error.response.headers,
          body: error.response.data   
        });
        Sentry.captureException(error); 
      })
    } 
  } catch (error) {
    console.log(error)
  }
  if(error.response.data&&error.response.data.result === 35000) {
    store.commit('SET_USER_INFO', {});
    store.commit('SET_USER_ADDRESS', {});
    store.commit('CLEAR_CACHE_LIST');
    router.push({name: 'Login'});
  } else {
    return Promise.reject(error)
  }
})

/*
 * get方法，对应get请求
 * @param{String}url[请求的url地址]
 * @param{Object}params[请求时携带的参数]
 */
export function get (url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
  })
}

/*
 *post方法，对应post请求
 * @param{String}url[请求的url地址]
 * @param{Object}params[请求时候携带的参数]
 */
export function post (url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

function errMsg (error) {
  switch (error.response.status) {
    case 401:
      ViewUI.Message.error('请重新登录');
      break;
    case 404:
      ViewUI.Message.error('请求页面不存在');
      break;
    case 500:
      ViewUI.Message.error('服务器开了一会儿小差，请稍后再试');
      break;
    default: ViewUI.Message.error('未知错误');
      break;
  }
}