<template>
  <div class="goods-list">
    <!-- 全部筛选 -->
    <div :style="{marginBottom: isHasSelectAttr ?  '20px' : '0'}">
      <!--  全部分类入口  -->
      <div class="category-container">
        <div class="category-all">全部商品分类</div>
        <div ref="category" v-if="categoryMainList.length" @mouseleave="categoryId=''" class="category-list">
          <Tooltip ref="tooltip" :disabled="disabled" @on-popper-hide="categoryId=''" placement="right-start" theme="light">
            <template v-for="(item, index) in categoryMainList">
              <!-- 按照UI只能显示13个分类，由于用overflow会限制content的显示和不满13个分类时，移入空白处也会触发弹窗显示，故综合考虑使用js控制个数来实现超出不显示功能 -->
              <div @mouseenter="handleCategoryShow(item.categoryId)" v-if="index < 13" :key="index" :class="['title', item.categoryId === categoryId?'title-active':'']">
                <span>{{item.name}}</span>
                <img v-if="item.categoryId === categoryId" src="../assets/imgs/arrow-right-red.png" alt="">
                <img v-else src="../assets/imgs/arrow-right.png" alt="">
              </div>
            </template>
            <template slot="content">
              <div v-for="(childItem, childIndex) in categorySubList" :key="childIndex" class="content">
                <div class="sub-title">
                  <span>{{childItem.categoryName}}</span>
                  <img src="../assets/imgs/arrow-right-grey.png" alt="">
                </div>
                <div class="sub-content">
                  <div class="sub-wrap">
                    <div @click="goSearch(cItem)" v-for="(cItem, cIndex) in childItem.subSection" :key="cIndex">{{cItem.categoryName}}</div>
                  </div>
                </div>
              </div>
            </template>
          </Tooltip>
        </div>
      </div>
     
      <!--  面包屑内容  -->
      <div class="navigation">
        <div class="nav-navigation-main">全部结果</div>
        <div class="right-tags" v-show="selectedFirstCategoryVOList.length">&gt;</div>
        <!--  1.1一级分类 -->
        <div class="nav-filterCategory" v-for="(item, index) in selectedFirstCategoryVOList" :key="index">
          <div class="nav-category-name">
            产品类型：
          </div>
          <div class="nav-category-content">
            <span :title="item.firstCategoryName">{{ item.firstCategoryName }}</span>
            <div class="nav-category-delete" @click="deleteFirstCategory(item)"> x </div>
          </div>
        </div>
        <div class="right-tags">&gt;</div>
        <!--   1.2 二级分类、三级分类    -->

        <div  class="nav-secondFilterCategory" v-for="(item, index) in selectedSecondCategoryVOList" :key="index">
          <div class="nav-filterCategory" v-for="(item2, index2) in item.threeCategoryList" :key="index2">
            <div class="nav-category-name">
              <span :title="item.secondCategoryName">{{ item.secondCategoryName }}：</span>
            </div>
            <div class="nav-category-content">
              <span :title="item2.shopThreeName">{{ item2.shopThreeName }}</span>
              <div class="nav-category-delete" @click="deleteOtherCategory(item, item2)"> x </div>
            </div>
          </div>
          <div class="right-tags">&gt;</div>
        </div>
        <!--   1.3  属性词  -->

        <div  class="nav-secondFilterCategory" v-for="(item, index) in selectedAttributeVOList" :key="index">
          <div class="nav-filterCategory" v-for="(item2, index2) in item.attributeValueList" :key="index2">
            <div class="nav-category-name">
              <span :title="item.attributeKeyName">{{ item.attributeKeyName }}：</span>
            </div>
            <div class="nav-category-content">
              <span :title="item2.valueName">{{ item2.valueName }}</span>
              <div class="nav-category-delete" @click="deleteAttr(item, item2)"> x </div>
            </div>
          </div>
          <div class="right-tags">></div>
        </div>
        <!--   1.4 关键词     -->
        <div class="nav-navigation-words">{{ `“${curKeyWords}”`}}</div>
        <div v-if="isSHowClearBtn" @click="handleClear" class="nav-navigation-clear">清空</div>
      </div>

      <div class="category-attr-container">
        <!--  分类内容  -->
        <CheckboxGroup v-model="categoryFirstCheckList">
          <!--   1、一级分类   -->
          <div :class="{categoryContainer: true, multiple: multipleIdList.includes(1)}" v-if="selectedFirstCategoryVOList.length === 0 && categoryVOList.length > 1">
            <div class="category">
              <div class="category-title">产品类型:</div>
              <div class="category-content">
                <div class="category-item" v-for="(item, index) in categoryVOList" :key="index" >
                  <Checkbox v-if="multipleIdList.includes(1)" class="checkbox-item" :label="item.firstCategoryCid">
                    <div class="category-item-title">
                      <span :title="item.firstCategoryName">{{ item.firstCategoryName }}</span>
                  </div>
                  </Checkbox>
                  <div v-else class="category-item-title" @click.stop="firstCategoryAction([item], multipleIdList.includes(1))">
                      <span :title="item.firstCategoryName">{{ item.firstCategoryName }}</span>
                  </div>
                </div>
              </div>
              <div class="category-buttons" v-if="!multipleIdList.includes(1)">
                  <!-- 更多、多选按钮 -->
                  <div class="category-more" v-if="categoryVOList.length > 6" @click="handleMore">更多</div>
                  <!-- 一级分类是否支持多选，单独用id为1点来标识 -->
                  <div class="category-multiple" v-if="categoryVOList.length >= 3" @click="(e) => canBeMultiple(1, e)">多选</div>
              </div>          
            </div>
            <div class="confirm-btn" v-if="multipleIdList.includes(1)">
              <div class="cancel" @click="(e) => handleCancel(1, e)">取消</div>
              <div :class="{confirm: true, disabled: !categoryFirstCheckList.length}" @click="(e) => handleConfirmFirst(categoryVOList, e)">确认</div>
            </div>
          </div>
        </CheckboxGroup>
        <CheckboxGroup v-model="categorySecondCheckList">
          <!--   2、二级分类和三级分类   -->
          <div v-for="(secondItem, secondIndex) in secondCategoryVOList" :key="`secondIndex${secondIndex}`">
                <!--   2.1 判断是否已经选了这个二级分类     -->
              <div :class="{categoryContainer: true, multiple: multipleIdList.includes(secondItem.secondCategoryCid)}"  v-if="!selectedSecondCategoryVOList.find((item) => secondItem.secondCategoryCid === item.secondCategoryCid) && secondItem.threeCategoryList.length > 1">
                <div class="category" >
                <div class="category-title">{{ secondItem.secondCategoryName }}:</div>
                <div class="category-content">
                  <div class="category-item" v-for="(thirdItem, thirdItemIndex) in secondItem.threeCategoryList" :key="thirdItemIndex">
                    <Checkbox v-if="multipleIdList.includes(secondItem.secondCategoryCid)" class="checkbox-item" :label="thirdItem.shopThreeCid">
                      <div class="category-item-title">
                        <span :title="thirdItem.shopThreeName">{{ thirdItem.shopThreeName }}</span>
                      </div>
                    </Checkbox>
                    <div v-else class="category-item-title" @click="thirdCategoryAction(secondItem, [thirdItem], multipleIdList.includes(secondItem.secondCategoryCid))">
                      <span :title="thirdItem.shopThreeName">{{ thirdItem.shopThreeName }}</span>
                    </div>
                  </div>
                </div>
                <div class="category-buttons" v-if="secondItem.threeCategoryList.length >= 3 && !multipleIdList.includes(secondItem.secondCategoryCid)">
                  <!-- 更多、多选按钮 -->
                  <div class="category-more" v-if="secondItem.threeCategoryList.length > 6" @click="handleMore">更多</div>
                  <!-- 二级分类是否支持多选-->
                  <div class="category-multiple" v-if="secondItem.threeCategoryList.length >= 3" @click="(e) => canBeMultiple(secondItem.secondCategoryCid,e)">多选</div>
                </div>
              </div>
              <div class="confirm-btn" v-if="multipleIdList.includes(secondItem.secondCategoryCid)">
                <div class="cancel" @click="(e) => handleCancel(secondItem.secondCategoryCid, e)">取消</div>
                <div :class="{confirm: true, disabled: !secondItem.threeCategoryList.some((val) => categorySecondCheckList.includes(val.shopThreeCid))}" @click="(e) => handleConfirmSecond(secondItem, secondItem.threeCategoryList, e)">确认</div>
              </div>
            </div>
          </div>
        </CheckboxGroup>
        <!--  属性内容  -->
        <CheckboxGroup v-model="filterCheckList">
          <div v-if="attributeVOFilterList.length > 0 && isShowFilterContainer()" class="categoryContainer filter">
            <div class="category" >
              <div class="category-title">高级筛选:</div>
              <div class="category-content">
                <div class="category-item" v-for="(item, index) in attributeVOFilterList" :key="index">
                  <div class="category-item-title"  @click="(e) => handleFilter(item.attributeKeyId, e)">
                    <span :title="item.attributeKeyName">{{ item.attributeKeyName}}</span>
                    <Icon class="filter-icon" :type="`ios-arrow-${openAttrKey === item.attributeKeyId ? 'up' : 'down'}`" color="#999" size="18"/>
                  </div>
                </div>
              </div>
              <div class="category-buttons" v-if="attributeVOFilterList.length > 6 && !multipleIdList.includes(-1)">
                  <!-- 更多、多选按钮 -->
                  <div class="category-more" v-if="attributeVOFilterList.length > 6" @click="handleMoreFilter">更多</div>
              </div>
            </div>
            <div class="filter-container" v-if="attributeValueData.list.length">
              <div class="category">
                <div class="category-content">
                  <div class="category-item" v-for="(item, index) in attributeValueData.list" :key="index">
                    <Checkbox v-if="attributeValueData.isMultiple" class="checkbox-item" :label="item.valueId">
                      <div class="category-item-title">
                        <span :title="item.valueName">{{ item.valueName}}</span>
                      </div>
                  </Checkbox>
                    <div v-else class="category-item-title" @click="(e) => filterAction([item], e)">
                      <span :title="item.valueName">{{ item.valueName}}</span>
                    </div>
                  </div>
                </div>
                <div class="category-buttons" v-if="attributeValueData.list.length >= 3 && !attributeValueData.isMultiple">
                  <!-- 二级分类是否支持多选-->
                  <div class="category-multiple" v-if="attributeValueData.list.length >= 3" @click="(e) => canBeFilterMultiple(e)">多选</div>
                </div>
              </div>
              <div class="confirm-btn" v-if="attributeValueData.isMultiple">
                <div class="cancel" @click="(e) => handleCancelOpenKey(attributeValueData.attributeKeyId, e)">取消</div>
                <div :class="{confirm: true, disabled: !attributeValueData.list.some((val) => filterCheckList.includes(val.valueId))}" @click="(e) => handleConfirmFilter(e)">确认</div>
              </div>
            </div>
          </div>
        </CheckboxGroup>
      </div>

    </div>
    <div class="goods">
      <!-- <div class="goods-sercah">为您找到<span style="color: #cc0000;">{{ total }}</span>条结果</div> -->
      <ul>
        <li
          @click="goGoodDetail(item)"
          class="goods-item"
          v-for="(item, index) in productList"
          :key="index"
        >
          <div class="goods-img">
            <img  :src="$fillImgPath(item.thumbUrl)" alt="" />
          </div>
          <div class="goods-price" v-if="item.marketPrice">
            <div class="cur-price">
              <span class="price-unit">￥</span>
              <span>{{ item.marketPrice }}</span>
            </div>
            <!-- 划线价 -->
            <div class="line-price" v-if="parseFloat(item.originalPrice) > parseFloat(item.marketPrice)">
              <span>￥{{ item.originalPrice }}</span>
            </div>
          </div>
          <div class="goods-describe">{{ item.productName }}</div>
          <div class="goods-tab">
            <div>
              <span v-if="item.selfRunFlag == 20" class="self-operated" style="background: #EE5153">自营</span>
              <template v-for="(itemTab, index) in item.activityLabels" >
                <span :key="index" v-if="itemTab == '新品'" style="background: #1480e3">{{
                  itemTab
                }}</span>
                <span :key="index" v-else-if="itemTab == '秒杀' || itemTab.startsWith('直降')" style="background: #cc0000">{{
                  itemTab
                }}</span>
                <span :key="index" v-else-if="!['满减','拼团','预售','以旧换新'].includes(itemTab)" style="background: #813DEE">{{
                  itemTab
                }}</span>
                
              </template>
            </div>
            <div v-if="item.isShowLabel" class="compare-btn" @click.stop="handleCompare(item)">
              <i :class="{ active: isCompare(item) }"></i>
              对比
            </div>
          </div>
        </li>
      </ul>
      <div v-if="requestFlag&&productList.length <= 0" class="nosearch">
        <img src="@/assets/imgs/search@2x.png" alt="" />
        <div class="noSearchDesc">抱歉，没有找到商品~</div>
        <div class="noSearchDesc">换个关键词吧</div>
      </div>
       <!-- 对比栏 -->
      <ComparisonColumn />
    </div>
    
    <!-- <Page
      prev-text="上一页"
      @on-change="handleChange"
      next-text="下一页"
    /> -->
    <div class="page-wrap">
      <div class="page-common-btn" @click="handleChange(1)" :class="{'page-btn-disable': pageIndex <= 1}">上一页</div>
      <div class="page-common-btn" @click="handleChange(2)" :class="{'page-btn-disable': !hasNextPage}">下一页</div>
      <div class="page-common-btn" @click="handleChange(3)" :class="{'page-btn-active': pageIndex === 1}">第一页</div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import {
  getKeywordSearchList,
  getSlogansListWordByName,
  getClassifySearchList,
  getAssociatedSearch,
  getHomeCategoryMainList,
  getHomeCategorySubList,
  getFilterSearch
} from "@/api/api";
import config from '@/config'
import ComparisonColumn from '@/components/ComparisonColumn.vue'; 
export default {
  name: "GoodsList",
  components: {
    ComparisonColumn
  },
  data() {
    return {
      categoryMainList: [],
      categorySubList: [],
      categorySecondCheckList: [],
      categoryFirstCheckList: [],
      filterCheckList: [],
      attributeValueData: {
        attributeKeyId: '',
        isMultiple: false,
        list: []
      },
      openAttrKey: '',
      categoryId: '',
      disabled: false,
      categoryFactory: {},
      productList: [],
      page: {
        current: 1,
        size: 20,
        total: 0,
      },
      pageSize: 20,
      total: 0,
      pageIndex: 1,
      thirdCid: '',
      searchMode: 0,
      hasNextPage: true,
      requestFlag: false,
      curKeyWords: this.$route.query.keyword || '',
      // 3.7.3添加筛选搜索功能
      categoryVOList:[],// 用来存储根据关键字查找到的所有分类的数据
      secondCategoryVOList:[],// 用来存储二级分类的数据
      attributeVOList:[],// 用来存储属性值
      // 用来存储已选的一级分类
      selectedFirstCategoryVOList:[],
      // 用来存储已选的二级、三级分类
      selectedSecondCategoryVOList:[],
      // 用来存储属性值
      selectedAttributeVOList:[],
      // 用来存储是否是可以支持多选的id（只存储一级分类和二级分类的id即可，一级分类用1标识）
      multipleIdList:[],
      // ||||
    };
  },
  // 监听selectedFirstCategoryVOList和selectedSecondCategoryVOList的变化，重新获取新数据
  watch: {
    selectedFirstCategoryVOList: {
      handler: function (newVal, oldVal) {
        this.categoryFirstCheckList = newVal.map((item) => item.firstCategoryCid)
        // 重新获取商品数据
        if (newVal.length === 0 && this.selectedSecondCategoryVOList.length === 0 && this.selectedAttributeVOList.length === 0) {
          this.queryList()
        } else {
          this.getFilterSearchFn()
        }
        // 重新获取筛选后的属性数据
        this.getAssociatedSearchFn()
      },
      deep: true
    },
    selectedSecondCategoryVOList: {
      handler: function (newVal, oldVal) {
        this.categorySecondCheckList = newVal.reduce((res, cur) => {
          if (cur?.threeCategoryList?.length) {
            res.concat(cur.threeCategoryList.map((val => val.shopThreeCid)))
          }
          return res
        }, [])
        // 只要有搜索结果发生变化那么重新请求接口
        // 重新获取商品数据
        if (newVal.length === 0 && this.selectedFirstCategoryVOList.length === 0 && this.selectedAttributeVOList.length === 0) {
          this.queryList()
        } else {
          this.getFilterSearchFn()
        }
        // 重新获取筛选后的属性数据
        this.getAssociatedSearchFn()
      },
      deep: true
    },
    // 高级筛选属性值变化
    selectedAttributeVOList: {
      handler: function (newVal, oldVal) {
        this.filterCheckList = newVal.reduce((res, cur) => {
          if (cur?.attributeValueList?.length) {
            res.concat(cur.attributeValueList.map((val => val.valueId)))
          }
          return res
        }, [])
        // 只要有搜索结果发生变化那么重新请求接口
        // 重新获取商品数据
        if (this.selectedSecondCategoryVOList.length === 0 && this.selectedFirstCategoryVOList.length === 0 && newVal.length === 0) {
          this.queryList()
        } else {
          this.getFilterSearchFn()
        }
        // 重新获取筛选后的属性数据
        this.getAssociatedSearchFn()
      },
      deep: true
    }
  },
  computed: {
    ...mapState(['cacheList', 'userInfo']),
    isHasSelectAttr() {
      return (this.categoryVOList.length || this.secondCategoryVOList.length || this.attributeVOFilterList.length)
    },
    // 过滤一个值后的高级筛选列表
    attributeVOFilterList() {
      // 过滤数量没有大于1的
      const list = this.attributeVOList.filter((item) => item.attributeValueList?.length > 1)
      
      return list.filter(item => this.selectedAttributeVOList.every(val => val.attributeKeyName !== item.attributeKeyName))
    }, 
    // 是否展示清空按钮
    isSHowClearBtn() {
      return this.selectedAttributeVOList.length || this.selectedFirstCategoryVOList.length || this.selectedSecondCategoryVOList.length
    },
    // 标签最多是3个
    displayActivityLabels: () => {
      return (item) => {
        let selfRunFlag = item.selfRunFlag == 20
        let maxCount = selfRunFlag ? 2 : 3
        if (item.activityLabels.length > maxCount) {
          return item.activityLabels.slice(0, maxCount)
        }
        return item.activityLabels
      };
    },
    isCompare() {
      return (item) => {
        item.isCompare = this.$store.state.comparisonColumn.goodsList?.find(el=> el.skuNo == item.skuId) || false
        return item.isCompare
      }
    }
  },
  async activated() {
    this.$emit("keyword", this.$route.query.keyword);
    const keyWord = (this.$route.query.keyword+'').trim()
    this.getCategoryMainData();
    // 如果是输入框占位的词条或空字符串，不需要调这个接口
    if (this.$route.query?.type == 'attr') {
      const cid = this.$route.query.platformCid
      cid? cid : this.curKeyWords = '空调' 
      this.getKeywordSearchListFn(1, cid)
      return
    }
    if(this.needSlogansList(keyWord)) {

      try {
  
        let res = await this.getSlogansList(keyWord)
        const {result, message, data: resData} = res
        if(result === 0){
          // 词条类型(10: 关键词 , 20:三级类目 , 30: 链接,40：秒杀专场，50：专题模板活动，60：直播频道)
          switch (resData.type) {
            case 10:
              if(resData.value) {
                this.curKeyWords = resData.value
              }
              
              this.queryList();
              break;
            case 20:
              this.thirdCid = resData.value
              this.queryList();
              break;
            case 30:
              this.thirdCid = ''
              //resData.value
              this.$router.back();
              window.location.href = resData.value.indexOf('http') === -1 ? `https://${resData.value}` : resData.value;
              break;
              
            case 40:
              this.thirdCid = ''
              // if(this.userInfo.token) {
              //   this.$router.push('/h5/transition?token=' + this.userInfo.token + '&page=seckillList&shopId=' +  config.STOREID)
              // } else {
                this.$router.push('/h5/seckillList?shopId=' + config.STOREID)
              // }
              break;
            case 50:
              this.thirdCid = ''
              // if(this.userInfo.token) {
              //   this.$router.push('/h5/transition?token=' + this.userInfo.token + '&page=themeActivities&id=' + resData.value + '&shopId=' +  config.STOREID)
              // } else {
                this.$router.push('/h5/themeActivities?id=' + resData.value + '&shopId=' + config.STOREID)
              // }
              break;
              
            default: // 跟10的情况一样
              if (this.$route?.query.searchType == 2) {
                this.thirdCid = this.$route?.query?.id;
              } else {
                this.thirdCid = ''
              }
              this.queryList();
              break;
          }
        }

      } catch (error) {
        
      }
    }
    this.getAssociatedSearchFn()
  },
  methods: {
    ...mapMutations('comparisonColumn', ['InsertGoodsList','DeleteGoodsList', 'SetVisibility', 'ToggleTab' ]),
    goSearch(item) {
      this.disabled = true;
      this.categoryId='';
      this.$router.push({
        name: 'GoodsList',
        query: {
          keyword: item.categoryName,
          id: item.categoryId,
          searchType: 2
        }
      });
    },
    filterThreeCategoryList(list) {
      let mergedData = {};
      list.forEach(item => {
          const name = item.shopThreeName;
          if (!mergedData[name]) {
              mergedData[name] = item;
          }
      });
      let result = Object.values(mergedData);
      return result
    },
    getCategoryMainData() {
      getHomeCategoryMainList().then((res) => {
        if (res.result === 0) {
          this.categoryMainList = res.data.tabs;
        } else {
        }
      }).catch((err) => {
      })
    },
    handleCategoryShow(id) {
      this.$refs.tooltip.handleShowPopper()
      this.disabled = false;
      this.categoryId = id;
      if(this.categoryFactory[id]){
        this.categorySubList = this.categoryFactory[id];
        return;
      }
      getHomeCategorySubList({
        categoryId: id 
      }).then((res) => {
        if (res.result === 0) {
          this.categorySubList = res.data.secondSection;
          this.categoryFactory[id] = res.data.secondSection;
        } else {
        }
      }).catch((err) => {
      })
    },
    // 是否展示筛选标签
    isShowFilterContainer() {
      const ids =  this.selectedAttributeVOList?.map((item) => item.attributeKeyId)
      const idArr = [...new Set(ids)]
      return idArr.length < this.attributeVOList.length
    },
    deleteFirstCategory(item) {
      // 从selectedFirstCategoryVOList里面删除分类
      // 删除掉这个一级分类
      let index = this.selectedFirstCategoryVOList.findIndex((item2) => {
        return item2.firstCategoryCid === item.firstCategoryCid
      })
      if(index > -1) {
        this.selectedFirstCategoryVOList.splice(index, 1)
      }
      // 如果这个一级分类里面的二级分类为空了，则也删除掉这个一级分类
      if(this.selectedFirstCategoryVOList.length <= 0) {
        this.selectedFirstCategoryVOList = []
      }
    },
    deleteOtherCategory(secondItem, thirdItem) {
      // 从selectedSecondCategoryVOList里面删除分类
      // 删除掉这个三级分类
      let index = secondItem.threeCategoryList.findIndex((item) => {
        return item.shopThreeCid === thirdItem.shopThreeCid
      })
      if(index > -1) {
        secondItem.threeCategoryList.splice(index, 1)
      }
      // 如果这个二级分类里面的三级分类为空了，则也删除掉这个二级分类
      if(secondItem.threeCategoryList.length <= 0) {
        let index2 = this.selectedSecondCategoryVOList.findIndex((item) => {
          return item.secondCategoryCid === secondItem.secondCategoryCid
        })
        let index3 = this.multipleIdList.findIndex((item) => {
          return item === secondItem.secondCategoryCid
        })
        if(index2 > -1) {
          this.selectedSecondCategoryVOList.splice(index2, 1)
          if (index3 > -1) {
            this.multipleIdList.splice(index3, 1)

          }
        }
      }
    },
    deleteAttr(attrItem) {
      let index = this.selectedAttributeVOList.findIndex((item2) => {
        return item2.attributeKeyId === attrItem.attributeKeyId
      })
      if(index > -1) {
        this.selectedAttributeVOList.splice(index, 1)
      }
      if(this.selectedAttributeVOList.length <= 0) {
        this.selectedAttributeVOList = []
      }
    },
    handleMore(e) {
      const currentNodeText = e.target?.innerText
      const categoryNode = e.target?.parentNode?.parentNode?.parentNode
      if (currentNodeText === '更多') {
        categoryNode.style.height = 'auto'
        e.target.innerText = '收起'
      } else {
        categoryNode.style.height = '52px'
        e.target.innerText = '更多'
      }
    },
    handleMoreFilter(e) {
      const currentNodeText = e.target?.innerText
      const categoryNode = e.target?.parentNode?.parentNode
      if (currentNodeText === '更多') {
        categoryNode.style.height = 'auto'
        e.target.innerText = '收起'
      } else {
        categoryNode.style.height = '52px'
        e.target.innerText = '更多'
      }
    },
    //  展开收起搜索
    handleFilter(id, e) {
      // 逻辑处理
      const currentData = this.attributeVOList.find(val => val?.attributeKeyId === id);
      // 样式展开收起
      if (e.target?.parentNode.querySelector('.ivu-icon-ios-arrow-down')) {
        this.openAttrKey = id;
        this.attributeValueData = {
          attributeKeyId: id,
          isMultiple: false,
          list: currentData?.attributeValueList || []
        }
      } else {
        this.attributeValueData = {
          isMultiple: false,
          attributeKeyId: '',
          list: []
        }
        this.openAttrKey = ''
      }
    },
    firstCategoryAction (firstCategory, isMultiple) {
      // 多选操作时跳出
      if (isMultiple) {
        return 
      }
      // 判断是否已经选中了一级分类
      if(this.selectedFirstCategoryVOList.length > 0) {
        // 判断是否已经选中了这个一级分类
        let index = this.selectedFirstCategoryVOList.findIndex((item) => {
          return firstCategory.some(val => val.firstCategoryCid === item.firstCategoryCid)
        })
        if(index > -1) {
          // 已经选中了这个一级分类，就删除掉
          this.selectedFirstCategoryVOList.splice(index, 1)
        } else {
          // 没有选中这个一级分类，就选中
          this.selectedFirstCategoryVOList = firstCategory
        }
      } else {
        console.log('出发了', firstCategory)

        // 没有选中一级分类，就选中
        this.selectedFirstCategoryVOList = firstCategory
      }
      console.log('this.selectedFirstCategoryVOList', this.selectedFirstCategoryVOList)
    },
    thirdCategoryAction(secondItem, thirdItem, isMultiple) {
      // 多选操作时跳出
      if (isMultiple) {
        return 
      }
      // 用selectedSecondCategoryVOList来存储secondItem和thirdItem
      // 判断是否已经选中了这个二级分类f
      let index = this.selectedSecondCategoryVOList.findIndex((item) => {
        return item.secondCategoryCid === secondItem.secondCategoryCid
      })
      if(index > -1) {
        // 已经选中了这个二级分类，判断这个二级分类是否支持多选
        if(this.multipleIdList.includes(secondItem.secondCategoryCid)) {
          // 支持多选，判断是否已经选中了这个三级分类
          let index2 = this.selectedSecondCategoryVOList[index].threeCategoryList.findIndex((item) => {
            return thirdItem.some(val => item.shopThreeCid === val.shopThreeCid)
          })
          if(index2 > -1) {
            // 已经选中了这个三级分类，就删除掉
            this.selectedSecondCategoryVOList[index].threeCategoryList.splice(index2, 1)
          } else {
            // 没有选中这个三级分类，就选中

            this.selectedSecondCategoryVOList[index].threeCategoryList = thirdItem
          }
          // 如果这个二级分类里面的三级分类为空了，则也删除掉这个二级分类
          if(this.selectedSecondCategoryVOList[index].threeCategoryList.length <= 0) {
            this.selectedSecondCategoryVOList.splice(index, 1)
          }
        } else {
          // 不支持多选，就删除掉这个二级分类，然后选中这个二级分类和三级分类
          this.selectedSecondCategoryVOList.splice(index, 1)
          const data = {
            'secondCategoryCid': secondItem.secondCategoryCid,
            'secondCategoryName': secondItem.secondCategoryName,
            'threeCategoryList': [{'shopThreeCid': thirdItem.shopThreeCid,'shopThreeName': thirdItem.shopThreeName}]
          }
          this.selectedSecondCategoryVOList.push(data)
        }

      } else {
        // 没有选中这个二级分类，就选中并且把这个三级分类也选中
        const data = {
          'secondCategoryCid': secondItem.secondCategoryCid,
          'secondCategoryName': secondItem.secondCategoryName,
          'threeCategoryList': thirdItem
        }
        this.selectedSecondCategoryVOList.push(data)
      }
      console.log('this.selectedSecondCategoryVOList', this.selectedSecondCategoryVOList)
    },
    filterAction(filterWords) {
      const _this = this;
      const selectAttributeItem = this.attributeVOList.find((item) => item.attributeKeyId === this.openAttrKey);
      const index =  this.selectedAttributeVOList.findIndex(item => item.attributeKeyId === selectAttributeItem.attributeKeyId)
      const list = filterWords.map((item) => {
        return {
          attributeKeyId: _this.openAttrKey,
          attributeKeyName: selectAttributeItem.attributeKeyName,
          attributeValueList: [{...item}]
        }
      })

      // 判断是否已经选中了这个属性
      if (index > -1) {
        this.selectedAttributeVOList.splice(index, 1)
      } else {
        // 没有这个属性则在这个基础上添加
        this.selectedAttributeVOList = this.selectedAttributeVOList.concat(list)
      }
      // 样式重置
      this.openAttrKey = ''
      this.attributeValueData = {
        attributeKeyId: '',
        list: []
      }
    },
    // 是否让这个一级/二级分类支持多选
    canBeMultiple(id, e) {
      const categoryNode = e.target?.parentNode?.parentNode?.parentNode
      // 如果已经存在可多选，则删除，否则添加
      let index = this.multipleIdList.findIndex((item) => {
        return item === id
      })
      if(index > -1) {
        this.multipleIdList = []
        categoryNode.style.height = '52px'
        categoryNode.className = 'categoryContainer'
      } else {
        this.multipleIdList.push(id)
        categoryNode.style.height = 'auto'
      }
    },
    canBeFilterMultiple(e) {
      const categoryNode = e.target?.parentNode?.parentNode?.parentNode
      // 如果已经存在可多选，则删除，否则添加
      let index = this.multipleIdList.findIndex((item) => {
        return item === id
      })
      if(this.attributeValueData.isMultiple) {
        this.attributeValueData.isMultiple = false
        categoryNode.style.height = '52px'
        categoryNode.className = 'categoryContainer'
      } else {
        this.attributeValueData.isMultiple = true
        categoryNode.style.height = 'auto'
      }
    },
    handleCancel(id, e) {
      const categoryNode = e.target?.parentNode?.parentNode
      // 如果已经存在可多选，则删除，否则添加
      let index = this.multipleIdList.findIndex((item) => {
        return item === id
      })
      if(index > -1) {
        this.multipleIdList.splice(index, 1)
        categoryNode.style.height = '52px'
        categoryNode.className = 'categoryContainer'
      } 
    },
    handleClear() {
      this.selectedAttributeVOList = [];
      this.selectedFirstCategoryVOList = [];
      this.selectedSecondCategoryVOList = [];
    },
    handleCancelOpenKey(id, e) {
      const categoryNode = e.target?.parentNode?.parentNode
      // 如果已经存在可多选，则删除，否则添加
      this.openAttrKey = ''
      this.attributeValueData = {
        attributeKeyId: '',
        isMultiple: false,
        list: []
      }
      categoryNode.style.height = '52px'
      categoryNode.className = 'categoryContainer'
    },
    handleConfirmFirst( list, e) {
      if (e.target.className.includes('disabled')) {
        return
      }
      const categoryNode = e.target?.parentNode?.parentNode
      const index = this.multipleIdList.findIndex(val => val === 1)
      const firstCategoryList = list.reduce((res, cur) => {
        if (this.categoryFirstCheckList.includes(cur.firstCategoryCid)) {
          res.push(cur)
        }
        return res
      }, [])
      // 样式重置
      categoryNode.style.height = '52px'
      categoryNode.className = 'categoryContainer'
      // const thirdItem = []
      this.multipleIdList.splice(index, 1)
      this.firstCategoryAction(firstCategoryList)
    },
    handleConfirmSecond(secondItem, list, e) {
      if (e.target.className.includes('disabled')) {
        return
      }
      const categoryNode = e.target?.parentNode?.parentNode
      const thirdList = list.reduce((res, cur) => {
        if (this.categorySecondCheckList.includes(cur.shopThreeCid)) {
          res.push(cur)
        }
        return res
      }, [])
      // 样式重置
      categoryNode.style.height = '52px'
      categoryNode.className = 'categoryContainer'
      // const thirdItem = []
      this.thirdCategoryAction(secondItem, thirdList)
    },
    handleConfirmFilter(e) {
      if (e.target.className.includes('disabled')) {
        return
      }
      const categoryNode = e.target?.parentNode?.parentNode
      const ids = this.attributeValueData.list.reduce((res, cur) => {
        if (this.filterCheckList.includes(cur.valueId)) {
          res.push(cur)
        }
        return res
      }, [])
      // 样式重置
      categoryNode.style.height = '52px'
      categoryNode.className = 'categoryContainer'
      // const thirdItem = []
      this.filterAction(ids)
    },
    getKeywordSearchListFn(pageIndex, platformCid) {
      getKeywordSearchList({
        keyWord: this.curKeyWords || this.$route.query.keyword || "",
        lastSortValues: [],
        pageIndex: pageIndex ? pageIndex : this.pageIndex,
        pageSize: this.pageSize,
        shopId: config.STOREID,
        sortKey: 10,
        sortValue: 10,
        searchMode: this.searchMode,
        platformCid // 平台三级类目Id
      }).then((res) => {
        const {result, message, data: resData} = res
        this.productList = resData.product.commonProductVOList;
        // this.total = resData.product.size
        this.pageIndex = resData.product.current
        if(resData.product.size < this.pageSize){
          this.hasNextPage = false
        } else {
          this.hasNextPage = true
        }
        this.searchMode = resData.searchMode
        this.requestFlag = true
      });
    },
    // 获取所有分类子项数据
    getAssociatedSearchFn() {
      const id = this.$route.query.id || ''
      // 1、获取一级分类的id
      let firstCids = []
      this.selectedFirstCategoryVOList.forEach((item) => {
        firstCids.push(item.firstCategoryCid)
      })
      // 2、获取三级分类的id
      let thirdCids = this.thirdCid ? [[this.thirdCid]] : []
      this.selectedSecondCategoryVOList.forEach((item) => {
        thirdCids.push(item.threeCategoryList.map((val) => val.shopThreeCid))
      })
       // 获取属性IDs
      const attributeListVOList = this.selectedAttributeVOList.map((item) => {
        return {
          attrId: item.attributeKeyId,
          children: item.attributeValueList
        }
      })
      let list = thirdCids.length ? [...thirdCids] : []
      if (firstCids.length) {
        list = list.concat([firstCids])
      }
      let params = {
        shopId: config.STOREID,
        keyWord: id ? '' : (this.curKeyWords || this.$route.query.keyword || ""),
        cids: list,
        attributeListVOList,
      }
      getAssociatedSearch(params).then((res) => {
        const {result, message, data: resData} = res
        this.categoryVOList = resData.categoryVOList
        this.attributeVOList = resData.attributeVOList
        this.secondCategoryVOList = []
        // 把categoryVOList里面的二级分类取出来，放到secondCategoryVOList里面
        this.categoryVOList.forEach((item) => {
          item.secondCategoryVOList.forEach((item2) => {
            this.secondCategoryVOList.push(item2)
          })
        })
        // 过滤同名称的二级类目相-组合
        this.secondCategoryVOList = this.secondCategoryVOList.reduce((acc, currentItem) => {
          const existingItem = acc.find(item => item.secondCategoryName === currentItem.secondCategoryName);

          if (existingItem) {
             existingItem.threeCategoryList = existingItem.threeCategoryList.concat(currentItem.threeCategoryList);
          } else {
            acc.push(currentItem);
          }
          return acc;
        }, []);

        this.multipleIdList = []
        this.openAttrKey = ''
        this.attributeValueData = {
          isMultiple: false,
          list: [],
          attributeKeyId: ''
        }
      })
    },
    // 获取筛选搜索的数据
    getFilterSearchFn(pageIndex) {
      // 1、获取一级分类的id
      let firstCids = []
      this.selectedFirstCategoryVOList.forEach((item) => {
        firstCids.push(item.firstCategoryCid)
      })
      // 2、获取三级分类的id
      let thirdCids = this.thirdCid ? [[this.thirdCid]] : []
      this.selectedSecondCategoryVOList.forEach((item) => {
        thirdCids.push(item.threeCategoryList.map((val) => val.shopThreeCid))
      })
      // 获取属性IDs
      const attributeValueList = this.selectedAttributeVOList.map((item) => {
        return {
          attrId: item.attributeKeyId,
          children: item.attributeValueList
        }
      })
      let list = thirdCids.length ? [...thirdCids] : []
      if (firstCids.length) {
        list = list.concat([firstCids])
      }
      let filterParams = {
        'shopId': config.STOREID,
        'keyWord': this.curKeyWords || this.$route.query.keyword || "",
        'cids': list,
        'attributeListVOList': attributeValueList,
        'pageIndex': pageIndex,
        'pageSize': this.pageSize,
      }
      getFilterSearch(filterParams).then((res) => {
        const {result, message, data: resData} = res
        if(result === 0) {
          this.productList = resData.product.commonProductVOList
          // this.total = resData.product.size
          this.pageIndex = resData.product.current
          if(resData.product.size < this.pageSize){
            this.hasNextPage = false
          } else {
            this.hasNextPage = true
          }
        }
        this.requestFlag = true
      })
    },
    // TODO:优惠券搜索
    // 商品搜索
    async queryList(pageIndex) {
      if(!this.thirdCid){
        this.getKeywordSearchListFn(pageIndex)
      } else {
        try {
          let res = await this.getClassifyList()
          const {result, message, data: resData} = res
          if(result === 0) {
            this.productList = resData.product.commonProductVOList
            // this.total = resData.product.size
            this.pageIndex = resData.product.current
            if((this.pageIndex * this.pageSize) < resData.product.total ){
              this.hasNextPage = true  // 可点击
            } else {
              this.hasNextPage = false // 不可点击
            }
          }
          this.requestFlag = true
        } catch (error) {
          console.log('getClassifyList error', error)
          this.requestFlag = true
        }
      }
    },
    handleChange(type) {
      switch (type) {
        case 1: // prev
          if(this.pageIndex <= 1){
            return;
          }
          this.pageIndex--;

          break;
        case 2: // next
          if(!this.hasNextPage){
            return;
          }
          this.pageIndex++;
          break;
        case 3: // page one
          this.pageIndex = 1
          break;
        default:
          this.pageIndex = 1
          break;
      }
      window.scrollTo(0,0)
      if (this.selectedFirstCategoryVOList.length === 0 && this.selectedSecondCategoryVOList.length === 0 && this.selectedAttributeVOList.length === 0) {
        this.queryList(this.pageIndex)
      } else {
        this.getFilterSearchFn(this.pageIndex)
      }
    },
    // 跳转商详页面
    goGoodDetail(item) {
      const route = {
        name: "GoodsDetail",
        query: {
          productNo: item.productId,
          skuNo: item.skuId,
          shopId: item.shopId,
        },
      };
      if (this.$route.name === "GoodsDetail") this.$router.replace(route);
      else this.$router.push(route);
    },
    // 搜索之前要查询下关键词类型
    getSlogansList(keyWord) {
      return getSlogansListWordByName({
        name: keyWord || '',
        shopId: config.STOREID
      })
    },
    // 按分类进行搜索
    getClassifyList(pageIndex, thirdCid) {
      return getClassifySearchList({
        lastSortValues: [],
        pageIndex: pageIndex ? pageIndex : this.pageIndex,
        pageSize: this.pageSize,
        shopId: config.STOREID,
        sortKey: 10,
        sortValue: 10,
        thirdCid: thirdCid || this.thirdCid
      })
    },
    needSlogansList(keyWord) {
      if(keyWord === '') {
        return false
      }
      return true
    },
    handleCompare(item) {
      const goods = {
        productNo: item.productId,
        skuNo: item.skuId,
        status: item.skuState,
        shopId: item.shopId,
        price: item.marketPrice,
        thumbUrl: item.thumbUrl,
        name: item.productName,
        thirdPlatformCid: item.thirdPlatformCid
      }

      // 显示对比栏
      if (!this.$store.state.comparisonColumn.visibility && item.isCompare) {
        this.SetVisibility(true)
        return
      }
      item.isCompare = !item.isCompare
      if (item.isCompare) {
        this.SetVisibility(true)
        this.InsertGoodsList({ goods, item })
        this.ToggleTab(0);
      }
      if (!item.isCompare) {
        this.DeleteGoodsList(goods)
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$emit("keyword", "");
    next();
  },
};
</script>

<style lang="less" scoped>
.goods-list {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  padding-top: 56px;
  padding-bottom: 5px;
  background: #f2f2f2;
  .category-attr-container {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .category-container {
    position: relative;
    width: 175px;
    .category-all {
      font-size: 12px;
      // width: calc(100% - 1025px);
      height: 26px;
      line-height: 26px;
      text-align: center;
      color: #fff;
      opacity: 1;
      background: #cc0000;
    }
    &:hover {
      .category-list {
        display: block;
      }
    }
    .category-list {
      display: none;
      position: absolute;
      margin: 0 auto;
      width: 175px;
      height: 450px;
      padding: 4px 0;
      background: rgba(51,51,51,1);
      z-index: 100;
      .title {
        padding: 10px 23px 10px 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 14px;
          color: white;
        }
        img {
          width: 13px;
          height: 13px;
        }
      }
      .title-active {
        // background-color: white;
        span {
          color: #CC0000;
        }
      }
      .content {
        padding-top: 16px;
        display: flex;
        align-items: flex-start;
        .sub-title {
          width: 100px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 1;
          font-size: 16px;
          color: rgba(51,51,51,0.80);
          img {
            width: 13px;
            height: 13px;
          }
        }
        .sub-content {
          margin-left: 56px;
          width: 344px;
          border-bottom: 1px solid rgba(51,51,51,0.20);
          .sub-wrap {
            margin-left: -32px;
            display: flex;
            flex-wrap: wrap;
            div {
              margin-left: 32px;
              margin-bottom: 16px;
              font-size: 14px;
              color: rgba(51,51,51,0.80);
              cursor: pointer;
            }
            div:hover {
              color: #CC0000;
            }
          }
        }
      }
    }
  }

  .navigation {
    display: flex;
    flex-wrap: wrap;
    background: #f2f2f2;
    width: 1200px;
    min-height: 50px;
    margin: 0 auto;
    padding: 16px 0;
    font-size: 12px;
    text-align: center;
    
    .right-tags,
    .nav-navigation-main {
      font-family: FZLanTingHeiS-L-GB, FZLanTingHeiS-L-GB-Regular;
      display: flex;
      height: 22px;
      line-height: 22px;
      margin-right: 6px;
      margin-left: 6px;
      color: #999;
    }
    .right-tags {
      line-height: 19px;
    }
    .nav-navigation-words {
      font-family: FZLanTingHeiS-L-GB, FZLanTingHeiS-L-GB-Regular;
      display: flex;
      height: 22px;
      line-height: 22px;
      margin-right: 6px;
      margin-left: 6px;
      color: #333;
    }
    .nav-navigation-clear {
      width: 40px;
      height: 22px;
      line-height: 22px;
      color: #cc0000;
      border: 1px solid #cc0000;
      border-radius: 5px;
      margin-left: 20px;
      cursor: pointer;
    }

    .nav-secondFilterCategory {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
    }
    .nav-filterCategory{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;
      margin-left: 6px;
      height: 22px;
      padding: 4px 6px;
      line-height: 22px;
      background: #ffffff;
      border: 1pt solid #cccccc;
      border-radius: 3px;
    }

    .nav-category-name,
    .nav-category-content {
      align-items: center;
      display: flex;
      color: #333333;
      
    }

    .nav-category-content {
      color: #CC0000;
      font-weight: 500;
      > span {
        max-width: 110px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .nav-category-delete {
      margin-left: 4px;
      margin-bottom: 2px;
      cursor: pointer;
    }
  }

  // 分类内容样式
  .categoryContainer {
    width: 1200px;
    margin: 0 auto;
    font-size: 12px;
    background: #fff;
    height: 52px;
    overflow: hidden;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #cccccc;
    &.filter {
      height: auto;
      .category {
        height: 52px;
        overflow: hidden;
      }
    }
    .filter-container {
      width: 1198px;
      margin: 0 auto;
      background: #fff8f8;
      border: 1px solid #cc0000;    
    }
    .confirm-btn {
      display: flex;
      margin: 0 auto;
      width: 132px;
      margin-bottom: 14px;
      > div {
        width: 60px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        border-radius: 5px;
        cursor: pointer;
      }
      .cancel {
        border: 1px solid #cccccc;
      }
      .confirm {
        background: #cc0000;
        margin-left: 12px;
        color: #fff;
        &.disabled {
          background: #cccccc;
          cursor: not-allowed;
        }
      }
    }
    &.multiple {
      background: rgba(204,0,0,0.05);
        border: 1px solid #cc0000;
    }
  }
  .category {
    
    // 水平展示
    display: flex;
    
  
    .category-title {
      font-size: 12px;
      flex: 0 0 100px;
      padding: 19px 0 19px 16px;
      width: 132px;
      text-align: LEFT;
      color: #333333;
      border-right: 1px solid #cccccc;
    }
    .category-content {
      display: flex;
      flex: auto;
      flex-wrap: wrap;
      margin-top: 6px;
      padding-bottom: 6px;
      // 从top开始
      align-items: flex-start;
      .category-item {
        display: flex;
        align-items: center;
        .checkbox-item {
          margin-left: 27px;
          display: flex;
          align-items: center;
          &:nth-child(6n + 1) {
            margin-left: 10px;
          }
        }
        .category-item-title {
          position: relative;
          display: flex;
          width: 130px;
          padding: 0 5px;
          height: 28px;
          line-height: 28px;
          opacity: 1;
          border: 1px solid #cccccc;
          border-radius: 5px;
          margin: 6px 6px;
          color: #333;
          cursor: pointer;
          text-align: center;
          > span {
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .filter-icon {
            position: absolute;
            right: 2px;
            top: 5px;
          }
        }
      }
    }
    .category-buttons {
      display: flex;
      flex-direction: row;
      width: 135px;
      margin-top: 14px;
      margin-right: 20px;
      justify-content: end;
      .category-more,.category-multiple {
        font-size: 12px;
        text-align: center;
        width: 60px;
        height: 24px;
        line-height: 24px;
        border: 1px solid #cccccc;
        border-radius: 5px;
        cursor: pointer;
        
        &:hover {
          color: #cc0000;
          border: 1px solid #cc0000;
        }
      }
      .category-multiple {
        margin-left: 12px;
      }
    }
  }
  .goods {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .nosearch {
      text-align: center;
      img {
        width: 350px;
        height: 350px;
      }
      .noSearchDesc {
        text-align: center;
        font-size: 18px;
        line-height: 30px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #999999;
      }
    }
    // background: pink;
    .goods-sercah {
      margin-bottom: 16px;
      font-size: 16px;
      font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
    }
    li:nth-of-type(4n) {
      margin-right: 0px !important;
    }
    .goods-item {
      width: 288px;
      height: 410px;
      display: block;
      float: left;
      background: #fff;
      margin: 0 16px 16px 0;
      cursor: pointer;
      border: 2px solid #efefef;
      transition: all 1s;
      .goods-img {
        width: 100%;
        height: 288px;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
          transition: all 1s;
        }
        &:hover img {
          transform: scale(1.05);
        }
      }
      .goods-price {
        display: flex;
        align-items: flex-end;
        margin-top: 8px;
        margin-left: 8px;
        text-align: LEFT;
        .cur-price {
          font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
          font-weight: 400;
          color: #cc0000;
          font-size: 20px;
          .price-unit {
            font-family: 微软雅黑;
            font-size: 14px;
            font-weight: 600;
          }
        }
        .line-price {
          margin-left: 8px;
          text-decoration: line-through;
          font-size: 10px;
          color: #999999;
        }
      }
      .goods-describe {
        height: 40px;
        margin: 8px 8px;
        font-size: 14px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: pre-line;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .goods-tab {
        display: flex;
        justify-content: space-between;
        margin: 0 8px;
        
        div:first-child {
          display: flex;
          flex-wrap: nowrap;
          width: 80%;
          height: 20px;
          overflow: hidden;
        }
        span {
          margin-right: 8px;
          border-radius: 4px;
          font-size: 12px;
          text-align: LEFT;
          color: #ffffff;
          padding: 4px 12px;
          flex-shrink: 0;
        }
        .compare-btn {
          display: flex;
          align-items: center;
          i {
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-right: 4px;
            border: 1px solid #ccc;
            border-radius: 50%;
            &.active {
              border: none;
              background-image: url('../assets/imgs/detail/fuwu.png');
              background-size: 100% 100%;
            }
          }
          font-size: 12px;
          color:#666;
        }
      }
      &:hover {
        border: 2px solid red;

      }
    }
  }
  .page-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    .page-common-btn{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 34px;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      color: #999999;
      line-height: 16px;
      border: 1px solid rgba(153,153,153,0.20);
      cursor: pointer;
    }
    .page-common-btn:not(.page-btn-active,.page-btn-disable):hover {
      color: cornflowerblue;
      border-color: cornflowerblue;
    }
    .page-btn-active {
      border-color: #CC0000;
      background-color: #CC0000;
      color: #ffffff;
    }
    .page-btn-disable {
      background: rgba(153,153,153,0.20);
      color: rgba(153,153,153,0.20);
      cursor: not-allowed;
    }
  }
}
/deep/ .ivu-page {
  margin-top: 10px;
  text-align: center;
}
/deep/ .ivu-page-prev {
  margin-right: 20px;
  padding: 0 10px;
}
/deep/ .ivu-page-next {
  margin-left: 20px;
  padding: 0 10px;
}
/deep/ .ivu-page-item-active {
    border-color: #CC0000;
    background-color: #CC0000;
}
/deep/ .ivu-page-item-active a {
    color: #fff;
}
</style>
<style lang="less">
.category {
  .ivu-checkbox-checked .ivu-checkbox-inner {
      border-color: #cc0000;
      background-color: #cc0000;
    }
  .ivu-checkbox-checked:hover .ivu-checkbox-inner  {
    border-color: #cc0000;
  }
}
 .category-list {
    .ivu-tooltip {
      width: 100%;
      .ivu-tooltip-rel {
        width: 100%;
      }
      .ivu-tooltip-light.ivu-tooltip-popper {
        padding-left: 0;
        .ivu-tooltip-arrow {
          display: none;
        }
        .ivu-tooltip-inner {
          max-width: none;
          background-color: #eeeeee;
          min-height: 450px;
          padding: 32px 102px 32px 48px;
          border-radius: 0;
        }
      }
    }
  }
  .fixed {
    .ivu-poptip-popper[x-placement^=left] {
      padding: 0 17px 0 7px;
      min-width: auto;
      .ivu-poptip-arrow {
        top: 50px;
        right: 10px;
      }
      .ivu-poptip-inner {
        border-radius: 8px;
        .outter-link {
          .ivu-divider-horizontal {
            margin: 9px 0;
          }
        }
      }
    }
  }
</style>