<template>
  <div>
    <Modal
        v-model="deleteDialog"
        width="500"
        :closable="false">
        <div style="text-align: center;">
          <Icon type="ios-alert-outline" style="color:#FA6900" size="40" />
          <div class="delete-description">确定要删除此地址吗</div>
        </div>
        <div style="padding-left:38px;">
          <span>收货人：</span>
          <span>{{address.contactPerson}}</span>
        </div>
        <div style="padding-left:24px;margin-top:8px;">
          <span>详细地址：</span>
          <span>{{address.fullAddress}}</span>
        </div>
        <div style="padding-left:24px;margin-top:8px;">
          <span>联系电话：</span>
          <span>{{address.contactPhone}}</span>
        </div>
        <div style="margin-top:40px; text-align: center;">
          <span class="confirm" @click="confirm">确定</span>
          <span class="cancel" @click="cancel">取消</span>
        </div>
    </Modal>  
  </div>
</template>

<script>
import{deleteAddress} from '@/api/api'

export default {
  props:{
    address:{
      type: Object,
      default:{},
    }
    },
  data() {
    return{
      deleteDialog:false 
    }
  },
  created() {},
  methods:{
    confirm() {
      // this.deleteDialog = false
      deleteAddress({
        id: this.address.id,
        userId: this.$store.state.userInfo.userId,
      }).then((res) =>{
        if(res.result === 0) {
          this.deleteDialog = false
          this.$parent.getAddress();
        }
      })
    },
    cancel() {
      this.deleteDialog = false
    },
  },
}
</script>

<style lang="less" scoped>
  /deep/.ivu-modal-body {
    padding: 24px 16px;
  }
  /deep/.ivu-modal-footer{
    border-top: 0;
    text-align: center;
  }
  /deep/.ivu-modal-footer{
    display: none;
  }
  .delete-description{
    margin-top: 16px;
    margin-bottom: 40px;
    font-size: 18px;
    font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
    font-weight: 400;
    text-align: center;
    color: #333333;  
  }
  .confirm{
    display: inline-block;
    width: 132px;
    height: 45px;
    opacity: 1;
    background: #cc0000;
    border-radius: 4px;
    font-size: 18px;
    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
    font-weight: 400;
    text-align: CENTER;
    color: #ffffff;
    line-height: 45px;
    cursor: pointer;
  }
  .cancel{
    display: inline-block;
    width: 132px;
    height: 45px;
    opacity: 1;
    border: 1px solid #999999;
    border-radius: 4px;
    font-size: 18px;
    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
    font-weight: 400;
    text-align: CENTER;
    color: #999999;
    line-height: 45px;
    margin-left: 24px;  
    cursor: pointer;
  }
</style>