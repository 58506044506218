<template>
  <div class="container user">
    <div class="path">
      <Breadcrumb>
        <BreadcrumbItem to="/home">首页</BreadcrumbItem>
        <BreadcrumbItem to="/user/userInfo">个人中心</BreadcrumbItem>
        <BreadcrumbItem>{{$route.meta.title}}</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="content">
      <div class="user-menu">
        <div class="top">
          <img @click="$router.push('/user/userInfo')" :src="userInfo.headImgUrl?$fillImgPath(userInfo.headImgUrl):require('@/assets/imgs/detail/author.png')" alt="">
          <span>{{userInfo.nickName}}</span>
        </div>
        <ul class="li-list">
          <template v-for="(item,index) in routesArr" >
          <li v-if="item.path!=='userInfo'" :class="['li-item',activeIndex === index?'active':'']" :key="item.path"  @click="handleClick(item,index)">
            <img :src="require(`@/assets/imgs/menu/${item.path+(activeIndex === index?'-active':'')}.png`)" alt="">
            <span >{{item.meta.title}}</span>
          </li>
          </template>
        </ul>
      </div>
      <div class="user-main">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data:()=>({
    routesArr:[],
    activeIndex:null
  }),
  created () {
    this.getChildrenRoutes()
  },
  mounted(){
    console.log(this.userInfo);
  },
  watch:{
    $route: {
      handler: function(){
        this.changeRouteFn()
      },
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    getChildrenRoutes(){
      const {routes} = this.$router.options
      this.routesArr = routes.find(item => item.path === '/user').children
      this.activeIndex =  this.routesArr.findIndex(item => `/user/${item.path}` === this.$route.path)
    },

    changeRouteFn(){
      this.activeIndex =  this.routesArr.findIndex(item => `/user/${item.path}` === this.$route.path)
    },

    handleClick(item,index){
      if(index === this.activeIndex) return
      this.$router.push(`/user/${item.path}`)
    }
  }
}
</script>

<style lang="less" scoped>
.user {
  .path {
    padding: 24px 0;
  }
  .content {
    display: flex;
    .user-menu {
      margin-right: 20px;
      width: 187px;
      background-color: white;
      .top{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 187px;
        border-bottom: 1px solid rgb(242, 242, 242);
        img{
          width: 66px;
          height: 66px;
          border-radius: 100%;
        }
        span{
          margin-top: 16px;
        }
      }
      .li-list{
        padding-top: 40px;
        .li-item{
          height: 66px;
          line-height: 66px;
          text-align: center;
          cursor: pointer;
          color: #999;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            margin-left: 16px;
          }
          &.active{
            background-color: rgba(153,153,153,0.20);
            color: #333;
          }
          &:hover {
            background-color: rgba(153,153,153,0.20);
          }
        }
      }
    }
    .user-main {
      flex: 1;
      overflow: hidden;
    }
  }
}
</style>