<template>
  <div class="container home">
    <!-- 分类-轮播 -->
    <div v-if="categoryMainList.length || bannerList.length" class="banner">
      <div ref="category" v-if="categoryMainList.length" @mouseleave="categoryId=''" class="category">
        <Tooltip ref="tooltip" :offset="offsetBannerTop" :disabled="disabled" @on-popper-hide="categoryId=''" placement="right-start" theme="light">
          <template v-for="(item, index) in categoryMainList">
            <!-- 按照UI只能显示13个分类，由于用overflow会限制content的显示和不满13个分类时，移入空白处也会触发弹窗显示，故综合考虑使用js控制个数来实现超出不显示功能 -->
            <div @mouseenter="handleCategoryShow(item.categoryId)" v-if="index < 13" :key="index" :class="['title', item.categoryId === categoryId?'title-active':'']">
              <span>{{item.name}}</span>
              <img v-if="item.categoryId === categoryId" src="../assets/imgs/arrow-right-red.png" alt="">
              <img v-else src="../assets/imgs/arrow-right.png" alt="">
            </div>
          </template>
          <template slot="content">
            <div v-for="(childItem, childIndex) in categorySubList" :key="childIndex" class="content">
              <div class="sub-title">
                <span>{{childItem.categoryName}}</span>
                <img src="../assets/imgs/arrow-right-grey.png" alt="">
              </div>
              <div class="sub-content">
                <div class="sub-wrap">
                  <div @click="goSearch(cItem)" v-for="(cItem, cIndex) in childItem.subSection" :key="cIndex">{{cItem.categoryName}}</div>
                </div>
              </div>
            </div>
          </template>
        </Tooltip>
      </div>
      <div @click="linkBanner"  v-if="bannerList.length" class="swiper">
        <Carousel :dots="bannerList.length===1?'none':'inside'" :arrow="bannerList.length===1?'never':'hover'" :autoplay="bannerList.length===1?false:true" :height="450" loop :autoplay-speed="5000" radius-dot>
          <CarouselItem v-for="(item, index) in bannerList" :key="index">
            <img :src="$fillImgPath(item.thumbUrl)" :data-itembanner="JSON.stringify(item)"  alt="">
          </CarouselItem>
        </Carousel>
      </div>
      <div v-else-if="noData" class="no-data-wrap">
        <img class="no-data-img" :src="$fillImgPath('/gree-mall-v3/pc/home_page_no_data.png')"/>
        <div class="no-data-desc">暂无内容</div>
      </div>
    </div>
    <!-- Metro图标 -->
    <div v-if="metroList.length === 5" class="metro">
      <div @click="link(item)" v-for="(item, index) in metroList" :key="index" class="metro-item">
        <img :src="$fillImgPath(item.thumbUrl)" alt="">
        <div>{{item.activityName}}</div>
      </div>
    </div>
    <!-- 专题广告位 -->
    <div @click="linkThemeAd(item)" :style="{'background-image':'url('+$fillImgPath(item.backgroundUrl)+')'}" v-for="(item, index) in themeAdList" :key="'theme'+index" class="theme-ad">
      <div v-if="item.userSideShowFlag" class="theme-ad-title">
        <div :style="{'color':item.specialAdTitleFontColor}" class="text">{{item.activityName}}</div>
        <div v-if="item.openType === 40" class="countdown">
          <CustomCountDown 
            :time="{time: item.seckillTime}"
            :isBegin="item.specialtyState !== 10"
            :isColorFixed="true"
            @finished="getThemeAdData"
          >
            <Icon type="md-alarm" color="white" size="18"/>
          </CustomCountDown>
        </div>
      </div>
      <div :class="['theme-ad-content', item.homePageSpecialAdConfigVOList[0].productName?'theme-ad-content-good':'']">
        <div @click.stop="link(child, item.openType)" v-for="(child,childIndex) in item.homePageSpecialAdConfigVOList.slice(item.current*pageSize,(item.current+1)*pageSize>item.homePageSpecialAdConfigVOList.length?item.homePageSpecialAdConfigVOList.length:(item.current+1)*pageSize)" :key="childIndex" class="theme-ad-content-item">
          <div class="img"><img :src="$fillImgPath(child.thumbUrl)" alt=""></div>
          <div :style="{'color':item.mainTitleFontColor}" class="multi-ellipsis main-title">{{child.mainTitle || child.productName}}</div>
          <div :style="{'color':item.subTitleFontColor}" class="sub-title">{{child.subtitle || '¥ '+child.currentPrice}}</div>
        </div>
        <div @click.stop="item.current--" v-if="item.current > 0" class="pre"></div>
        <div @click.stop="item.current++" v-if="(item.current+1)*pageSize < item.homePageSpecialAdConfigVOList.length" class="next"></div>
      </div>
    </div>
    <!-- 为你推荐 -->
    <div v-for="(item, index) in recommendList" :key="'recommend'+index" class="recommend">
      <div class="recommend-title">
        <div class="title-text">
          <span>{{item.floorName}}</span>
          <span>{{item.floorTitle}}</span>
        </div>
        <div v-if="item.pages > 1" @click="getNextRecommendData(item)" class="btn">换一批</div>
      </div>
      <goods
        :listData="item.homeRecommendProductVOList"
        priceKey="currentPrice"
        lineKey="originalPrice"
        labelKey="activityLabs"
        @link="link"
      ></goods>
    </div>
    <!-- 热点广告位 -->
    <div class="hot-banner"><img :src="$fillImgPath('/static/img/distribution_shop/yejiao.png')" alt=""></div>
    <!-- 回到顶部 -->
    <div class="fixed" :style="{bottom:showGoTop?'222px':'312px'}">
      <div v-if="floatingInfo?.endTime" class="floating">
        <img @click="link(floatingInfo)" :src="$fillImgPath(floatingInfo.thumbUrl)" alt="">
        <div @click="closeFloating" class="close"><img src="../assets/imgs/home/close.png" alt=""></div>
      </div>
      <Poptip trigger="hover" placement="left-start" padding="10px 8px" offset="-22">
        <div @mouseenter="isQrcodeHover = true" @mouseleave="isQrcodeHover = false" :class="['back-top', 'app', {'back-top-active': isQrcodeHover}]">
          <img :src="isQrcodeHover?qrcode_active:qrcode" alt="">
          <div>移动端下载</div>
        </div>
        <div class="outter-link" slot="content">
          <div class="link-img"><img src="../assets/imgs/home/miniprogram.jpg" alt=""></div>
          <div class="link-txt">扫码进入<br>格力董明珠店<br>小程序</div>
          <Divider />
          <div class="link-img"><vue-qr :logoSrc="gree" :text="appDownloadUrl" :margin="5" :size="86"></vue-qr></div>
          <div class="link-txt">扫码下载<br>格力董明珠店<br>APP</div>
        </div>
      </Poptip>
      <div v-if="showGoTop" @click="backTop" @mouseenter="isTopHover = true" @mouseleave="isTopHover = false" :class="['back-top', {'back-top-active': isTopHover}]">
        <img :src="isTopHover?top_active:top" alt="">
        <div>回到顶部</div>
      </div>
      <div class="bg"><img src="../assets/imgs/home/floating-shadow.png" alt=""></div>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr' // https://github.com/Binaryify/vue-qr
import config from '@/config'
import { mapState } from 'vuex'
import {
  getHomeManageData,
  getHomeCategoryMainList,
  getHomeCategorySubList,
  getHomeThemeAdList,
  getHomeRecommendList,
  getHomeNextRecommendList,
} from '@/api/api'
import CustomCountDown from './goods-detail/CustomCountDown.vue'
import Goods from '../components/Goods.vue'
export default {
  name: 'Home',
  data() {
    return {
      qrcode: require('@/assets/imgs/home/qrcode.png'), // 移动端下载
      qrcode_active: require('@/assets/imgs/home/qrcode_active.png'), // 移动端下载
      top: require('@/assets/imgs/home/top.png'), // 回到顶部
      top_active: require('@/assets/imgs/home/top_active.png'), // 回到顶部
      gree: require('@/assets/imgs/home/gree.jpg'), // gree
      appDownloadUrl: config.H5_LINK + 'appdownload',
      bannerList: [],
      categoryMainList: [],
      categorySubList: [],
      categoryFactory: {},
      categoryId: '',
      disabled: false,
      metroList: [],
      themeAdList: [],
      pageSize: 4,
      recommendList: [],
      floatingInfo: {},
      showGoTop: false,
      isQrcodeHover: false,
      isTopHover: false,
      offsetBannerTop: 0,
      noData: false
    }
  },
  components:{
    CustomCountDown,
    Goods,
    VueQr
  },
  computed: {
    ...mapState([
      'userInfo',
    ]),
  },
  watch: {
    categoryId: function(val, oldVal) {
      if(val) {
        this.$refs.tooltip.popperJS.destroy();
        this.$refs.tooltip.popperJS=null;
        let num = 0
        let categoryTop = this.$refs.category.getBoundingClientRect().top
        let headerHeight = this.$parent.$refs.header.getBoundingClientRect().height
        let gapNum = categoryTop - headerHeight
        if(gapNum < 0) {
          num = gapNum
        }
        this.offsetBannerTop = -4-num
        if(oldVal) {
          this.$nextTick(() => {
            this.$refs.tooltip.updatePopper()
          })
        }
      }
    }
  },
  methods: {
    // 轮播图Carousel的loop导致点击事件失效
    linkBanner(e){
      if (e.target.dataset.itembanner) {
        let params = JSON.parse(e.target.dataset.itembanner)
        this.link(params)
      }
    },

    handleScroll() {
      let scrolltop = document.documentElement.scrollTop; // 获取当前页面的滚动条纵坐标位置 
      if(scrolltop > window.innerHeight){  // 浏览器窗口的内部高度 window.innerHeight
        this.showGoTop = true // 超过就显示出来
      }else{
        this.showGoTop = false // 不超过还藏起来
      }
    },
    backTop() {
      let scrolltop = document.documentElement.scrollTop; // 获取当前页面的滚动条纵坐标位置 
      // 定时器平滑滚动
      const time = setInterval(() => {
        document.documentElement.scrollTop = scrolltop -= 40;
        if (scrolltop <= 0) {  // 定时器要及时清除掉，要不然一直执行很恐怖的
          clearInterval(time);
        }
      }, 10);
    },
    closeFloating() {
      this.floatingInfo = {};
      sessionStorage.setItem('closeFloating', 'closed')
    },
    linkThemeAd(item) {
      if(item.openType === 40) {
        this.link(item);
      }
    },
    link(item, type) {
      const openType = type || +item.openType
      switch (openType) {
        case 10: // 商品详情
          this.$router.push({
            name: 'GoodsDetail',
            query: {
              productNo: item.productId,
              skuNo: item.skuId,
            }
          })
          break;
        case 20: // 自定义链接
          window.location.href = item.appUrl.indexOf('http') === -1 ? `https://${item.appUrl}` : item.appUrl;
          break;
        case 40: // 秒杀专场
          if(this.userInfo.token) {
            this.$router.push(`/h5/transition?token=${this.userInfo.token}&callBackUrl=pagesA/seckill/seckillPage?shopId=${config.STOREID}`)
          } else {
            this.$router.push('/h5/pagesA/seckill/seckillPage?shopId=' + config.STOREID)
          }
          break;
        case 50: // 专题模板活动
          if(this.userInfo.token) {
            this.$router.push(`/h5/transition?token=${this.userInfo.token}&callBackUrl=pagesA/themeActivity/themeActivity?configValue=${item.configValue}&shopId=${config.STOREID}`)
          } else {
            this.$router.push(`/h5/themeActivities?configValue=${item.configValue}&shopId=${config.STOREID}`)
          }
          break;
        default:
          break;
      }
    },
    goSearch(item) {
      this.disabled = true;
      this.categoryId='';
      this.$router.push({
        name: 'GoodsList',
        query: {
          keyword: item.categoryName,
          id: item.categoryId,
          searchType: 2
        }
      });
    },
    handleCategoryShow(id) {
      this.$refs.tooltip.handleShowPopper()
      this.disabled = false;
      this.categoryId = id;
      if(this.categoryFactory[id]){
        this.categorySubList = this.categoryFactory[id];
        return;
      }
      getHomeCategorySubList({
        categoryId: id 
      }).then((res) => {
        if (res.result === 0) {
          this.categorySubList = res.data.secondSection;
          this.categoryFactory[id] = res.data.secondSection;
        } else {
        }
      }).catch((err) => {
      })
    },
    
    getNextRecommendData(item) {
      if(item.current + 1 > item.pages) {
        item.current = 0;
      }
      getHomeNextRecommendList({
        id: item.id,
        curNum: item.current + 1,
        offset: '10'
      }).then((res) => {
        if (res.result === 0) {
          item.current = res.data.current;
          item.pages = res.data.pages;
          if(res.data.homeAdsInfoDO){
            res.data.homeAdsInfoDO.isAd = true;
            res.data.records.splice(res.data.records.length>5?5:res.data.records.length,0,res.data.homeAdsInfoDO);
          }
          item.homeRecommendProductVOList = res.data.records;
        } else {
        }
      }).catch((err) => {
      })
    },
    getRecommendData() {
      getHomeRecommendList().then((res) => {
        if (res.result === 0) {
          res.data.forEach(item => {
            item.current = 0;
            item.pages = 1;
            item.homeRecommendProductVOList = [];
            this.getNextRecommendData(item)
          })
          this.recommendList = res.data;
        } else {
        }
      }).catch((err) => {
      })
    },
    getThemeAdData() {
      getHomeThemeAdList().then((res) => {
        if (res.result === 0) {
          const nowTime = new Date(res.data?.nowTime.replace(/-/g, '/'));
          res.data?.records.forEach((element) => {
            element.current = 0;
            if (element.openType === 40) {
              const beginTime = new Date(element.seckillBeginTime.replace(/-/g, '/'));
              const endTime = new Date(element.seckillEndTime.replace(/-/g, '/'));
              element.seckillTime = element.specialtyState === 10 ? beginTime.getTime() - nowTime.getTime() : endTime.getTime() - nowTime.getTime();
            }
          });
          this.themeAdList = res.data.records;
        } else {
        }
      }).catch((err) => {
      })
    },
    
    getCategoryMainData() {
      getHomeCategoryMainList().then((res) => {
        if (res.result === 0) {
          this.categoryMainList = res.data.tabs;
        } else {
        }
      }).catch((err) => {
      })
    },
    getHomeData() {
      getHomeManageData().then((res) => {
        if (res.result === 0) {
          const {flowBannerResultVOList, floatingResultVO, metroResultVOList} = res.data
          this.bannerList = flowBannerResultVOList;
          this.metroList = metroResultVOList?.length > 5 ? metroResultVOList.splice(0, 5) : metroResultVOList;
          // floating广告位一个会话展示一次
        if(!sessionStorage.getItem('closeFloating'))
          this.floatingInfo = floatingResultVO

          if(!res.data?.flowBannerResultVOList.length) {
            this.showNoData()
          }
        } else {
          this.showNoData()
        }
      }).catch((err) => {
      })
    },
    showNoData() {
      this.noData = true
    },
  },
  created() {
    this.getHomeData();
    this.getCategoryMainData();
    this.getThemeAdData();
    this.getRecommendData();
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true)
  }
}
</script>

<style lang="less" scoped>
.home {
  .banner {
    width: 100%;
    height: 450px;
    display: flex;
    .category {
      width: calc(100% - 1025px);
      height: 100%;
      position: relative;
      padding: 4px 0;
      background: rgba(51,51,51,0.60);
      .title {
        padding: 10px 23px 10px 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 14px;
          color: white;
        }
        img {
          width: 13px;
          height: 13px;
        }
      }
      .title-active {
        background-color: white;
        span {
          color: #CC0000;
        }
      }
      .content {
        padding-top: 16px;
        display: flex;
        align-items: flex-start;
        .sub-title {
          width: 100px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 1;
          font-size: 16px;
          color: rgba(51,51,51,0.80);
          img {
            width: 13px;
            height: 13px;
          }
        }
        .sub-content {
          margin-left: 56px;
          width: 344px;
          border-bottom: 1px solid rgba(51,51,51,0.20);
          .sub-wrap {
            margin-left: -32px;
            display: flex;
            flex-wrap: wrap;
            div {
              margin-left: 32px;
              margin-bottom: 16px;
              font-size: 14px;
              color: rgba(51,51,51,0.80);
              cursor: pointer;
            }
            div:hover {
              color: #CC0000;
            }
          }
        }
      }
    }
    .swiper {
      width: 1025px;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }
  .no-data-wrap {
    display: flex;
    width: 1027px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .no-data-img {
      width: 350px;
      height: 350px;
    }
    .no-data-desc {
      font-size: 18px;
      // font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
      font-weight: 400;
      text-align: center;
      color: #999999;

    }
  }
  .metro {
    padding: 24px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .metro-item {
      cursor: pointer;
      img {
        width: 88px;
        height: 88px;
      }
      div {
        margin-top: 16px;
        text-align: center;
        font-size: 18px;
        color: #000000;
      }
    }
  }
  .theme-ad {
    margin-top: 15px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .theme-ad-title {
      padding: 24px 0;
      display: flex;
      align-items: center;
      .text {
        font-size: 28px;
        color: #333333;
      }
      .countdown {
        margin-left: 24px;
        color: white;
      }
    }
    .theme-ad-content {
      display: flex;
      position: relative;
      .theme-ad-content-item {
        width: 291px;
        margin-left: calc((100% - 1164px)/3);
        padding-bottom: 14px;
        text-align: center;
        color: #333333;
        cursor: pointer;
        .img {
          width: 100%;
          height: 180px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .main-title {
          margin-top: 32px;
          font-size: 20px;
        }
        .sub-title {
          margin-top: 12px;
          font-size: 16px;
        }
      }
      .theme-ad-content-item:first-child {
        margin-left: 0;
      }
      .pre, .next {
        position: absolute;
        top: 69px;
        width: 42px;
        height: 42px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;
      }
      .pre {
        left: 16px;
        background-image: url(../assets/imgs/home/pre.png);
      }
      .next {
        right: 16px;
        background-image: url(../assets/imgs/home/next.png);
      }
    }
    .theme-ad-content-good {
      .theme-ad-content-item {
        width: 288px;
        margin-left: calc((100% - 1152px)/3);
        background-color: white;
        .img {
          width: 100%;
          height: 288px;
        }
        .main-title {
          margin-top: 16px;
          font-size: 20px;
        }
        .sub-title {
          margin-top: 16px;
          font-size: 20px;
          color: #e41f1f;
        }
      }
      .pre, .next {
        top: 123px;
      }
    }
    .theme-ad-content:hover {
      .pre {
        background-image: url(../assets/imgs/home/pre-dark.png);
      }
      .next {
        background-image: url(../assets/imgs/home/next-dark.png);
      }
    }
  }
  .recommend {
    .recommend-title {
      padding: 56px 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-text {
        display: flex;
        align-items: center;
        span:first-child {
          font-size: 28px;
          line-height: 33px;
          color: #333333;
          font-weight: 600;
        }
        span:last-child {
          margin-left: 24px;
          font-size: 16px;
          line-height: 18px;
          color: rgba(51,51,51,0.80);
        }
      }
      .btn {
        font-size: 16px;
        color: #cc0000;
        cursor: pointer;
      }
    }
  }
  .hot-banner {
    margin-top: 56px;
    width: 100%;
    img {
      width: 100%;
    }
  }
  .fixed {
    position: fixed;
    margin-left: 1265px;
    // bottom: 252px;
    width: 90px;
    .floating {
      margin-bottom: 2px;
      width: 100%;
      height: 90px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      .close {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(100%, -100%);
        width: 16px;
        height: 16px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .back-top {
      margin-top: 2px;
      width: 90px;
      height: 90px;
      background-color: #fefefe;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 30px;
        height: 30px;
      }
      div {
        margin-top: 7px;
        font-size: 16px;
        color: rgba(51,51,51,0.60);
      }
    }
    .back-top-active {
      div {
        color: #EF2F2F;
      }
    }
    .app {
      margin-top: 0;
    }
    .outter-link {
      width: 130px;
      .link-img {
        width: 100%;
        font-size: 0;
        img {
          width: 100%;
        }
      }
      .link-txt {
        margin-top: 4px;
        font-size: 12px;
        color: #333333;
        text-align: center;
        line-height: 1.3;
      }
    }
    .bg {
      width: 100%;
      font-size: 0;
      img {
        width: 100%;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }
}
</style>

<style lang="less">
.home {
  .banner {
    .category {
      .ivu-tooltip {
        width: 100%;
        .ivu-tooltip-rel {
          width: 100%;
        }
        .ivu-tooltip-light.ivu-tooltip-popper {
          padding-left: 0;
          .ivu-tooltip-arrow {
            display: none;
          }
          .ivu-tooltip-inner {
            max-width: none;
            min-height: 450px;
            padding: 32px 102px 32px 48px;
            border-radius: 0;
          }
        }
      }
    }
  }
  .fixed {
    .ivu-poptip-popper[x-placement^=left] {
      padding: 0 17px 0 7px;
      min-width: auto;
      .ivu-poptip-arrow {
        top: 50px;
        right: 10px;
      }
      .ivu-poptip-inner {
        border-radius: 8px;
        .outter-link {
          .ivu-divider-horizontal {
            margin: 9px 0;
          }
        }
      }
    }
  }
}
</style>
