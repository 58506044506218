import SignUp from '../views/SignUp.vue'
import ForgetPassword from '../views/ForgetPassword.vue'

import Home from '../views/Home.vue'
import GoodsList from '../views/GoodsList.vue'
import goodsAttrCompare from '../views/goodsAttrCompare.vue'

import Cart from '../views/Cart.vue'
import Order from '../views/Order.vue'
import Similar from '../views/Similar.vue'

import User from '../views/user/index.vue'
import UserFavorite from '../views/user/Favorite.vue'
import UserAddress from '../views/user/Address.vue'
import MyCoupon from '../views/user/MyCoupon.vue'
import MyOrder from '../views/user/newOrder.vue'
import UserInfo from '../views/user/userInfo.vue'
import NewOrderDetail from '../views/order-detail/newOrderDetail.vue'
import couponGoodsList from '../views/user/couponGoodsList.vue'
import CloseShopPage from '../views/closeShopPage.vue'

// 支付
import PayOrder from '../views/pay/PayOrder.vue'
import CodePay from '../views/pay/CodePay.vue'
import PayTimeout from '../views/pay/PayTimeout.vue'
import PaySuccess from '../views/pay/PaySuccess.vue'

// path：以-分隔
// name，component：首字母大写
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login', // 登录
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      title: '欢迎登录'
    }
  },
  {
    path: '/wxLogin', // 登录
    name: 'wxLogin',
    component: () => import('@/views/wxLogin.vue'),
    meta: {
      title: '欢迎登录'
    }
  },
  {
    path: '/wxTemp', // 自动登录
    name: 'wxTemp',
    component: () => import('@/views/wxTemp.vue'),
    meta: {
      title: '欢迎登录'
    }
  },
  {
    path: '/signup', // 注册
    name: 'SignUp',
    component: SignUp,
    meta: {
      title: '欢迎注册'
    }
  },
  {
    path: '/forget-password', // 忘记密码
    name: 'ForgetPassword',
    component: ForgetPassword,
    meta: {
      title: '找回密码'
    }
  },
  {
    path: '/home', // 首页
    name: 'Home',
    component: Home
  },
  {
    path: '/registerAgreement',
    name: 'RegisterAgreement',
    component: () => import  ('@/views/registerAgreement.vue')
  },
  {
    path: '/privacyAgreement',
    name: 'PrivacyAgreement',
    component: () => import  ('@/views/privacyAgreement.vue')
  },
  {
    path: '/privacyHistory',
    name: 'privacyHistory',
    component: () => import  ('@/views/privacyHistory.vue')
  },
  {
    path: '/privacyHistoryDetail',
    name: 'privacyHistoryDetail',
    component: () => import  ('@/views/privacyHistoryDetail.vue')
  },
  {
    path: '/goods-attr', // 商品列表
    name: 'goodsAttrCompare',
    component: goodsAttrCompare
  },
  {
    path: '/goods-list', // 商品列表
    name: 'GoodsList',
    component: GoodsList
  },
  {
    path: '/coupon-goods-list', // 优惠券商品列表
    name: 'couponGoodsList',
    component: couponGoodsList
  },
  {
    path: '/goodsDetail', // 商品详情
    name: 'GoodsDetail',
    component: () => import  ('@/views/GoodsDetail.vue')
  },
  {
    path: '/cart/:id?',
    name: 'Cart',
    component: Cart,
    meta: {
      auth: true
    }
  },
  {
    path: '/order', // 确认订单
    name: 'Order',
    component: Order,
    meta: {
      auth: true
    }
  },
  {
    path: '/payOrder', // 待支付
    name: 'PayOrder',
    component: PayOrder,
    meta: {
      auth: true
    }
  },
  {
    path: '/codePay', // 待支付
    name: 'CodePay',
    component: CodePay,
    meta: {
      auth: true
    }
  },
  {
    path: '/payTimeout', // 待支付
    name: 'PayTimeout',
    component: PayTimeout,
    meta: {
      auth: true
    }
  },
  {
    path: '/paySuccess', // 待支付
    name: 'PaySuccess',
    component: PaySuccess,
    meta: {
      auth: true
    }
  },
  {
    path: '/newOrderDetail', // 订单详情
    name: 'NewOrderDetail',
    component: NewOrderDetail,
    meta: {
      auth: true
    }
  },
  {
    path: '/similar/:skuId', // 找相似
    name: 'Similar',
    component: Similar
  },
  {
    path: '/user', // 个人中心
    component: User,
    redirect: '/user/newOrder',
    meta: {
      auth: true
    },
    children: [
      {
        path: 'newOrder',
        name: 'newOrder',
        component: MyOrder,
        meta: {
          auth: true,
          title:'我的订单'
        },
      },
      {
        path: 'myCoupon',
        name: 'MyCoupon',
        component: MyCoupon,
        meta: {
          auth: true,
          title:'我的资产'
        },
      },
      {
        path: 'favorite',
        name: 'UserFavorite',
        component: UserFavorite,
        meta: {
          auth: true,
          title:'我的收藏'
        },
      },
      {
        path: 'address',
        name: 'UserAddress',
        component: UserAddress,
        meta: {
          auth: true,
          title:'地址管理'
        },
      },
      {
        path: 'userInfo',
        name: 'UserInfo',
        component: UserInfo,
        meta: {
          auth: true,
          title:'个人信息'
        },
      },
      {
        path: 'invoiceCenter',
        name: 'invoiceCenter',
        component: () => import  ('@/views/user/InvoiceCenter.vue'),
        meta: {
          auth: true,
          title:'发票中心'
        },
      }
    ]
  },
  {
    path: '/closeShopPage', 
    name: 'closeShopPage',
    component: () => import  ('@/views/closeShopPage.vue')
  },
  {
    path: '/h5/*', // 去h5
    name: 'H5',
    component: () => import  ('@/views/H5.vue')
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }
]

export default routes