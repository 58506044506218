<template>
  <div v-infinite-scroll="handleReachBottom" infinite-scroll-disabled="busy" class="recommend">
    <div v-if="total > 0" class="title">
      <slot name="title"></slot>
    </div>
    <goods
      :listData="recommendList"
      :lineNum="5"
      :type="2"
      priceKey="marketPrice"
      lineKey="originalPrice"
      labelKey="activityLabels"
    ></goods>
    <Divider v-if="total > 0 && total === recommendList.length">已经到底了~</Divider>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  getRecommendGoodList,
  getRecommendList,
} from '@/api/api'
import Goods from '../components/Goods.vue'
export default {
  name: 'Recommend',
  props: {
    skuId: String
  },
  data() {
    return {
      recommendList: [],
      current: 1,
      total: 0,
      busy: false,
    }
  },
  components:{
    Goods,
  },
  computed: {
    ...mapState([
      'userInfo',
    ])
  },
  methods: {
    handleReachBottom () {
      if(this.recommendList.length === this.total) return;
      this.getData();
    },
    getRecommendGoodData() {
      this.busy = true;
      getRecommendGoodList({
        skuId: this.skuId,
        pageIndex: this.current,
        pageSize: 10
      }).then((res) => {
        if (res.result === 0) {
          if(res.data.product) {
            this.recommendList = this.recommendList.concat(res.data.product);
            this.total = res.data.total;
            this.current++;
          }
          this.busy = false;
          this.$emit('getData', res.data.keyProduct)
        } else {
        }
      }).catch((err) => {
        
      })
    },
    getRecommendData() {
      this.busy = true;
      getRecommendList({
        userId: this.userInfo.userId,
        pageIndex: this.current,
        pageSize: 10
      }).then((res) => {
        if (res.result === 0) {
          this.recommendList = this.recommendList.concat(res.data.product);
          this.total = res.data.total;
          this.current++;
          this.busy = false;
        } else {
        }
      }).catch((err) => {
      })
    },
    getData() {
      if(this.skuId) { // 关联商品查询
        this.getRecommendGoodData();
      }else { // 不关联商品查询
        this.getRecommendData();
      }
    }
  },
  created() {
    this.getData();
  }
}
</script>

<style lang="less" scoped>
.recommend {
  .title {
    padding: 27px 0 24px;
    .icon {
      text-align: center;
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}
</style>

<style lang="less">
.recommend {
  .title {
    .ivu-divider {
      margin: 0;
      font-size: 16px;
      color: #333333;
    }
  }
  .ivu-divider {
    font-size: 16px;
    color: #999999;
  }
}
</style>