<template>
  <div class="user-myOrder">
    <span v-show="waitingPaymentOrderCount !==0" :class="{'sing-flag': waitingPaymentOrderCount>99}" class="sing obligationNum">{{waitingPaymentOrderCount|changeCount}}</span>
    <span v-show="waitingDeliveredOrderCount !==0" :class="{'sing-flag': waitingDeliveredOrderCount>99}" class="sing pendingNum">{{waitingDeliveredOrderCount|changeCount}}</span>
    <span v-show="waitingReceivedOrderCount !==0" :class="{'sing-flag': waitingReceivedOrderCount>99}" class="sing receivingNum">{{waitingReceivedOrderCount|changeCount}}</span>
    <Tabs @on-click="changeTabs" value="allOrder">
      <TabPane v-for="(tabItem,index) in tabs" :key="index" :label="tabItem.label" :name="tabItem.name">
        <div v-show="orderList.length!==0" class="myOrder-title">
          <span>商品信息</span>
          <span class="myOrder-price">单价</span>
          <span class="myOrder-number">数量</span>
          <span class="myOrder-state">订单状态</span>
          <span class="myOrder-sumPrice">总金额</span>
          <span class="myOrder-consignee">收货人</span>
          <span class="myOrder-operation">交易操作</span>
        </div>
        <ul>
          <li v-for="(item,index) in orderList" :key="index+'-'+item.orderId" style="padding-bottom: 8px;">
            <div class="myOrder-contentTite">
              <span class="content-time">{{item.orderTime}}</span>
              <span class="content-number">订单号：</span>
              <span class="content-serial">{{item.orderId}}</span>
              <span class="content-store">{{item.shopName}}</span>
            </div>
            <div class="content-list">
              <div class="content-product">
                <ul>
                  <li v-for="(item2,index) in item.itemInfoList" :key="index" @click="toGoodsDetail(item2)">
                      <div class="product-info-item">
                        <div class="product-img">
                          <img :src="$fillImgPath(item2.skuPic)" alt="">
                          <!-- 22.10.26 订单商品图片的活动标签隐藏 -->
                          <span v-if="item2.activityType === 70" class="group-tag">拼团</span>
                          <span v-if="item.tradeIn === 1 && item2.activityType !== 110" class="oldNew-tag">以旧换新</span>
                        </div>
                        <div class="product-desc">
                          <div class="product-describe">
                            <span class="name">{{item2.skuName}}</span>
                          </div>
                          <div class="product-specification">{{item2.skuAttribute}}</div>
                        </div>
                        <div class="product-per">
                          <span class="product-price">￥{{item2.payPrice}}</span>
                          <span class="product-numbers">×{{item2.quantity}}</span>
                        </div>  
                      </div>
                      <div class="product-info-item" v-for="(attach, attachInd) in item2.itemInfoList" :key="attachInd">
                        <div class="product-img">
                          <img :src="$fillImgPath(attach.skuPic)" alt="">
                        </div>
                        <div class="product-desc">
                          <div class="product-describe">
                            <span class="tag">附件</span>
                            <span class="name">{{attach.skuName}}</span>
                          </div>
                          <div class="product-specification">{{attach.skuAttribute}}</div>
                        </div>
                        <div class="product-per">
                          <span class="product-numbers">×{{attach.quantity}}</span>
                        </div>  
                      </div>
                  </li>
                </ul>
              </div>
              <div class="content-desc" :class="{'content-active':item.itemInfoList.length!==1}">
                <!-- <span v-if="item.state === 1">等买家付款</span>
                <span v-else-if="item.state === 2">等商家发货</span>
                <span v-else-if="item.state === 3">待收货</span>
                <span v-else-if="item.state === 5">待评价</span>
                <span v-else-if="item.state === 6">已完成</span>
                <span v-else-if="item.state === 7">已取消</span>
                <span v-else-if="item.state === 8">交易关闭</span> -->
                <span>{{ item.state | statusText('title', item) }}</span>
              </div>
              <div class="content-price" :class="{'content-active':item.itemInfoList.length!==1}">
                <span v-if="item.state === 1" class="content-pay">{{ paymentState(item) }}</span>
                <!-- 预售订单 根据付款状态不同展示金额 -->
                <span v-if="item.itemInfoList.length && item.itemInfoList[0].activityType === 110">￥
                  <span v-if="item.itemInfoList[0].orderItemState === 1">{{item.depositAmount}}</span>
                  <span v-else-if="item.itemInfoList[0].orderItemState === 14">{{item.finalPaymentAmount}}</span>
                  <span v-else-if="item.itemInfoList[0].orderItemState === 2">{{item.payAmount}}</span>
                  <span v-else>{{item.paymentPrice}}</span>
                </span>
                <span v-else-if="item.itemInfoList.length && item.state === 1 && item.itemInfoList[0].orderItemState == 14 && item.itemInfoList[0].activityType === 30">￥
                  <span>{{item.finalAmount}}</span>
                </span>
                <span v-else>￥{{item.paymentPrice}}</span>
              </div>
              <div class="content-person" :class="{'content-active':item.itemInfoList.length!==1}">
                <span v-if="item.addressStatus != 10">{{item.receiverName}}</span>
                <span v-if="[1,2].includes(item.state)&&item.addressStatus == 10" @click="goAppraise" class="edit-address">去设置</span>
                <span @click="goAppraise" v-if="
                      !item.selfTake &&
                      (item.state === 1 ||
                        item.state === 2) &&
                      item.addressStatus != 10
                    " class="edit-address">
                    修改地址
                </span>
              </div>
              <div class="content-operation" :class="{'content-active':item.itemInfoList.length!==1}">
                <!-- 定金预售订单，待付款及待付尾款状态隐藏去支付按钮 -->
                <span v-if="item.state === 1 && !(item.itemInfoList.length && item.itemInfoList[0].activityType === 110 && item.state === 1) && !(item.itemInfoList.length && item.itemInfoList[0].activityType === 30 && item.state === 1)" 
                      @click="goWaitPay(item)" class="operating-btn">
                  去付款
                </span>
                <!-- <template v-if="showPayment">
                  <div v-if="item.state === 1" class="content-payType">
                    <span :class="{activePayType:item.state === 1}">在线支付</span>
                    <span>线下支付</span>
                  </div>
                </template> -->
                <span @click="goReceiving(item)" v-if="item.state === 3" class="operating-btn">确认收货</span>
                <span @click="goAppraise" v-if="item.state === 4" class="operating-btn">去评价</span>
                <span @click="remindDelivery(item)"
                     v-if="(item.state === 2 ||
                      item.state === 60) &&
                      item.addressStatus == 20 && 
                      isShowRemindSend(item)
                    " class="operating-detail">提醒发货</span>
                <span @click="goDelete(item)" v-if="item.state === 6 || 
                      item.state === 7" class="operating-detail">
                  删除订单
                </span>
                <span @click="goToDetail(item)" class="operating-detail">订单详情</span>
              </div>
            </div>
          </li>
        </ul>
        <!-- 支付弹窗 -->
        <Modal v-model="payDialog" :closable="false" width="600">
           <div slot="header" class="payDialogTitle">
              <img src="../../assets/imgs/newOrder/Frame 348@2x.png" style="width:40px;height:40px;">
              <span class="payDec">以下订单将一起付款</span>
           </div>
           <div v-for="(items,index) in shopOrders" :key="index" class="payDialogContent">
              <div class="contentShop">
                <div class="shopName">{{items.shopName}}</div>
                <div style="margin-left:247px">
                  <span>共{{items.quantity}}件，计</span>
                  <span style="color:#CC0000">￥{{items.payAmount}}</span>
                </div>
              </div>
              <div class="productItem">
                <img v-for="(childItems,index) in items.products" :key="index" :src="$fillImgPath(childItems.thumbUrl)" alt="">
              </div>
           </div>
           <div slot="footer">
              <span @click="goPay()" class="cancel">付款</span>
              <span @click="cancelPay" class="confirm">取消</span>
           </div>
        </Modal>
        <!-- 删除订单弹窗/确认收货 -->
        <Modal v-model="deleteDialog" :closable="false" width="500">
           <p slot="header" style="height:40px;color:#f60;text-align:center">
               <img src="../../assets/imgs/newOrder/Frame 348@2x.png" style="width:40px;height:40px;">
           </p>
           <div style="text-align:center; font-size: 18px; font-weight: 400;">
               <span >{{dialogMsg}}</span>
           </div>
           <div slot="footer">
              <span @click="cancel" class="cancel">再想想</span>
              <span @click="confirm()" class="confirm">确定</span>
           </div>
        </Modal>
        <!-- 二维码弹窗 -->
        <Modal v-model="codeDialog" :footer-hide="true" width="500">
           <div class="codeDialog">
             <img src="../../assets/imgs/newOrder/erweima.jpg" alt="">
             <span>请打开微信扫一扫 进行下一步操作吧</span>
           </div>
        </Modal>
      </TabPane>
    </Tabs>
     <div v-show="orderList.length === 0" class="noCollection">
       <img src="@/assets/imgs/newOrder/noOrder@2x.png" alt="">
       <div class="noFavorite">还没有相关订单</div>
     </div>
     <Page v-show="orderList.length!==0" placement="top" @on-page-size-change="changeSize" show-elevator show-sizer  show-total @on-change="changePage" :page-size="pageSize" :current="pageIndex" :total="pageTotal" />
  </div>
</template>

<script>
import{getOrderCount,queryOrderDetail,getNewOrder,getDeletOrder,receiptOrder,remindOrder,discountInfo} from '@/api/api'
import { statusText } from '../../utils/commonFilters'

export default {
  data() {
    return{
      waitingPaymentOrderCount:0, //待付款
      waitingDeliveredOrderCount:0, //待发货
      waitingReceivedOrderCount:0, //待收货
      tabsName:'',
      tabs:[
        {
          label:'全部订单',
          name:'allOrder',
        },
        {
          label:'待付款',
          name:'waitingPaymentOrder',
        },
        {
          label:'待发货',
          name:'waitingDeliveredOrder',
        },
        {
          label:'待收货',
          name:'waitingReceivedOrder',
        }
      ],
      payDialog:false, // 支付弹窗
      orderStatus:'',
      orderList:[],
      pageIndex:1,
      pageSize:10,
      pageTotal:0,
      deleteDialog:false,
      codeDialog:false,
      dialogMsg:'',
      dialogFlag:true,
      deleteItem:{},
      showPayment:false,
      shopOrders:[],
      twentyFour: 86400000, //24小时转换为毫秒数
      clickItem: {}
    }
  },
   filters: {
    //订单状态(分为单个订单)
    statusText,
    changeCount:(count)=>{
      if(count>99){
        return '...'
      }
      return count
    }
  },
  created() {
    this.getOrderNumber()
    this.getOrderList()
  },
  computed: {
    isShowRemindSend(){
      return function isShowRemindSend(detailData) {
        // 判断提醒发货展示时间下单24小时后
        return this.isShowRemindDeliveryBtn(
            detailData.serverCurrentTime,
            detailData.orderTime,
          )
      }
    }
  },
  methods:{
    // 显示提醒发货按钮
    isShowRemindDeliveryBtn(serveTime, orderTime) {
      const current = Date.parse(serveTime.replace(/-/gi, '/')) //返回时间戳
      const order = Date.parse(orderTime.replace(/-/gi, '/'))

      if (current - order > this.twentyFour) {
        return true
      }
      return false
    },
    //   tabs栏切换
    changeTabs(name) {
      this.tabsName = name
      if(name === 'allOrder') {
          this.pageIndex = 1
        this.orderStatus = ''
      }else if(name === 'waitingPaymentOrder'){
          this.pageIndex = 1
        this.orderStatus = 1
      }else if(name === 'waitingDeliveredOrder'){
        this.pageIndex = 1
        this.orderStatus = 2
      }else if(name === "waitingReceivedOrder"){
          this.pageIndex = 1
        this.orderStatus = 3
      }
      this.getOrderList()
    },
    // 获取订单数量
    getOrderNumber(){
      getOrderCount({
        userId: this.$store.state.userInfo.userId
      }).then((res) => {
        
        this.waitingPaymentOrderCount = res.data.waitingPaymentOrderCount || 0
        this.waitingDeliveredOrderCount = res.data.waitingDeliveredOrderCount || 0
        this.waitingReceivedOrderCount = res.data.waitingReceivedOrderCount || 0
      })
    },
    // 获取订单列表
    getOrderList(){
      getNewOrder({
        orderStatus: this.orderStatus,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        userId: this.$store.state.userInfo.userId
      }).then((res) => {
        this.pageTotal = res.data.total || 0
        this.orderList = res.data.records || []
      })
    },
    // 去评价
    goAppraise() {
      this.codeDialog = true
    },
    toGoodsDetail(childItem) { // 商详页面
      this.$router.push({
        path: '/goodsDetail',
        query: {
          productNo: childItem.spuId,
          skuNo: childItem.skuId
        },
      })
    },
    // 提醒收货
    remindDelivery(childItem) {
      remindOrder({
        userId: this.$store.state.userInfo.userId,
        orderId: childItem.orderId,
      }).then((res) => {
        if(res.result === 0) {
          this.$Message.success({
          content:'已发送消息通知商家发货'
        })

        }
      })
    },
    
    // 查看详情
    goToDetail(childItem) {
      this.$router.push({
        path: '/newOrderDetail',
        query: {
          orderId: childItem.orderId.toString(),
          parentOrderId: childItem.parentOrderId.toString(),
          userId: this.$store.state.userInfo.userId,
        },
      })
    },

    // 确认收货
    goReceiving(childItem) {
      // let _count = 0
      // let flag = false
      // if (
      //   childItem.itemInfoList.length == 1 &&
      //   ((childItem.itemInfoList[0].orderItemState == 8 &&
      //     childItem.itemInfoList[0].afterSaleDTO.afterSaleNum >=
      //       childItem.itemInfoList[0].num) ||
      //     [9, 13, 14, 15].includes(childItem.itemInfoList[0].orderItemState))
      // ) {
      //   flag = true
      // } else if (childItem.itemInfoList.length > 1) {
      //   for (let i = 0; i < childItem.itemInfoList.length; i++) {
      //     if (
      //       [9, 13, 14, 15].indexOf(childItem.itemInfoList[i].orderItemState) >
      //       -1
      //     ) {
      //       _count++
      //     } else if (
      //       [8].indexOf(childItem.itemInfoList[i].orderItemState) > -1 &&
      //       childItem.itemInfoList[i].afterSaleDTO.afterSaleNum >=
      //         childItem.itemInfoList[i].num
      //     ) {
      //       _count++
      //     }
      //   }
      // }
      // if (flag || _count == childItem.itemInfoList.length) {
      //   this.$Message.warning({
      //     content:'商品正在售后,暂不能收货'
      //   })
      //   return
      // }
      // 测试代码
      // childItem.itemInfoList[0].afterSaleDTO = {
      //   afterSaleType:0,
      //   afterSaleStatus:14
      // }
      let flag = false
      childItem.itemInfoList.forEach(item=>{
        if(item.afterSaleDTO) {
          if(item.afterSaleDTO.afterSaleType === 0 || item.afterSaleDTO.afterSaleType === 1){
            if(item.afterSaleDTO.afterSaleStatus !== 5 && item.afterSaleDTO.afterSaleStatus !== 14 ) {
              flag = true
            }
          }
          if(item.afterSaleDTO.afterSaleType === 2 && item.afterSaleDTO.afterSaleStatus !== 5){
              flag = true
          }
        }
      })
      if(flag) {
        this.$Message.warning({
          content:'商品正在售后,暂不能收货'
        })
        return;
      }

      this.deleteItem = childItem
      this.dialogMsg = '请确认已经收到所有商品。'
      this.deleteDialog = true  
      this.dialogFlag = false
    }, 
    // 删除订单
    goDelete(item) {
      this.dialogMsg = '删除之后将无法恢复，确定删除吗？'
      this.deleteItem = item
      this.deleteDialog = true
      this.dialogFlag = true
    },
    cancel() {
      this.deleteDialog = false
    },
    confirm(){
      if(this.dialogFlag) {
        getDeletOrder({
          orderId:this.deleteItem.orderId,
          userId:this.$store.state.userInfo.userId
        }).then((res) => {
          if(res.result === 0){
          location.reload()
        }
      })
      }else{
        // 确认收货
        receiptOrder({
          orderId:this.deleteItem.orderId,
          userId:this.$store.state.userInfo.userId
        }).then((res) => {
          if(res.result === 0){
          location.reload()
        }
      })
    }
      
      this.deleteDialog = false
    },

    changePage(page) {
      this.pageIndex = page
      this.getOrderList()
      window.scrollTo(0,0)
    },
    changeSize (size) {
      this.pageSize = size
      this.getOrderList()
      window.scrollTo(0,0)
    },
    // 多个订单联合支付
    getDiscountInfo(item){
      return discountInfo({
        orderId: item.orderId,
        userId: this.$store.state.userInfo.userId,
      })
    },
			// 订单状态校验
		async orderStatusCheck(obj) {
			const params = {
				userId: this.$store.state.userInfo.userId,
				orderId: obj.orderId,
			}
			const res = await queryOrderDetail({...params})
			try {
				if (res.result === 0) {
					return +res.data.state === 1
				} else {
					uni.showToast({
						icon: 'none',
						title: res.message
					})
					return false
				}
			} catch (e) {
				console.error(e)
				return false
			}
		},
    // 去支付
    async goWaitPay(item) {
      // 判断当前主订单下的订单状态是否为待付款
      if (!(await this.orderStatusCheck(item))) {
        this.$Modal.warning({
          title: '当前订单状态已变更，请刷新获取最新订单信息。',
          onOk: () => {
            this.$router.go(0)
          },
        })
        return
      }
      
    // this.payDialog = true
    const resDiscount= await this.getDiscountInfo(item)

    const {result, message, data: {records, exist}} = resDiscount

    if(result === 0) {
      this.clickItem = item
      this.shopOrders = records || []

      if(exist) {
        // 多个订单支付弹窗逻辑
        this.payDialog = true
      }else{
        const query = {
          value: item.paymentPrice,
          orderId: item.orderId,
          parentOrderId: item.parentOrderId,
          exist: false,
          mutipOrder: 1,
        }
        this.$router.push({
          path: '/payOrder',
          query,
        })
      }
    }

    },
    // 付款
    goPay(){
      this.payDialog = false
      const  item = this.clickItem || {}
      const query = {
          value: item.paymentPrice,
          orderId: item.orderId,
          parentOrderId: item.parentOrderId,
          exist: false,
          mutipOrder: 1,
        }
        this.$router.push({
          path: '/payOrder',
          query,
        })
    },
    // 取消取款
    cancelPay(){
      this.payDialog = false
    },
    // 订金预购付尾款阶段
    paymentState(childItem) {
      let defaultText = '需支付'
      const type = childItem.itemInfoList[0]?.activityType
      const state = childItem.itemInfoList[0].orderItemState
      const textType = {
        // 订金预购
        30: () => {
          if (state == 1) {
            return '需支付订金'
          }
          if (state == 14) {
            return '需支付尾款'
          }
          return defaultText
        }
      }
      if (!textType[type]) {
        return defaultText
      }
      return textType[type]()
    },
  },
}
</script>

<style lang="less" scoped>
  .user-myOrder{
    background: #FFFFFF;
    position: relative;
    overflow: hidden;
    .sing{
      position: absolute;
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #CC0000;
      font-size: 14px;
      font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      line-height: 24px;
      top: 15px; 
    }
    .sing-flag {
      line-height: 18px!important;
    }
    .obligationNum{
      left: 253px;
    }
    .pendingNum{
      left: 363px;
    }
    .receivingNum{
      left: 470px;
    }
    .myOrder-title{
      margin-left: 48px;
      border-bottom: 1px solid rgba(153,153,153,0.60);
      font-size: 14px;
      font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
      padding-bottom: 5px;
      .myOrder-price{
        margin-left: 297px;
      }
      .myOrder-number{
        margin-left: 39px;
      }
      .myOrder-state{
        margin-left: 42px;
      }
      .myOrder-sumPrice{
        margin-left: 68px;
      }
      .myOrder-consignee{
        margin-left: 64px;
      }
      .myOrder-operation{
        margin-left: 79px;
      }
    }
    .myOrder-contentTite{
      margin-top: 24px;
      width: 100%;
      height: 36px;
      line-height: 36px;
      background: rgba(204,0,0,0.05);
      font-size: 14px;
      font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
      .content-time{
        margin-left: 48px;
      }
      .content-number{
        margin-left: 80px;
      }
      .content-serial{
        color: #0085FF;
      }
      .content-store{
        margin-left: 80px;
      }
    }
    .content-list{
      display: flex;
      justify-content: flex-end;
      border-bottom: 1px solid rgba(153,153,153,0.20);
    //   padding-left: 48px;
    //   padding-top: 8px;
    //   padding-bottom: 8px;
      .content-product{
        display: flex;
        justify-content: flex-start;
       
        border-right: 1px solid rgba(153,153,153,0.20);
        .product-info-item{
          display: flex;
          padding: 8px 0 7px 64px;
          border-bottom: 1px solid rgba(153,153,153,0.20);
        }
        .goods { 
          
        }
        .product-info-item:last-child{
          border-bottom: none;
        }
        .product-img{
          width: 100px;
          height: 100px;
          position: relative;
          img{
            width: 100%;
            height: 100%;
          }
          .group-tag{
            position: absolute;
            left: 0;
            top: 0;
            padding: 2px 4px;
            background: #f46363;
            font-size: 10px;
            font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 15px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
          .oldNew-tag{
            position: absolute;
            left: 0;
            top: 0;
            padding: 2px 4px;
            background: #66b6ff;
            font-size: 10px;
            font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 15px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
        .product-desc{
          display: flex;
          flex-direction: column;
          margin-left: 16px;
          .product-describe{
            display: flex;
            align-items: baseline;
            width: 200px;
            .tag {
              width: 32px;
              height: 16px;
              margin-right: 5px;
              line-height: 16px;
              flex-shrink: 0;
              text-align: center;
              font-size: 12px;
              color: #66b6ff;
              border: 1px solid #66b6ff;
              border-radius: 3px;
            }
            .name {
              height: 34px;
              font-size: 12px;
              font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #333333;
              line-height: 17px;
              margin-top: 8px;
              margin-bottom: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
          .product-specification{
            width: 185px;
            height: 34px;
            font-size: 12px;
            font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #999999;
            line-height: 17px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
        .product-per{
          display: flex;
          margin-top: 8px;
          width: 142px;
          .product-price{
            flex:2;
            margin-left: 16px;
            font-size: 14px;
            font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #333333;
          }
          .product-numbers{
            flex:1;
            margin-left: 10px;
            font-size: 8px;
            font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
            font-weight: 400;
            text-align: CENTER;
            color: #333333;
          }
        }
      }
      .content-desc{
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: 1px solid rgba(153,153,153,0.20);
        width: 117px;
        font-size: 14px;
        font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #cc0000;
      }
      .content-price{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba(153,153,153,0.20);
        width: 117px;
        font-size: 14px;
        font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #cc0000;
        .content-pay{
          margin-bottom: 8px;
          font-size: 12px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #999999;
        }
      }
      .content-person{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba(153,153,153,0.20);
        width: 95px;
        font-size: 14px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
        .edit-address{
          margin-top: 8px;
          font-size: 12px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #0085ff;
          cursor: pointer;
        }
      }
      .content-operation{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 155px;
        position: relative;
        /*.content-payType{
          width: 112px;
          height: 60px;
          position: absolute;
          top: 45px;
          border: 1px solid rgba(153,153,153,0.60);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          span{
            width: 100%;
            height: 30px;
            font-size: 12px;
            font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
            font-weight: 400;
            text-align: center;
            color: #999999;
            line-height: 30px;
            cursor: pointer;
          }
          .activePayType{
            background: #cc0000;
            color: #ffffff; 
          }
          :hover{
            background: #cc0000;
            color: #ffffff;
          }
          >:first-child{
            border-bottom: 1px solid rgba(153,153,153,0.60);
          }
        }*/
        :first-child {
          margin-top: 0!important;
        }
        .click-pay{
          position: absolute;
          top: 45px;
          left: 15px;
          width: 126px;
          height: 62px;
          background: #fff;
          border: 1px solid red;
          span{
            display: block;
            width: 125px;
            height: 30px;
            font-size: 12px;
            font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #999999;
            line-height: 30px;
            cursor: pointer;
            &:hover{
              background: #CC0000;
              color: #FFFFFF;
            }
          }
        }
        .operating-btn{
          width: 66px;
          height: 26px;
          opacity: 1;
          border: 1px solid #cc0000;
          border-radius: 5px;
          font-size: 14px;
          font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: #cc0000;
          line-height: 26px;
          cursor: pointer;
          &:hover{
          background: #cc0000;
          color: #ffffff;
          }
        }
        .operating-detail{
          margin-top: 8px;
          font-size: 14px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: #0085ff;
          cursor: pointer;
        }
      }
      .content-active{
        align-items: center;
        justify-content: center;
      }
    }
  }
  /deep/.ivu-tabs-bar{
    margin-left: 48px;
    margin-right: 48px;
    margin-bottom: 48px;
  }
  /deep/.ivu-tabs-nav-wrap{
    height: 79px;
    line-height: 63px;
  }
  /deep/.ivu-tabs-ink-bar{
    background: #CC0000;
  }
  /deep/.ivu-tabs-nav .ivu-tabs-tab-active{
    color:#CC0000
  }
  /deep/.ivu-tabs-nav .ivu-tabs-tab:hover{
    color:#CC0000
  }
  /deep/.ivu-tabs-nav .ivu-tabs-tab{
    font-size: 20px;
    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
    font-weight: 400;
    text-align: LEFT;
  }
  /deep/.ivu-page{
    text-align: center;
    margin: 20px auto;
  }
//   删除弹窗样式
/deep/.ivu-modal-header{
  border-bottom: none;
}
/deep/.ivu-modal-footer{
  border-top: none;
}
/deep/.ivu-modal-footer{
  text-align: center;
  span {
    display: inline-block;
    width: 168px;
    height: 45px;
    border: 1px solid #999999;
    border-radius: 5px;
    font-size: 18px;
    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
    font-weight: 400;
    text-align: CENTER;
    color: #999999;
    line-height: 45px;
    cursor: pointer;
  }
  .cancel{
    margin-right: 12px;
    color: #ffffff;
    background: #cc0000;
    border:none;
  }
  .confirm{
    margin-left: 12px;
    &:hover{
      color: #ffffff;
      background: #cc0000;
      border: 1px solid #fff;
    }
  }
}
.codeDialog{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.payDialogTitle{
  height:80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:#f60;
  text-align:center;
  .payDec{
    margin-top:16px;
    font-size: 18px;
    font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
    font-weight: 400;
    text-align: CENTER;
    color: #333333;
  }
}
.payDialogContent{
  width: 552px;
  height: 136px;
  display:flex;
  flex-direction: column;
  margin:0 auto;
  margin-bottom:16px;
  border: 1px solid rgba(153,153,153,0.60);
  .contentShop{
    display:flex;
    padding-top:17px;
    .shopName{
      margin-left:16px;
      font-size: 14px;
      font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
    }
  }
  .productItem{
    width: 70px;
    height: 70px;
    display: flex;
    margin:16px 16px 0 16px;
    img{
      width: 70px;
      height: 70px;
      margin-left: 16px;
    }
    img:first-child{
      margin-left: 0;
    }
  }
}
.noCollection{
      text-align: center;
      img{
        width: 200px;
        height: 200px;
      }
      .noFavorite{
        // margin-top: 43px;
        padding-bottom: 60px;
        text-align: center;
        font-size: 18px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #999999;
      }
    }
</style>