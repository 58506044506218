<template>
  <div class="userInfo">
    <div class="userInfo-title">个人信息</div>
    <div class="userInfo-name">
      <span>账号名：</span>
      <span>{{userName}}</span>
    </div>
    <div class="userInfo-user">
      <div class="userInfo-img">
        <span>头像：</span>
        <span>
          <!-- require('@/assets/imgs/detail/author.png') -->
          <img :src="profile === ''?  require('@/assets/imgs/detail/author.png'): $fillImgPath(profile)" alt="">
        </span>
      </div>
      <div class="userInfo-nickname">
        <span>用户昵称：</span>
        <span>{{nickName || phone || "默认昵称"}}</span>
      </div>
      <div class="userInfo-sex">
        <span>性别：</span>
        <span v-if="sex === 1">男</span>
        <span v-else-if="sex === 2">女</span>
        <span v-else>保密</span>
        <span @click="showUserPop(userPop)">更改</span>
      </div>
    </div>
    <div class="userInfo-email">
      <span>邮箱：</span>
      <span>{{email}}</span>
    </div>
    <div class="userInfo-phone">
      <span>手机号：</span>
      <span>{{phone}}</span>
      <span @click="handlePhoneDialog('formInline')">更改</span>
    </div>
    <div class="userInfo-login">
      <span>登录密码：</span>
      <span>8-16位密码，区分大小写</span>
      <span @click="handlePwdDialog('formValidate')" v-if="!pwdFlag">设置</span>
      <span @click="handlePwdDialog('formValidate')" v-else>更改</span>
    </div>
    <!-- 基本信息修改弹窗 -->
    <Modal
      v-model="showUserInfo"
      @on-cancel="cancel"
      width="700"
      title="修改基本信息">
      <div class="user-img">
        <span style="margin-left:68px">头像：</span>
          <Upload
            ref="upload"
            :format="['jpg','png','jpeg']"
            multiple
            type="drag"
            :on-success="success"
            action="/mallv2/oss/upload"
            :headers='{"x-flag":"pc","mobile-login-token": userInfo.token}'
            style="display: inline-block;margin-left:37px;height:100px;width:100px;">
             <div class="upload-img">
               <img v-if="showImg" style="height:100%;width:100%;" :src="$fillImgPath(myProfile)" alt="">
               <span class="edit-Titleimg">上传新头像</span>
             </div>
          </Upload>
      </div>
      <div class="user-nickname">
        <span class="nickname-title">用户昵称：</span>
        <span style="margin-left:24px;"><Input v-model="myNickName" placeholder="请输入昵称" style="width: 504px" /></span>
      </div>
      <div class="user-sex">
        <span class="user-checkSex">性别：</span>
        <RadioGroup v-model="mySex">
          <Radio :label="1">
            <span>男</span>
          </Radio>
          <Radio :label="2">
            <span>女</span>
          </Radio>
        </RadioGroup>
      </div>
      <div slot="footer" align="center">
        <span class="cancelBtn" @click="cancel">取消</span>
        <span class="saveBtn" @click="saveUserInfo">保存</span>
      </div>
    </Modal>
    <!-- 手机号修改弹窗 -->
    <Modal
      v-model="editPhoneDialog"
      width="700"
      title="修改手机号"
      @on-cancel="closePhoneDialog()">
      
       <Form ref="formInline" :model="formInline" :rules="ruleInline" label-position="left" :label-width="100" inline>
         <template v-if="steps ===1">
           <div style="margin-top:45px;margin-bottom:35px">
             <span style="margin-left:64px;">手机号：</span>
             <span style="margin-left:16px;">{{phone}}</span>
             <span style="margin-left:345px;" class="code-btn" @click="getFirstCode" v-if="checkFlag">{{firstFlag?'发送验证码':'重新发送'}}</span>
             <span style="margin-left:345px;" class="countDown-btn" v-else>倒计时{{countDownTime}}s</span>
           </div>
           <FormItem label="手机验证码：" prop="code">
             <Input v-model="formInline.code" placeholder="请输入手机验证码" style="width: 504px"></Input>
           </FormItem>
         </template>
         <template v-else>
           <div style="margin-top:45px;">
             <FormItem label="新手机号：" prop="newPhone">
               <Input v-model="formInline.newPhone" maxlength="11" placeholder="请输入新手机号" style="width: 300px"></Input>
               <span style="margin-left:134px;" class="code-btn" @click="getCode" v-if="checkFlag">{{firstFlag?'发送验证码':'重新发送'}}</span>
               <span style="margin-left:134px;" class="countDown-btn" v-else>倒计时{{countDownTime}}s</span>
             </FormItem>
             <FormItem label="手机验证码：" prop="newCode">
               <Input v-model="formInline.newCode" placeholder="请输入手机验证码" style="width: 504px"></Input>
             </FormItem>
           </div>
         </template>
       </Form>
       <div slot="footer" align="center">
         <span class="cancelBtn" @click="closePhoneDialog()">取消</span>
         <span class="saveBtn" @click="nextStep('formInline')">{{steps===1?'下一步':'完成' }}</span>
       </div>
    </Modal>
    <!-- 登录密码修改弹窗 -->
    <Modal
      v-model="editPasswordDialog"
      width="700"
      :title= title
      @on-cancel="closePwdDialog()">
      <div style="margin-left:150px;margin-bottom:32px;">
        <span>当前手机号：</span>
        <span>{{phone}}</span>
      </div>
      <div style="margin-left:150px;">
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate">
        <FormItem prop="pwdCode">
          <Input v-model="formValidate.pwdCode" placeholder="输入验证码" style="width: 260px"></Input>
          <span style="margin-left:55px;" class="code-btn" @click="getFirstCode" v-if="checkFlag">{{firstFlag?'发送验证码':'重新发送'}}</span>
          <span style="margin-left:55px;" class="countDown-btn" v-else>倒计时{{countDownTime}}s</span>
        </FormItem>
        <FormItem prop="oldPwd" v-if="pwdFlag">
          <Input v-model="formValidate.oldPwd" type="password" placeholder="请输入当前密码" style="width: 400px"></Input>
        </FormItem>
        <FormItem prop="newPwd">
          <Input v-model="formValidate.newPwd" type="password" maxlength="16" placeholder="8-16位密码，区分大小写" style="width: 400px"></Input>
        </FormItem>
        <FormItem prop="confirmPwd">
          <Input v-model="formValidate.confirmPwd" type="password" maxlength="16" placeholder="确认密码" style="width: 400px"></Input>
        </FormItem>
      </Form>
      </div>
      <div slot="footer" align="center">
        <span class="cancelBtn" @click="closePwdDialog()">取消</span>
        <span class="saveBtn" @click="savePwd('formValidate')">保存</span>
      </div>
    </Modal>
    <div id="captcha"></div>
  </div>
</template>

<script>
import {queryUserInfo,modifyUserInfo,getCode,getCaptcha,modifyUserPassword,checkCaptchaPhone, checkLogin} from '@/api/api'
import {JSEncrypt} from "jsencrypt";
import { mapState } from 'vuex'
import { rasEncrypt, checkLoginConfig } from '@/utils/common'
export default {
  name:'UserInfo',
  data() {
    const validateCode = (rule, value, callback) => {
      let reg = /^[0-9]{6}$/
      if (reg.test(value)) {
        getCaptcha({
          oldSmsCode: this.formInline.code,
          oldMobile: this.phone
        }).then((res) => {
          if(res.result !=0 ) {
            callback(new Error(res.message))
            return
          }
          callback()
        })
      } else {
        callback(new Error('请输入正确的验证码！'))
        return
      }

    };

    const validateNewcode = (rule, value, callback) => {
      checkCaptchaPhone({
        newSmsCode: this.formInline.newCode,
        newMobile: this.formInline.newPhone,
        userId: this.$store.state.userInfo.userId
      }).then((res) =>{
        if(res.result === 0){
          this.steps = 1
          this.editPhoneDialog = false
          this.getQueryUserInfo()
        }else if(res.result === 1989){
          callback(new Error(res.message))
        }
      })
    };

    const validateNewPhone = (rule, value, callback) => {
      const regPhone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if(regPhone.test(value)){
        callback()
      }else{
        callback(new Error('请输入正确的手机号码！'))
      }
    };

    const validateNewPwd = (rule,value,callback) =>{
      const regPWD =/^(?=.*[0-9].*)(?=.*[a-zA-Z].*)(?=[^_].*)[\w.@$!%*#\-~?&^].{8,16}$/g ;
      if(this.formValidate.newPwd.length < 8 ||!regPWD.test(this.formValidate.newPwd)){
        callback(new Error('请输入8-16位数字与字母组合可包含下划线！'))
      }else{
        callback()
      }
    }

    const validateConfirmPwd =(rule,value,callback) =>{
      if(this.formValidate.newPwd !=this.formValidate.confirmPwd){
        callback(new Error('两次输入的新密码不一致！'))
      }else{
        callback()
      }
    }

    return{
      captchaIns:null, //网易网盾插件实例
      showImg:false,
      userName:'',
      profile:'',
      nickName:'',
      sex:0,
      email:'',
      phone:'',
      showUserInfo:false,
      editPhoneDialog:false,
      editPasswordDialog:false,
      firstFlag:true,
      checkFlag:true,
      countDownTime:null,
      steps:1,
      userPop:{},
      myProfile:'',
      myNickName:'',
      mySex:0,
      pwdFlag: false,
      title: '',
      formInline: {
        code: '',
        newPhone:'',
        newCode:''
      },
      ruleInline: {
        code: [
         { required: true, message: '请输入验证码！', trigger: 'blur' },
         { validator: validateCode, trigger: 'click' },
        ],
        newPhone:[
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validateNewPhone, trigger: 'blur' },
        ],
        newCode:[
          { required: true, message: '请输入验证码！', trigger: 'blur' },
           { validator: validateNewcode, trigger: 'click' },
        ]
      },
      formValidate:{
        pwdCode:'',
        oldPwd:'',
        newPwd:'',
        confirmPwd:'',
      },
      ruleValidate:{
        pwdCode: [
         { required: true, message: '请输入验证码！', trigger: 'blur' },
        ],
        oldPwd: [
         { required: true, message: '请输入密码！', trigger: 'blur' },
        ],
        newPwd: [
         { required: true, message: '请输入密码！', trigger: 'blur' },
         { validator: validateNewPwd, trigger: 'blur' },
        ],
        confirmPwd: [
         { required: true, message: '请输入密码！', trigger: 'blur' },
         { validator: validateConfirmPwd, trigger: 'blur' },
        ],
      },
    publicKey :
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCDSsM7GPsZzpWg8mX3St6inIUg3dYk9xQCxXm3OrmT8oIZDDDFNqxOcQFHw+G2y9oXY77csOV9ZmjIPCtjGcr3ax0qyQfqltplYmmq4MgxejU4nS9cGeOHMV3Z9ubhmQnLxhIzcidWwFWMLAp1AwjUD72LYaG+YSkV/qwXl9uDIQIDAQAB"
    }
  },
  computed: {
    ...mapState([
      'userInfo',
    ]),
  },
  created(){
    this.getQueryUserInfo()
    // this.phone = this.$store.state.userInfo.mobile
  },
  mounted() {
    // 网易网盾配置
    checkLoginConfig({
      init: (instance) => {
        // 初始化成功后得到验证实例instance，可以调用实例的方法
        this.captchaIns = instance
      },
      afterCheck: (val) => {
        // 验证成功后，调用close方法关闭弹框（enableClose为true时调用）
        this.captchaIns.close()
        checkLogin({
          captchaValidateValue: val.validate,
          mobileNumber: this.phone,
        }).then((res) => {
          if(res.result === 0){
            this.getFirstCode() //发送验证码
          }
        })
      },
    })
  },
  methods:{
    // ras加密
    rasEncrypt(input) {
      let encrypt = new JSEncrypt();
      encrypt.setPublicKey(this.publicKey);
      let password = encrypt.encrypt(input);
      return "LOGINPASSWORD" + encodeURIComponent(password);
    },

    // 获取个人信息
    getQueryUserInfo(){
      queryUserInfo({
        userId:this.$store.state.userInfo.userId
      }).then((res) => {
        if(res.result ===0){
          this.userPop = res.data
          this.phone = res.data.mobile
          this.userName = res.data.userAcct
          this.profile = res.data.photoUrl
          this.nickName = res.data.nickName
          this.email = res.data.email
          this.sex = res.data.sex
          this.pwdFlag = res.data.pwdFlag
          this.title = this.pwdFlag ? "修改登录密码" : "设置登录密码"
        }
      })
    },
    // 图像上传成功钩子
    success(response, file, fileList) {
      this.showImg = true
      this.myProfile = response.data.url
    },
    showUserPop(item) {
      if(item.photoUrl !== '') {
        this.showImg = true
      }else{
        this.showImg = false
      }
      this.showUserInfo = true
      this.myProfile = item.photoUrl
      this.myNickName = item.nickName
      this.mySex = item.sex
    },
    saveUserInfo(){
      modifyUserInfo({
        userId:this.$store.state.userInfo.userId,
        photoUrl:this.myProfile,
        nickName:this.myNickName,
        sex:this.mySex
      }).then((res) =>{
        if(res.result === 0) {
          this.showImg = true
          this.showUserInfo = false
          this.getQueryUserInfo()
        }
      })
    },
    cancel(){
      this.showUserInfo = false
      this.showImg = false
    },
    nextStep(name){
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.steps = 2
          this.checkFlag = true
          this.firstFlag = true
        } else {
          this.editPhoneDialog = true
        }
      })
    },
    closePhoneDialog(){
      this.editPhoneDialog = false
      this.steps = 1
    },
    /* 手机号失焦 */
    phoneBlur(){
      this.$refs.formInline.validateField('newPhone',async valid => {
        if(!valid && !this.firstFlag){
          this.firstFlag = true
        }
      })
    },
    getFirstCode() {
      let flag = ['PC_MALL_UPDATE_PWD', 'PC_MALL_VERIFY_OLD_MOBILE']
      if (this.editPhoneDialog) {
        flag = 'PC_MALL_VERIFY_OLD_MOBILE'
      }
      if (this.editPasswordDialog) {
        flag = 'PC_MALL_UPDATE_PWD'
      }
      getCode({
        messageFlag: flag,
        mobileNumber: this.phone
      }).then((res) =>{
        if(res.result === 2232) {
          this.checkFlag = true
          this.firstFlag = true
          this.$Message.warning({
            background: true,
            content: res.message
          });
        } else if (res.result === 2231) {
            this.captchaIns.popUp();
        } else {
          this.checkFlag = false
          this.firstFlag = false
          this.timeCountDown(60) 
        }
      })
    },
    // 获取短信验证码
    getCode(){
      this.$refs.formInline.validateField('newPhone',async valid => {
        if(valid) return
        this.checkFlag = false
        this.firstFlag = false
        this.timeCountDown(60)
        getCode({
          // messageFlag: "VERIFY_NEW_MOBILE",
          messageFlag: "PC_MALL_VERIFY_NEW_MOBILE",
          mobileNumber: this.formInline.newPhone
        }).then((res) =>{
          console.log('res',res)
        })
      })
      
    },
    /* 倒计时 */
    timeCountDown(time){
      this.countDownTime = time
      let timer = setInterval(() => {
        this.countDownTime -=1
        if(this.countDownTime <= 0){
          this.checkFlag = true
          clearInterval(timer)
        }
      },1000)
    },
    // 重置修改手机号表单
    handlePhoneDialog(name){
      this.$refs[name].resetFields();
      this.checkFlag = true
      this.firstFlag = true
      this.editPhoneDialog = true
    },
    // 重置修密码表单
    handlePwdDialog(name){
      this.$refs[name].resetFields();
      this.checkFlag = true
      this.firstFlag = true
      this.editPasswordDialog = true
    },
    // 修改密码弹窗
    closePwdDialog(){
      this.editPasswordDialog = false
    },
    savePwd(name){
      this.$refs[name].validate((valid) => {
        if (valid) {
          modifyUserPassword({
            userId: this.$store.state.userInfo.userId,
            smsCode: this.formValidate.pwdCode,
            oldPwd: this.pwdFlag ? this.rasEncrypt(this.formValidate.oldPwd): '',
            newPwd: this.rasEncrypt(this.formValidate.newPwd)
          }).then((res) =>{
            if(res.result === 0) {
              this.editPasswordDialog = false
              this.getQueryUserInfo()
              this.$Message.success('Success!');
            } else {
              
              this.$Message.error(res.message || '系统错误');
            }
          })
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
  .userInfo{
    width: 100%;
    height: 100%;
    background: #ffffff;
    .userInfo-title{
      width: 100%;
      height: 60px;
      padding-left: 24px;
      line-height: 60px;
      font-size: 18px;
      font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
      border-bottom: 1px solid #cc0000;;
    }
    .userInfo-name{
      width: 977px;
      height: 64px;
      margin: 8px auto;
      border: 1px solid rgba(153,153,153,0.20);
      line-height:64px;
      span{
        font-size: 14px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: LEFT;
      }
      >:first-child{
        margin-left: 41px;
        color: #333333;
      }
      >:last-child{
        margin-left: 38px;
        color: #999999;
      }
    }
    .userInfo-user{
      width: 977px;
      height: 210px;
      margin: 0 auto;
      border: 1px solid rgba(153,153,153,0.20);
      .userInfo-img{
        margin-top: 22px;
        padding-left: 55px;
        >:first-child{
          font-size: 14px;
          font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
          font-weight: 400;
          text-align: RIGHT;
          color: #333333;
        }
        >:last-child{
          display: inline-block;
          margin-left: 37px;
          width: 68px;
          height: 68px;
          vertical-align: middle;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
      .userInfo-nickname{
        margin-top: 32px;
        padding-left: 27px;
        >:first-child{
          font-size: 14px;
          font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
          font-weight: 400;
          text-align: RIGHT;
          color: #333333;
        }
        >:last-child{
          margin-left: 38px;
          font-size: 14px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #333333;
        }
      }
      .userInfo-sex{
        margin-top: 32px;
        padding-left: 55px;
        span{
          font-size: 14px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #333333;
        }
        >:nth-child(2){
            display: inline-block;
            width: 28px;
          margin-left: 38px;
        }
        >:last-child{
          display: inline-block;
          width: 60px;
          height: 30px;
          margin-left: 727px;
          opacity: 1;
          background: #e00000;
          border-radius: 4px;
          font-size: 14px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: center;
          line-height: 30px;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
    .userInfo-email{
      margin: 0 auto;
      margin-top: 8px;
      width: 977px;
      height: 64px;
      border: 1px solid rgba(153,153,153,0.20);
      line-height: 64px;
      padding-left: 55px;
      >:first-child{
        font-size: 14px;
        font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
        font-weight: 400;
        text-align: RIGHT;
        color: #333333;
      }
      >:last-child{
        margin-left: 38px;
        font-size: 14px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #999999;
      }
    }
    .userInfo-phone{
      margin: 0 auto;
      margin-top: 8px;
      width: 977px;
      height: 64px;
      border: 1px solid rgba(153,153,153,0.20);
      line-height: 64px;
      padding-left: 41px;
      span{
        font-size: 14px;
        font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
        font-weight: 400;
        text-align: RIGHT;
        color: #333333;
      }
      >:nth-child(2){
        margin-left: 38px;
      }
      >:last-child{
        display: inline-block;
        width: 60px;
        height: 30px;
        margin-left: 665px;
        opacity: 1;
        background: #e00000;
        border-radius: 4px;
        font-size: 14px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: center;
        line-height: 30px;
        color: #ffffff;
        cursor: pointer;
      }
    }
    .userInfo-login{
      margin: 0 auto;
      margin-top: 8px;
      width: 977px;
      height: 64px;
      border: 1px solid rgba(153,153,153,0.20);
      line-height: 64px;
      padding-left: 27px;
      margin-bottom: 23px;
      span{
        font-size: 14px;
        font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
        font-weight: 400;
        text-align: RIGHT;
        color: #333333;
      }
      >:nth-child(2){
        margin-left: 38px;
      }
      >:last-child{
        display: inline-block;
        width: 60px;
        height: 30px;
        margin-left: 598px;
        opacity: 1;
        background: #e00000;
        border-radius: 4px;
        font-size: 14px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: center;
        line-height: 30px;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  .user-img{
    align-items: center;
    display: flex;
    .upload-img{
      position: relative;
      width: 100px;
      height: 100px;
      background:url(../../assets/imgs/detail/author.png) no-repeat 15px 0px;
      // background-image: '@/assets/imgs/detail/author.png';
      .edit-Titleimg{
        display: inline-block;
        width: 100px;
        height: 32px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        opacity: 1;
        background: rgba(51,51,51,0.60);
        font-size: 14px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: center;
        line-height: 32px;
        color: #ffffff;
      }
    }
  }
  .user-nickname{
    margin-top: 34px;
    padding-left: 40px;
    .nickname-title{
      font-size: 14px;
      font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
      font-weight: 400;
      text-align: RIGHT;
      color: #333333;
    }
  }
  .user-sex{
    margin-top: 36px;
    padding-left: 68px;
    .user-checkSex{
      margin-right: 37px;
      font-size: 14px;
      font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
      font-weight: 400;
      text-align: center;
      color: #333333;
      vertical-align: middle;
    }
  }
  .code-btn{
    cursor: pointer;
    font-size: 14px;
    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
    font-weight: 400;
    text-align: RIGHT;
    color: #0085ff;
  }
  .countDown-btn{
    cursor: pointer;
    font-size: 14px;
    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
    font-weight: 400;
    text-align: RIGHT;
  }
  .cancelBtn{
    width: 131px;
    height: 49px;
    display: inline-block;
    margin-right: 12px;
    background-color: #FFFFFF;
    border-radius: 5px;
    color: #999999;
    border: 1px solid #999999;
    font-size: 18px;
    line-height: 49px;
    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
    font-weight: 400;
    text-align: CENTER;
    cursor: pointer;  
  }
  .saveBtn{
    width: 131px;
    height: 49px;
    display: inline-block;
    margin-left: 12px;
    background-color: #CC0000;
    border-radius: 5px;
    color: #FFFFFF;
    border: 1px solid #CC0000;
    font-size: 18px;
    line-height: 50px;
    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
    font-weight: 400;
    text-align: CENTER;
    cursor: pointer;
  }
  
  /deep/.ivu-modal-body{
    padding: 0px;
    padding: 25px 0;
  }
  /deep/.ivu-upload-list{
    display: none;
  }
  /deep/.ivu-upload-drag{
    border: none;
    border-radius: 0px;
  }
  /deep/.ivu-upload-drag:hover{
    border: none;
  }
  /deep/.ivu-radio-wrapper{
    margin-right: 56px;
  }
  /deep/.ivu-form-inline .ivu-form-item{
    margin-left: 36px;
  }
  /deep/.ivu-form-item-required .ivu-form-item-label:before{
    display: none;
  }
</style>