<template>
  <div class="pay-order">
    <div class="container">
      <Breadcrumb />
      <div class="pay-top">
        <p class="pay-tip">订单提交成功！去付款咯~</p>
        <div class="pay-time">
          请在<span class="time red-color">{{ deadlineTime }}</span
          >完成支付,超时后将取消订单
        </div>
        <div class="pay-amount">
          待付款：<span class="amount red-color">{{ payAmount }}元</span>
        </div>
      </div>
      <div class="pay-bottom">
        <p class="title">请选择以下支付方式</p>
        <div class="line"></div>
        <RadioGroup v-model="radio" class="pay-way" @on-change="changeRadio">
          <Radio :label="0" class="QRcode-pay pay-button">
            <img :src="require('@/assets/imgs/pay/scan.png')" alt="" /><span class="radio-item"
              >扫码支付(支持微信、支付宝支付)</span
            >
          </Radio>
          <div @click="handleRadioClick">
            <Radio :disabled="isDisableUp ? true : false" :label="1" class="QRcode-pay pay-button">
            <img :src="require('@/assets/imgs/yl.png')" alt="" /><span class="radio-item"
              >银联卡支付</span
            >
          </Radio>
          </div>
         
          <Radio v-if="lyToggle" :label="2" class="QRcode-pay pay-button">
            <img :src="require('@/assets/imgs/lh.png')" alt="" /><span class="radio-item"
              >农行支付</span
            >
            <div v-if="lyPromote.length" class="lybank-text">
              <span v-for="(p, ind) in calcTagsByType('5')" :key="ind">
                {{ p }}
              </span>
            </div>
          </Radio>
          <Radio v-if="isShowOtherPay" :label="3" class="QRcode-pay pay-button">
            <img :src="require('@/assets/imgs/pay/card.png')" alt="" /><span class="radio-item"
              >对公转账</span
            >
          </Radio>
        </RadioGroup>
        <button
          v-if="!isShowAccount"
          class="btn"
          :class="isShowActive ? 'active-btn' : ''"
          @click="goCodePay"
        >
          确定
        </button>
      </div>
      <AccountInfo v-if="isShowAccount" :parentOrderId="parentOrderId" :payAmount="payAmount" :bankInfo="bankInfo" :eocAddressRestrictType="eocAddressRestrictType"/>
      <!-- 其他支付方式弹窗 -->
      <Modal
        v-model="isShow"
        :closable="false"
        :mask-closable="false"
        class-name="vertical-center-modal"
      >
        <div
          class="modal-title"
          slot="header"
          style="color: #f60; text-align: center"
        >
          <Icon type="ios-alert-outline" />
          <span class="modal-tip">重要提醒</span>
        </div>
        <div style="text-align: left; font-size: 14px; color: #333">
          <p style="text-align: left; white-space: pre-line;">
            1、我们会为每个订单生成专属的收款账号，请按要求转账到指定账号，否则将导致订单支付失败，影响发货。
          </p>
          
          <p style="text-align: left; white-space: pre-line; margin-top: 15px;">
            2、用“对公转账”付款的订单发生退货退款时，使用的积分和优惠券有退还失败的风险，请知悉！
          </p>
        </div>
        <div class="footer-button" slot="footer" v-if="shouldSelected">
          <Button @click="goTransferPay">我已阅读</Button>
        </div>
        <div class="unSelected-button" slot="footer" v-else>
          <Button >我已阅读({{ countDownTime }}秒)</Button>
        </div>
      </Modal>
      <!-- 其他页面付款提示 -->
      <Modal
        v-model="payTipShow"
        :closable="false"
        :mask-closable="false"
        class-name="pay-tip vertical-center-modal"
      >
        <div class="tip-title" slot="header">
          <Icon type="ios-alert-outline" />
          <span>请您在新打开的页面完成支付</span>
        </div>
        <div class="middle-content">
          <p>支付完成后请根据结果选择</p>
          <div class="pay-way">
            <Button class="other-pay" @click="payTipShow = false"
              >选择其他方式付款</Button
            >
            <Button class="have-pay" @click="goAllOrder">已完成付款</Button>
          </div>
        </div>
        <div class="tip-footer" slot="footer">
          <p>如果您在支付过程中遇到问题，请拨打客服电话：4006-770-878</p>
        </div>
      </Modal>
    </div>
    <div ref="formPayRef" class="formPay" v-html="formPayData"></div>
  </div>
</template>

<script>
import Breadcrumb from './components/Breadcrumb.vue'
import AccountInfo from './components/AccountInfo.vue'
import { queryAccountBookNo, queryOrderDetail, queryPayAmount, unionPay, lybankPay, getProgressPromote, abcBankSwitch } from '@/api/api'
import config from '@/config'
export default {
  name: 'PayOrder',
  components: {
    Breadcrumb,
    AccountInfo,
  },
  data() {
    return {
      isShow: false,
      countDownTime:null,
      shouldSelected: false,
      isShowAccount: false,
      isShowActive: false,
      payTipShow: false,
      payAmount: null,
      parentOrderId: null,
      bankInfo: {},
      deadlineTime: '',
      onlinePayTime: '',
      radio: 0,
      orderStatus: null, // 订单状态
      isShowOtherPay: 0, // 是否展示其他付款方式 0-不展示 1-展示
      formPayData: '', // 银联网付通表单
      lyPromote: [], // 农行优惠文案
      lyToggle: false,
      isDisableUp: false, // 是否禁用银联卡
      disableUpMessage: '', // 禁用银联卡文案
      eocAddressRestrictType: 0, // 0 不限制  1 提交订单限制  2 仅限制线下支付订单
    }
  },
  watch: {
    isShow(val) {
      if (val === true) {
        this.shouldSelected = false
        this.timeCountDown(3)
      }
    }
  },
  created() {
    this.payAmount = this.$route.query.value
    this.parentOrderId = this.$route.query.parentOrderId
    this.getlyPromote()
    this.getAbcBankSwitch()
  },
  async mounted() {
    const { serverCurrentTime, deadLineTime, payAmount, otherPayType, isDisableUp, disableUpMessage, eocAddressRestrictType } = await this.getPayDetail()
    const time = this.filterDeadline(serverCurrentTime, deadLineTime)
    this.deadlineTime = time
    this.onlinePayTime = time
    this.payAmount = payAmount
    this.isShowOtherPay = otherPayType
    this.isDisableUp = isDisableUp
    this.disableUpMessage = disableUpMessage
    this.eocAddressRestrictType = eocAddressRestrictType || 0
  },
  methods: {
    handleRadioClick() {
      const _this = this
      if (this.isDisableUp) {
        this.$Message.info(_this.disableUpMessage);
        return
      }
    },
    /* 倒计时 */
    timeCountDown(time){
      this.countDownTime = time
      let timer = setInterval(() => {
        this.countDownTime -=1
        if(this.countDownTime <= 0){
          this.shouldSelected = true
          clearInterval(timer)
        }
      },1000)
    },
    // 订单状态校验
    orderStatusCheck() {
      return new Promise(async resolve => {
        const { state, orderItems } = await this.getOrderDetail()
        // state: 1-待付款
        if (state !== 1) {
          this.$Modal.warning({
            title: '当前订单状态已变更，请刷新获取最新订单信息。',
            onOk: () => {
              // 单商品跳订单详情, 多商品跳订单列表
              if (orderItems.length === 1) {
                this.$router.replace({
                  path: '/newOrderDetail',
                  query: {
                    orderId: this.$route.query.orderId,
                    parentOrderId: this.$route.query.parentOrderId,
                  },
                })
              } else {
                this.$router.replace({ path: '/newOrder' })
              }
            },
          })
          resolve(false)
        } else {
          resolve(true)
        }
      })
    },
    goTransferPay() {
      // 订单状态校验
      // if (!await this.orderStatusCheck()) {
      //   return
      // }
      this.isShow = false
      // this.isShowAccount = true
    },
    //根据类型计算标签数组
    calcTagsByType(type) {
      let list = [...this.lyPromote]
      let bankType = this.lookupBankTypeByType(type)
      if(bankType) {
        let tags = list?.filter(item => item.bankType === bankType && item.status === 20).map(it => {
          return it.promotionInfo
        })
        return this.upToFourTags(tags)
      }
      return []
    },
    lookupBankTypeByType(type) {
      let bankType = undefined
      if(type === '5') {
        //农行支付
        bankType = 3
      } else if(type === '8') {
        //花呗分期
        bankType = 2
      } else if(type === '9') {
        //信用卡分期
        bankType = 1
      }
      return bankType
    },
    //最多选取四个标签
    upToFourTags(tags) {
      if(tags) {
        return tags.length > 4 ? tags.slice(0,4) : tags
      }
      return []
    },
    // 获取订单详情
    getOrderDetail() {
      return new Promise(async (resolve, reject) => {
        const params = {
          orderId: this.$route.query.orderId,
          userId: this.$store.state.userInfo.userId
        }
        const res = await queryOrderDetail(params)
        if (res.result === 0) {
          console.log('------', res)
          resolve(res.data)
        } else {
          reject(false)
        }
      })
    },
    async goCodePay() {
      // 订单状态校验
      if (!await this.orderStatusCheck()) {
        return
      }
      if (this.radio == 1) {
        this.handleUnionPay()
        return
      }
      if (this.radio == 2) {
        this.handlelyPay()
        return
      }
      let codePayPath = this.$router.resolve({
        path: '/CodePay',
        query: {
          value: this.payAmount,
          orderId: this.$route.query.orderId,
          parentOrderId: this.parentOrderId
        }
      })
      window.open(codePayPath.href, '_blank')
      this.payTipShow = true
    },
    handleUnionPay() {
      const params = {
        orderId: this.parentOrderId,
        buyerId: this.$store.state.userInfo.userId,
        buyerOrderShopId: config.STOREID,
        childOrderId: this.$route.query.orderId,
        multiOrder: 1
      }
      unionPay(params).then(res=> {
        const { data } = res.data.data
        if (res.data.paymentCashierMode === 3) {
          this.formPayData = data
          this.$nextTick(() => {
            this.$refs.formPayRef.getElementsByTagName('form')[0].submit()
          })
        }

      })
    },
    handlelyPay() {
      const params = {
        orderId: this.parentOrderId,
        buyerId: this.$store.state.userInfo.userId,
        buyerOrderShopId: config.STOREID,
        childOrderId: this.$route.query.orderId,
        multiOrder: 1
      }
      lybankPay(params).then(res=> {
        const { paymentUrl } = res.data
        window.location.replace(paymentUrl)
      })
    },
    async changeRadio(index) {
      this.isShowActive = true
      if ([0, 1, 2].includes(index)) {
        // this.deadlineTime = this.onlinePayTime
        this.isShowAccount = false
      } else if (index === 3) {
        const isAccountBook = await this.queryAccountBook()
        if (isAccountBook) {
          this.isShow = true
          this.isShowAccount = true
        } else {
          this.radio = 0
        }
      }
    },
    goAllOrder(){
      this.payTipShow = false
      this.$router.push('/user/newOrder')
    },
    filterDeadline(currentTime, deadline) {
      if (!currentTime || !deadline) return ''
      const timestamp = +new Date(deadline) - +new Date(currentTime)
      if (timestamp < 0) {
        this.$router.replace({
          path: '/payTimeout'
        })
      }
      let d, h, m, s;
      d = parseInt(timestamp / 1000 / 60 / 60 / 24);
      h = parseInt(timestamp / 1000 / 60 / 60 % 24);
      m = parseInt(timestamp / 1000 / 60 % 60);
      s = parseInt(timestamp / 1000 % 60);
      let timeStr = ''
      if (d > 0) {
        timeStr = m === 0 ? `${d}天${h}小时` : `${d}天${h}小时${m}分`
      } else {
        if (h < 1) {
          timeStr = s === 0 ? `${m}分钟内` : `${m + 1}分钟内`
        } else {
          timeStr = `${h}小时${m}分`
        }
      }
      return timeStr
    },

    // 获取订单详情
    getPayDetail() {
      return new Promise(async (resolve, reject) => {
        const params = {
          orderId: this.$route.query.parentOrderId,
          userId: this.$store.state.userInfo.userId
        }
        const res = await queryPayAmount(params)
        if (res.result === 0) {
          resolve(res.data)
        } else {
          reject(false)
        }
      })
    },
    // 校验系统是否有可用的账簿号
    async queryAccountBook() {
      const { parentOrderId } = this.$route.query
      const res = await queryAccountBookNo({ parentOrderId })
      if (res.result === 0) {
        this.bankInfo = res.data || {}
        this.deadlineTime = this.filterDeadline(res.data?.systemTime, res.data?.orderPaymentTime)
        return true
      } else {
        this.$Modal.warning({
          title: res.message,
        })
        return false
      }
    },
    getlyPromote() {
      getProgressPromote().then(res=> {
        const { data, result } = res
        if (result == 0) {
          this.lyPromote = data
        }
      })
    },
    getAbcBankSwitch() {
      abcBankSwitch().then(res => {
        const { data, result } = res
        if (result == 0) {
          this.lyToggle = data
        }
      })
    }
  },
}
</script>
<style lang="less" scoped>
.pay-order {
  width: 1400px;
  margin: auto;
  height: 100%;
  background-color: #fff;
  padding: 50px 0 305px;
}
.disabled-payment {
  opacity: 6;
}
.pay-top {
  color: #333;
  font-size: 18px;
  margin-top: 60px;
  .pay-tip {
    font-size: 24px;
    font-weight: 600;
  }
  .pay-time {
    margin: 24px 0;
  }
  .amount {
    font-size: 22px;
    font-weight: 600;
  }
}
.pay-bottom {
  margin-top: 60px;
  color: #666;
  font-size: 14px;
  .title {
    font-size: 22px;
  }
  .line {
    width: 100%;
    height: 1px;
    margin: 40px 0 50px 0;
    background-color: #e5e5e5;
  }
  .pay-way {
    font-size: 16px;
    display: flex;
    .pay-button {
      margin-right: 38px;
      img {
        width: 30px;
        height: 30px;
        vertical-align: middle;
        margin: 0 15px 0 35px;
      }
      .radio-item {
        font-size: 16px;
      }
    }
    .active-button {
      border: 1px solid #cc0000;
    }
    .lybank-text {
      width: 274px;
      margin: 3px 0 0 102px;
      display: flex;
      flex-wrap: wrap;
      span {
        display: inline-block;
        padding: 0 5px;
        margin-top: 3px;
        margin-right: 8px;
        color: #EF2F2F;
        font-size: 10px;
        font-weight: 500;
        line-height: 18px;
        border: 1px solid #ef2f2f;
        border-radius: 31px;
      }
    }
  }
  .btn {
    font-size: 20px;
    color: #f2f2f2;
    padding: 10px 50px;
    margin-top: 75px;
    border-radius: 4px;
    background-color: rgba(204, 0, 0, 0.6);
  }
  .active-btn {
    background-color: #cc0000;
  }
  /deep/.ivu-radio-disabled .ivu-radio-inner {
    background: rgba(153,153,153,0.40);
    border: none;
    position: relative;
    &::after {
      position: absolute;
      width: 10px;
      height: 2px;
      display: table;
      border-left: 0;
      content: ' ';
      background-color: #fff;
      top: 7px;
      left: 3px;
      transform: scale(1);
      opacity: 1;
    }
  }
  /deep/.ivu-radio-wrapper-disabled {
    img, .radio-item {
      opacity: 0.6;
    }
  }
  /deep/.ivu-radio-checked .ivu-radio-inner {
    border-color: #cc0000;
  }
  /deep/.ivu-radio-checked .ivu-radio-inner:after {
    background-color: #cc0000;
  }
  /deep/.ivu-radio-focus {
    box-shadow: none;
  }
}
/deep/.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .ivu-modal {
    top: 0;
  }
}
/deep/.ivu-modal-content {
  width: 532px;
  height: 318px;
  padding: 26px 77px 42px;
  .modal-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    .ivu-icon-ios-alert-outline {
      font-size: 34px;
    }
    .modal-tip {
      font-size: 20px;
      color: #cc0000;
      margin: 24px 0;
    }
  }
  .ivu-modal-header {
    border: none;
    padding: 0;
  }
  .ivu-modal-body {
    padding: 0;
    margin-bottom: 30px;
  }
  .ivu-modal-footer {
    border: none;
    padding: 0;
    width: 140px;
    height: 40px;
    margin: 0 auto;
    .footer-button {
      width: 100%;
      height: 100%;
      .ivu-btn {
        width: 100%;
        height: 100%;
        color: #fff;
        background-color: #cc0000;
        border-radius: 4px;
      }
    }
    .unSelected-button {
      width: 100%;
      height: 100%;
      .ivu-btn {
        width: 100%;
        height: 100%;
        color: #fff;
        background-color: #cc0000;
        border-radius: 4px;
        opacity: 0.5;
      }
    }
  }
}
.red-color {
  color: #cc0000;
}
/deep/.pay-tip {
  .ivu-modal-content {
    width: 532px;
    height: 318px;
    padding: 70px 54px 60px;
    .ivu-modal-body {
      margin-bottom: 24px;
    }
    .ivu-modal-footer {
      width: 100%;
    }
  }
  .tip-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    .ivu-icon-ios-alert-outline {
      color: #f60;
      margin-right: 10px;
      font-size: 34px;
      font-weight: 500;
    }
    span {
      font-size: 22px;
    }
  }
  .middle-content {
    margin-top: 40px;
    p {
      text-align: center;
      margin-bottom: 24px;
    }
    .pay-way {
      margin: 0 auto;
      display: flex;
      justify-content: center;

      button {
        height: 40px;
      }
      .other-pay {
        width: 190px;
        background-color: #e5e5e5;
        border: 1px solid #999999;
        margin-right: 30px;
      }
      .have-pay {
        width: 140px;
        background-color: #cc0000;
        color: #f2f2f2;
      }
    }
  }
  .tip-footer {
    width: 100%;
    font-size: 14px;
  }
}
</style>
