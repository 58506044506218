<template>
  <div class="user-address">
    <div v-if="addressList.length !==0" @click="showAddDialog('formValidate')" class="user-addAddress">新增收货地址</div>
    <ul style="overflow: hidden;padding:0 48px">
      <li v-for="(item,index) in addressList" :key="index" class="user-shipping">
        <Icon type="ios-close" size="28" @click="deleteAddress(item)" />
        <div class="userinfo">
          <span class="addressTag" v-if="item.tagId ===1">家</span>
          <span class="addressTag" v-else-if="item.tagId ===2">公司</span>
          <span class="addressTag" v-else-if="item.tagId ===3">学校</span>
          <span class="addressTag" v-else-if="item.tagName !==''">{{item.tagName}}</span>
          <span class="userName">{{item.contactPerson}}</span>
          <span class="userName region">{{item.provinceName}}</span>
          <span v-if="item.isDefault ===1" class="default-address">默认地址</span>
        </div>
        <div class="user-receiving" style="margin-top:20px; padding-left:38px;">
          <span class="user-information">收货人:</span>
          <span class="user-profile">{{item.contactPerson}}</span>
        </div>
        <div class="user-receiving">
          <span class="user-information">所在地区:</span>
          <span class="user-profile">{{item.address}}</span>
        </div>
        <div class="user-receiving">
          <span class="user-information">详细地址:</span>
          <div class="user-profile">{{item.detailAddress}}</div>
        </div>
        <div class="user-receiving">
          <span class="user-information">联系电话:</span>
          <span class="user-profile">{{item.contactPhone}}</span>
          <div class="user-handle">
             <span @click="settingAddress(item)" v-if="item.isDefault !==1" style="margin-right: 24px;">设为默认地址</span>
             <span @click="editAddress(item)">编辑</span>
          </div>
        </div>
      </li>
    </ul>
    <!-- 缺省页 -->
    <div v-if="addressList.length ===0" class="receivingAddress">
       <img src="@/assets/imgs/shippingAddress.png" alt="">
       <div class="noFavorite">您还没有收货地址</div>
       <div @click="showAddDialog('formValidate')" class="noFavorite" style="color:#0085FF;margin-top: 10px;cursor: pointer;">新建地址</div>
    </div>
    <!-- 删除地址弹窗 -->
    <delete-dialog :address="address" ref="showDialog"></delete-dialog>
    <!-- 添加/编辑收货地址弹窗 -->
    <Modal
        v-model="addDialog"
        @on-cancel="cancel('formValidate')"
        width="700"
        :styles="{top: '20px'}"
        :footer-hide="true"
        :title="title">
        <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
            <div class="userProvinces">
               <FormItem class="actives" label="所在地区: " prop="province"> 
                 <!-- 省 -->
                 <Select @on-change="getCity" v-model="formValidate.province"   style="width:130px">
                   <Option v-for="(item,index) in formValidate.provinceList" :value="item.addressCode+','+item.addressName" :key="index">{{ item.addressName }}</Option>
                 </Select>
               </FormItem>
               <FormItem prop="city" style="position: absolute;top: 0px;left: 225px;">
                 <!-- 市 -->
                 <Select @on-change="getRegionList" v-model="formValidate.city" style="width:140px">
                   <Option v-for="(item,index) in formValidate.cityList" :value="item.addressCode+','+item.addressName" :key="index">{{ item.addressName }}</Option>
                 </Select>
               </FormItem> 
               <FormItem prop="region" style="position: absolute;top: 0px;left: 375px;">
                 <!-- 区 -->
                 <Select @on-change="getCountryCode" v-model="formValidate.region" style="width:140px">
                   <Option v-for="(item,index) in formValidate.regionList" :value="item.addressCode+','+item.addressName" :key="index">{{ item.addressName }}</Option>
                 </Select>
               </FormItem>
               <FormItem prop="town" style="position: absolute;top: 0px;left: 525px;">
                 <!-- 街道 parentCode--> 
                 <Select @on-change="getStreetCode" v-model="formValidate.town" style="width:140px">
                   <Option v-for="(item,index) in formValidate.streetList" :value="item.addressCode+','+item.addressName" :key="index">{{ item.addressName }}</Option>
                 </Select>
               </FormItem>
            </div>
        <!-- <FormItem label="所在地区: " prop="province">
          省
          <Select @on-change="getCity"  style="width:185px">
            <Option v-for="(item,index) in formValidate.provinceList" :value="item.addressCode+','+item.addressName" :key="index">{{ item.addressName }}</Option>
          </Select>
          市
          <Select @on-change="getRegionList" v-model="formValidate.city" style="width:185px">
            <Option v-for="(item,index) in formValidate.cityList" :value="item.addressCode+','+item.addressName" :key="index">{{ item.addressName }}</Option>
          </Select>
          区
          <Select v-model="formValidate.region" style="width:185px">
            <Option v-for="(item,index) in formValidate.regionList" :value="item.addressCode" :key="index">{{ item.addressName }}</Option>
          </Select>
        </FormItem> -->

        <FormItem label="详细地址: " prop="address">
           <Input style="width:99%" v-model="formValidate.address" type="textarea" maxlength="50" show-word-limit placeholder="详细地址，街道、门牌号等"></Input>
        </FormItem>
        <FormItem label="收货人: " prop="consignee">
          <Input v-model="formValidate.consignee" style="width: 169px" />
        </FormItem>
        <FormItem label="手机号: " prop="mobile">
          <Input maxlength="11" v-model="formValidate.mobile" style="width: 169px" />
        </FormItem>
        <FormItem label="地址标签: " class="addresTag">
          <Input @on-blur="customTag" v-model="inputContent" maxlength="5" style="width: 169px" />
          <div class="label-list">
            <div class="completeTag">
              <span>建议填写常用别名</span>
              <p @click="selectedChange(1)" :class="{'selected': selected === 1}">家</p>
              <p @click="selectedChange(2)" :class="{'selected': selected === 2}">公司</p>
              <p @click="selectedChange(3)" :class="{'selected': selected === 3}">学校</p>
            </div>
          </div>
        </FormItem>
        <FormItem>
          <Checkbox v-model="formValidate.single"></Checkbox>
          <span>设为默认</span>
        </FormItem>
           <div class="addAddress">
             <span @click="save('formValidate')" class="saveBtn">保存</span>
             <span @click="cancel('formValidate')" class="cancelBtn">取消</span>
           </div>
    </Form>
    </Modal>
    <Page @on-change="changePage" :current="pageIndex" :total="total" />
  </div>
</template>

<script>
import{getAddressList,getUserDetail,setAddress,getCityList,addOrUpdateAddress} from '@/api/api'
import deleteDialog from "./address/deleteDialog.vue"

export default {
  name:'UserAddress',
  components:{
    deleteDialog
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      const regPhone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if(regPhone.test(value)){
        callback()
      }else{
        callback(new Error('请输入正确的手机号码！'))
      }
    }

    return{
      addressList:[],
      address:{},
      isdefault:'',
      addDialog:false,
      title:'',
      tagId:0,
      selected:0, //标签是否选中
      inputContent: '', //自定义地址标签内容
      pageIndex:1,
      pageSize:10,
      total:0,

      formValidate: {
        address:'',
        consignee:'', //收货人
        mobile:'',
        postCode:'',
        single:false,
        provinceList:[],
        province:'',
        cityList:[],
        city:'',
        regionList:[],
        streetList: [],
        region:'',
        town: '',
        cityCode:'',  //市编号
        countryCode:'',  //区编号
        provinceCode:'',   //省编号
        townCode: '', //街道编号
        provinceAddress:'',
        isdefault:2,
        id:'',
        },
       ruleValidate: {
         province: [
           { required: true, message: '请选择地区', trigger: 'change' }
         ],
         city: [
           { required: true, message: '请选择地区', trigger: 'change' }
         ],
         region: [
           { required: true, message: '请选择地区', trigger: 'change' }
         ], 
         town: [
           { required: true, message: '请选择地区', trigger: 'change' }
         ], 
         address:[
           { required: true, message: '请填写详细地址', trigger: 'blur' }
         ],
         consignee:[
           { required: true, message: '请输入收货人姓名', trigger: 'blur' }
         ],
         mobile:[
           { required: true, message: '请填写手机号码', trigger: 'blur' },
           { validator: validatePhone, trigger: 'blur' }
         ] 
         },
    }
  },
  created() {
    this.getAddress()
    this.getCityAddress()
  },
  methods:{
    // 自定义标签
    customTag() {
      if(this.inputContent !=='') {
        this.tagId = 4
      }
    },
    deleteAddress (val){
      this.address = val
      this.$refs.showDialog.deleteDialog = true
    },
    // 获取地址列表
    getAddress() {
      getAddressList({
        userId:this.$store.state.userInfo.userId,
        pageIndex:this.pageIndex,
        pageSize:this.pageSize
      }).then((res) =>{
          this.addressList = res.data.records
          this.total = res.data.total
      })
    },
    // 设置默认地址
    settingAddress(item) {
      setAddress({
        id: item.id,
        userId: item.userId,
      }).then((res) => {
        if(res.result === 0){
          this.getAddress()
        }
      })
    },
    //添加收货地址弹窗
    showAddDialog(name){
      this.$refs[name].resetFields();
      this.title = '添加地址'
      this.addDialog = true
      this.tagId = 0
      this.selected = 0
      this.inputContent = ''
    },
    //编辑收货地址弹窗
    editAddress(item){
      this.title = '编辑地址'
      this.addDialog = true
      getUserDetail({
        id:item.id,
        userId:item.userId
      }).then((res) =>{
        this.formValidate.province = res.data.provinceCode+','+res.data.provinceName
        this.formValidate.provinceCode = res.data.provinceCode
         getCityList({
        parentCode: res.data.provinceCode
      }).then((res) =>{
          this.formValidate.cityList = res.data
      })
        this.formValidate.city = res.data.cityCode+','+res.data.cityName
        this.formValidate.cityCode = res.data.cityCode
        getCityList({
        parentCode: res.data.cityCode
      }).then((res) =>{
          this.formValidate.regionList = res.data
      })
        this.formValidate.region = res.data.countryCode+','+res.data.countryName
        this.formValidate.countryCode = res.data.countryCode
        getCityList({
        parentCode: res.data.countryCode
      }).then((res) =>{
          this.formValidate.streetList = res.data
      })
        this.formValidate.town = res.data.townCode+','+res.data.townName
        this.formValidate.provinceAddress = res.data.address
        this.formValidate.address = res.data.detailAddress.trim()
        this.formValidate.consignee = res.data.contactPerson
        this.formValidate.mobile = res.data.contactPhone
        this.formValidate.id = res.data.id
        let tagId = res.data.tagId ? Number(res.data.tagId) : 0
        this.tagId = tagId
        this.selected = tagId
        if (tagId === 4 || tagId === 0) {
          this.inputContent = res.data.tagName
        }
        if (res.data.isDefault === 1) {
          this.formValidate.single = true
        }else {
          this.formValidate.single = false
        }
      })
    },
    //保存
    save(name) {
    //   this.addDialog = false
      // this.$refs[name].resetFields();
    if(this.formValidate.single) {
      this.formValidate.isdefault = 1
    } else {
      this.formValidate.isdefault = 2
    }
    this.$refs[name].validate((valid) => {
      if (valid) {
        addOrUpdateAddress({
          id:this.formValidate.id,
          address: this.formValidate.provinceAddress,
          cityCode: this.formValidate.cityCode,
          contactPerson: this.formValidate.consignee,
          contactPhone: this.formValidate.mobile,
          countryCode: this.formValidate.countryCode,
          townCode: this.formValidate.townCode,
          detailAddress: this.formValidate.address.trim(),
          isdefault: this.formValidate.isdefault,
          provinceCode: this.formValidate.provinceCode,
          tagId: this.tagId,
          tagName: this.inputContent,
          userId: this.$store.state.userInfo.userId,
        }).then((res) =>{
            if(res.result ===0) {
              this.$Message.success('Success!');
              this.getAddress()
              this.addDialog = false
              this.inputContent = ''
              this.formValidate.id = ''
              this.formValidate.single = false
              this.formValidate.isdefault = 2
              this.$refs[name].resetFields();
            }
        })
      }
    })
      this.formValidate.postCode = ''   
    },
    //取消
    cancel (name) {
      this.$refs[name].resetFields();
      this.addDialog = false
      this.formValidate.single = false
      this.formValidate.postCode = ''
    },
    // 获取城市信息parentCode: 0
    getCityAddress() {
      getCityList().then((res) =>{
        this.formValidate.provinceList = res.data
      }) 
    },
    // 市
    getCity(val) {
      const info = val.split(',')
      this.formValidate.provinceCode = info[0]
      this.formValidate.provinceAddress = info[1]
      getCityList({
        parentCode: info[0]
      }).then((res) =>{
          this.formValidate.cityList = res.data
      })
    },
    // 区
    getRegionList(val) {
      const regionInfo = val.split(',')
      this.formValidate.cityCode = regionInfo[0]
      this.formValidate.provinceAddress = this.formValidate.provinceAddress+''+regionInfo[1]
      getCityList({
        parentCode: regionInfo[0]
      }).then((res) =>{
          this.formValidate.regionList = res.data
      })
    },
    // 获取区编号
    getCountryCode(val) {
      const CountryInfo = val.split(',')
      this.formValidate.countryCode = CountryInfo[0]
      this.formValidate.provinceAddress = this.formValidate.provinceAddress+''+CountryInfo[1]
      getCityList({
        parentCode: CountryInfo[0]
      }).then((res) =>{
          this.formValidate.streetList = res.data
      })
    },
    // 获取街道编号
    getStreetCode(val) {
      const StreetInfo = val.split(',')
      this.formValidate.townCode = StreetInfo[0]
      this.formValidate.provinceAddress = this.formValidate.provinceAddress+''+StreetInfo[1]
    },
    //标签选择器
    selectedChange(id) {
      let sid = this.selected
      sid === id ? this.selected = 0 : this.selected = id
      this.tagId = this.selected
    },
    changePage(page) {
      this.pageIndex = page
      this.getAddress()
    },
  },
}
</script>

<style lang="less" scoped>
  .user-address{
    width: 100%;
    height: 100%;
    // padding-top: 32px;
    background: #fff;
    // padding-bottom: 113px;
    overflow: hidden;
    .user-addAddress{
      width: 144px;
      height: 43px;
      margin: 32px 48px 8px 48px;
      opacity: 1;
      background: rgba(239,47,47,0.05);
      border: 1px solid #ef2f2f;
      border-radius: 5px;
      font-size: 16px;
      font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
      font-weight: bold;
      text-align: center;
      line-height: 43px;
      color: #ef2f2f;
      cursor: pointer;
    }
    .user-shipping{
      width: 100%;
      height: 180px;
      position: relative;
      margin-top: 24px;
      border: 1px solid rgba(153,153,153,0.60);
      /deep/.ivu-icon{
        position: absolute;
        right: 0;
      }
      .userinfo{
        width: 100%;
        padding: 24px 0 0 24px;
        .addressTag{
          display: inline-block;
          min-width: 48px;
          height: 22px;
          margin-right: 24px;
          padding: 0 12px;
          opacity: 1;
          background: #0085ff;
          border-radius: 21px;
          font-size: 12px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: #ffffff;
          line-height: 22px;
        }
        .userName{
          font-size: 18px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: #000000;
          vertical-align: middle;
        }
        .region{
          margin-left: 5px;
        }
        .default-address{
          width: 72px;
          height: 28px;
          margin-left: 24px;
          display: inline-block;
          opacity: 1;
          background: #cc0000;
          border-radius: 4px;
          font-size: 12px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          line-height: 28px;
        }
      }
      .user-receiving{
        margin-top:8px;
        padding-left:24px;
        padding-right: 24px;
        display: flex;
        .user-information{
          margin-right: 10px;
          font-size: 14px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          color: #999999;
        }
        .user-profile{
          flex: 1;
          font-size: 14px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          white-space:pre-line;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .user-handle{
          float: right;
          span{
            font-size: 14px;
            font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
            font-weight: 400;
            color: #0085ff;
            cursor: pointer;
          }
        }
      }
    }
    .receivingAddress{
      text-align: center;
      margin-top: 24px;
      img{
        width: 200px;
        height: 200px;
      }
      .noFavorite{
        // margin-top: 43px;.
        text-align: center;
        font-size: 18px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #999999;
      }  
    }
  }
// 添加收货地址弹窗样式
/deep/.ivu-select{
  margin-right: 8px;
}
/deep/.ivu-form .ivu-form-item-label{
  width: 83px !important;
}
.addAddress{
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    span{
      display: inline-block;
      width: 132px;
      height: 45px;
      opacity: 1;
      border-radius: 4px;
      font-size: 18px;
      font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
      font-weight: 400;
      text-align: CENTER;
      line-height: 45px;
    }
    .saveBtn{
      margin-right:24px;
      background: #cc0000;
      color: #ffffff;
      cursor: pointer;
    }
    .cancelBtn{
      border: 1px solid #999999;
      color: #999999;
      cursor: pointer;
    }
}
.userProvinces{
  position: relative;
  /deep/.ivu-form-item-content{
    margin-left: 0px !important;
  } 
}
.actives{
  /deep/.ivu-form-item-error-tip{
    left: 83px !important;
  }
}

// 地址标签样式
.addresTag{
/deep/.ivu-form-item-content{
    display: flex;
}
}
.label-list{
  .completeTag{
    display: flex;
    align-items: center;
    span{
      margin-left: 15px;
      margin-right: 11px;
      font-size: 14px;
      font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #999999;
    }
      p{
      //   min-width: 50px;
       width: 48px;
       height: 27px;
       border: 1px solid #cccccc;
       font-size: 13px;
       text-align: center;
       color: #333333;
       border-radius: 13.5px;
       line-height: 25px;
       margin-right: 12px;
       box-sizing: border-box;
       font-family: PingFang SC Medium, PingFang SC Medium-Medium;
       font-weight: 500;
       cursor: pointer;
       &:hover{
         background-color: #ef2f2f;
         border-color: #ef2f2f;
         color: #fff;
       }
     }
    .selected{
      background: #0085FF;
      color: #ffffff;
      border: none;
      font-family: PingFang SC Medium, PingFang SC Medium-Medium;
      font-weight: 500;
      border: 1px solid #e7f1ff;
    }
    }
  }
  /deep/.ivu-page{
    text-align: center;
    margin-top: 100px;
    margin-bottom: 10px;
  }
 /deep/ .ivu-checkbox-checked .ivu-checkbox-inner {
    border-color: #cc0000;
    background-color: #cc0000;
}
</style>