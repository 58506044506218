<template>  
  <div class="coupon-outer">
    <span v-if="showCouponTitle" @click.stop="handleClick(index)">领券</span>
    <div class="coupon-list" v-for="(item,index) in layoutCouponList" :key="item.couponId">
      <span :class="{'coupon-item':true}" @click.stop="handleClick(index)" >{{item.layoutName}}</span>
      <img :src="showPopup && index === activeIndex?require('@/assets/imgs/detail/coupon-icon-up.png'):require('@/assets/imgs/detail/coupon-icon-down.png')" alt="">
    </div>
    <div class="coupon-popup" v-show="showPopup" @click.stop="() => {}">
      <div class="header">
        <h3 class="coupon-title">可领优惠券</h3>
        <Icon type="ios-close" size="25" @click="showPopup = false"/>
      </div>
      <div class="coupon-content">
        <template v-for="(k,index) in customCouponList">
          <p v-if="k.couponGroupName" class="discount" :key="index">{{k.couponGroupName}}</p>
          <div v-for="ele in k.couponCommodityDetailDataVOList" class="inner">
            <coupon-item :couponInfo="ele" />
            <p :class="['coupon-btn',filterBtnType(ele).className]" @click="handleDraw(k, ele)">{{filterBtnType(ele).txt}}</p>
            <!-- 已领取标记 -->
            <div v-if="ele.hadGet && ele.receiveState !='6'" class="discounts-item-mark"></div>
          </div>
        </template>
      </div>
    </div>   
  </div>
</template>
 
<script>
import { mapState } from 'vuex';
import { getShopCoupon } from '@/api/api'
import CouponItem from './CouponItem.vue'

export default {
  name: '',
  components:{
    CouponItem
  },
  props:{
    // 优惠券标签文案
    layoutCouponList: {
      type: Array,
      default: () => {
        return [{
          layoutName: '优惠券'
        }]
      }
    },
    // 优惠券列表
    couponList:Array,
    showCouponTitle: {
      type: Boolean,
      require: false,
      default: false
    },
    shopId: String
  },
  data () {
    return {
      showPopup:false,
      activeIndex:0,
    }
  },
  computed: {
    ...mapState(['userInfo']),
    customCouponList(){
      return this.couponList
    }
  },
  mounted () {
    document.addEventListener('click',this.listenerUtils)
  },
  methods:{
    handleClick(index) {
      this.showPopup = !this.showPopup;
      this.activeIndex = index
      if(this.showPopup)
        this.$emit('init');
    },
    /**
     * 变更按钮显示状态
     * @param {reState}  按钮状态
     * @param {couponId} 按钮Id
     * */ 
    setState(reState, couponId) {
      for (let i = 0; i < this.customCouponList.length; i += 1) {
        const k = this.customCouponList[i];
        let indexCache
        const indexC = k.couponCommodityDetailDataVOList.findIndex((j, index) => {
          indexCache = index
          return j.couponId === couponId
        })

        if (indexC >= 0) {
          this.customCouponList[i].couponCommodityDetailDataVOList[indexCache].receiveState = reState
          return
        }
      }
    },
    /**
     * 跳转去使用页面
    */
    // TODO:跳转到商品列表
    handleToUse(item) {
      const { costAllocation, usingRangeInfoVOs, couponUsingRange } = item
      const categoryId = usingRangeInfoVOs && usingRangeInfoVOs.map((id) => id.couponUsingId).join(',') // 平台类目
      // 平台通用参数
      let data = {
        shopId: this.shopId,
        costAllocation,
        couponUsingRange,
        type: 'coupon'
      }
      if (costAllocation === 1) {
        // 店铺通用
        if (couponUsingRange === 2) {
          data.shopId = categoryId
        }
        // 品类通用
        if (couponUsingRange === 3) {
          data.categoryId = categoryId
        }
      }
      // 部分商品通用
      if (couponUsingRange === 4) {
        data.skuId = categoryId
      }
      
      sessionStorage.setItem('couponData', JSON.stringify(data))
      this.$router.push({
        name: 'couponGoodsList',
        query: {
          type: 'coupon'
        }
      })
    },
    // 领取优惠劵
    async handleDraw (k, item) {
      if (item.receiveState == '3') {
        this.handleToUse(item)
        return
      }
      if (item['receiveState'] != 1) return
      if (!this.userInfo.userId) {
        // 进入登录页
        this.$router.push({
          path: '/login',
          query: { back: window.location.href }
        })
        return
      }
      const { couponId } = item
      const { message, data: { state: couponState, hadGet } } = await getShopCoupon({ couponId, userId:this.userInfo.userId})
      // couponState优惠劵状态 10 领取成功 20 已领取 30 已领完
      // 领取过一次，则打上图印
      if (hadGet) {
        item.hadGet = true
      }
      switch (couponState) {
        case 20:
          this.setState('3', couponId) // 去使用
          break
        case 30:
          this.setState('2', couponId) // 已抢完
          break
        case 40:
          this.setState('5', couponId) // 未开始
          break
        case 80:
          this.setState('6', couponId) // 已使用
          break
        case 90:
          this.setState('8', couponId) // 已过期
          break
      }

      // this.$Message.success(message);
    },
    // 优惠券按钮过滤
    filterBtnType(btnData) {
      const btnType = [
        { receiveState: 1, txt: '领取', className: 'coupon-btn-red' },
        { receiveState: 2, txt: '已抢光', className: 'coupon-btn-gray' },
        { receiveState: 3, txt: '去使用', className: 'coupon-btn-red' },
        { receiveState: 5, txt: '未开始', className: 'coupon-btn-gray' },
        { receiveState: 6, txt: '已使用', className: 'coupon-btn-gray' },
        { receiveState: 8, txt: '已过期', className: 'coupon-btn-red' },
      ]
      return btnType.find((item) => item.receiveState == btnData.receiveState) ?? {}
    },
    listenerUtils(){
      this.showPopup && (this.showPopup = false)
    }
  },
  beforeDestroy () {
    document.removeEventListener('click',this.listenerUtils)
  }
}
</script>
 
<style lang="less" scoped>
  .triangle{
    width: 20px;
    height: 20px;
    background-color: #999;
  }

  .coupon-outer{
    margin-left: 15px;
    color: #EF2F2F;
    font-size: 14px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    > span:first-child{
      text-align: center;
      width:46px;
      height:27px;
      line-height: 27px;
      margin-right: 5px;
      display: inline-block;
      color: red;
      border:1px solid red;
    }
    .coupon-list{
      display: flex;
      align-items: center;
      margin-right: 15px;
      margin-bottom: 5px;
      img{
        width: 8px;
        height: 8px;
      }
      .coupon-item{
        display: block;
        text-align: center;
        padding: 5px 15px;
        background:url('~@/assets/imgs/detail/coupon.png') no-repeat;
        background-size: 100% 100%;
        position: relative;
        cursor: pointer;
        
        &:hover{
          background:url('~@/assets/imgs/detail/coupon-hover.png') no-repeat;
          background-size: 100% 100%;
          color: #fff;
        }
      }
    }
  }

  .coupon-popup{
    position: absolute;
    left: 0;
    top: 40px;
    border: 1px solid #999;
    z-index: 99999;
    padding: 10px 0;
    background-color: #fff;
    &::before{
      position: absolute;
      left: 20px;
      top: -20px;
      width: 0;
      height: 0;
      display: block;
      content: '';
      border: 10px solid transparent;
      border-bottom-color: #999;
    }
    &::after{
      position: absolute;
      left: 22px;
      top: -16px;
      width: 0;
      height: 0;
      display: block;
      content: '';
      border: 8px solid transparent;
      border-bottom-color: #fff;
    }
    
    .coupon-title{
      color: #333;
      font-size: 14px;
      font-weight: bold;
    }
    .header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
    }
    .coupon-content{
      width: 420px;
      height: 500px;
      overflow-x: hidden;
      overflow-y: scroll;
      padding:0 10px;
      .discount{
        font-size: 14px;
        font-weight: 700;
        color: #1a1a1a;
        margin: 10px 0 15px 0;
      }
      .inner{
        position: relative;
        margin-bottom: 10px;
        .discounts-item-mark{
          position: absolute;
          top: 0;
          right: 0;
          width: 45px;
          height: 45px;
          z-index: 999;
          background: url('~@/assets/imgs/discounts/yiyongwan@2x 3@2x.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      .coupon-btn{
        width: 70px;
        height: 42px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        font-size: 18px;
        font-weight: bold;
        line-height: 42px;
        text-align: center;
        border-radius: 2px;
        white-space: nowrap;
        color: #fff;
        cursor: pointer;
        &-red{
          background-color: #cc0000;
        }
        &-gray{
          background-color: #999999;
        }
      }
    }
    /deep/.ivu-icon{
      cursor: pointer;
    }
  }
</style>