<template>
  <div class="pay-success">
    <div class="container">
      <div class="success-tip">
        <Icon type="md-checkmark-circle" />
        <span>订单已支付成功!</span>
      </div>
      <p class="other-tip">若无其他操作，3s后跳转至订单列表</p>
    </div>
    <div class="link">
      <span class="order-btn btn" @click="$router.push('/user/newOrder')">查看订单</span>
      <span class="line">|</span>
      <span class="shop-btn btn" @click="$router.replace('/')">继续购物</span>
    </div>
  </div>
</template>

<script>
export default {
 name: 'PaySuccess',
 beforeRouteLeave (to, from, next) {
  if (this.timeID) {
    clearTimeout(this.timeID)
  }
  next()
 },
 data() {
  return {
    timeID: null,
  }
 },
 mounted() {
  this.timeID = setTimeout(() => {
    this.$router.push('/user/newOrder')
  }, 3000);
 },
}
</script>

<style lang="less" scoped>
  .pay-success {
    padding: 200px 0 305px 500px;
    .container {
      margin: 0 auto;
      .success-tip {
        color: #CC0000;
        font-size: 24px;
        span {
          color: #CC0000;
          font-size: 24px;
          font-weight: 700;
          margin-left: 15px;
        }
      }
      .other-tip {
        color: #747474;
        font-size: 12px;
        margin-top: 20px;
      }
    }
    .link {
      float: right;
      margin: 50px 500px 0 0;
      .line {
        margin: 0 5px;
      }
      .btn {
        color: #7ABD54;
        font-size: 12px;
      }
    }
  }
</style>