<template>
  <div class="goods-list">
    <div class="goods">
    <!-- <div class="goods-sercah">为您找到{{page.total}}条结果</div> -->
     <ul>
       <li @click="goGoodDetail(item)" class="goods-item" v-for="(item,index) in productList" :key="index">
         <img class="goods-img" :src="$fillImgPath(item.thumbUrl)" alt="">
         <div class="goods-price" v-if="item.marketPrice">￥{{item.marketPrice}}</div>
         <div class="goods-describe">{{item.productName}}</div>
         <div class="goods-tab">
          <span v-if="item.selfRunFlag == 20" style="background: #EE5153">自营</span>
          <template v-for="(itemTab, index) in item.activityLabels">
              <span v-if="itemTab == '新品'" style="background: #1480e3">{{
                itemTab
              }}</span>
              <span v-else-if="itemTab == '秒杀' || itemTab.startsWith('直降')" style="background: #cc0000">{{
                itemTab
              }}</span>
              <span v-else-if="!['满减','拼团','预售','以旧换新'].includes(itemTab)" style="background: #813DEE">{{
                itemTab
              }}</span>
            </template>
         </div>
       </li>
     </ul>
     <div v-if="requestFlag&&productList.length <= 0" class="nosearch">
       <img src="@/assets/imgs/search@2x.png" alt="">
        <div class="noSearchDesc">没有找到商品</div>
     </div>
    </div>
    <!-- <Page v-if="page.total!==0" :total="page.total" :current="page.current" :page-size="page.size" prev-text="上一页" next-text="下一页" @on-change="handleChange" /> -->
    <div class="page-wrap">
      <div class="page-common-btn" @click="handleChange(1)" :class="{'page-btn-disable': page.current <= 1}" @hover="">上一页</div>
      <div class="page-common-btn" @click="handleChange(2)" :class="{'page-btn-disable': !hasNextPage}">下一页</div>
      <div class="page-common-btn" @click="handleChange(3)" :class="{'page-btn-active': page.current === 1}">第一页</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  getKeywordSearchList,
  getClassifySearchList,
  getCouponSearchList
} from '@/api/api'

export default {
  name: 'couponGoodsList',
  data() {
    return{
      productList:[],
      page:{
        current: 1,
        pageSize: 20,
        total: 0
      },
      couponData: [],
      lastSortValues: [],
      hasNextPage: true,
      requestFlag: false
    }
  },
  computed: {
    ...mapState([
      'cacheList'
    ])
  },
  created() {
      this.getCouponSearchList()
  },
   
  watch:{
  },
  activated() {
  },
  methods:{
    // 优惠券商品搜索
    getCouponSearchList () {
      let query = this.$route.query
      if (this.$route.query.type === 'coupon') {
        this.couponData = JSON.parse(sessionStorage.getItem('couponData'))
        this.couponTypeSearch(query.type)
        this.couponData['searchKeyType'] = 'coupon'
        sessionStorage.removeItem('couponData')
        sessionStorage.setItem('couponData', JSON.stringify(this.couponData))
      }
    },
   couponTypeSearch(type) {
      let query = this.couponData,
        params = {
          costAllocation: +query.costAllocation, // 1平台分摊 2店铺分摊
          pageIndex: this.page.current,
          pageSize: this.page.pageSize,
          sortKey: 10, // 10 综合
          sortValue: 10, // 升序
          searchMode: 0,
          couponUsingRange: +query.couponUsingRange
        }
      // 判断当前类目id 是哪个界面的
      if (query.categoryId) {
            params['categoryId'] = query.categoryId.split(',')
        } else if (query.usingRangeInfoVOs) {
            //专题模板活动优惠券组件类目id
            let usingId = {}
            query.usingRangeInfoVOs.map(i => {
            usingId = i
            })
            params['categoryId'] = usingId.couponUsingId.split(',')
        }
        if (query.skuId) params['skuId'] = query.skuId.split(',')
        if (query.shopId) params['shopId'] = query.shopId.toString().split(',')
        if(this.page.current === 1){
          params['lastSortValues'] = []
        } else {
          params['lastSortValues'] = this.lastSortValues
        }
        getCouponSearchList(params).then((res) =>{
          const {result, message, data: resData} = res
        if (result === 0) {

          this.lastSortValues = resData.product.lastSortValues
          // this.page.total = resData.product.size
          let goodsList = resData.product.commonProductVOList.map(good => {
            return good
          })
          this.productList = goodsList
          if(resData.product.size < this.page.pageSize){
            this.hasNextPage = false
          } else {
            this.hasNextPage = true
          }
          this.requestFlag = true
        } else {
          this.hasNextPage = false
          Toast.fail(data.message)
          this.requestFlag = true
      }
      })
    },
    handleChange(type) {
      switch (type) {
        case 1: // prev
          if(this.page.current <= 1){
            return;
          }
          this.page.current--;
          break;
        case 2: // next
          if(!this.hasNextPage){
            return;
          }
          this.page.current++;
          break;
        case 3: // page one
          this.page.current = 1
          break;
        default:
          this.page.current = 1
          break;
      }
      this.couponTypeSearch()
    },
    // 跳转商详页面
    goGoodDetail(item) {
      const route = {
        name:'GoodsDetail',
        query:{
          productNo: item.productId,
          skuNo: item.skuId,
          shopId: item.shopId
        }
      }
      if(this.$route.name === 'GoodsDetail')
        this.$router.replace(route);
      else
        this.$router.push(route);
    }
  }
}
</script>

<style lang="less" scoped>
.goods-list {
  padding-top: 56px;
  padding-bottom: 5px;
  background: #f2f2f2;
  .goods{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .nosearch{
      text-align: center;
      img{
        width: 350px;
        height: 350px;
      }
      .noSearchDesc{
        // margin-top: 43px;.
        text-align: center;
        font-size: 18px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #999999;
      }
    }
    // background: pink;
    .goods-sercah{
      margin-bottom: 16px;
      font-size: 16px;
      font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
    }
    li:nth-of-type(4n)
      {
      margin-right: 0px !important;
      }
    .goods-item{
      width: 288px;
      height: 435px;
      display: block;
      float: left;
      background: #fff;
      margin: 0 16px 16px 0;
      cursor: pointer;
      .goods-img{
        width: 288px;
        height: 310px;
      }
      .goods-price{
        margin-top: 8px;
        margin-left: 8px;
        font-size: 20px;
        font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #cc0000;
      }
      .goods-describe{
        height: 40px;
        margin: 8px 8px;
        font-size: 14px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space:pre-line;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .goods-tab{
        margin: 0 5px;
       span{
        //  background: #ff4d4f;
         border-radius: 4px;
         font-size: 12px;
         font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
         font-weight: 400;
         text-align: LEFT;
         color: #ffffff;
         line-height: 12px;
         margin: 0 4px;
         padding: 0 12px
       }
      }
    }
  }
  .page-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    .page-common-btn{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 34px;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      color: #999999;
      line-height: 16px;
      border: 1px solid rgba(153,153,153,0.20);
      cursor: pointer;
    }
    .page-common-btn:not(.page-btn-active,.page-btn-disable):hover {
      color: cornflowerblue;
      border-color: cornflowerblue;
    }
    .page-btn-active {
      border-color: #CC0000;
      background-color: #CC0000;
      color: #ffffff;
    }
    .page-btn-disable {
      background: rgba(153,153,153,0.20);
      color: rgba(153,153,153,0.20);
      cursor: not-allowed;
    }
  }
}
/deep/ .ivu-page{
  margin-top: 10px;
  text-align: center;
}
</style>