<template>
  <div class="signup">
    <div class="nTab">
      <div class="Tab">
        <div class="tabTitle">
          用户注册
        </div>
        <div class="tabContent">
          <!-- 注册表单区域 -->
          <div class="registerBox">
            <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="100" >
            <Form-item label="手机:" prop="phone">
              <Input  placeholder="请输入手机号" :maxlength="11" clearable  v-model="formValidate.phone"></Input>
            </Form-item>
          
            <!-- 验证码获取 -->
            <Form-item label="手机验证码:" prop="authCode">
              <Row>
                <Col span="14">
                  <Form-item prop="authCode" >
                    <Input @on-blur="loginCode()" placeholder="请输入手机验证码" v-model="formValidate.authCode"></Input>
                  </Form-item>
                </Col>
                <Col span="3" style="text-align: center"></Col>
                <Col span="6">
                  <Form-item>
                  <!-- <Button @click="getVerificationCode">获取验证码</Button> -->
                  <div class="code-btn" @click="getVerificationCode" v-if="checkFlag">{{firstFlag?'获取验证码':'重新发送'}}</div>
                  <div class="countDown-btn" v-else>倒计时{{countDownTime}}s</div>
                  </Form-item>
                </Col>
              </Row>
            </Form-item>
            <!-- 设置密码 -->
            <Form-item label="请设置密码:" prop="password">
              <Input type="password" password  placeholder="请输入密码" v-model="formValidate.password"></Input>
            </Form-item>
            <Form-item label="请确认密码:" prop="confrimPassword">
              <Input type="password" password   placeholder="请再次输入密码" v-model="formValidate.confrimPassword"></Input>
            </Form-item>

            <!-- 注册 -->
            <Form-item>
              <span @click="register('formValidate')" class="registerBtn" :disabled="btnDisabled">注册</span>
              <span @click="login" style="margin-left:105px;color: #2d8cf0;cursor:pointer;">使用已有账户登录</span>
            </Form-item>

            <!-- 协议 -->
            <Form-item>
              <Checkbox v-model="formValidate.single" style="vertical-align: middle;"></Checkbox>
              <span style="margin-left:5px;vertical-align:sub;">同意
                <a href="">《格力官方商城用户注册协议》</a> 
                和
                <a href="javascript:;" @click="$router.push('/privacyAgreement')">《隐私声明》</a>
              </span>
            </Form-item>
            
          </Form>
          </div>
        </div>
      </div> 
      <div id="captcha"></div>
    </div>
  </div>
</template>

<script>
import { getCode, codeLogin,regist,checkLogin} from '@/api/api'
import { rasEncrypt,checkLoginConfig} from '@/utils/common'
import ViewUI from 'view-design'
export default {
  name: 'SignUp',
  data () {
    const validatePhone = (rule, value, callback) => {
      if(!value) {
        return callback(new Error('手机号不能为空'))
      }else if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(value)) {
        callback('手机号格式不合法')
        }else{
        callback()
        }
    };
    
    const validatePass = (rule, value, callback) => {
      if (this.formValidate.password != this.formValidate.confrimPassword) {
        callback(new Error('前后密码不一致'));
      } else {
        callback();
      }
    };

    return {
      countDownTime:null,
      checkFlag:true,
      firstFlag:true,
      captchaIns:null, //网易网盾插件实例
      formValidate: {
        phone:'',
        authCode:'',
        password:'',
        confrimPassword:'',
        single:true,
        },
      ruleValidate: {
        phone: [
          // { required: true, message:'手机号码不能为空', trigger: 'blur' }
          { validator: validatePhone, trigger: 'blur' }
        ],
        authCode: [
          { required: true, message: '验证码不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        confrimPassword: [
          { required: true, message: '确认密码不能为空', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    // 网易网盾配置
    checkLoginConfig({
      init: (instance) => {
        // 初始化成功后得到验证实例instance，可以调用实例的方法
        this.captchaIns = instance
      },
      afterCheck: (val) => {
        // 验证成功后，调用close方法关闭弹框（enableClose为true时调用）
        this.captchaIns.close()
        checkLogin({
          captchaValidateValue: val.validate,
          mobileNumber: this.formValidate.phone,
        }).then((res) => {
          if(res.result === 0){
            this.getVerificationCode() //发送验证码
          }
        })
      },
    })
  },
  computed: {
    btnDisabled() {
      return !(this.formValidate.phone  && this.formValidate.authCode && this.formValidate.password && this.formValidate.confrimPassword && this.formValidate.single)
    },
  },
  methods:{
    /* 倒计时 */
    timeCountDown(time){
      this.countDownTime = time
      let timer = setInterval(() => {
        this.countDownTime -=1
        if(this.countDownTime <= 0){
          this.checkFlag = true
          clearInterval(timer)
        }
      },1000)
    },

    // 获取验证码
    getVerificationCode(){
      this.$refs.formValidate.validateField('phone',async valid =>{
        if(valid) return
        const { result,message } = await getCode({
        messageFlag: "PC_SMS_REGISTER",
        mobileNumber: this.formValidate.phone,
      })
      switch (result) {
          case 0:
            this.$Message.success({
              content: '短信验证码发送成功',
              duration: 2,
            })
            this.checkFlag = false
            this.firstFlag = false
            this.timeCountDown(60)
            break;
          case 2233:
          case 2232:
            this.$Message.warning({
              content: message,
              duration: 2,
            })
            break
          case 2231: //人工滑块
            this.captchaIns.popUp()
            break
          default:
            break;
        }
      })
    },

    // 验证码失去焦点登录
    async loginCode() {
      const { data } = await codeLogin({
        loginFlag: "pcMall",
        messageCode:this.formValidate.authCode,
        mobileNumber:this.formValidate.phone,
      })
    },

    //注册
    register(name) {
      if (!this.formValidate.single) {
					ViewUI.Message.error('请您同意用户协议')
          return
      }
      this.$refs[name].validate((valid) => {
        if (valid) {
          regist({
            mobile:this.formValidate.phone,
            smsCode:this.formValidate.authCode,
            pwd:rasEncrypt(this.formValidate.password)
          }).then((res)=>{
            if(res.result === 0) {
            this.$router.back()
        }else {
          ViewUI.Message.error(res.message)
        }
      })
        }
      })
    },

    // 跳转到登录页
    login() {
      this.$router.push({ path:'/login'})
    },
  }
}
</script>

<style lang="less" scoped>
.signup{
  background: #fafafa;
  .nTab{
  width: 980px;
  margin: 10px auto;
  height: auto;
  overflow: hidden;
  .tabTitle{
    width: 140px;
    height: 35px;
    border-top: 2px solid #2daae4;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #2daae4;
    line-height: 35px;
    cursor:pointer;
  }
  .tabContent{
    min-height: 500px;
    overflow: hidden;
    text-align: left;
    background: #fff;
    margin: 0px auto;
    padding: 20px;
    margin-top: -1px;
    border: 1px #e8e8e8 solid;
    position: relative;
    .registerBox{
      min-width: 360px;
      min-height: 200px;
      float: left;
      margin: 30px 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .registerBtn{
        display: inline-block;
        width: 150px;
        height: 30px;
        text-align: center;
        background: #ef2f2f;
        border-radius: 2px;
        font-size: 18px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        color: #ffffff;
        line-height: 30px;
        cursor: pointer;
      }
      .registerBtn[disabled] {
        opacity: 0.5;
      }
    }
  }
}
}
.code-btn {
    width: 100px;
    height: 34px;
    border: 1px solid #dcdee2;
    text-align: center;
    line-height: 34px;
    cursor: pointer;
    color: #ef2f2f;
    font-size: 14px;
    position: absolute;
    right: -15px;
  }
  .countDown-btn{
    width: 100px;
    height: 34px;
    text-align: center;
    line-height: 34px;
    color: #ef2f2f;
    border: 1px solid #dcdee2;
    font-size: 14px;
  }
</style>