<template>
    <div class="main-goods">
        <div class="product-info" @click="toGoodsDetail(item)">
            <div class="product-img">
                <img :src="$fillImgPath(item.skuPic)" alt="">
                <!-- 22.10.26 订单商品图片的活动标签隐藏 -->
                <span v-if="item.activityType === 70" class="group-tag">拼团</span>
                <span v-if="tradeIn === 1 && item.activityType !== 110" class="oldNew-tag">以旧换新</span>
            </div>
            <div class="product-desc">
                <div class="product-information">
                    <span v-if="goodsType !== 'main'" class="tag">附件</span>
                    <span class="name">
                        {{ item.skuName }}
                    </span>
                </div>
                <div class="product-specification">{{ item.skuAttribute }}</div>
            </div>
        </div>
        <div class="product-price" >
            <template v-if="goodsType == 'main'">
                <span v-if="[1, 7].includes(orderStatus)" class="pirce-discount">￥{{ item.originPrice }}</span>
                <span v-else>
                    <span class="pirce-discount">￥{{ item.realPrice }}</span>
                    <span class="original-price">￥{{ item.originPrice }}</span>
                </span>
            </template>
        </div>
        <div class="product-number">
            <span>×{{ item.quantity }}</span>
        </div>
        <div class="product-operation">
            <span v-if="orderStatus === 2 && goodsType == 'main'" @click="showCodeDialog" style="margin-top:12px">退款</span>
            <span @click.stop="distributionDetail(item)" v-if="!selfTake && [3, 4, 5, 6].includes(item.state)" style="margin-top:12px">查看物流</span>
            <span @click="codeDialog" v-if="[3, 4].includes(orderStatus) && goodsType == 'main'" style="margin-top:12px">退换</span>
            <span v-if="[4, 5, 6].includes(item.state) && item.extnServiceType == 0 && goodsType == 'main'" @click="handleOpenInstallInfo(item)" style="margin-top:12px">安装信息</span>
            <span @click="codeDialog" v-if="orderStatus === 5" style="margin-top:12px">申请售后</span>
            <span @click="codeDialog" v-if="[6,7].includes(orderStatus) && item.afterSaleState == 6 && goodsType == 'main'" style="margin-top:12px">已退款</span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        item: {
            type: Object,
            default: {}
        },
        selfTake: {
            type: Boolean,
            default: false
        },
        tradeIn: {
            type: Number,
            default: 0
        },
        orderStatus: {
            type: Number,
            default: 0
        },
        goodsType: {  // 附件商品仅支持查看物流及申请售后按钮
            type: String,
            default: 'main'
        }
    },
    data() {
        return {

        }
    },
    methods: {
        toGoodsDetail(item) {
            this.$router.push({
                path: '/goodsDetail',
                query: {
                    productNo: item.spuId,
                    skuNo: item.skuId
                },
            })
        },
        distributionDetail(item) {
            this.$emit('distributionDetail', item)
        },
        handleOpenInstallInfo(item) {
            this.$emit('handleOpenInstallInfo', item)
        },
        showCodeDialog() {
            this.$emit('showCodeDialog')
        },
        codeDialog() {
            this.$emit('showCodeDialog')
        }
    }
}
</script>
<style scoped lang="less">
.main-goods {
    display: flex;
    justify-content: flex-start;
    .product-info {
        display: flex;
        flex: 2;
        .product-img {
            width: 130px;
            height: 130px;
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }

            .group-tag {
                position: absolute;
                left: 0;
                top: 0;
                padding: 2px 4px;
                background: #f46363;
                font-size: 10px;
                font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
                font-weight: 400;
                text-align: center;
                color: #ffffff;
                line-height: 15px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            .oldNew-tag {
                position: absolute;
                left: 0;
                top: 0;
                padding: 2px 4px;
                background: #66b6ff;
                font-size: 10px;
                font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
                font-weight: 400;
                text-align: center;
                color: #ffffff;
                line-height: 15px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }

        .product-desc {
            display: flex;
            flex-direction: column;
            padding-left: 16px;

            .product-information {
                display: flex;
                align-items: baseline;
                max-width: 290px;
                .tag {
                    width: 32px;
                    height: 16px;
                    margin-right: 5px;
                    line-height: 16px;
                    flex-shrink: 0;
                    text-align: center;
                    font-size: 12px;
                    color: #66b6ff;
                    border: 1px solid #66b6ff;
                    border-radius: 3px;
                }
                .name {
                    height: 40px;
                    opacity: 1;
                    font-size: 14px;
                    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
                    font-weight: 400;
                    text-align: LEFT;
                    color: #333333;
                    line-height: 20px;
                    letter-spacing: 0px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
            }
            .product-specification {
                margin-top: 24px;
                font-size: 12px;
                font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
                font-weight: 400;
                text-align: LEFT;
                color: #999999;
            }
        }
    }
    .product-price {
      flex:1;
      >span{
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      // align-items: center;
      // margin-left: 105px;
      .pirce-discount {
        font-size: 14px;
        font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
      }

      .original-price {
        margin-top: 6px;
        font-size: 14px;
        font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
        font-weight: 400;
        text-decoration: line-through;
        text-align: LEFT;
        color: rgba(153, 153, 153, 0.6);
      }
    }
    .product-number {
        flex:1;
        text-align: center;
        display: inline-block;
    }

    .product-operation {
      flex:1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      // padding: 16px 0 16px 24px;
      span {
        font-size: 14px;
        font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #0085ff;
        cursor: pointer;
      }
    }
}
</style>