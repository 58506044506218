import { getBrowHistoryList } from '@/api/api'

const format = (date) => {
    return new Date(date).getTime()
}
// 浏览数据排序
const sort = (list) => {
    list.sort((a, b) => {
        return format(b.date) - format(a.date)
    })
}
// 浏览数据去重
const duplicateRemoval = (list) => {

    let obj = {};
    list = list.reduce((newArr, next) => {
        obj[next.skuNo] ? "" : (obj[next.skuNo] = true && newArr.push(next));
        return newArr;
    }, []);
    return list;
    // 兼容老数据
}

// 全部历史数据过滤
const checkAllHistoryList = (val) => {
    state.historyMap['all'].unshift(val)
    state.historyMap['all'] = state.historyMap['all'].slice(0, 50)
    state.historyMap['all'] = duplicateRemoval(state.historyMap['all'])
    sort(state.historyMap['all'])
}

const state = {
    goodsList: JSON.parse(localStorage.getItem('vuex'))?.goodsList || [], // 对比商品列表
    historyMap: JSON.parse(localStorage.getItem('vuex'))?.historyMap || { 'all': [] }, // 最近浏览数据
    historyGoodsList: [], // 最近浏览数据
    visibility: JSON.parse(localStorage.getItem('vuex'))?.visibility || false, // 对比栏显示隐藏
    selectTab: 0 // tab初始值
}
const  mutations = {
    // 获取浏览记录
    SetHistoryList(state, { thirdPlatformCid, list }) {
        if (Reflect.has(state.historyMap, thirdPlatformCid)) {
            const arr = JSON.parse(JSON.stringify(state.historyMap[[thirdPlatformCid]]))
            // 老数据兼容
            arr.forEach(old => {
                const obj = list.find(el => el.skuNo == old.skuNo )
                if (obj) {
                    old.downAndUpStatus = obj.downAndUpStatus
                }
            })
            arr.push(...list)
            sort(arr)
            state.historyMap[thirdPlatformCid] = duplicateRemoval(arr).slice(0, 50)
            return
        }
        state.historyMap[thirdPlatformCid] = duplicateRemoval(list)
    },
    // 设置对比栏隐藏
    SetVisibility(state, val) {
        state.visibility = val
    },
    // 插入历史浏览数据
    InsertHistoryList(state, val) {
        const key = val.thirdPlatformCid
        checkAllHistoryList(val)
        if (Reflect.has(state.historyMap, key)) {
            const arr = state.historyMap[key]
            arr.unshift(val)
            state.historyMap[key] = duplicateRemoval(arr)
            return
        }
        state.historyMap[key] = [val]
    },
    // 插入对比栏数据
    async InsertGoodsList(state, { goods, item }) {
        const InsertFail = (item) => {
            if (item) {
                item.isCompare = false
            }
        }
        if (state.goodsList.length === 4) {
            InsertFail(item)
            this._vm.$Message.warning('对比栏已满，请先删除不需要的栏内商品再继续添加哦');
            return
        }
        const diff = state.goodsList.some(el => el.thirdPlatformCid != goods?.thirdPlatformCid)
        if (diff) {
            InsertFail(item)
            this._vm.$Message.warning('对比商品为不同品类，请选择相同品类商品进行对比');
            return
        }
        state.goodsList.push(goods)
    },
    // 删除对比栏数据
    DeleteGoodsList(state, val) {
        const { skuNo } = val
        let goodsIndex = state.goodsList.findIndex(el => el.skuNo === skuNo)
        if (goodsIndex != -1) {
            state.goodsList.splice(goodsIndex, 1)
        }
    },
    // 清空对比栏数据
    ClearGoodsList(state) {
        state.goodsList = []
    },
    ToggleTab(state, val) {
        state.selectTab = val;
    }
}

const getters = {
    isCompare(state) {
        return (item) => {
            return state.goodsList.find(el=> el.skuNo == item.skuNo) || false
        }
    },
    platformCid() {
        return state.goodsList[0]?.thirdPlatformCid || 'all'
    },
}

const actions = {
    async browHistoryList({ commit }, params) {
        const { result, data } = await getBrowHistoryList(Object.assign({ pageSize: 50, pageIndex: 1 }, params))
        if (result == 0) {
            const { records } = data
            let goodsList = []
            records.forEach(item => {
                const arr = item.dateValues.map(el => {
                    return {
                        shopId: el.baseShopId,
                        productNo: el.itemId,
                        skuNo: el.skuId,
                        downAndUpStatus: el?.downAndUpStatus != null? el.downAndUpStatus: true,
                        price: el.itemPrice,
                        thumbUrl: el.thumbUrl,
                        name: el.skuName,
                        thirdPlatformCid: el.thirdPlatformCid,
                        date: format(el.modifyTime)
                    }
                })
                goodsList.push(...arr)
            });
            commit('SetHistoryList', {
                thirdPlatformCid: params.platformCid? params.platformCid : 'all',
                list: goodsList
            })
        }
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
  