<template>
  <div id="app" :style="{'background-color': isLoginModule?'white':'#F2F2F2'}">
    <!-- 导航栏 -->
    <div v-if="(!isExtraModule)&&(!isLoginModule)" class="nav">
      <div class="container content">
        <div class="wrap">
          <template v-if="userInfo.userId === undefined">
            <router-link to="/login">登录</router-link>
            <div class="divided">|</div>
            <router-link to="/signup">注册</router-link>
          </template>
          <template v-else>
            <a>{{userInfo.nickName || userInfo.mobile || "默认昵称"}}</a>
            <a class="login-up" @click="logOut">退出</a>
            <router-link to="/user/userInfo" active-class="router-link-active-nav">个人中心</router-link>
          </template>
        </div>
        <div class="wrap">
          <router-link to="/home" active-class="router-link-active-nav">商城首页</router-link>
          <div class="divided">|</div>
          <router-link to="/user/newOrder" active-class="router-link-active-nav">我的订单</router-link>
          <div class="divided">|</div>
          <Dropdown @on-click="handleDropdownClick" @on-visible-change="handleVisibleChange" trigger="click">
            <a href="javascript:void(0)">
              卖家中心
              <Icon v-if="isVisible" type="ios-arrow-down"></Icon>
              <Icon v-else type="ios-arrow-forward"></Icon>
            </a>
            <DropdownMenu slot="list">
              <DropdownItem name="center">卖家中心</DropdownItem>
              <DropdownItem v-if="userInfo.userId === undefined || (/^00[0c]0[0a]$/.test(userInfo.userType))" name="create">开店申请</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <div class="divided">|</div>
          <router-link to="/user/favorite" active-class="router-link-active-nav">我的收藏</router-link>
          <div class="divided">|</div>
          <img src="./assets/imgs/customer-service.png" alt="">
          <a @click="goOnlineService">客服中心</a>
        </div>
      </div>
    </div>
    <!-- 头部 -->
    <div v-if="(!isExtraModule)&&isLoginModule" class="header">
      <div class="container content">
        <div>
          <img @click="$router.push('/home')" :src="$fillImgPath('/static/img/distribution_shop/logo.gif')"/>
          <span class="title"><span>|</span>{{ $route.meta.title }}</span>
        </div>
      </div>
    </div>
    <Affix v-if="(!isExtraModule)&&(!isLoginModule)" class="header">
      <div ref="header" class="container content">
        <div>
          <img @click="$router.push('/home')" :src="$fillImgPath('/static/img/distribution_shop/logo.gif')" />
        </div>
        <div class="cart">
          <div class="search-box">
            <input type="text" class="search-input" :maxlength="50" :placeholder="placeholder" v-model="keyword" @input="changeKeyword" @keyup.enter="search(keyword)" @blur="blurSearch">
            <Button @click="search(keyword)" size="large">搜索</Button>
            <img class="search-icon" src="./assets/imgs/search.png" />
            <ul class="option-wrapper" v-if="searchList.length">
              <li v-for="item in searchList" :key="item.id" @mousedown="handleSelect(item.name)">{{ item.name }}</li>
            </ul>
          </div>
          <Button @click="$router.push('/cart')" shape="circle" size="large">
            <Badge :class="['btn-badge', userInfo.userId === undefined || cartNum === 0?'btn-badge-empty':'']" :count="userInfo.userId === undefined?0:cartNum">
              <img class="search-icon" src="./assets/imgs/home/cart.png" />
            </Badge>
            <span class="btn-text">购物车</span>
          </Button>
        </div>
      </div>
      <div class="carousel">
        <Carousel :autoplay="true" :height="50" :width="200" loop :autoplay-speed="5000" arrow="never" v-model="carouselIndex">
          <CarouselItem v-for="(item, index) in advertisingList" :key="index">
            <div>{{item.name}}</div>
          </CarouselItem>
        </Carousel>
      </div>
    </Affix>
    <!-- 主体 -->
    <div class="main">
      <keep-alive :include="cacheList">
        <router-view @keyword="handleInputKeyword"  @cart="updateCartNum" :key="$route.fullPath"/>
      </keep-alive>
    </div>
    <!-- 链接 -->
    <div v-if="!isExtraModule" class="link">
      <div class="container content">
        <div>
          <div class="link-group">
            <div class="link-item-title">关于配送</div>
            <template v-if="helpCenterInfo[40]">
              <template v-for="(item, index) in helpCenterInfo[40]">
                <div v-if="item.pageType === 10" @click="goHelpCenterDetail(item.pageTitleOne)" :key="index">{{item.pageTitleOne}}</div>
              </template>
            </template>
          </div>
          <div class="link-line-gap link-group">
            <div class="link-item-title">关于售后</div>
            <template v-if="helpCenterInfo[50]">
              <template v-for="(item, index) in helpCenterInfo[50]">
                <div v-if="item.pageType === 10" @click="goHelpCenterDetail(item.pageTitleOne)" :key="index">{{item.pageTitleOne}}</div>
              </template>
            </template>
          </div>
          <div class="link-line-gap link-group">
            <div class="link-item-title">资质证明</div>
            <template v-if="helpCenterInfo[70]">
              <template v-for="(item, index) in helpCenterInfo[70]">
                <div v-if="item.pageType === 10" @click="goHelpCenterDetail(item.pageTitleOne)" :key="index">{{item.pageTitleOne}}</div>
              </template>
            </template>
          </div>
        </div>
        <div class="link-group">
          <div class="link-item-title">管理制度</div>
          <template v-if="helpCenterInfo[60]">
            <template v-for="(item, index) in helpCenterInfo[60]">
              <div v-if="item.pageType === 10" @click="goHelpCenterDetail(item.pageTitleOne)" :key="index">{{item.pageTitleOne}}</div>
            </template>
          </template>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div v-if="!isExtraModule" class="footer">
      <div class="container">
        <div class="promise">
          <div>
            <img src="./assets/imgs/footer/1.png" alt="">
            <span>正品保证</span>
          </div>
          <div>
            <img src="./assets/imgs/footer/2.png" alt="">
            <span>七天无理由退换货</span>
          </div>
          <div>
            <img src="./assets/imgs/footer/3.png" alt="">
            <span>送货到家</span>
          </div>
          <div>
            <img src="./assets/imgs/footer/4.png" alt="">
            <span>售后无忧</span>
          </div>
        </div>
        <div class="company">
          <div>珠海格力电器股份有限公司版权所有 粤ICP备05006515号</div>
          <div class="cursor-pointer" @click="goHelpCenterDetail(tppFilingCertificate)">{{`${tppFilingCertificate} (粤)网械平台备字(2021)第00028号`}}</div>
          <div><img src="./assets/imgs/copyright.png" alt=""></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { debounce } from '@/utils/common'
import config from '@/config'
import {
  getOnlineServiceToken,
  getKeywordAssociateList,
  getCartNum,
  getHelpCenterList,
  getSearchSlogans
} from '@/api/api'
export default {
  name: 'App',
  data() {
    return {
      isVisible: false,
      keyword: '',
      searchList: [],
      cartNum: 0,
      toName: '',
      fromName: '',
      helpCenterList: [],
      advertisingList: [], // 搜索栏循环广告列表
      placeholder: '',
      carouselIndex: 0,
      tppFilingCertificate: '医疗器械网络交易服务第三方平台备案凭证'
    }
  },
  computed: {
    ...mapState([
      'userInfo',
      'cacheList',
    ]),
    isLoginModule() {
      const excludeRoutes = ['Login', 'SignUp', 'ForgetPassword', 'wxLogin'];
      return excludeRoutes.indexOf(this.$route.name) !== -1;
    },
    isExtraModule() {// 不需要头部和尾部等公共模块
      const excludeRoutes = ['RegisterAgreement', 'PrivacyAgreement', 'H5', 'privacyHistory', 'privacyHistoryDetail'];
      return excludeRoutes.indexOf(this.$route.name) !== -1;
    },
    helpCenterInfo() {
      return this.helpCenterList.reduce((info, currentValue) => {
        info[currentValue.category] = currentValue.categoryList
        return info
      }, {})
    }
  },
  watch:{
    $route: {
      handler: function(to, from){
        this.toName = to.name;
        this.fromName = from.name;
      }
    },
    carouselIndex (index) {
      this.placeholder = this.advertisingList[index].name || '空调'
    },
  },
  methods: {
    goHelpCenterDetail(keyword) {
      this.$router.push('/h5/details?keyword=' + keyword)
    },
    handleDropdownClick(name) {
      switch(name) {
        case 'center': window.location.href = process.env.VUE_APP_SELLER_CENTER;break;
        case 'create': window.location.href = process.env.VUE_APP_SELLER_CENTER;break;
      }
    },
    handleInputKeyword(value) {
      this.keyword = value
    },
    changeKeyword:debounce('handleSearch',500),
    search(keyword) {
      if(keyword === '') {
        keyword = this.placeholder
      }
      if(this.$route.name === 'GoodsList' && this.$route.query.keyword === keyword && this.$route.query.searchType === 1) {
        return;
      }
      const route = {
        name: 'GoodsList',
        query: {
          keyword: keyword,
          searchType: 1
        }
      }
      this.$router.push(route);
      this.searchList = []
    },
    blurSearch () {
      this.searchList = []
    },
    handleSelect(value) {
      this.search(value)
    },
    handleSearch(e) {
      if(this.$route.query.searchType === 2) return;
      getKeywordAssociateList({
        name: e[0].target.value,
      }).then((res) => {
        if (res.result === 0) {
          this.searchList = res.data instanceof Array? res.data: [];
        } else {
        }
      }).catch((err) => {
      })
    },
    logOut() {
      this.$store.commit('SET_USER_INFO', {});
      this.$store.commit('SET_USER_ADDRESS', {});
      this.$store.commit('CLEAR_CACHE_LIST');
      this.$router.push({name: 'Login'});
    },
    handleVisibleChange(visible) {
      this.isVisible = visible;
    },
    updateCartNum(num) {
      if(num !== undefined) {
        this.cartNum = num;
        return;
      }
      getCartNum({
        userId: this.userInfo.userId,
      }).then((res) => {
        if (res.result === 0) {
          this.cartNum = res.data.cartSize;
        } else {
        }
      }).catch((err) => {
      })
    },
    goOnlineService() {
      if(this.userInfo.userId === undefined) {
        this.$router.push({name: 'Login'});
        return;
      }
      getOnlineServiceToken({
        token: this.userInfo.token,
      }).then((res) => {
        if (res.result === 0) {
          let time = new Date().getTime()
          let url = process.env.VUE_APP_ONLINE_SERVICE + '&time='+ time +'&token=' + res.data.subToken + '&user_id=' + this.userInfo.userId + '&consultation_page=1&store_type=1&shop_id=' + config.STOREID;
          window.open(url,'newwindow', 'height=650, width=650, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no')
        } else {
        }
      }).catch((err) => {
      })
    },
    goBack() {
      // 回退页面
      if (config.WHITE_LIST.indexOf(this.toName) === -1 && config.WHITE_LIST.indexOf(this.fromName) === -1) {
        this.$store.commit('REMOVE_CACHE_LIST');
        this.$store.commit('REMOVE_CACHE_LIST');
      }else if(!(config.WHITE_LIST.indexOf(this.toName) !== -1 && config.WHITE_LIST.indexOf(this.fromName) !== -1)){
        this.$store.commit('REMOVE_CACHE_LIST');
      }
    },
    getHelpCenterData() {
      getHelpCenterList({}).then((res) => {
        if (res.result === 0) {
          this.helpCenterList = res.data;
        } else {
        }
      }).catch((err) => {
      })
    },
    getSearchSlogans () {
      const params = {
        pageIndex: 1,
        pageSize: 10,
        productStatus: '20',
        shopId: config.STOREID,
      }
      getSearchSlogans(params).then(res => {
        const { data } = res
        if (res.result === 0) {
          const records = data.records
          this.advertisingList = records.length ? records : [{ name: '空调' }]
        } else {
          this.advertisingList = [{ name: '空调' }]
        }
      })
    },
    scrolling () {
      this.searchList = []
    }
  },
  created(){
    if((! this.isLoginModule) && this.userInfo.userId){
      this.updateCartNum();
    }
    this.getHelpCenterData()
    this.getSearchSlogans()

  },
  mounted() {
    if(window.history &&  window.history.pushState) {
      window.addEventListener('popstate', this.goBack, false);
    }
    window.addEventListener('scroll', this.scrolling)
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  }
}
</script>

<style lang="less" scoped>
#app {
  width: 100%;
  min-width: 1200px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  line-height: 1;
  .nav {
    flex: none;
    width: 100%;
    background-color: #333333;
    .content {
      padding: 19px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .wrap {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: white;
        a {
          color: white;
        }
        > a:hover {
          color: #EF2F2F;
        }
        .ivu-dropdown-item:hover {
          color: #EF2F2F;
        }
        .divided {
          margin: 0 12px;
          font-size: 14px;
        }
        .login-up {
          margin: 0 24px 0 12px;
          color: #EF2F2F;
        }
        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
        .router-link-active-nav {
          font-weight: 600;
        }
      }
    }
  }
  .header {
    flex: none;
    width: 100%;
    .content {
      padding-right: 54px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 300px;
        height: 97px;
        vertical-align: middle;
        cursor: pointer;
      }
      .title {
        margin-left: 40px;
        font-size: 28px;
        color: #666666;
        span {
          margin-right: 42px;
        }
      }
      .cart {
        display: flex;
        align-items: center;
        .search-box {
          position: relative;
          width: 525px;
          height: 40px;
          .search-icon {
            position: absolute;
            top: 10px;
            left: 10px;
            width: 20px;
            height: 20px;
            z-index: 99;
          }
          .search-input {
            position: absolute;
            top: 0;
            left: 0;
            width: 415px;
            height: 40px;
          }
          button {
            position:absolute ;
            top: 0px;
            right: 10px;
          }
          .option-wrapper {
            position: absolute;
            top: 40px;
            width: 415px;
            height: auto;
            will-change: top, left;
            transform-origin: center top;
            margin-top: 5px;
            z-index: 99;
            padding: 5px 0;
            background-color: #fff;
            border-radius: 5px;
            li {
              padding-left: 10px;
              height: 30px;
              line-height: 30px;
              width: 100%;
              cursor: pointer;
              color: #515a6e;
              white-space: nowrap;
              transform: background .2s ease-in-out;
              &:hover {
                background-color: #eee;
              }
            }

          }
        }
       > button {
          margin-left: 24px;
          height: 43px;
          font-size: 14px;
          color: #333333;
          .btn-badge {
            margin-right: 23px;
            position: relative;
            top: 3px;
            img {
              width: 27px;
              height: 26px;
            }
          }
          .btn-badge-empty {
            top: 0;
          }
          .btn-text {
            vertical-align: middle;
          }
        }
      }
    }
    .carousel {
      width: 200px;
      display: none;
    }
  }
  .main {
    position: relative;
    width: 100%;
    flex: 1;
  }
  .link {
    flex: none;
    width: 100%;
    .content {
      padding: 20px 0px 40px 0;
      display: flex;
      justify-content: space-around;
      .link-line-gap {
        margin-top: 30px;
      }
      .link-group {
        line-height: 1;
        font-size: 12px;
        color:rgba(51,51,51,0.60);
        div {
          margin-top: 12px;
          cursor: pointer;
        }
        .link-item-title {
          cursor: auto;
          margin-top: 0;
          font-size: 14px;
          color: #333333;
        }
      }
    }
  }
  .footer {
    flex: none;
    width: 100%;
    background-color: #333333;
    .promise {
      padding: 32px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        font-size: 25px;
        color: white;
        img {
          width: 50px;
          height: 50px;
          margin-right: 8px;
          vertical-align: middle;
        }
        span {
          vertical-align: middle;
        }
      }
    }
    .company {
      border-top: 2px solid #999999;
      padding: 24px 0;
      text-align: center;
      font-size: 14px;
      color: white;
      .cursor-pointer {
        cursor: pointer;
        margin-top: 6px;
      }
      div:last-child {
        margin-top: 8px;
        font-size: 0;
        img {
          width: 120px;
          height: 40px;
        }
      }
    }
  }
}
</style>

<style lang="less">
.container {
  width: 1200px;
  margin: auto;
  height: 100%;
}
#app {
  font-family: 'Helvetica Neue',Helvetica,Arial,'Microsoft Yahei','Hiragino Sans GB','Heiti SC','WenQuanYi Micro Hei',sans-serif;
  .header {
    .ivu-affix {
      background-color: white;
    }
    .content {
      .cart {
        .search-box {
          input {
            padding: 6px 7px 6px 39px;
            border: 1px solid #CC0000;
            border-right: none;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
          }
          .ivu-input:focus {
            box-shadow: none;
          }
          button {
            padding: 0px 38px 0px 34px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            border: none;
            background-color: #CC0000;
            color: white;
            font-size: 18px;
          }
          .ivu-btn:hover {
            color: white;
          }
        }
        > button {
          padding: 0 33px;
          border-color: #CC0000;
          .btn-badge {
            .ivu-badge-count {
              padding: 0 5px;
              line-height: 16px;
              border-radius: 9px;
              height: 18px;
              background: #CC0000;
              top: -6px;
              font-weight: 400;
            }
          }
        }
        > .ivu-btn:hover {
          color: #EE2B2B;
          font-weight: 600;
        }
        > .ivu-btn:focus {
          box-shadow: none;
        }
      }
    }
  }
}
// 显示一行
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
// 显示两行
.multi-ellipsis {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
/*滚动条样式*/
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: hsla(0, 0%, 100%, 0.6);
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: rgba(95, 95, 95, 0.4);
  transition: background-color 0.15s;
}
::-webkit-scrollbar-track {
  border-radius: 0;
}
</style>
