<template>
  <div class="code-pay">
    <div class="container">
      <Breadcrumb />
      <div class="pay-top">
        <p class="pay-tip">订单提交成功！去付款咯~</p>
        <div class="pay-amount">
          待付款：<span class="amount red-color">{{ payAmount }}元</span>
        </div>
      </div>
      <div class="line"></div>
      <div class="pay-bottom">
        <p class="title">请打开支付宝，扫描“二维码”进行支付</p>
        <p v-if="countdownTime > 0" class="code-tip">
          距离二维码过期还剩<span class="time red-color">{{
            countdownTime
          }}</span
          >秒，过期后请刷新页面重新获取二维码
        </p>
        <p v-else class="code-tip red-color">
          二维码已过期，刷新页面重新获取二维码
        </p>
        <div class="scan-area">
          <p class="scan-title">请扫描支付</p>
          <div class="QRcode">
            <vue-qr :text="qrCode" :margin="0" :size="180"></vue-qr>
          </div>
          <div class="code-icon">
            <!-- <img :src="require('@/assets/imgs/pay/wechat.png')" alt="" /> -->
            <img :src="require('@/assets/imgs/pay/alipay.png')" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from './components/Breadcrumb.vue'
import { getQrCodePayInfo, getNotifyMessage } from '@/api/api'
import VueQr from 'vue-qr' // https://github.com/Binaryify/vue-qr
export default {
  name: 'PayOrder',
  components: {
    Breadcrumb,
    VueQr
  },
  data() {
    return {
      countdownTime: 60,
      qrCode: '',
      payAmount: null,
      timer: null,
      countNum: 0,
    }
  },
  created() {},
  mounted() {
    this.payAmount = this.$route.query.value
    // 获取支付二维码
    this.getPayQrCode()
    // 获取支付结果
    // this.timer = setInterval(() => {
    //   this.getOrderResult()
    //   this.countNum++
    //   if (this.countNum >= 180) {
    //     clearInterval(this.timer)
    //   }
    // }, 60000)
  },
  beforeRouteLeave(to, from, next) {
    if (this.timer) {
      clearInterval(this.timer)
    }
    next()
  },
  methods: {
    countDown() {
      let timeID = setInterval(() => {
        this.countdownTime--
        if (this.countdownTime === 0) {
          this.getPayQrCode()
          clearInterval(timeID)
          this.countdownTime = 60
        }
      }, 1000)
    },
    getPayQrCode() {
      getQrCodePayInfo({
        orderId: this.$route.query.parentOrderId,
      }).then((res) => {
        if (res.result === 0) {
          this.qrCode = res.data.data.data
          if (this.qrCode) {
            // 时间倒计时
            this.countDown()
          }
        } else if (res.result === 70009) {
          // 非待付款状态
          this.$router.replace({ path: '/user/newOrder' })
        } else {
          this.countdownTime = 0
        }
      })
    },
    // 获取支付结果 20403-继续请求支付结果
    getOrderResult() {
      getNotifyMessage({
        parentOrderId: this.$route.query.parentOrderId,
      }).then((res) => {
        if (+res.result === 0) {
          this.$router.push('/paySuccess')
        }
        if (+res.result !== 20403) {
          clearInterval(this.timer)
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.code-pay {
  width: 1400px;
  margin: auto;
  height: 100%;
  background-color: #fff;
  padding: 50px 0 166px;
}
.pay-top {
  color: #333;
  font-size: 18px;
  margin-top: 60px;
  .pay-tip {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
  }
  .amount {
    font-size: 22px;
    font-weight: 600;
  }
}
.line {
  width: 100%;
  height: 1px;
  margin: 10px 0 30px 0;
  background-color: #e5e5e5;
}
.pay-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #333;
  .title {
    font-size: 20px;
    font-weight: 600;
  }
  .code-tip {
    font-size: 16px;
    margin: 15px 0;
  }
  .scan-area {
    width: 420px;
    height: 365px;
    border: 1px solid #e5e5e5;
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25);
    .scan-title {
      font-size: 22px;
      font-weight: 600;
      margin-top: 18px;
    }
    .QRcode {
      width: 200px;
      height: 200px;
      padding: 10px;
      margin: 18px auto 20px;
      border: 1px solid #e5e5e5;
    }
    .code-icon {
      width: 200px;
      padding: 0 10px;
      margin: 0 auto;
      // display: flex;
      // justify-content: space-between;
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}
.red-color {
  color: #cc0000;
}
</style>
