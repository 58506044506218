import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
import VueLazyload from 'vue-lazyload'
import VueClipboard from 'vue-clipboard2'
import infiniteScroll from 'vue-infinite-scroll'
import * as Sentry from "@sentry/vue";
import './assets/css/reset.css'

import { fillImgPath } from '@/utils/common'

// 性能监控初始化
try {
  if(window.location.href.includes('mall')){
    const userInfo = localStorage.getItem('greeUserInfo')
    Sentry.init({
      Vue,
      dsn: "https://3c5e5d32668b7ff07b6c3c068aea15ca@liveqa.gree.com/5",
      release: '1.0.0',
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        Sentry.browserTracingIntegration({
          idleTimeout: 3000
        }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      environment: window.location.href.includes('fmall') ? 'production' : 'development',
    });
    // 用户信息设置
    if (userInfo) {
      Sentry.setUser({
          id: `${userInfo.nickName}(${userInfo.userId})`,
          userId: userInfo.userId, // cookie.get('username')
          userName: userInfo.nickName,
          mobile: userInfo.mobile
      })
    }
  }
  

} catch (error) {
  console.log(error)
}


Vue.prototype.$fillImgPath = fillImgPath

Vue.use(ViewUI)
Vue.use(VueLazyload)
Vue.use(VueClipboard)
Vue.use(infiniteScroll)

Vue.config.productionTip = false

Vue.prototype.$app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
