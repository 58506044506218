<template>
    <div class="container ">
        <img src="../assets/imgs/gree-shop.png" alt="">
        <span class="text">啊哦~当前店铺已关闭</span>
        <div class="btn" @click="goBackAction">
            点击返回
        </div>
    </div>
</template>
<script>
    export default {
        name: 'closeShopPage',
        methods: {
            goBackAction() {
				this.$router.replace({ path: '/home' })
			}
        }
    }
</script>
<style lang="less" scoped>
.container  {
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.text {
    margin-top: 24px;
    width: 174px;
    height: 21px;
    font-size: 18px;
    color: #999999;
    line-height: 21px;
}
.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;;
    width: 138px;
    height: 46px;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    cursor: pointer;
    background: #EF2F2F;
    color: #fff;
    &:hover {
        background: #f14e4e;
    }
}
</style>