<template>
  <div class="pay-bottom">
    <div class="title">
      尊敬的客户，您的订单应付金额为<span class="red-color">{{payAmount}}元</span
      >，请在5天内转账至如下账户，并务必备注您的订单号：<span class="red-color"
        >{{parentOrderId}}
        <img
          v-clipboard:copy="parentOrderId"
          v-clipboard:success="copyAccount"
          :src="require('@/assets/imgs/pay/copy.png')"
          alt=""
      /></span>
    </div>
    <p class="tip">
      {{ getTipsText }}
    </p>
    <!-- 账户信息 -->
    <ul class="account-info">
      <li><span class="name">收款账户名：</span>{{ bankInfo.accountName }}</li>
      <li><span class="name">收款账号：</span>{{ bankInfo.account }}</li>
      <li><span class="name">开户名称：</span>{{ bankInfo.bankName }}</li>
      <li><span class="name">开户行行号：</span>{{ bankInfo.bankAccount }}</li>
      <li><span class="name">用途/附言/摘要：</span>{{ `${parentOrderId}（汇款识别码）` }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    parentOrderId: {
      type: String,
      default: null
    },
    payAmount: {
      type: String,
      default: null
    },
    bankInfo: {
      type: Object,
      default: () => ({})
    },
    eocAddressRestrictType: {
      type: Number,
      default: 0
    },
  },
  computed: {
    // 获取对公转账文案
    getTipsText() {
      // 0 不限制  1 提交订单限制  2 仅限制线下支付订单
      if (this.eocAddressRestrictType === 2) {
        return "温馨提示：线下付款到账周期为3个工作日，请合理安排付款时间。对公转账成功后，订单不允许修改地址。";
      }
      return "温馨提示：线下付款到账周期为3个工作日，请合理安排付款时间。";
    },

  },
  methods: {
    goCodePay() {
      this.$router.push({
        name: "CodePay",
      });
    },
    // 复制银行账户
    copyAccount() {
      this.$Message.success("复制成功");
    },
  },
};
</script>

<style lang="less" scoped>
.pay-bottom {
  margin-top: 48px;
  color: #666;
  .title {
    .red-color {
      font-weight: 600;
    }
    img {
      width: 16px;
      vertical-align: middle;
    }
  }
  .tip {
    margin: 24px 0;
    color: #CC0000;
    font-size: 14px;
    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
    font-weight: 400;

  }
  .account-info {
    margin: 28px 0;
    padding: 20px 0 20px 23px;
    background-color: rgba(204, 0, 0, 0.04);
    li {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      .name {
        color: #333;
      }
    }
  }
}
.red-color {
  color: #cc0000;
}
</style>