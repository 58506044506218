import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import * as actions from './actions'
import * as mutations from './mutations'
import * as getters from './getters'
import comparisonColumn from './modules/comparisonColumn.js'


Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    userInfo: JSON.parse(localStorage.getItem('greeUserInfo')) || {}, //用户信息
    cacheList:JSON.parse(sessionStorage.getItem('cacheList')) || [], // 页面缓存
    isLoading: false, //加载动画
    goodsData: {}, //商品详情
    couponList: [], //商详优惠券
    currentSkuInfos: {}, //当前sku信息
    userAddress: JSON.parse(sessionStorage.getItem('userAddress')) || {}, //用户选择的地址信息
    sKillToken: '', //秒杀下单token
    orderInfo: JSON.parse(sessionStorage.getItem('orderInfo')) || {}, // 确认订单数据源
  },
  getters,
  actions,
  mutations,
  modules: {
    comparisonColumn
  },
  plugins: [
    createPersistedState({
      reducer(state) {
        console.log("state", state);
        return {
          goodsList: state.comparisonColumn.goodsList,
          historyMap: state.comparisonColumn.historyMap,
          visibility: state.comparisonColumn.visibility
        };
      }
    })
  ]
})
