<template>
  <Modal
    v-model="isShow"
    title="选择发货日期"
    :footer-hide="true"
    :closable="false"
    class-name="delivery-date"
    width="843">
      <div class="list">
        <div @click="choose('')" :class="['item', val === ''?'active':'']">
          <!-- 为了样式加一个div -->
          <div style="line-height:48px;">今天</div>
        </div>
        <div @click="choose(item.value)" v-for="(item, index) in list" :key="index" :class="['item', val === item.value?'active':'']">
          {{item.text}}<br>周{{item.day | parseDay}}
        </div>
      </div>
      <div class="btn">
        <div @click="ok" class="left">确定</div>
        <div @click="isShow = false" class="right">取消</div>
      </div>
  </Modal>
</template>

<script>
export default {
  name: 'DeliveryDate',
  props: {
    value: Boolean,
    orderDate: {
      type: String,
      default: ''
    },
    deliveryDate: {
      type: String,
      default: ''
    },
    currDate: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      list: [],
      val: '',
    }
  },
  computed: {
    isShow: {
      get: function() {
        return this.value;
      },
      set: function(val) {
        this.$emit('input', val);
      },
    }
  },
  filters: {
    parseDay(value) {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
  },
  watch: {
    value(newValue) {
      if(newValue) {
        this.init()
      }
    },
  },
  methods: {
    ok() {
      this.isShow = false
      this.$emit('change', this.val)
    },
    choose(value) {
      this.val = value
    },
    init() {
      let startTime = this.currDate ? new Date(this.currDate) : new Date()
      let endTime
      if(this.orderDate) {
        endTime = new Date(this.orderDate)
      } else {
        endTime = this.currDate ? new Date(this.currDate) : new Date()
      }

      startTime.setDate(startTime.getDate() + 1)
      endTime.setDate(endTime.getDate() + 60)

      let list = [], month = startTime.getMonth() + 1
      while(startTime.getTime() <= endTime.getTime() || startTime.getDate() === endTime.getDate()) {
        let nowYear = startTime.getFullYear()
        let nowMonth = startTime.getMonth() + 1
        let nowDate = startTime.getDate()

        let show = month > nowMonth
        list.push({
          text: show?(nowYear + '年'):'' + nowMonth + '月' + nowDate + '日',
          value: nowYear + '-' + (nowMonth.toString().padStart(2, '0')) + '-' + (nowDate.toString().padStart(2, '0')),
          day: startTime.getDay()
        })
        startTime.setDate(startTime.getDate() + 1)
      }

      this.list = list
      this.val = this.deliveryDate
    }
  }
}
</script>

<style lang="less" scoped>
.delivery-date {
  .list {
    border-top: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    display: flex;
    flex-wrap: wrap;
    box-sizing: content-box;
    .item {
      box-sizing: content-box;
      width: 80px;
      padding: 5px 0;
      border-right: 1px solid #e8e8e8;
      border-bottom: 1px solid #e8e8e8;
      cursor: pointer;
      font-size: 14px;
      color: #666666;
      text-align: center;
      line-height: 24px;
    }
    .item:hover {
      background: rgba(204,0,0,.2);
      color: #cc0000;
    }
    .item.active {
      background: rgba(204,0,0,1);
      color: #ffffff;
    }
  }
  .btn {
    margin-top: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    .left {
      cursor: pointer;
      line-height: 40px;
      padding: 0px 25px;
      background: #cc0000;
      border-radius: 2px;
      color: #ffffff;
      font-size: 14px;
      margin-right: 8px;
    }
    .right {
      cursor: pointer;
      line-height: 40px;
      padding: 0px 25px;
      background-color: #cccccc;
      border-radius: 2px;
      color: #ffffff;
      font-size: 14px;
    }
  }
}
</style>