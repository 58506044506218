import { render, staticRenderFns } from "./closeShopPage.vue?vue&type=template&id=8b004a58&scoped=true"
import script from "./closeShopPage.vue?vue&type=script&lang=js"
export * from "./closeShopPage.vue?vue&type=script&lang=js"
import style0 from "./closeShopPage.vue?vue&type=style&index=0&id=8b004a58&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b004a58",
  null
  
)

export default component.exports