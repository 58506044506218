// 需要特殊处理的状态码
export const ERROR_CODE = [
  1458,
  1687,
  35077, // 未注册
  1989, // 验证码已失效
  1099, // 验证码不正确
  33004, // 密码不正确
  35086, //未注册
  33005,
  2233,
  2232,
  33002,
  33037,
  35077,
  2083,
  40109
]
