import Vue from 'vue'

// 用户信息
export const SET_USER_INFO = (state, data) => {
  state.userInfo = data;
  if(data.userId === undefined) {
    localStorage.removeItem('greeUserInfo');
  }else{
    localStorage.setItem('greeUserInfo', JSON.stringify(data));
  }
};

// 加载动画
export const SET_LOAD_STATUS = (state, status) => {
  state.isLoading = status;
};

// 商品详情
export const SET_GOODS_DETAIL = (state, data) => {
  state.goodsData = data;
};
// 当前sku信息
export const SET_CURRENT_SKU_INFOS = (state, data) => {
  state.currentSkuInfos = data;
};
// 商详优惠券
export const SET_COUPON_LIST = (state, data) => {
  state.couponList = data;
};
// 商详-秒杀下单token
export const SET_SKILL_TOKEN = (state, data) => {
  state.sKillToken = data;
};

// 页面缓存 - 新增
export const PUSH_CACHE_LIST = (state, data) => {
  state.cacheList.push(data);
  sessionStorage.setItem('cacheList', JSON.stringify(state.cacheList));
}

// 页面缓存 - 删除
export const REMOVE_CACHE_LIST = (state) => {
  state.cacheList.pop();
  sessionStorage.setItem('cacheList', JSON.stringify(state.cacheList));
}

// 页面缓存 - 清空
export const CLEAR_CACHE_LIST = (state) => {
  state.cacheList = [];
  sessionStorage.setItem('cacheList', JSON.stringify(state.cacheList));
}

// 用户选择的地址信息
export const SET_USER_ADDRESS = (state, data) => {
  state.userAddress = data;
  sessionStorage.setItem('userAddress', JSON.stringify(data));
};

// 确认订单数据源
export const SET_ORDER_INFO = (state, data) => {
  state.orderInfo = data;
  sessionStorage.setItem('orderInfo', JSON.stringify(data));
};
