<template>
  <div class="container order">
    <div class="line breadcrumb">
      <Breadcrumb>
        <BreadcrumbItem to="/home">首页</BreadcrumbItem>
        <BreadcrumbItem to="/cart">购物车</BreadcrumbItem>
        <BreadcrumbItem>确认页</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="line title">填写并核对订单</div>
    <div class="line main-content">
      <div class="address">
        <div class="module-name">
          <div>收货地址</div>
          <div @click="showAddressDialog()" class="new-address">新增收货地址</div>
        </div>
        <div ref="addressContainer" :class="['address-content', isHidden?'':'address-content-more']">
          <div @click="chooseAddress(item)" v-for="(item, index) in sortedAddressList" :key="index" :class="['address-item', item.id === userCurrAddress.id?'address-item-checked':'']">
            <div class="address-item-icon">{{item.contactPerson}}&nbsp;{{item.provinceName}}</div>
            <div class="address-item-info">{{item.contactPerson}}&nbsp;&nbsp;&nbsp;&nbsp;{{item.fullAddress}}&nbsp;&nbsp;&nbsp;&nbsp;{{item.contactPhone}}<span v-if="item.isDefault === 1">默认地址</span></div>
            <div class="address-item-action">
              <div v-if="item.isDefault === 2" @click.stop="setAddressDefault(item.id)">设为默认地址</div>
              <div @click.stop="showAddressDialog(item)">编辑</div>
              <div @click.stop="delAddress(item.id)">删除</div>
            </div>
          </div>
        </div>
        <div v-if="sortedAddressList.length === 0" class="address-empty">
          <img :src="require('../assets/imgs/empty/address.png')" alt="">
          <div class="tip">请添加新地址</div>
        </div>
        <div v-if="sortedAddressList.length > 4" ref="addressMore" class="address-more">
          <div @click="toggleAddressMore" class="btn">
            {{isHidden?'查看':'收起'}}更多
            <img v-if="isHidden" src="@/assets/imgs/cart/order-address-down.png" alt="">
            <img v-else src="@/assets/imgs/cart/order-address-up.png" alt="">
          </div>
        </div>
      </div>
      <div class="good">
        <div class="module-name">
          <div>送货清单</div>
          <div @click="goCart()" class="back-cart">返回修改购物车</div>
        </div>
        <div v-for="(item, index) in orderList" :key="index" class="shop">
          <div class="left-panel">
            <div class="shop-name">{{ item.shopName }}</div>
            <div v-for="(child, cIndex) in item.products" :key="cIndex" class="product">
              <div class="product-detail">
                <div class="img"><img :src="$fillImgPath(child.productImg)" alt=""></div>
                <div class="txt">
                  <div class="multi-ellipsis product-name">{{ child.skuName }}</div>
                  <div class="multi-ellipsis product-attr">{{ child.skuAttr }}</div>
                </div>
              </div>
              <div class="product-account">
                <div><span>¥</span>{{ child.price }}</div>
                <div>X{{ child.productQuantity }}</div>
              </div>
            </div>
          </div>
          <div class="right-panel">
            <div class="cell">
              <div class="cell-title">运送方式：</div>
              <div class="cell-value-default cell-value">{{ item.freight?item.freight === '0.00'?'包邮':item.freight:'包邮' }}</div>
              <div class="cell-txt">¥{{ item.freight }}</div>
            </div>
            <div v-if="item.proprietary === 1 && !item.isInsourcingShop" class="cell">
              <div class="cell-title">预约发货时间：</div>
              <div class="cell-value-default cell-value">{{ item.deliveryDate?parseDate(item.deliveryDate):'立即发货' }}</div>
              <div @click="showDateDialog(item)" class="cell-txt cell-action">选择发货日期</div>
            </div>
            <div class="cell">
              <div class="cell-title">发票信息：</div>
              <div class="cell-value-default cell-value cell-value-invoice">
                <template v-if="item.invoiceInfo.invoiceType===1">
                  请选择发票信息
                </template>
                <template v-else>
                  {{item.invoiceInfo.invoiceType===4?'专票':item.proprietary === 1 ? '电子' : '普票'}}/<span class="ellipsis">{{item.invoiceInfo.invoiceTitle}}</span>/商品明细
                </template>
              </div>
              <div @click="showInvoiceDialog(item)" class="cell-txt cell-action">修改</div>
            </div>
            <div class="cell">
              <div class="cell-title">备注：</div>
              <div class="cell-value-default"><Input v-model="item.remark" type="textarea" placeholder="选填，请先与商家协商确认" /></div>
            </div>
          </div>
        </div>
      </div>
      <div class="discount">
        <div class="module-name">
          <div>使用优惠&nbsp;/&nbsp;明珠卡&nbsp;/&nbsp;优惠券</div>
        </div>
        <div class="discount-wrap">
          <div class="discount-line">
            <div @click="changeDiscountTab(0)" :class="['line-item', discountIndex === 0? 'line-item-active' : '']">优惠券</div>
            <div @click="changeDiscountTab(1)" :class="['line-item', discountIndex === 1? 'line-item-active' : '']">明珠卡</div>
          </div>
          <div class="discount-content">
            <template v-if="discountIndex === 0">
              <template v-if="allUsableCouponList.length">
                <Checkbox @on-change="handleCheckboxChange" :value="isRecommend" style="margin-left: 16px;">优惠组合推荐</Checkbox>
                <div class="coupon-list">
                  <!-- 平台优惠券 -->
                  <div @click="handleCouponClick(item)" v-for="(item, index) in allUsableCouponList" :key="'use'+index" :class="['coupon-item', 'coupon-item-hover', selectedCouponList.includes(item.couponId)?'coupon-item-active':'' ]">
                    <coupon-item :couponInfo="item" :isGray="false"/>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="empty">
                  <img :src="require('../assets/imgs/empty/coupon.png')" alt="">
                  <div class="tip">暂无可用优惠券</div>
                </div>
              </template>
            </template>
            <template v-if="discountIndex === 1">
              <div class="filter-card">
                <Button @click="changeCardTab(0)" type="primary" :class="['use', cardType === 0? 'active-filter' : '']">可用<span v-if="cardUseList.length">（{{cardUseList.length}}）</span></Button>
                <Button @click="changeCardTab(1)" type="primary" :class="['unuse', cardType === 1? 'active-filter' : '']">
                  不可用
                  <span v-if="cardUnuseList.length">（{{cardUnuseList.length}}）</span>
                  <template v-if="cardType === 1">
                    <Poptip placement="bottom-start">
                      <div class="card-rule"><img :src="require('../assets/imgs/newOrder/wenhao.png')" alt=""></div>
                      <div class="card-rule-tip" slot="content">
                        <p>以下原因导致卡不可用：</p>
                        <p>1.该卡不在有效期内；</p>
                        <p>2.结算商品中有部分不支持使用该卡;</p>
                        <p>3.结算金额未满足使用门槛;</p>
                      </div>
                    </Poptip>
                  </template>
                </Button>
              </div>
              <div class="bind-card">
                <Form ref="dataForm" :model="form" :rules="rules" inline>
                  <FormItem prop="cardPsd">
                    <Input v-model="form.cardPsd" placeholder="请输入密码，可联系发卡人获得" size="large" style="width: 368px"/>
                  </FormItem>
                  <FormItem>
                    <Button @click="bindCard" type="primary" class="sure" size="large">绑卡</Button>
                  </FormItem>
                </Form>
              </div>
              <div class="card-list">
                <template v-if="cardType === 0">
                  <div @click="handleCardClick(item)" v-for="(item, index) in cardUseList" :key="'carduse'+index">
                    <div class="card-item">
                      <card-item @changeCardMoney="handleCardMoneyChange" :cardId="selectedCardItem.cardId+''" :money="selectedCardItem.cardAmount" :info="item" :isGray="false"></card-item>
                    </div>
                    <div class="choice-icon"><img :src="selectedCardItem.cardId === item.cardId?require('../assets/imgs/discounts/card-choice-yes.png'):require('../assets/imgs/discounts/card-choice-no.png')" alt=""></div>
                  </div>
                </template>
                <template v-else>
                  <div v-for="(item, index) in cardUnuseList" :key="'cardunuse'+index" class="card-item">
                    <card-item :info="item" :isGray="true"></card-item>
                  </div>
                </template>
              </div>
              <div v-if="(cardType === 0 && cardUseList.length === 0) || (cardType === 1 && cardUnuseList.length === 0)" class="empty" style="margin-left: 16px;width: 508px;">
                <img :src="require('../assets/imgs/empty/card.png')" alt="">
                <div class="tip">{{cardType === 0?'没有可使用的格力明珠卡(提货凭证)':'没有不可使用的格力明珠卡(提货凭证)'}}</div>
              </div>
            </template>
          </div>
          <div class="discount-line discount-total">
            <span class="discount-total-title">金额抵用：</span>
            <span class="discount-total-num">¥{{allAmount}}</span>
            <span v-if="selectedCouponList.length">使用优惠券{{selectedCouponList.length}}张，优惠{{allCouponAmount}}元</span>
            <span v-if="selectedCardItem.cardId&&selectedCardItem.cardId!==-1">使用明珠卡1张，抵扣{{selectedCardItem.cardAmount}}元</span>
          </div>
        </div>
      </div>
    </div>
    <div class="line account">
      <div class="detail">
        <div class="detail-line">
          <div class="val-title"><span>{{quantity}}</span>件商品，总商品金额：</div>
          <div class="val-num">¥{{Number(total) + Number(discount)}}</div>
        </div>
        <div class="detail-line">
          <div class="val-title">运费：</div>
          <div class="val-num">¥{{freight}}</div>
        </div>
        <div class="detail-line">
          <div class="val-title">店铺优惠：</div>
          <div class="val-num">-¥{{shopCouponAmount}}</div>
        </div>
        <div class="detail-line">
          <div class="val-title">平台优惠：</div>
          <div class="val-num">-¥{{selectedCouponItem.platformCouponAmount || '0.00'}}</div>
        </div>
        <div class="detail-line">
          <div class="val-title">明珠卡：</div>
          <div class="val-num">-¥{{selectedCardItem.cardId&&selectedCardItem.cardId!==-1?selectedCardItem.cardAmount:'0.00'}}</div>
        </div>
      </div>
      <div class="sum">
        <div class="total-num">
          <div class="val-title">应付总额：</div>
          <div class="val-num">
            <span class="unit">¥</span>
            <span class="num">{{total}}</span>
          </div>
        </div>
        <div v-if="Object.keys(userCurrAddress).length > 0 && sortedAddressList.length" class="address">寄送至：{{userCurrAddress.id?userCurrAddress.fullAddress : sortedAddressList[0].fullAddress}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;收件人：{{userCurrAddress.contactPerson || sortedAddressList[0].contactPerson}}&nbsp;&nbsp;{{userCurrAddress.contactPhone || sortedAddressList[0].contactPhone}}</div>
      </div>
    </div>
    <div class="line action"><div @click="checkSubmitAddress">提交订单</div></div>
    <!-- 创建编辑地址 -->
    <address-edit 
      v-model="isShowEditAddress"
      :id="addressInfo.id"
      @init="getAddressData">
    </address-edit>
    <!-- 选择发货日期 -->
    <delivery-date
      v-model="isShowDeliveryDate"
      :deliveryDate="dateInfo.deliveryDate"
      :currDate="serverCurrentTime"
      @change="handleDeliveryDateChange">
    </delivery-date>
    <!-- 发票 -->
    <invoice
      v-model="isShowInvoice"
      :info="dateInfo.invoiceInfo"
      :proprietary="dateInfo.proprietary"
      :shopId="dateInfo.shopId"
      :address="userCurrAddress"
      @change="handleInvoiceChange">
    </invoice>
    <!-- 优惠券确认弹窗 -->
    <confirm 
      v-model="isShowConfirm"
      @ok="confirmInfo[confirmType].ok()"
      @cancel="confirmInfo[confirmType].cancel()"
      :title="confirmInfo[confirmType].title"
      :des="confirmInfo[confirmType].des"
      :leftText="confirmInfo[confirmType].leftText"
      :rightText="confirmInfo[confirmType].rightText"
      :goodList="confirmInfo[confirmType].goodList"
      >
    </confirm>
  </div>
</template>

<script>
import config from '@/config'
import { mapState } from 'vuex'
import {
  submitOrder,
  bindCard,
  getOrderInfo,
  getUserAddressList,
  checkOrderAddress,
  deleteAddress,
  setAddress,
  getUserDetail,
} from '@/api/api'
import CardItem from '../components/Card.vue'
import Confirm from '../components/Confirm.vue'
import Invoice from '../components/Invoice.vue'
import AddressEdit from './user/address/Edit.vue'
import CouponItem from '../components/CouponItem.vue'
import DeliveryDate from '../components/DeliveryDate.vue'
export default {
  name: 'Order',
  components:{
    Confirm,
    Invoice,
    CardItem,
    CouponItem,
    AddressEdit,
    DeliveryDate,
  },
  data() {
    return {
      fromOrderList: this.$store.state.orderInfo.shops,
      userChangeDiscountCode: '0',
      userCurrAddress: this.$store.state.userAddress,
      isHidden: true,
      addressList: [],
      isShowEditAddress: false,
      addressInfo: {},
      orderList: [],
      total: '',
      discount: '',
      quantity: 0,
      ifFreight: false,
      isShowDeliveryDate: false,
      isShowInvoice: false,
      dateInfo: {},
      discountIndex: 0, // 优惠券-明珠卡tab位序
      selectedCouponId: -1,
      couponUseList: [],
      selectedCouponItem: {},
      selectedCouponList: [], // 店铺和平台组合在一起选中的卡券ID
      isShowConfirm: false,
      confirmType: 1,
      confirmInfo: {
        1: {
          title: '确定要继续修改优惠？',
          des: '您的订单金额已优惠至0元',
          leftText: '再想想',
          rightText: '确定',
          goodList: [],
          ok: () => {
            this.isShowConfirm = false
            this.chooseCoupon()
          },
          cancel: () => {
            this.isShowConfirm = false
          },
        },
        2: {
          title: '您的订单金额已优惠至0元，请先取消至少一项已选择的优惠。',
          leftText: '',
          rightText: '我知道了',
          goodList: [],
          ok: () => {
            this.isShowConfirm = false
          },
        },
        3: {
          title: '确定要继续修改优惠？',
          des: '您的订单金额已优惠至0元',
          leftText: '再想想',
          rightText: '确定',
          goodList: [],
          ok: () => {
            this.isShowConfirm = false
            this.chooseCard()
          },
          cancel: () => {
            this.isShowConfirm = false
          },
        },
        4: {
          title: '您还没有填写收货地址。建议先填写地址，以免影响订单配送。',
          leftText: '取消',
          rightText: '填写地址',
          goodList: [],
          ok: () => {
            this.isShowConfirm = false
            this.showAddressDialog()
          },
          cancel: () => {
            this.isShowConfirm = false
            // this.submit()
          },
        },
        5: {
          title: '您的详细地址包含不支持的字符，请先修改后再提交订单。',
          leftText: '',
          rightText: '立即修改',
          goodList: [],
          ok: () => {
            this.isShowConfirm = false
            this.showAddressDialog(this.userCurrAddress)
          },
        },
        6: {
          title: '商品已下架',
          des: '以下商品已下架，继续购买将此订单中清除下架商品',
          leftText: '重新选择',
          rightText: '继续购买',
          goodList: [],
          ok: () => {
            this.isShowConfirm = false
            this.continueBuy();
          },
          cancel: () => {
            this.isShowConfirm = false
            this.$router.back()
          },
        },
        7: {
          title: '商品库存不足',
          des: '以下商品库存不足，继续购买将按剩余库存计算',
          leftText: '重新选择',
          rightText: '继续购买',
          goodList: [],
          ok: () => {
            this.isShowConfirm = false
            this.continueBuy();
          },
          cancel: () => {
            this.isShowConfirm = false
            this.$router.back()
          },
        },
        8: {
          title: '商品价格更新提醒',
          des: '以下商品价格已经更新,继续购买将按新的价格进行结算',
          leftText: '重新选择',
          rightText: '继续购买',
          goodList: [],
          ok: () => {
            this.isShowConfirm = false
            this.continueBuy();
          },
          cancel: () => {
            this.isShowConfirm = false
            this.$router.back()
          },
        },
        9: {
          title: '活动信息发生变化',
          des: '以下商品活动信息发生变化请重新选择，继续购买将从订单中移除',
          leftText: '重新选择',
          rightText: '继续购买',
          goodList: [],
          ok: () => {
            this.isShowConfirm = false
            this.continueBuy();
          },
          cancel: () => {
            this.isShowConfirm = false
            this.$router.back()
          },
        },
        10: {
          des: '',
          leftText: '返回上一页',
          rightText: '修改地址',
          goodList: [],
          ok: () => {
            this.isShowConfirm = false
            this.showAddressDialog(this.userCurrAddress);
          },
          cancel: () => {
            this.isShowConfirm = false
            if (this.orderInfo.shopCart) {
              this.goCart()
              return
            } 
            this.$router.go(-1)
          },
        },
        11: {
          title: '商品不支持配送',
          des: '以下商品在所选地址不支持配送，继续购买将从订单中移除,继续购买将按新的价格进行结算',
          leftText: '重新选择',
          rightText: '继续购买',
          goodList: [],
          ok: () => {
            this.isShowConfirm = false
            this.fromOrderList = this.orderInfo.shops.filter(item => {
              item.products = item.products.filter(child => {
                let flag = this.deleteSkuIds && this.deleteSkuIds.includes(child.skuId)
                return !flag
              })
              return item.products.length
            })
            this.continueBuy();
          },
          cancel: () => {
            this.isShowConfirm = false
            this.goCart()
          },
        },
      },
      confirmItem: {},
      cardType: 0,
      form: {
        cardPsd: '',
      },
      cardUseList: [],
      cardUnuseList: [],
      cardId: -1,
      cardAmount: '',
      selectedCardItem: {
        cardId: '',
        cardAmount: '',
      },
      rules: {
        cardPsd: [
          { required: true, message: "请输入明珠卡密码", trigger: 'blur'},
          { type: 'string', max: 12, message: '明珠卡密码限12位数', trigger: 'blur' },
        ],
      },
      serverCurrentTime: '', // 服务器当前时间
      deleteSkuIds: []
    }
  },
  computed: {
    ...mapState([
      'orderInfo',
      'userInfo',
    ]),
    sortedAddressList() {
      let list = this.addressList.map(item => item); // 复制一份;
      if(list.length && this.userCurrAddress.id) {
        let index = list.findIndex(item => item.id === this.userCurrAddress.id);
        let temp = list.splice(index, 1);
        list = temp.concat(list);
      }
      return list;
    },
    isRecommend() {
      return this.selectedCouponId === -1 && this.fromOrderList.every(item => item.shopCouponId === -1)
    },
    // 店铺和平台组合在一起的可用卡券列表
    allUsableCouponList() {
      let selectedCouponList = [this.selectedCouponItem.selectedCouponId]
       // v3.8.7平台券是否叠加 overlayType：叠加类型 0为不可叠加,10为平台与店铺券可叠加，需要有文字提示
       // 不可叠加提示：新增不可叠加提示，当某商品可用多张优惠券，但eoc设置平台券不可与店铺券叠加，
       // 或多张同类型优惠券不可叠加时，用户选中其中一种类型的券，另外不可叠加的优惠券下方需要小字提示“此券暂不可与已勾选券叠加使用”，
       // 切换选择优惠券后，原提示文字隐藏，不可叠加的优惠券下方继续提示
      let shopCouponList = this.orderList.reduce((list, currentValue) => {
        // list: 平台优惠券， currentValue.storeCoupons.usableCoupons 店铺优惠券
        selectedCouponList.push(currentValue.selectedCouponId)
        if (currentValue.selectedCouponId) { //历史bug: 已经选择的店铺券，复制到提交数据中
          this.fromOrderList.forEach(item => {
          if(item.shopId === currentValue.shopId) {
            item.shopCouponId = currentValue.selectedCouponId
          }
        })
        }
        let subList = []
        if(currentValue.storeCoupons&&currentValue.storeCoupons.usableCoupons) {
          subList = currentValue.storeCoupons.usableCoupons.map(item => {
            // 填充ID选择优惠券时方便修改
            item.shopId = currentValue.shopId
            // if(selectedCouponList.indexOf(item.couponId) === -1) { // 同类型优惠券：有选中的，除去选中的其余的需要标识 不可叠加的标识
            //   item.overflag = true
            // }
            return item
          })
        }
        // let flag = false
        // let nonStackable = false
        // for (const _ of selectedCouponList) {
        //    if (list.some(i => i.couponId === _)) {
        //     flag = true
        //     if (list.find(i => i.couponId === _)?.overlayType === 0) { nonStackable = true }
        //     break
        //    }
        // }
        // if (!flag) { list.map(i => { i.overflag = false}) } // 当前优惠券类型都没选中的，那么全部都可以叠加
        // let storeFlag = false
        //   for (const _ of selectedCouponList) {
        //    if (currentValue.storeCoupons.usableCoupons.some(i => i.couponId === _)) {
        //     storeFlag = true
        //     break
        //    }
        // }
        // if (!storeFlag && !nonStackable) { currentValue.storeCoupons.usableCoupons.map(i => { i.overflag = false}) } // 当前店铺优惠券类型都没选中的，并且选中的平台券是可叠加的，那么全部都可以叠加
        return list.concat(subList)
      }, this.couponUseList.map(item => {
        // 填充ID选择优惠券时方便修改
        item.shopId = ''
        // 同类型优惠券：除去选中的其余的需要标识 不可叠加的标识
        // if(selectedCouponList.indexOf(item.couponId) === -1) {
        //   item.overflag = true
        // }
        return item
      }))
  
      // 统一处理不可叠加标识
      // 如果有选中的，剩下的给加 不可叠加标识
      shopCouponList.forEach( item => {
        if (selectedCouponList.indexOf(item.couponId) === -1) { item.overflag = true }
      })
      // 如果平台券都没选，那么平台券去掉叠加标识
        let flag = false
        let nonStackable = false
        for (const _ of selectedCouponList) {
           if (shopCouponList.filter(a => !a.shopId).some(i => i.couponId === _)) {
            flag = true
            
            if (shopCouponList.filter(a => !a.shopId).find(i => i.couponId === _)?.overlayType === 0) { nonStackable = true }
            break
           }
        }
        if (!flag) { shopCouponList.filter(a => !a.shopId).map(i => { i.overflag = false}) } // 当前平台券都没选中的，那么全部都可以叠加
        // 商品券都没选，如果平台券选了不可叠加的，则展示不可叠加标识，如果没有，则都不展示
        let storeFlag = false
          for (const _ of selectedCouponList) {
           if (shopCouponList.filter(a => a.shopId).some(i => i.couponId === _)) {
            storeFlag = true
            break
           }
        }
        if (!storeFlag && !nonStackable) { shopCouponList.filter(a => a.shopId).map(i => { i.overflag = false}) }

      // 按优惠力度最大的放前面
      shopCouponList.sort(function(a, b) {
        return Number(b.currentDiscountAmount) - Number(a.currentDiscountAmount)
      })
      // 选中的放前面
      shopCouponList.sort(function(a, b) {
        return (selectedCouponList.includes(b.couponId)) - (selectedCouponList.includes(a.couponId))
      })
      this.selectedCouponList = selectedCouponList.filter(item => item)
      return shopCouponList
    },
    // 多个店铺邮费汇总
    freight() {
      let num = this.orderList.reduce((total, currentValue) => {
        return total + Number(currentValue.freight)
      }, 0)
      return num.toFixed(2)
    },
    // 店铺优惠金额汇总
    shopCouponAmount() {
      let num = this.orderList.reduce((total, currentValue) => {
        let temp = currentValue.couponDiscountAmount?Number(currentValue.couponDiscountAmount):0 // 可能没返回，做一个判空处理
        return total + temp
      }, 0)
      return num.toFixed(2)
    },
    // 平台+店铺
    allCouponAmount() {
      let num = this.selectedCouponItem.platformCouponAmount?Number(this.selectedCouponItem.platformCouponAmount):0 // 可能没返回，做一个判空处理
      num += Number(this.shopCouponAmount)
      return num.toFixed(2)
    },
    // 平台+店铺+明珠卡
    allAmount() {
      let num = this.selectedCardItem.cardId&&this.selectedCardItem.cardId!==-1&&this.selectedCardItem.cardAmount?Number(this.selectedCardItem.cardAmount):0 // 可能没返回，做一个判空处理
      num += Number(this.allCouponAmount)
      return num.toFixed(2)
    },
  },
  watch: {
    'userCurrAddress.id': {
      handler: function() {
        this.getData();
      },
      immediate:true
    }
  },
  methods: {
    // ------------- 跳转模块 ---------------
    goCart() {
      if(this.orderInfo.shopCart) {
        // 从购物车过来
        this.$router.back()
      } else {
        // 从商详或我的订单过来
        this.$router.replace({
          name: 'Cart'
        })
      }
    },

    // ------------- 结算模块 ---------------
    continueBuy() {
      this.getData();
    },
    submit() {
      let param = {
        userId: this.userInfo.userId,
        tradeSource: parseInt('24'),
        shopCart: this.orderInfo.shopCart,
        homeShopId: config.STOREID,
        serviceShopId: config.STOREID,
        name: this.userCurrAddress.contactPerson,
        phone: this.userCurrAddress.contactPhone,
        provinceCode: this.userCurrAddress.provinceCode,
        cityCode: this.userCurrAddress.cityCode,
        countryCode: this.userCurrAddress.countryCode,
        townCode: this.userCurrAddress.townCode,
        detailAddress: this.userCurrAddress.detailAddress,
        address: this.userCurrAddress.address,
        ifAddress: !this.userCurrAddress.id,
        addressId: this.userCurrAddress.id,
        selfTake: false,
        internalBuyActivityId: this.orderInfo.internalBuyActivityId,
        shops: this.orderList.map(item => {
          let currShop = this.orderInfo.shops.find(shop => shop.shopId === item.shopId)
          return {
            shopId: item.shopId,
            deliveryDate: item.deliveryDate,
            remark: item.remark,
            tradeIn: 0,
            invoiceInfo: item.invoiceInfo,
            shopName: item.shopName,
            shopType: currShop.shopType,
            shopCouponId: item.selectedCouponId || '',
            shopCouponAmount: item.couponDiscountAmount || '',
            products: item.products.map(child => {
              let currProduct = currShop.products.find(product => product.skuId === child.skuId)
              
              return {
                productId: child.productId,
                quantity: child.productQuantity,
                skuId: child.skuId,
                price: child.price,
                freight: child.freight,
                activityInfos: currProduct.activityInfos || [],
              }
            }),
          }
        }),
        cardId: this.selectedCardItem.cardId === -1?'':this.selectedCardItem.cardId,
        cardAmount: this.selectedCardItem.cardAmount || '',
        platformCouponId: this.selectedCouponItem.selectedCouponId || '',
        platformCouponAmount: this.selectedCouponItem.platformCouponAmount || '',
        idempotentId: this.idempotentId,
      }
      submitOrder(param).then((res) => {
        const { result, data, message } = res
        if (result === 0) {
          if(data.payStatus === 0) {
            if(data.zeroPay) {
              this.$router.push({
                path: '/paySuccess',
                query: {
                  value: data.payAmount,
                  orderId: data.subOrderId,
                  parentOrderId: data.parentOrderId,
                }
              })
            } else {
              this.$router.push({
                path: '/payOrder',
                query: {
                  value: data.payAmount,
                  orderId: data.subOrderId,
                  parentOrderId: data.parentOrderId,
                }
              })
            }
          } else if (
            data.payStatus == 1001 || 
            data.payStatus == 1002 ||
            data.payStatus == 1003 ||
            data.payStatus == 1010
          ) {
            let type = data.payStatus === 1003?6:
                       data.payStatus === 1002?7:
                       (data.payStatus === 1001 || data.payStatus === 1010)?8:9;
            this.fromOrderList=this.orderInfo.shops.filter(item => {
              item.products = item.products.filter(child => {
                let flag = data.deleteSkuIds&& data.deleteSkuIds.includes(child.skuId)
                if(!flag) {
                  let index = data.payErrorProducts.findIndex(good => good.skuId === child.skuId)
                  if(index >= 0) {
                    if(type === 8) {
                      child.price = data.payErrorProducts[index].currentPrice
                    } else {
                      child.quantity = data.payErrorProducts[index].remnantInventory
                    }

                  }
                }
                return !flag
              })
              return item.products.length
            })
            this.confirmInfo[type].goodList = data.payErrorProducts;
            this.isShowConfirm = true;
            this.confirmType = type
          } else if (data.payStatus == 1013) {
            const type = 11
            let goods;
            this.confirmType = type
            this.confirmInfo[type].goodList = data.deleteSkuIds.map(el=> {
              this.orderInfo.shops.forEach(item=> {
                goods = item.products.find(goods=> goods.skuId == el)
              })
              return {
                thumbUrl: goods.productImg,
                skuName: goods.skuName,
                currentPrice: goods.price
              }
            })
            
            this.fromOrderList = this.orderInfo.shops.filter(item => {
              item.products = item.products.filter(child => {
                let flag = data.deleteSkuIds && data.deleteSkuIds.includes(child.skuId)
                return !flag
              })
              return item.products.length
            })
            this.isShowConfirm = true;
          }
        } else {
          if(
            result === 2068 ||
            result === 1690 ||
            result === 2059 ||
            result === 2422 ||
            result === 2240 ||
            result === 10120
          ) {
            this.$router.back()
          } else if(result === 2082){
            this.getData();
          } else if(result === 1687){
            let type = 10
            this.confirmInfo[type].des = message;
            this.isShowConfirm = true;
            this.confirmType = type
            return
          }
        }
      }).catch((err) => {
      })
    },
    checkSubmitAddress() {
      // 校验是否有地址
      if(!this.userCurrAddress.id) {
        let flag = this.orderList.every(item => item.proprietary === 1)
        if(this.ifFreight && flag) {
          this.isShowConfirm = true;
          this.confirmType = 4;
        } else {
          this.$Message.info('请填写收货地址');
        }
      } else {
        // 校验详细地址格式
        const reg = /^[0-9a-zA-Z\u4e00-\u9fa5\-\(\)\（\）\s*]+$/
        if(!reg.test(this.userCurrAddress.detailAddress)){
          this.isShowConfirm = true;
          this.confirmType = 5;
        } else {
          this.submit()
        }
      }
    },

    // ------------- 优惠模块 ---------------
    chooseCard() {
      if(this.selectedCardItem.cardId === this.confirmItem.cardId) {
        this.cardId = ''
        this.cardAmount = ''
      } else {
        this.cardId = this.confirmItem.cardId
        this.cardAmount = this.confirmItem.currentMaxCardAmount
      }
      this.userChangeDiscountCode = '30'
      this.getData();
    },
    handleCardClick(item) {
      this.confirmItem = item;
      if(this.total === '0.00') {
        if(this.selectedCardItem.cardId === item.cardId) {
          this.isShowConfirm = true;
          this.confirmType = 3;
        } else {
          this.isShowConfirm = true;
          this.confirmType = 2;
        }
      } else {
        this.chooseCard()
      }
    },
    handleCardMoneyChange(val) {
      this.cardId = this.selectedCardItem.cardId
      this.cardAmount = val
      this.userChangeDiscountCode = '30'
      this.getData();
    },
    bindCard() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          bindCard({
            userId: this.userInfo.userId,
            cardPassword: this.form.cardPsd,
          }).then((res) => {
            if(!res) return
            if (res.result === 0) {
              switch(res.data.bindResult) {
                case 1:
                  this.$Message.info('该卡密不存在请重新输入');
                  break
                case 2:
                  this.$Message.info('绑卡成功, 但该卡已过期或停用');
                  this.getData();
                  break
                case 3:
                  this.$Message.info('该卡密已被使用');
                  break
                case 4:
                  this.$Message.info('绑卡成功');
                  this.form.cardPsd = ''
                  this.getData();
                  break
                case 5:
                  this.$Message.info('绑卡成功, 该卡需等到有效期才能使用');
                  this.getData();
                  break
              }
            } else {
            }
          }).catch((err) => {
          })
        }
      })
    },
    changeCardTab(index) {
      if(this.cardType !== index) {
        this.cardType = index;
      }
    }, 
    chooseCoupon() {
      let flag = this.selectedCouponList.includes(this.confirmItem.couponId)
      // v3.8.7平台券是否叠加 overlayType：叠加类型 0为不可叠加,10为平台与店铺券可叠加
      // 不可叠加提示：新增不可叠加提示，当某商品可用多张优惠券，但eoc设置平台券不可与店铺券叠加，
      // 或多张同类型优惠券不可叠加时，用户选中其中一种类型的券，另外不可叠加的优惠券下方需要小字提示“此券暂不可与已勾选券叠加使用”，
      // 切换选择优惠券后，原提示文字隐藏，不可叠加的优惠券下方继续提示
      console.log(this.fromOrderList, 111111111)
      if(this.confirmItem.shopId === '') { // 平台券
        this.userChangeDiscountCode = '20'
        if (flag) { // 取消选中操作
          this.selectedCouponId = ''
        } else { // 选中操作
        if (this.confirmItem && this.confirmItem?.overlayType === 0) { // 不可叠加
          let flag = false
          for (const _ of this.selectedCouponList) {
           if (this.allUsableCouponList.filter(i => i.shopId).some(i => i.couponId === _)) {
            flag = true
            break
           }
        }
        if (flag) { //  当平台券不可叠加时，如果存在店铺券（商品券,不论多少张商品券，满减券都删点），该店铺券全部取消
          this.selectedCouponList.forEach(_ => {
            this.fromOrderList.forEach(item => {
              if(item.shopCouponId === _) {
                item.shopCouponId = ''
              }
            })
          })
         }
        }
        this.selectedCouponId = this.confirmItem.couponId
        }
        // this.userChangeDiscountCode = '20'
        // this.selectedCouponId = flag ? '': this.confirmItem.couponId // 取消/选中
      } else {
        // 店铺券
        this.userChangeDiscountCode = '10'
        console.log(this.selectedCouponList, 6666)
        if(!flag) {
          // 如果存在平台券，且该平台券不能叠加，那么平台券被取消
          let storeflag = false
          for (const _ of this.selectedCouponList) {
           if (this.allUsableCouponList.find(i => i.couponId === _)?.overlayType === 0) {
            storeflag = true
            break
           }
        }
        console.log(this.selectedCouponId, 676776)
        if (storeflag)  { // 选中的平台券不能叠加,清空选中的券
          this.selectedCouponId = ''
        }
        }
        this.fromOrderList.forEach(item => {
          if(item.shopId === this.confirmItem.shopId) {
            item.shopCouponId = flag ? '' : this.confirmItem.couponId
          }
        })
      }
      this.getData();
    },
    handleCouponClick(item) {
      this.confirmItem = item;
      if(this.total === '0.00') {
        if(this.selectedCouponList.includes(item.couponId)) {
          this.isShowConfirm = true;
          this.confirmType = 1;
        } else {
          this.isShowConfirm = true;
          this.confirmType = 2;
        }
      } else {
        this.chooseCoupon()
      }
    },
    handleCheckboxChange(val) {
      let temp = val?-1:''
      this.selectedCouponId = temp
      this.fromOrderList.forEach(item => {
        item.shopCouponId = temp
      })
      this.userChangeDiscountCode = '20'
      this.getData();
    },
    changeDiscountTab(index) {
      if(this.discountIndex !== index) {
        this.discountIndex = index;
      }
    },

    // ------------- 发票模块 ---------------
    showInvoiceDialog(item) {
      this.isShowInvoice = true;
      this.dateInfo = item
    },
    handleInvoiceChange(val) {
      this.dateInfo.invoiceInfo = val
    },

    // ------------- 修改发货日期模块 ---------------
    showDateDialog(item) {
      this.isShowDeliveryDate = true;
      this.dateInfo = item
    },
    handleDeliveryDateChange(val) {
      this.dateInfo.deliveryDate = val
    },
    parseDate(val) {
      let valDate = new Date(val)

      let nowYear = valDate.getFullYear()
      let nowMonth = valDate.getMonth() + 1
      let nowDate = valDate.getDate()
      let nowDay = ['日', '一', '二', '三', '四', '五', '六'][valDate.getDay()]

      return '预计 ' + nowMonth + '月' + nowDate + '日[周' + nowDay +'] 发货'
    },

    // ------------- 地址模块 ---------------
    chooseAddress(item) {
      this.userCurrAddress = item
      this.$store.commit('SET_USER_ADDRESS', item)
      this.checkAddress()
    },
    toggleAddressMore() {
      const BeforeTop = this.$refs.addressMore.offsetTop;
      this.$refs.addressContainer.scrollTop = 0;
      this.isHidden = !this.isHidden;
      this.$nextTick(() => {
        window.scrollTo(0, (window.pageYOffset || window.document.documentElement.scrollTop || window.scrollY) + (this.$refs.addressMore.offsetTop - BeforeTop));
      });
    },
    delAddress(id) {
      this.$Modal.confirm({
        title: '确定要删除地址吗？',
        onOk: () => {
          deleteAddress({
            userId: this.userInfo.userId,
            id: id
          }).then((res) => {
            if (res.result === 0) {
              this.$Message.success('删除成功');
              this.getAddressData();
            } else {
            }
          }).catch((err) => {
            
          })
        }
      });
    },
    setAddressDefault(id) {
      getUserDetail({
        userId: this.userInfo.userId,
        id: id,
      }).then((res) => {
        if (res.result === 0) {
          res.data.isDefault = 1;
          setAddress(res.data).then((res) => {
            if (res.result === 0) {
              this.$Message.success('设置默认地址成功');
              this.getAddressData();
            } else {
            }
          }).catch((err) => {
          })
        } else {
        }
      }).catch((err) => {
      })
    },
    // 打开新增编辑地址弹窗
    showAddressDialog(item) {
      this.isShowEditAddress = true;
      this.addressInfo = item?item: {}
    },
    checkAddress() {
      checkOrderAddress({
        cityCode: this.userCurrAddress.townCode || this.userCurrAddress.countryCode,
        shops: this.orderInfo.shops.map(item => {
          return {
            shopId: item.shopId,
            shopName: item.shopName,
            products: item.products.map(el=>{
              return {
                skuId: el.skuId,
                productId: el.productId,
                thirdPlatformCid: el.thirdPlatformCid
              }
            })
          }
        }),
      }).then((res) => {
        if (res.result === 0) {
          if(!res.data.support && !Reflect.has(res.data, 'skuIdList')) {
            let type = 10
            this.confirmInfo[type].des = res.message;
            this.isShowConfirm = true;
            this.confirmType = type
            return
          }
          if (res.data.skuIdList && res.data.skuIdList.length) {
            let type = 11
            this.deleteSkuIds = res.data.skuIdList
            const goods = this.orderInfo.shops.reduce((pre, cur) => {
              pre.push(...cur.products)
              return pre
            }, [])
            this.confirmInfo[type].goodList = res.data.skuIdList.map(el=> {
              let item = goods.find(good=> good.skuId == el)
              return {
                thumbUrl: item.productImg,
                skuName: item.skuName,
                currentPrice: item.price
              }
            })
            this.confirmType = type
            this.isShowConfirm = true;
          }
        } else {
        }
      }).catch((err) => {
      })
    },
    getAddressData() {
      getUserAddressList({
        userId: this.userInfo.userId,
      }).then((res) => {
        if (res.result === 0) {
          this.addressList = res.data;
          let index = res.data.findIndex((item) => item.id === this.userCurrAddress.id)
          this.userCurrAddress = this.userCurrAddress.id && index !== -1?res.data[index]:res.data.length?res.data[0]:{}
        } else {
        }
      }).catch((err) => {
      })
    },

    // ------------- 获取数据模块 ---------------
    getData() {
      getOrderInfo({
        userId: this.userInfo.userId,
        shops: this.fromOrderList,
        shopCart: this.orderInfo.shopCart,
        addressId: this.userCurrAddress.id,
        ifAddress: !this.userCurrAddress.id,
        
        homeShopId: config.STOREID,
        tradeSource: parseInt('24'),
        selfTake: false,

        platformCouponId: this.selectedCouponId,
        cardId: this.cardId,
        cardAmount: this.cardAmount,

        userChangeDiscountCode: this.userChangeDiscountCode,
      }).then((res) => {
        if (res.result === 0) {
          res.data.orders.forEach(element => {
            element.deliveryDate = '';
            element.invoiceInfo = this.dateInfo.invoiceInfo || {
              invoiceType: 2,
              invoiceTitle: '个人',
              invoiceContent: '商品明细',
              ticketMobile: element.proprietary !== 1 && this.userCurrAddress.id?this.userCurrAddress.contactPhone:this.userInfo.mobile,
            };
            element.remark = '';
          });
          this.orderList = res.data.orders;
          // 优惠券
          this.couponUseList = res.data.platformCoupons?res.data.platformCoupons.usableCoupons: [];
          let couponItem = res.data.selectedCouponId?res.data.platformCoupons.usableCoupons.find(item => item.selectedCoupon):{};
          this.selectedCouponItem = {
            ...couponItem,
            selectedCouponId: res.data.selectedCouponId,
            platformCouponAmount: res.data.platformCouponAmount,
          }
          // 明珠卡
          this.cardUseList = res.data.cardList&&res.data.cardList.usableCard?res.data.cardList.usableCard: [];
          this.cardUnuseList = res.data.cardList&&res.data.cardList.unusableCard?res.data.cardList.unusableCard: [];
          this.selectedCardItem = {
            cardId: res.data.cardId?res.data.cardId:-1,
            cardAmount: res.data.cardAmount,
          }

          // 金额
          this.total = res.data.totalAmount;
          this.discount = res.data.discountAmount;
          this.quantity = res.data.totalQuantity;
          this.ifFreight = res.data.ifFreight;
          this.idempotentId = res.data.idempotentId;
          this.serverCurrentTime = res.data.serverCurrentTime;
        } else {
          if(res.result === 1690 || res.result === 2059 || res.result === 2068) {
            setTimeout(() => {
              this.$router.back()
            }, 2000)
          }
        }
      }).catch((err) => {
      })
    },
  },
  created() {
    if((!this.userCurrAddress.id) && (Object.keys(this.userCurrAddress).length !== 0)) {
      // 保存一份四级地址
      let editAddress = JSON.parse(JSON.stringify(this.userCurrAddress))
      this.$Modal.confirm({
        title: '您在浏览商品过程中选择了新的地址，是否新建一个收货地址？',
        okText: '去新建',
        onOk: () => {
          this.showAddressDialog(editAddress);
        }
      });
    }
    this.getAddressData();
  }
}
</script>

<style lang="less" scoped>
.order {
  .line {
    padding: 0 16px;
  }
  .breadcrumb {
    padding-top: 14px; 
    padding-bottom: 14px; 
    .ivu-breadcrumb {
      font-size: 16px;
      line-height: 18px;
    }
  }
  .title {
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 20px;
    line-height: 23px;
    color: #333333;
  }
  .main-content {
    padding: 0 15px;
    padding-bottom: 24px;
    border: 1px solid rgba(153,153,153,0.20);
    > div {
      margin-top: 16px;
      border-top: 1px solid rgba(153,153,153,0.20);
    }
    > div:first-child {
      margin-top: 0;
      border: none;
    }
    .module-name {
      padding: 16px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div:first-child {
        line-height: 19px;
        font-size: 16px;
        color: #333333;
        font-weight: bold;
      }
      .new-address {
        line-height: 16px;
        font-size: 14px;
        color: #333333;
        cursor: pointer;
      }
      .new-address:hover {
        color: #0085ff;
      }
      .back-cart {
        line-height: 16px;
        font-size: 14px;
        color: #0085ff;
        cursor: pointer;
      }
      .back-cart:hover {
        color: #CC0000;
      }
    }
    .address {
      .address-content {
        max-height: 208px;
        overflow: hidden;
        .address-item {
          margin-top: 16px;
          display: flex;
          align-items: center;
          cursor: pointer;
          .address-item-icon {
            width: 172px;
            line-height: 38px;
            background-color: #F2F2F2;
            border: 1px solid rgba(153,153,153,0.60);
            text-align: center;
            font-size: 14px;
            color: #333333;
          }
          .address-item-info {
            flex: 1;
            overflow: hidden;
            margin: 0 24px 0 38px;
            font-size: 14px;
            color: #333333;
            span {
              display: inline-block;
              margin-left: 24px;
              padding: 4px 12px;
              background: rgba(51,51,51,0.20);
              color: rgba(51,51,51,0.60);
            }
          }
          .address-item-action {
            display: none;
            justify-content: flex-end;
            align-items: center;
            font-size: 14px;
            color: #0085ff;
            div {
              margin-right: 24px;
            }
            div:hover {
              color: #CC0000;
            }
          }
        }
        .address-item-checked {
          .address-item-icon {
            border-color: #cc0000;
            position: relative;
          }
          .address-item-icon::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 24px;
            height: 24px;
            background-image: url(../assets/imgs/cart/order-address-checked.png);
            background-size: 100% 100%;
          }
        }
        .address-item:first-child {
          margin-top: 0px;
        }
        .address-item:hover {
          background-color: #F3EFE9;
          .address-item-action {
            display: flex;
          }
        }
      }
      .address-empty {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0;
        img {
          width: 40px;
          height: 40px;
        }
        .tip {
          margin-left: 13px;
          font-size: 14px;
          color: #999999;
        }
      }
      .address-content-more {
        max-height: 376px;
        overflow: auto;
      }
      .address-more {
        margin-top: 16px;
        text-align: right;
        .btn {
          display: inline-block;
          font-size: 14px;
          color: #333333;
          img {
            width: 10px;
            height: 11px;
          }
        }
        .btn:hover {
          cursor: pointer;
          color: #CC0000;
        }
      }
    }
    .good {
      .shop {
        margin-bottom: 16px;
        display: flex;
        .left-panel {
          width: 612px;
          background-color: rgba(0,133,255,0.05);
          padding: 0 9px;
          .shop-name {
            font-size: 14px;
            line-height: 28px;
            color: #333333;
            font-weight: bold;
          }
          .product {
            height: 133px;
            border-bottom: 1px solid #F5F8FA;
            display: flex;
            align-items: center;
            .product-detail {
              display: flex;
              .img {
                width: 100px;
                height: 100px;
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 4px;
                }
              }
              .txt {
                margin-left: 7px;
                width: 284px;
                .product-name {
                  font-size: 14px;
                  color: #333333;
                  line-height: 20px;
                }
                .product-attr {
                  margin-top: 15px;
                  font-size: 14px;
                  color: #333333;
                  line-height: 16px;
                }
              }
            }
            .product-account {
              margin-left: 6px;
              flex: 1;
              overflow: hidden;
              display: flex;
              align-items: center;
              div:first-child {
                width: 148px;
                line-height: 21px;
                font-size: 16px;
                color: #cc0000;
                font-weight: bold;
                span {
                  font-size: 14px;
                }
              }
              div:last-child {
                line-height: 14px;
                font-size: 12px;
                color: #333333;
              }
            }
          }
          .product:last-of-type {
            height: 132px;
            border: none;
          }
        }
        .right-panel {
          flex: 1;
          overflow: hidden;
          background-color: rgba(153,153,153,0.10);
          padding: 8px 16px;
          .cell {
            margin-top: 8px;
            display: flex;
            .cell-title {
              width: 98px;
              line-height: 30px;
              font-size: 14px;
              color: #333333;
            }
            .cell-value-default {
              flex: 1;
              overflow: hidden;
            }
            .cell-value {
              line-height: 28px;
              border: 1px solid #dcdee2;
              background: #ffffff;
              text-align: center;
              font-size: 14px;
              color: #333333;
            }
            .cell-value-invoice {
              padding: 0 9px;
              text-align: left;
              font-size: 12px;
              color: #999999;
              span {
                display: inline-block;
                max-width: 138px;
                vertical-align: top;
              }
            }
            .cell-txt {
              margin-left: 16px;
              width: 84px;
              line-height: 30px;
              font-size: 16px;
              color: #cc0000;
            }
            .cell-action {
              font-size: 14px;
              color: #0085ff;
              cursor: pointer;
            }
            .cell-action:hover {
              color: #CC0000;
            }
          }
          .cell:first-child {
            margin-top: 0;
          }
        }
      }
      .shop:last-of-type {
        margin-bottom: 0px;
      }
    }
    .discount {
      .discount-wrap {
        border: 1px solid rgba(153,153,153,0.20);
        .discount-line {
          background: rgba(153,153,153,0.20);
          display:flex;
          align-items: center;
          .line-item {
            width: 119px;
            background-color: #E0E0E0;
            line-height: 29px;
            text-align: center;
            font-size: 14px;
            color: #999999;
            cursor: pointer;
          }
          .line-item:hover {
            background: #cc0000;
            opacity: 0.4;
            color: #ffffff;
          }
          .line-item-active,  .line-item-active:hover{
            background: #cc0000;
            opacity: 1;
            color: #ffffff;
          }
        }
        .discount-content {
          min-height: 286px;
          max-height: 523px;
          overflow: overlay;
          padding: 24px 14px 24px 0;
          .coupon-list {
            display: flex;
            flex-wrap: wrap;
            .coupon-item {
              margin-top: 16px;
              margin-left: 16px;
              width: 368px;
              position: relative;
              cursor: pointer;
            }
            .coupon-item-hover:hover ::after{
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 368px;
              height: 120px;
              border: 1px solid #cc0000;
              border-radius: 16px;
            }
            .coupon-item-active ::before{
              content: '';
              position: absolute;
              bottom: 0;
              right: 0;
              width: 24px;
              height: 24px;
              background-image: url(../assets/imgs/cart/order-address-checked.png);
              background-size: 100% 100%;
            }
            .coupon-item-active ::after{
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 368px;
              height: 120px;
              border: 1px solid #cc0000;
              border-radius: 16px;
            }
          }
          .empty {
            height: 238px;
            text-align: center;
            font-size: 0;
            img {
              width: 226px;
              height: 226px;
            }
            .tip {
              font-size: 12px;
              color: #cc0000;
            }
          }
          .filter-card {
            margin-left: 16px;
            .use {
              background: #999999;
              border-color: #999999;
            }
            .unuse {
              margin-left: 40px;
              background: #999999;
              border-color: #999999;
              .card-rule {
                display: inline-block;
                width: 12px;
                height: 12px;
                font-size: 0;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .card-rule-tip {
                text-align: left;
                color: #666;
              }
            }
            .active-filter {
              background: #cc0000;
              border-color: #cc0000;
            }
          }
          .bind-card {
            margin-top: 16px;
            margin-left: 16px;
            .sure {
              margin-left: 6px;
              width: 124px;
              background: #cc0000;
              border-color: #cc0000;
            }
          }
          .card-list {
            margin-top: -16px;
            display: flex;
            flex-wrap: wrap;
            .card-item {
              margin-top: 16px;
              margin-left: 16px;
              width: 368px;
            }
            .choice-icon {
              margin-top: 24px;
              text-align: center;
              font-size: 0;
              cursor: pointer;
              img {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
        .discount-total {
          line-height: 29px;
          padding: 0 85px;
          font-size: 12px;
          color: rgba(102,102,102,0.60);
          .discount-total-title {
            font-size: 14px;
            color: #333333;
          }
          .discount-total-num {
            margin-right: 49px;
            font-size: 16px;
            color: #cc0000;
          }
        }
      }
    }
  }
  .account {
    .detail {
      padding: 27px 0;
      .detail-line {
        margin-top: 15px;
        text-align: right;
      }
      .detail-line:first-child {
        margin-top: 0px;
      }
    }
    .val-title {
      display: inline-block;
      font-size: 14px;
      color: #333333;
      span {
        color: #CC0000;
      }
    }
    .val-num {
      display: inline-block;
      margin-left: 5px;
      width: 165px;
      font-size: 16px;
      color: #CC0000;
    }
    .sum {
      padding: 16px 0;
      background: rgba(153,153,153,0.20);
      text-align: right;
      .total-num {
        line-height: 16px;
        font-size: 14px;
        color: #333333;
        .unit {
          display: inline-block;
          line-height: 27px;
          font-size: 18px;
          color: #cc0000;
        }
        .num {
          display: inline-block;
          line-height: 29px;
          font-size: 24px;
          color: #cc0000;
        }
      }
      .address {
        margin-top: 10px;
        line-height: 16px;
        font-size: 14px;
        color: #666666;
      }
    }
  }
  .action {
    margin-top: 24px;
    text-align: right;
    div {
      cursor: pointer;
      display: inline-block;
      width: 169px;
      line-height: 60px;
      text-align: center;
      background: #cc0000;
      border-radius: 4px;
      font-size: 30px;
      color: #ffffff;
      font-weight: bold;
    }
  }
}
</style>

<style lang="less">
.order {
  .breadcrumb {
    .ivu-breadcrumb {
      >span:last-child {
        color: #333333;
        font-weight: 400;
      }
      a {
        color: #999999;
      }
      .ivu-breadcrumb-item-separator {
        margin: 0 2px;
        color: #999999;
      }
    }
  }
  .ivu-checkbox-inner {
    border: 1px solid #999999;
    background-color: #F2F2F2;
  }
  .ivu-checkbox-checked .ivu-checkbox-inner {
    border-color: #CC0000;
    background-color: #CC0000;
  }
  .ivu-checkbox:hover .ivu-checkbox-inner {
    border-color: #999999;
  }
  .ivu-checkbox-checked:hover .ivu-checkbox-inner {
    border-color: #CC0000;
  }
  .ivu-btn-primary:focus {
    box-shadow: none;
  }
}
</style>