import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './router'
import store from '../store'
import ViewUI from 'view-design'
import config from '@/config'
import { param2Obj } from '@/utils/common'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function (location, onResolve, onReject) {
  if (config.WHITE_LIST.indexOf(router.currentRoute.name) === -1) {
    store.commit('REMOVE_CACHE_LIST');
  }
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

// 1. 区分页面：全局添加时间戳参数
const transitionTo = router.history.transitionTo;
router.history.transitionTo = function (location, onComplete, onAbort) {
  // 前进回退都会进
  if(typeof location === 'object') {
    if(location.query && location.query.e) {
      location = {
        ...location,
        query: {
          ...location.query,
        }
      }
    } else {
      location = {
        ...location,
        query: {
          ...location.query,
          e: Date.now()
        }
      }
    }
  } else {
    let obj = param2Obj(location);
    if(obj.e) {
      location = {
        path: location
      }
    } else {
      location = {
        path: location,
        query: {
          e: Date.now()
        }
      }
    }
  }
  transitionTo.call(router.history, location, onComplete, onAbort);
}

router.beforeEach((to, from, next) => {
  ViewUI.LoadingBar.start()
  const userInfo = store.state.userInfo;
  if (userInfo.userId === undefined && to.meta.auth) {
    next({
      name: 'Login',
      query: {
        redirect: to.fullPath
      }
    })
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  ViewUI.LoadingBar.finish();
  window.scrollTo(0, 0) // 新开页面滚动条设置，必须加；
  // push新页面
  if (config.WHITE_LIST.indexOf(to.name) === -1) {
    store.commit('PUSH_CACHE_LIST', to.name);
  }
})

export default router
