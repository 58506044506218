<template>
  <div :class="['goods', 'goods-line'+lineNum]">
    <div class="goods-inner">
      <div @click="goDetail(item)" v-for="(item, index) in listData" :key="index" :class="['good', item.isAd?'':'good-hover']">
        <template v-if="!item.isAd">
          <div class="img"><img :src="$fillImgPath(item.thumbUrl)" alt=""></div>  
          <div class="detail">
            <div v-if="type === 1" class="multi-ellipsis title">{{item.productName}}</div>
            <div class="price">
              <template v-if="item[priceKey]">
                <span class="price-unit">¥</span>
                <span class="price-num">{{item[priceKey]}}</span>
              </template>
              <!-- 划线价 -->
              <span v-if="parseFloat(item[lineKey]) > parseFloat(item[priceKey])" class="line-price">
                ¥{{ item[lineKey] }}
              </span>
              <!-- <span v-else class="no-price">商务合作</span> -->
            </div>
            <div v-if="type === 2" class="multi-ellipsis title">{{item.productName}}</div>
            <div v-if="(item[labelKey]&&item[labelKey].length) || item.selfRunFlag === '20'" class="labels">
              <span v-if="item.selfRunFlag === '20'" class="icon-3">自营</span>
              
              <!-- <span :class="child==='满减'||child==='直降'||child==='秒杀'||child==='拼团'?'icon-1':child==='新品'?'icon-2':child==='以旧换新'?'icon-5':'icon-4'" v-for="(child, childIndex) in item[labelKey]" :key="childIndex">{{child}}</span> -->
              <template v-for="(itemTab, index) in item[labelKey]">
                <span v-if="itemTab == '新品'" style="background: #1480e3">{{
                  itemTab
                }}</span>
                <span v-else-if="itemTab == '秒杀' || itemTab.startsWith('直降')" style="background: #cc0000">{{
                  itemTab
                }}</span>
                <span v-else-if="!['满减','拼团','预售','以旧换新'].includes(itemTab)" style="background: #813DEE">{{
                  itemTab
                }}</span>
              </template>
            </div>
          </div>
        </template>
        <div @click.stop="$emit('link',item);" class="ad" v-else>
          <img :src="$fillImgPath(item.thumbUrl)" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Goods',
  props: {
    lineNum: {
      type: Number,
      default: 5
    },
    listData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    type: {
      type: Number,
      default: 1 
    },
    priceKey: String,
    lineKey: String,
    labelKey: String,
  },
  methods: {
    goDetail(item) {
      this.$router.push({
        name: 'GoodsDetail',
        query: {
          productNo: item.productId,
          skuNo: item.skuId,
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.goods {
  .goods-inner {
    margin-bottom: -16px;
    margin-left: calc((1135px - 100%)/4);
    display: flex;
    flex-wrap: wrap;
    .good {
      width: 227px;
      margin-bottom: 16px; 
      margin-left: calc((100% - 1135px)/5);
      background-color: white;
      cursor: pointer;
      .img {
        width: 100%;
        height: 227px;
        padding: 8px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .detail {
        height: 125px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .price {
          display: flex;
          align-items: baseline;
          color: #E41F1F;
          .price-unit {
            font-weight: 600;
            font-size: 14px;
          }
          .price-num {
            font-weight: 600;
            margin-left: 4px;
            font-size: 20px;
            font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
          }
          .line-price {
            margin-left: 8px;
            text-decoration: line-through;
            font-size: 14px;
            color: #999999;
          }
          .no-price {
            font-size: 16px;
          }
        }
        .title {
          height: 40px;
          line-height: 20px;
          font-size: 16px;
          color: #333333;
        }
        .labels {
          height: 20px;
          overflow: hidden;
          span {
            display: inline-block;
            margin-left: 8px;
            padding: 4px 12px;
            font-size: 12px;
            border-radius: 4px;
            color: white;
          }
          span:first-child {
            margin-left: 0;
          }
          .free-delivery {
            color: #cc0000;
            padding-top: 3px;
            padding-bottom: 3px;
            border: 1px solid #cc0000;
          }
          .after-market {
            padding-top: 3px;
            padding-bottom: 3px;
            color: #1480e3;
            border: 1px solid #1480e3;
          }
          .icon-1 {
            background-color: #cc0000;
          }
          .icon-2 {
            background-color: #1480e3;
          }
          .icon-3 {
            background-color:  #ee5153;
          }
          .icon-4 {
            background-color:  #813DEE;
          }
          .icon-5 {
            background-color:  #F25C0C;
          }
        }
      }
      .ad {
        width: 227px;
        height: 352px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .good-hover {
      transition: all 1s;
      border: 1px solid #fff;
      .img {
        height: 227px;
        padding: 8px;
        overflow: hidden;
        img {
          transition: all 1s;
        }
        &:hover img {
          transform: scale(1.04);
        }
      }
      .detail {
        height: 124px;
        padding: 8px 7px 7px;
      }
    }
    .good-hover:hover {
      border: 1px solid #FF8A00;
    }
  }
}
.goods-line4 {
  .goods-inner {
    margin-left: calc((1152px - 100%)/3);
    .good {
      width: 288px;
      margin-left: calc((100% - 1152px)/4);
      .img {
        width: 100%;
        height: 310px;
        padding: 0;
      }
      .detail {
        .title {
          font-size: 14px;
        }
      }
    }
  }
}
</style>