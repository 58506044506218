<template>
  <div
    class="container-column"
    v-if="isVisibility"
    :style="{ marginTop: origin == 'detail' ? '56px' : '' }"
  >
    <div class="wrapper">
      <div class="operation">
        <div>
          <div
            class="compare-column-btn"
            :class="{ 'active-tab': selectTab == 0 }"
            @click="toggleTab(0)"
          >
            对比栏
          </div>
          <div
            class="recently-view"
            :class="{ 'active-tab': selectTab == 1 }"
            @click="toggleTab(1)"
          >
            最近浏览
          </div>
        </div>
        <div class="hidden-btn" @click="handleHideColumn">
          <button>隐藏</button>
        </div>
      </div>
      <!--  对比栏 -->
      <div v-if="selectTab == 0" class="goods-column">
        <div class="goods-column-left">
          <div class="goods-list">
            <template v-for="goods in goodList">
              <div class="goods">
                <div>
                  <img
                    :src="$fillImgPath(goods.thumbUrl)"
                    alt=""
                    @click="handleToDetail(goods)"
                  />
                </div>
                <div class="goods-right">
                  <div class="title">{{ goods.name }}</div>
                  <div class="goods-right-bottom">
                    <div class="price">
                      <i class="symbol">￥</i>
                      <span class="num">{{ goods.price }}</span>
                    </div>
                    <div class="delete-btn" @click="handleDeleteGood(goods)">
                      删除
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-for="def in 4- goodsList.length">
              <div
                class="add-c-goods"
                @click="handleCanAdd"
              >
                <i class="cross"></i>
                <p>您还可以添加哦</p>
              </div>
            </template>
          </div>
        </div>
        <div class="goods-column-right">
          <div class="right-btn">
            <div
              :class="{ gray: goodsList.length < 2 }"
              @click="handleGoGoodsAttr"
            >
              对比
            </div>
            <div @click="handleClearColumn">清空对比栏</div>
          </div>
        </div>
      </div>
      <!--  最近浏览 -->
      <div v-else>
        <div
          v-if="historyMap[platformCid] && historyMap[platformCid].length"
          class="goods-column"
        >
          <!--  左箭头 -->
          <div
            class="arrow-l"
            :class="{ 'arrow-default': scrollIndex == 0 }"
            @click="handleScrollLeft"
          ></div>
          <div class="scroll-width">
            <div
              class="goods-list scroll"
              ref="goodsListRef"
              :style="{
                left: ScrollLeft + 'px',
                transitionDuration: isClick ? '1s, 500ms' : '',
              }"
            >
              <template v-for="goods in historyMap[platformCid]">
                <!-- null 兼容老数据 -->
                <div class="goods" v-if="goods.downAndUpStatus || goods.downAndUpStatus == null">
                  <div>
                    <img
                      :src="$fillImgPath(goods.thumbUrl)"
                      alt=""
                      @click="handleToDetail(goods)"
                    />
                  </div>
                  <div class="goods-right">
                    <div class="title">{{ goods.name }}</div>
                    <div class="goods-right-bottom">
                      <div class="price">
                        <i class="symbol">￥</i>
                        <span class="num">{{ goods.price }}</span>
                      </div>
                      <div class="compare-btn" @click="handleCompare(goods)">
                        <i :class="{ active: isCompare(goods) }"></i>
                        对比
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <!--  右箭头 -->
          <div
            class="arrow arrow-r"
            :class="{ 'arrow-default': scrollIndex == stack }"
            @click="handleScrollRight"
          ></div>
        </div>
        <div v-else class="no-his-goods">
          <img src="@/assets/imgs/empty/content.png" alt="" />
          <p>暂无最近浏览~</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { querySkuInfo } from '@/api/api'

export default {
  props: {
    origin: {
      typeof: String,
      default: "",
    },
  },
  data() {
    return {
      ScrollLeft: 0, // 宽度距离
      goodsWidth: 304 * 4, // 商品宽度
      scrollIndex: 0,
      stack: 0, // 可滑动总次数
      pageIndex: 1,
      isClick: false, // 是否点击
      historyList: [],
    };
  },
  created() {
    this.initStack()
  },
  methods: {
    ...mapActions("comparisonColumn", ["browHistoryList"]),
    ...mapMutations("comparisonColumn", [
      "InsertGoodsList",
      "DeleteGoodsList",
      "ClearGoodsList",
      "SetVisibility",
      "ToggleTab"
    ]),
    initStack() {
      if (this.historyMap[this.platformCid]?.length) {
        const len = this.historyMap[this.platformCid]?.length
        const s = parseInt(len / 4)
        this.stack = len / 4 > s? s : s - 1
      }
    },
    toggleTab(flag) {
      this.ToggleTab(flag)
      this.isClick = false;
      if (flag) {
        // 展示最近浏览数据与对比栏同一个平台类目下得商品
        this.scrollIndex = 0;
        this.ScrollLeft = 0;
        this.browHistoryListFn();
      }
    },
    browHistoryListFn() {
      if (!this.userInfo.userId) return;
      const params = {
        userId: this.userInfo.userId,
        platformCid: this.platformCid,
      };
      if (this.platformCid == "all") delete params.platformCid;
      this.browHistoryList(params);
    },
    handleDeleteGood(item) {
      this.DeleteGoodsList(item);
    },
    handleClearColumn() {
      this.ClearGoodsList();
    },
    handleHideColumn() {
      this.SetVisibility(false);
    },
    async handleCompare(item) {
      item.isCompare = !item.isCompare;
      if (item.isCompare) {
        const { result, data } = await querySkuInfo({
          skuId: item.skuNo
        })
        if (result == 92007) {
          this.scrollIndex = 0;
          this.ScrollLeft = 0;
          this.browHistoryListFn();
          return
        }
        this.InsertGoodsList({ goods: item })
      } else {
        this.DeleteGoodsList(item)
      }
        
      this.ToggleTab(0);
    },
    handleScrollLeft() {
      this.isClick = true;
      if (!this.scrollIndex) return;
      this.ScrollLeft += this.goodsWidth;
      this.scrollIndex--;
    },
    handleScrollRight() {
      this.isClick = true;
      if (this.scrollIndex == this.stack) return;
      this.ScrollLeft -= this.goodsWidth;
      this.scrollIndex++;
    },
    handleCanAdd() {
      // 搜索结果页内，不跳转
      if (this.$route.name == "GoodsList") {
        return;
      }
      const route = {
        name: "GoodsList",
        query: {
          keyword: "空调",
          searchType: 1,
          type: 'attr'
        },
      };
      if (this.goodsList.length) {
        route.query.keyword = "";
        route.query.platformCid = this.platformCid;
      }
      this.$router.push(route);
    },
    handleGoGoodsAttr() {
      if (this.goodList.length < 2) return;
      this.$router.push({
        name: "goodsAttrCompare",
      });
    },
    handleToDetail(item) {
      this.$router.push({
        name: "GoodsDetail",
        query: {
          productNo: item.productNo,
          skuNo: item.skuNo,
        },
      });
    },
    // 对比
    isCompare(item) {
      const index = this.goodsList.findIndex((el) => el.skuNo == item.skuNo)
      const flag = index != -1 ? true : false 
      item.isCompare = flag
      return flag
    },
  },
  computed: {
    ...mapState(["userInfo"]),
    ...mapState("comparisonColumn", ["goodsList", "visibility", "historyMap", "selectTab"]),
    ...mapGetters("comparisonColumn", ["platformCid"]),
    goodList() {
      return this.goodsList;
    },
    // 对比栏隐藏/显示
    isVisibility() {
      return this.visibility;
    },
  },
  watch: {
    "$store.state.comparisonColumn": {
      handler: function (newVal, oldVal) {
        if (newVal.historyMap[this.platformCid]) {
          console.log('newVal', newVal.historyMap[this.platformCid])
          this.initStack()
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.container-column {
  position: fixed;
  bottom: 0;
  z-index: 99999;
  width: 1200px;
  height: 149px;
  padding: 20px 22px 23px;
  background: #f9f9f9;

  .wrapper {
    .operation {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .active-tab {
        font-weight: 700;
        color: #333 !important;
      }

      > div:first-child {
        display: flex;
      }

      .hidden-btn {
        button {
          font-size: 12px;
          color: #cc0000;
          cursor: pointer;
        }
      }

      .compare-column-btn {
        position: relative;
        width: fit-content;
        height: 14px;
        padding-right: 17px;
        margin-right: 18px;
        font-size: 14px;
        font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
        color: #666;
        line-height: 14px;
        cursor: pointer;

        &::after {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          width: 1px;
          height: 10px;
          background-color: #e8e8e8;
        }
      }

      .recently-view {
        font-size: 14px;
        color: #666;
        cursor: pointer;
      }
    }

    .scroll-width {
      overflow: hidden;

      .scroll {
        position: relative;
        left: 0;

        .goods:not(:last-child) {
          margin-right: 34px !important;
        }

        .goods {
          &-right {
            margin-right: 34px !important;

            .compare-btn {
              display: flex;
              align-items: center;

              i {
                display: inline-block;
                width: 14px;
                height: 14px;
                margin-right: 4px;
                border: 1px solid #ccc;
                border-radius: 50%;
                cursor: pointer;

                &.active {
                  border: none;
                  background-image: url("../assets/imgs/detail/fuwu.png");
                  background-size: 100% 100%;
                }
              }

              font-size: 12px;
              color: #666;
            }
          }
        }
      }
    }

    .goods-column {
      position: relative;
      height: 70px;
      margin-top: 22px;
      display: flex;
      justify-content: space-between;

      &-left {
        display: flex;
        align-items: center;
      }

      &-right {
        display: flex;
      }

      .goods-list {
        display: flex;

        .goods:not(:last-child) {
          margin-right: 22px;
        }

        .goods {
          position: relative;
          display: flex;
          height: 70px;
          .title { 
            line-height: 20px;
          }
          img {
            width: 70px;
            height: 100%;
          }

          &-right {
            width: 156px;
            margin-left: 10px;
            margin-right: 22px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            > div:first-child {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              font-size: 12px;
              font-weight: 400;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            &-bottom {
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #CC0000;

              .price {
                display: flex;

                .symbol {
                  font-size: 12px;
                  font-weight: 700;
                  line-height: 18px;
                }

                .num {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 19px;
                }
              }

              .delete-btn {
                font-size: 12px;
                font-weight: 400;
                cursor: pointer;
              }
            }
          }
        }

        .goods:not(:last-child)::after {
          position: absolute;
          right: 0;
          top: 50%;
          width: 1px;
          height: 64px;
          background-color: #e8e8e8;
          transform: translateY(-50%);
          content: "";
        }
      }
      
      .add-c-goods:not(:last-child)::after {
        position: absolute;
        right: -22px;
        top: 50%;
        width: 1px;
        height: 64px;
        background-color: #e8e8e8;
        transform: translateY(-50%);
        content: "";
      }
      .add-c-goods:not(:last-child) {
        margin-right: 44px;
      }
      .add-c-goods {
        position: relative;
        width: 236px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 12px;
        background: #f1f1f1;
        border-radius: 8px;
        margin-right: 22px;
        cursor: pointer;

        p {
          height: 14px;
          font-weight: 400;
          color: #666;
          margin-top: 35px;
          line-height: 14px;
        }

        .cross {
          position: absolute;
          top: 13px;
          display: inline-block;
          width: 17px;
          height: 17px;
        }

        .cross::before,
        .cross::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 3px;
          height: 17px;
          background-color: #fff;
          transform: translateY(-50%);
        }

        .cross::before {
          transform: rotate(90deg);
        }

        .cross::after {
          transform: rotate(0deg);
        }

        &:hover {
          width: 236px;
          height: 70px;
          background: #f7f1f1;
          border: 1px solid #ef2f2f;
          p {
            color: #666;
          }
        }
      }

      .right-btn {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        div {
          cursor: pointer;
        }

        :first-child {
          width: 60px;
          height: 26px;
          color: #fff;
          text-align: center;
          font-size: 14px;
          line-height: 26px;
          background: #ef2f2f;
          border-radius: 4px;

          &:hover {
            background-color: #f14e4e;
          }
        }

        .gray {
          background-color: #ccc;

          &:hover {
            background-color: #ccc;
          }
        }

        div:nth-child(2) {
          width: 60px;
          height: 19px;
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 19px;

          &:hover {
            color: #f14e4e;
          }
        }
      }

      .arrow-default {
        background-image: url("../assets/imgs/Frame 2.png") !important;
      }

      .arrow-l {
        position: absolute;
        left: -18px;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        width: 18px;
        height: 18px;
        background-image: url("../assets/imgs/arrow-right-grey.png");
        background-size: 100% 100%;
      }

      .arrow-r {
        position: absolute;
        right: -18px;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        background-image: url("../assets/imgs/arrow-right-grey.png");
        background-size: 100% 100%;
      }
    }

    .no-his-goods {
      margin-top: 11px;
      text-align: center;
      color: #cc0000;
      font-size: 10px;

      img {
        width: 60px;
        height: 60px;
      }

      p {
        margin-top: 4px;
      }
    }
  }
}
</style>
