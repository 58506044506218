<template>
  <div class="empty"> 
    <img :src="require('../assets/imgs/empty/'+icon+'.png')" alt="">
    <div class="tip">{{tip}}</div>
    <div @click="btnClick" class="btn">{{btn}}</div>
  </div>
</template>

<script>
export default {
  name: 'Empty',
  props: {
    icon: String,
    tip: String,
    btn: String,
  },
  methods: {
    btnClick() {
      this.$emit('btn-click');
    }
  }
}
</script>

<style lang="less" scoped>
.empty {
  padding: 80px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 350px;
    height: 350px;
  }
  .tip {
    margin-top: 25px;
    font-size: 21px;
    color: #999999;
  }
  .btn {
    margin-top: 11px;
    font-size: 16px;
    color: #0085ff;
    cursor: pointer;
  }
}
</style>