<template>
  <Modal
    v-model="isShow"
    :closable="false"
    :footer-hide="true"
    class-name="confirm"
    :width="title?goodList.length===0?500:600:400">
    <div v-if="title" class="img"><img src="../assets/imgs/cart/confirm-icon.png" alt=""></div>
    <div :class="['title',goodList.length===0?'':'title-good']">{{title}}</div>
    <div :class="['des',title?'':'des-no-title',goodList.length===0?'':'des-good']">{{des}}</div>
    <div v-if="goodList.length" class="goods">
      <div v-for="(item, index) in goodList" :key="index" class="item">
        <img :src="$fillImgPath(item.thumbUrl)" alt="">
        <div class="text">
          <div class="multi-ellipsis name">{{item.skuName}}</div>
          <div v-if="title === '商品已下架' || title === '商品库存不足'" class="state">{{title === '商品已下架'?'已下架':'剩余库存 '+ (item.inventory === undefined?item.remnantInventory:item.inventory)}}</div>
          <div v-else class="state">
            <del class="rePrice"><span class="sub">¥</span>{{item.beforePrice}}</del>
            <span class="gap sub">¥</span>{{item.currentPrice}}
          </div>
        </div>
      </div>
    </div>
    <div :class="['btn',goodList.length===0?'':'btn-good']">
      <div v-if="leftText" @click="cancel" :class="['left',goodList.length===0?'':'left-good']">{{leftText}}</div>
      <div @click="ok" :class="['right',goodList.length===0?'':'right-good']">{{rightText}}</div>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'Confirm',
  props: {
    value: Boolean,
    title: String, // title为空表示是没有icon的
    des: String,
    leftText: String,
    rightText: String,
    goodList: Array,
  },
  computed: {
    isShow: {
      get: function() {
        return this.value;
      },
      set: function(val) {
        this.$emit('input', val);
      },
    }
  },
  methods: {
    ok() {
      this.$emit('ok');
    },
    cancel() {
      this.$emit('cancel');
    },
  }
}
</script>

<style lang="less" scoped>
.confirm {
  .img {
    text-align: center;
    img {
      width: 40px;
      height: 40px;
    }
  }
  .title {
    margin-top: 16px;
    line-height: 1;
    text-align: center;
    color: #333333;
    font-size: 18px;
    font-weight: bold;
  }
  .title-good {
    margin-top: 7px;
    font-size: 16px;
  }
  .des {
    margin-top: 17px;
    line-height: 1;
    text-align: center;
    color: #999999;
    font-size: 14px;
  }
  .des-good {
    margin-top: 8px;
  }
  .des-no-title {
    padding: 0 63px;
    color: #333333;
    line-height: 22px;
  }
  .goods {
    margin-top: 16px;
    max-height: 538px;
    padding: 8px;
    border: 1px solid rgba(153,153,153,0.60);
    overflow: auto;
    .item {
      margin-top: 8px;
      background: #f2f2f2;
      padding: 10px 24px;
      display: flex;
      img {
        width: 60px;
        height: 60px;
      }
      .text {
        margin-left: 16px;
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .name {
          font-size: 14px;
          line-height: 20px;
          color: #999999;
        }
        .state {
          font-size: 12px;
          line-height: 14px;
          color: #CC0000;
          .gap {
            margin-left: 8px;
          }
          .rePrice {
            color: #333333;
          }
        }
      }
    }
    .item:first-child {
      margin-top: 0;
    }
  }
  .btn {
    margin-top: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    .left {
      cursor: pointer;
      line-height: 1;
      padding: 8px 12px;
      background: #cc0000;
      border-radius: 4px;
      color: #ffffff;
      font-size: 14px;
      margin-right: 8px;
    }
    .left-good {
      background: #999999;
    }
    .left:hover {
      background: #ef2f2f;
    }
    .left-good:hover {
      padding: 7px 11px;
      background: #ebebeb;
      border: 1px solid #cc0000;
      color: #cc0000;
    }
    .right {
      cursor: pointer;
      line-height: 1;
      padding: 7px 11px;
      border: 1px solid #cc0000;
      border-radius: 4px;
      color: #cc0000;
      font-size: 14px;
    }
    .right-good {
      padding: 8px 12px;
      background: #cc0000;
      border: none;
      color: #ffffff;
    }
    .right:hover {
      padding: 8px 12px;
      background: rgba(228,31,31,0.20);
      border: none;
    }
    .right-good:hover {
      background: #ef2f2f;
    }
  }
  .btn-good {
    margin-top: 24px;
  }
}
</style>