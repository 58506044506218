<template>
    <div class="container">
        <div class="wrapper">
            <div class="goods-top">
                <div class="radio">
                    <div :class="{ active: lightFlag && toggleFlag == 0 }" @click="handleToggleLight(0)">
                        <i></i>
                        <span>高亮不同项</span>
                    </div>
                    <div :class="{ active: hiddenFlag && toggleFlag == 1 }" @click="handleToggleHidden(1)">
                        <i></i>
                        <span>隐藏相同项</span>
                    </div>
                </div>
                <div class="goods-list">
                    <div class="goods-list-item" v-for="(goods, index) in attrGoodsList" :key="index">
                        <div class="goods-list-del" @click="handleDelete(goods)">删除</div>
                        <div class="main-img">
                            <img :src="getCurrentImg(goods)" alt="">
                        </div>
                        <div class="scroll-img">
                            <div class="arrow-l" :class="{ 'arrow-default' : goods.scrollIndex == 0 }" @click="handleScrollLeft(goods)"></div>
                            <div class="scroll-width">
                                <div class="scroll" :style="{ 'left': goods.ScrollLeft + 'px'}">
                                    <template v-for="img in goods.skuPicUrls" >
                                        <div>
                                            <img :class="{ 'active': img == goods.skuAdPicture}" :src="$fillImgPath(img)" style="width: 40px; height: 40px; object-fit: contain;"  alt="" @click="handleClickImg(goods, img)" />
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="arrow arrow-r" :class="{ 'arrow-default' : goods.scrollIndex == getSkuImgsLen(goods) }" @click="handleScrollRight(goods)"></div>
                        </div>
                        <div class="goods-list-bottom">
                            <div class="title">{{ goods.skuName }}</div>
                            <div class="footer"> 
                                <div>
                                    <i class="symbol">￥</i>
                                    <span class="num">{{ goods.currentPrice }}</span>
                                </div>
                                <div class="view-detail"  @click="handleViewDetail(goods, index)">查看详情</div>
                            </div>
                        </div>
                    </div>
                    <div class="goods-list-item" v-for="def in defaultLength" style="align-items: center;">
                        <div class="add-c-goods" v-if="attrGoodsList.length < 4" @click="handleCanAdd">
                            <i class="cross"></i>
                            <p>添加同类商品</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div 
                    class="attr-list" 
                    :class="{ light: isLight(values[0]), hidden: isHidden(values[0]), toggle: isToggle(values[0]), 'group-bg': !Array.isArray(values[1]) }" 
                    v-for="values of attrs" 
                    @click="handleToggleAttr(values[0], values[1])">
                    <div class="attr-list-key" :title="values[0]">
                        <template v-if="!Array.isArray(values[1])">
                            <i :class="{ 'putAway': values[1].toggle, 'expand': !values[1].toggle }"></i>
                        </template>
                        <span>{{ getAttrKey(values[0]) }}</span>
                    </div>
                    <template v-if="Array.isArray(values[1])">
                        <div class="attr-list-val" v-for="val,index in values[1]" :key="index" :title="val">
                            {{ val }}
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { commodityContrastList } from '@/api/api'
import { mapState, mapMutations, mapGetters } from 'vuex';
    export default {
        data () {
            return {
                attrs: new Map(),
                lightList: {}, // 高亮行
                hiddenList: {}, // 隐藏显示行
                toggleList: {}, // 折叠行
                lightFlag: false,
                hiddenFlag: false,
                toggleFlag: null, // 高亮开关,相同项隐藏开关
                imgWidth: 44 * 4, // 图片宽度
                attrGoodsList: [], // 属性列表
            }
        },
        created() {
            // 有对比商品才请求接口
            if (this.goodsList.length) {
                this.initGoodsAttr()
            }
        },
        computed: {
            ...mapState('comparisonColumn',['goodsList','visibility']),
            ...mapState(['userInfo']),
            ...mapGetters('comparisonColumn', ['platformCid']),
            defaultLength() {
                return 4 - this.goodsList.length
            },
            isLight() {
                return (key) => {
                    const flag = this.lightList[this.getAttrKey(key)]
                    return flag
                }
            },
            isHidden() {
                return (key) => {
                    const flag = this.hiddenList[this.getAttrKey(key)]
                    return flag
                }
            },
            isToggle() {
                return (key) => {
                    // 只适用分组属性
                    if (key.indexOf('-') == -1) return false
                    const flag = this.toggleList[this.getAttrKey(key)]
                    return flag
                }
            },
            getCurrentImg() {
                return (goods) => {
                    return this.$fillImgPath(goods.skuAdPicture)
                }
            }
        },
        methods: {
            ...mapMutations('comparisonColumn', ['DeleteGoodsList']),
            // 设置销售属性
            setSaleKey(data) {
                let _this = this
                let saleMap = new Map()
                data[0]?.itemSaleAttributeList.forEach(el => {
                    saleMap.set(el.attrKey, [el.attrValue])
                    _this.lightList[el.attrKey] = false
                    _this.hiddenList[el.attrKey] = false
                })
                for (let index = 1; index < data.length; index++) {
                    for (let  key of saleMap.keys()) {
                        const attr = data[index].itemSaleAttributeList?.find(el=> el.attrKey == key)
                        const attrValue = saleMap.get(key)
                        // 属性值不在，设置-
                        if (!attr) {
                            attrValue.push('-')
                        } else {
                            attrValue.push(attr.attrValue)
                        }
                    } 
                }
                return saleMap
            },
            // 设置分组基础属性
            setGroupBaseKey(data) {
                let _this = this
                let baseMap = new Map()
                data[0]?.attrContrastDetail.forEach(el => {
                    baseMap.set(el.attrGroupName, { toggle: true })
                    _this.hiddenList[el.attrGroupName] = false
                    el.attrDetail.forEach(attr => {
                        baseMap.set(`${attr.cKey}-${el.attrGroupName}`, [attr.attrValue])
                        _this.lightList[attr.cKey] = false
                        _this.hiddenList[attr.cKey] = false
                        _this.toggleList[attr.cKey] = false
                    })
                })
                for (let index = 1; index < data.length; index++) {
                    // 1.将属性打上所属组的key标识。2.将属性从组从剥离出来
                    const curAttrs = data[index].attrContrastDetail.reduce((prev,cur) => {
                        const arr = cur.attrDetail.map(el => {
                            return {
                                cKey: `${el.cKey}-${cur.attrGroupName}`,
                                attrValue: el.attrValue
                            }
                        })
                        return [...prev, ...arr]
                    }, [])
                    for (let  key of baseMap.keys()) {
                        // 找到相同组下的属性，没有这个组下的属性则展示-
                        const attrO = curAttrs.find(attr => attr.cKey === key)
                        const attrValue = baseMap.get(key)
                        if (Array.isArray(attrValue)) {
                            if (!attrO) {
                                attrValue.push('-')
                            } else {
                                attrValue.push(attrO.attrValue)
                            }
                        }
                    } 
                }
                return baseMap
            },
            setAttrKey(data) {
                let _this = this
                // 获取对比第一个商品属性值
                const saleMap = this.setSaleKey(data)
                const baseMap = this.setGroupBaseKey(data)
                _this.attrs = new Map([...saleMap, ...baseMap])
            },
            async initGoodsAttr() {
                let _this = this 
                this.attrGoodsList = [] 
                const param = {
                    params: [],
                    userId: this.userInfo.userId,
                    appType: 'web'
                }
                _this.goodsList.forEach(el => {
                    param.params.push({
                        productId: el.productNo,
                        skuId: el.skuNo,
                        shopId: el.shopId
                    })
                })
                const { result, data } = await commodityContrastList(param)
                this.attrGoodsList = data
                // 初始化滚动属性
                data.forEach(el => {
                    el.scrollIndex = 0
                    el.ScrollLeft = 0
                })
                this.setAttrKey(data)
                // 初始高亮不同项
                // this.handleLightDiff()
            },

            handleDelete(item) {
                this.DeleteGoodsList(item)
            },
            resetValue(Object) {
                for (const key in Object) {
                    Object[key] = false
                }
                this.$forceUpdate()
            },
            handleToggleLight(flag) {
                this.lightFlag = !this.lightFlag
                this.toggleFlag = flag
                if (this.lightFlag) {
                    this.handleLightDiff()
                    this.hiddenFlag = false
                    this.resetValue(this.hiddenList)
                    return
                }
                this.resetValue(this.lightList)
            },
            handleToggleHidden(flag) {
                this.hiddenFlag = !this.hiddenFlag
                this.toggleFlag = flag
                if (this.hiddenFlag) {
                    this.handleHiddenSame()
                    this.lightFlag = false
                    this.resetValue(this.lightList)
                    return
                }
                this.resetValue(this.hiddenList)
            },
            handleToggle(flag) {
                this.toggleFlag = flag
                this.toggleFlag? this.handleHiddenSame() : this.handleLightDiff()
                this.toggleFlag? this.resetValue(this.lightList) : this.resetValue(this.hiddenList)
            },
            handleLightDiff() {
                for (let key of this.attrs.keys()) {
                    const attrValue = this.attrs.get(key)
                    if (Array.isArray(attrValue)) {
                        const arr = [...new Set(attrValue)]
                        // 无过滤代表没有相同项
                        if (arr.length != 1) {
                            this.lightList[this.getAttrKey(key)] = true
                        }
                    }
                }
                this.$forceUpdate() 
            },
            handleHiddenSame() {
                let attrs = new Map()
                for (let key of this.attrs.keys()) {
                    const attrValue = this.attrs.get(key)
                    if (!Array.isArray(attrValue)) {
                        attrs.set(key, [])
                    }
                    if (Array.isArray(attrValue)) {
                        const arr = [...new Set(attrValue)]
                        let obj = {
                            k: this.getAttrKey(key),
                            flag: false
                        }
                        // 所有项都相同
                        if (arr.length == 1) {
                            obj.flag = true
                            this.hiddenList[this.getAttrKey(key)] = true
                        }
                        // 分组内的属性
                        if (key.indexOf('-') != -1) {
                            const arrs = key.split('-')
                            let vals = attrs.get(arrs[1])
                            vals.push(obj)
                        }
                    }
                }

                // 判断分组属性是否全部是相同项
                console.log('attrs', attrs)
                for (let i of attrs) {
                    const element = i[1];
                    const arr = []
                    element.forEach(el => {
                        arr.push(el.flag)
                    })
                    const arr1 = [...new Set(arr)]
                    if (arr1.length == 1 && arr1[0]) {
                        this.hiddenList[this.getAttrKey(i[0])] = true
                    }
                }
                
                this.$forceUpdate() 
            },
            handleToggleAttr(groupName, item) {
                if (Array.isArray(item)) return
                item.toggle = !item.toggle
                for (let key of this.attrs.keys()) {
                    const sy = key.split('-')[1]
                    if (sy === groupName) {
                        this.toggleList[this.getAttrKey(key)] = item.toggle? false : true
                    }
                }
                this.$forceUpdate()
            },
            handleViewDetail(item, index) {
                this.$router.push({
                    path: '/goodsDetail',
                    query: {
                        productNo: this.goodsList[index].productNo,
                        skuNo: item.skuNo
                    },
                })
            },
            handleCanAdd() {
                const route = {
                    name: 'GoodsList',
                    query: {
                        keyword: '空调',
                        searchType: 1,
                        type: 'attr'
                    }
                }
                if (this.goodsList.length) {
                    route.query.keyword = ''
                    route.query.platformCid = this.platformCid
                }
                this.$router.push(route);
            },
            handleScrollLeft(item) {
                if (!item.scrollIndex) return
                item.ScrollLeft += this.imgWidth
                item.scrollIndex --
                this.$forceUpdate()
            },
            handleScrollRight(item) {
                if (item.scrollIndex == item.stack) return
                item.ScrollLeft -= this.imgWidth
                item.scrollIndex ++
                this.$forceUpdate()
            },
            getSkuImgsLen(goods) {
                const len = goods.skuPicUrls.length
                const s = parseInt(len / 4)
                goods.stack = len / 4 > s? s : s - 1
                return goods.stack
            },
            handleClickImg(item, imgUrl) {
                item.skuAdPicture = imgUrl
            },
            getAttrKey(values) {
                if (values.indexOf('-') != -1) {
                    return values.split('-')[0]
                }
                return values
            },
        },
        watch: {
            defaultLength(newV){
                if (newV == 4) {
                    this.attrs = new Map()
                    this.attrGoodsList = [] 
                    return
                }
                this.initGoodsAttr()
            }
        }
    }
</script>
<style lang="less" scoped>
    .container {
        margin-bottom: 100px;
        background: #fff;
        border-right: 1px solid #f2f2f2;
        .wrapper {
            .goods-top {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #f2f2f2;
                .radio {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 132px;
                    height: 391px;
                    > div {
                        display: flex;
                        line-height: 24px;
                        margin-left: 17px;
                        margin-bottom: 31px;
                        font-size: 12px;
                        cursor: pointer;
                        &.active {
                            i {
                                border: none;
                                background-image: url('../assets/imgs/detail/comare-attr-btn.png');
                                background-size: 100% 100%;
                            }
                            span {
                                color:#CC0000;
                            }
                        }
                    };
                    i {
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        border: 1px solid #ccc;
                        border-radius: 50%;
                        margin-right: 10px;
                        
                    }
                }
                .goods-list {
                    display: flex;
                    &-item {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 266px;
                        height: 391px;
                        padding: 8px 20px 15px ;
                        background: #fff;
                        border-left: 1px solid #f2f2f2;
                        .scroll-img {
                            display: flex;
                            justify-content: space-around;
                            position: relative;
                            width: 226px;
                            margin-top: 5px;
                            .scroll-width {
                                overflow: hidden;
                                .scroll {
                                    display: flex;
                                    width: 172px;
                                    position: relative;
                                    left:0;
                                    div {
                                        width: 40px;
                                        height: 40px;
                                    } 
                                    div:not(:first-child) {
                                        margin-left: 4px;
                                    } 
                                    .active {
                                        border: 1px solid #CC0000;
                                    }  
                                    
                                }
                            }
      
                            .arrow-default{
                                background-image: url('../assets/imgs/Frame 2.png') !important;
                            }
                            .arrow-l {
                                position: absolute;
                                left: 0px;
                                top: 50%;
                                transform: translateY(-50%) rotate(180deg);
                                width: 18px;
                                height: 18px;
                                background-image: url('../assets/imgs/arrow-right-grey.png');
                                background-size: 100% 100%;
                            }
                            .arrow-r {
                                position: absolute;
                                right: 0px;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 18px;
                                height: 18px;
                                background-image: url('../assets/imgs/arrow-right-grey.png');
                                background-size: 100% 100%;
                            }
                        }
                    }
                    &-del {
                       text-align: right;
                       margin-bottom: 8px;
                       font-size: 12px;
                       color:#333;
                       cursor: pointer;
                       &:hover {
                            color: #CC0000;
                       }
                    }
                    .main-img {
                        img {
                            width: 226px;
                            height: 226px;
                        }
                    }
                    &-bottom {
                        height: 72px;
                        > div:first-child {
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            font-size: 14px;
                            line-height: 20px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            color: #333;
                            margin-top: 8px
                        }
                        .footer {
                            position: absolute;
                            bottom: 15px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 226px;
                            margin-top: 8px;
                            color:#CC0000;
                            > div:first-child {
                                display: flex;
                                align-items: center;
                            }
                            .symbol {
                                margin-right: 4px;
                                font-size: 12px;
                                font-weight: 700;
                            }
                            .num {
                                font-size: 16px;
                                font-weight: 700;
                            }
                            .view-detail {
                                font-size: 12px;
                                color: #ccc;
                                cursor: pointer;
                                &:hover {
                                    color:#CC0000;
                                }
                            }
                        }
                    }
                    .default-goods {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-left: 1px solid #f2f2f2;
                        padding: 8px 20px 15px ;
                    }
                    .add-c-goods {
                        position: relative;
                        width: 147px;
                        height: 60px;
                        line-height: 60px;
                        text-align: center;
                        font-size: 12px;
                        background: #f1f1f1;
                        border-radius: 8px;
                        cursor: pointer;
                        p {
                            height: 14px;
                            font-weight: 400;
                            color: #666;
                            margin-top:35px;
                            line-height: 14px;
                        }
                        .cross {
                            position: absolute;
                            top: 13px;
                            display: inline-block;
                            width: 17px;
                            height: 17px;
                        }
                        .cross::before,
                        .cross::after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 3px;
                            height: 17px;
                            background-color: #fff;
                            transform: translateY(-50%);
                        }
                        .cross::before {
                            transform: rotate(90deg);
                        }

                        .cross::after {
                            transform: rotate(0deg);
                        }
                        &:hover {
                            width: 147px;
                            height: 60px;
                            background: #f7f1f1;
                            border: 1px solid #ef2f2f;
                            border-radius: 9px;
                            p {
                                color: #EF2F2F;
                            }
                        }
                    }
                }
            }
            .attr-list {
                display: flex;
                height: 44px;
                line-height: 44px;
                border-bottom: 1px solid #f2f2f2;
                font-family: FZLanTingHeiS-L-GB, FZLanTingHeiS-L-GB-Regular;
                font-size: 12px;
                color: #333;
            
                &-key {
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding-left: 39px;
                    width: 132px;
                    span {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                    i { 
                        position: absolute;
                        left: 20px;
                        display: inline-block;
                        margin-right: 4px;
                        width: 15px;
                        height: 15px;
                    }
                    .putAway {
                        background:url('../assets/imgs/putAway.png');
                        background-size: 100% 100%;
                    }
                    .expand {
                        background:url('../assets/imgs/expand.png');
                        background-size: 100% 100%;
                    }
                }
                &-val {
                    padding-left: 20px;
                    border-left: 1px solid #f2f2f2;
                    width: 266px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
                &-val:last-child {
                    width: 267px;
                    border-right: 1px solid #f2f2f2;
                }
            }
            .light {
                background: rgba(204,0,0,0.03);
            }
            .hidden {
                display: none
            };
            .toggle {
                display: none
            }
            .group-bg {
                background-color: #F7F7F7;
            }
        }
    }
</style>