<template>
  <div :class="['coupon-wrap']">
    <div class="left">
      <div class="top">{{couponUsingRange2text(couponInfo.couponUsingRange)}}</div>
      <!-- 满减 -->
      <div class="bottom" v-if="couponInfo.couponType === 1">
        <div class="coupon-price"><span class="symbol">￥</span>{{couponInfo.couponAmount || couponInfo.couponPrice}}</div>
        <div v-if="couponInfo.meetPrice" class="text">
          {{+couponInfo.meetPrice > 0 ?  '满' + couponInfo.meetPrice + '元可用' : '无门槛' }}
        </div>
        <div v-else class="text">
          {{+couponInfo.meetAmount > 0 ?  '满' + couponInfo.meetAmount + '元可用' : '无门槛' }}
        </div>
      </div>

      <!-- 现金 -->
      <div class="bottom" v-if="couponInfo.couponType === 2">
        <div class="coupon-price"><span class="symbol">￥</span>{{couponInfo.couponAmount || couponInfo.couponPrice}}</div>
        <div class="text">无门槛</div>
      </div>

      <!-- 折扣 -->
      <div class="bottom" v-if="couponInfo.couponType === 3">
        <div class="coupon-price">{{couponInfo.meetPrice || couponInfo.meetAmount}}<span class="symbol">折</span></div>
      </div>

      <!-- 每满减 -->
      <div class="bottom" v-if="couponInfo.couponType === 4">
        <div class="coupon-price"><span class="symbol">￥</span>{{couponInfo.couponAmount || couponInfo.couponPrice}}</div>
        <div v-if="couponInfo.meetPrice" class="text">
          {{+couponInfo.meetPrice > 0 ?  '每满' + couponInfo.meetPrice + '元可用' : '无门槛' }}
        </div>
        <div v-else class="text">
          {{+couponInfo.meetAmount > 0 ?  '每满' + couponInfo.meetAmount + '元可用' : '无门槛' }}
        </div>
      </div>
    </div>
    <div class="right">
      <div class="multi-ellipsis coupon-name">{{couponInfo.couponName}}</div>
      <div class="over-flag" v-if="couponInfo.overflag">此券暂不可与已勾选券叠加使用</div>
      <div>
        <div class="coupon-max-discount" v-if="couponInfo.couponType === 3 && (couponInfo.couponAmount > 0 || couponInfo.couponPrice > 0)">最高可抵扣{{ couponInfo.couponAmount || couponInfo.couponPrice }}元</div>
        <div class="coupon-endTime">{{couponInfo.endTimeDesc || `有效期: ${couponEndTime}`}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default ({
  name: 'Coupon',
  props: {
    // 优惠券信息
    couponInfo: {
      type: Object,
      default:() => {}
    }
  },
  computed: {
    couponEndTime() {
      let str = ''
      if(this.couponInfo.couponEndTime) {
        str = this.couponInfo.couponEndTime.replace(/-/g,'.')
        str = str.substring(0, 16)
      }
      return str
    }
  },
  methods: {
    // 优惠券的使用范围转换文案
    couponUsingRange2text(couponUsingRange){
      const operators = {
        1: '平台通用',
        2: '店铺通用',
        3: '部分品类',
        4: '部分商品',
        5: '部分商品',
        6: '自营商品通用',
        7: '店铺通用',
      }
      return operators[couponUsingRange] || ''
    }
  }
})

</script>

<style lang="less" scoped>
.coupon-wrap {
  width: 100%;
  height: 120px;
  display: flex;
  border-radius: 12px;
  position: relative;
  background:url('~@/assets/imgs/detail/coupon-bg-red.png');
  background-size: 100% 100%;
  .left {
    width:110px;
    color: #fff;
    .top {
      width: 100%;
      height: 20px;
      border-radius: 24px 0 0 0;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      line-height: 20px;
    }
    .bottom {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .coupon-price {
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        .symbol {
          font-size: 20px;
        }
      }
      .text {
        margin-top: 7px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
      }
    }
  }
  .right {
    flex: 1;
    margin-left: 18px;
    padding: 20px 20px 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    .coupon-name {
      font-size: 16px;
      font-weight: 700;
      color: #333;
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;
    }
    .over-flag {
      font-size: 12px;
    }
    .coupon-max-discount {
      font-size: 14px;
      color: #333;
      margin-bottom: 5px;
    }
    .coupon-endTime {
      font-size: 14px;
      color: #333;
    }
  }
}
</style>
