<template>
  <div class="container similar">
    <div @click="goDetail" class="product">
      <div class="img">
        <img :src="$fillImgPath(infoData.thumbUrl)" alt="">
        <div v-if="!(infoData.downAndUpStatus && infoData.inStore)" class="state">{{infoData.downAndUpStatus?"售罄":"已下架"}}</div>
      </div>
      <div class="detail">
        <div class="title">{{infoData.productName}}</div>
        <div class="price">
          <template v-if="infoData.downAndUpStatus">
            <span class="now-price">¥{{infoData.marketPrice}}</span>
            <span v-if="infoData.originalPrice" class="del-price">¥{{infoData.originalPrice}}</span>
          </template>
          <span v-else class="no-price">暂无报价</span>
        </div>
      </div>
    </div>
    <recommend
      :skuId = "id"
      @getData = "getData"
      >
      <template slot="title">
        <div class="icon"><img src="../assets/imgs/cart/recommend.png" alt=""></div>
        <Divider>相似产品</Divider>
      </template>
    </recommend>
  </div>
</template>

<script>
import Recommend from '../components/Recommend.vue'

export default {
  name: 'Similar',
  data() {
    return {
      id: '',
      infoData: {}
    }
  },
  components:{
    Recommend,
  },
  methods: {
    goDetail() {
      this.$router.push({
        name: 'GoodsDetail',
        query: {
          productNo: this.infoData.productId,
          skuNo: this.infoData.skuId,
        }
      });
    },
    getData(info) {
      this.infoData = info
    }
  },
  created() {
    this.id =  this.$route.params.skuId
  }
}
</script>

<style lang="less" scoped>
.similar {
  .product {
    padding: 24px;
    background-color: white;
    display: flex;
    .img {
      width: 130px;
      height: 130px;
      font-size: 0;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .state {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        line-height: 26px;
        background: rgba(0,0,0,0.70);
        text-align: center;
        font-size: 12px;
        color: white;
      }
    }
    .detail {
      margin-left: 14px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        word-break: break-all;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
      }
      .price {
        .now-price {
          font-size: 16px;
          font-weight: 700;
          font-family: SF UI Display Medium, SF UI Display Medium-Medium;
          color:#EF2F2F;
        }
        .del-price {
          margin-left: 8px;
          font-weight: 500;
          font-size: 12px;
          color:#999999;
          text-decoration: line-through;
        }
        .no-price {
          font-size: 14px;
          color: #333333;
          line-height: 14px;
        }
      }
    }
  }
}
</style>