<template>
  <div v-infinite-scroll="getGoodData" infinite-scroll-disabled="busy" infinite-scroll-immediate-check="false" class="container cart">
    <div v-show="cartList.length">
      <div class="address-line">
        <div class="num">全部商品&nbsp;{{cartSize}}</div>
        <div class="address">配送至：<Address @changeAddressCallBack="getData" :positionRight="true" /></div>
      </div>
      <div class="line line-header">
        <div class="select"><img @click="chooseAll" :src="allChecked?checkboxIcon_checked:checkboxIcon" alt=""></div>
        <div class="detail">全选<span>商品</span></div>
        <div class="price">单价</div>
        <div class="count">数量</div>
        <div class="total">小计</div>
        <div class="action">操作</div>
      </div>
      <template v-for="(item, index) in cartList">
        <div :class="['line', item.shopId !== '-1'?'line-shop':'line-shop-useless']" :key="'shop'+index">
          <div class="select"><img @click="chooseShop(index)" v-if="item.shopId !== '-1'" :src="shopCheckedList[index]?checkboxIcon_checked:checkboxIcon" alt=""></div>
          <div class="detail">
            <template v-if="item.shopId !== '-1'">
              <div class="shop-title">{{item.shopName}}</div>
              <div class="shop-coupon" v-if="item.discountFlag===10">
                <Coupon :couponList="item.couponList" :shopId='item.shopId' @init="getCouponData(item)"/>
              </div>
            </template>
            <template v-else>以下宝贝已失效</template>
          </div>
          <div @click="delGoods(3, 'clear')" v-if="item.shopId === '-1'" class="action">一键清空</div>
        </div>
        <div :class="[item.shopId !== '-1'?'good-wrap':'good-wrap-useless']" :key="'good'+index">
          <div v-for="(child, childIndex) in item.productList" :class="['line', item.shopId !== '-1'?'line-good':'line-good-useless']" :style="{'background-color': child.checked?'rgba(255,208,153,0.20)':''}" :key="'shop'+index+'good'+childIndex">
            <div class="select">
              <img @click="chooseGood(index,childIndex)" v-if="item.shopId !== '-1'" :src="child.optional?child.checked?checkboxIcon_checked:checkboxIcon:checkboxIcon_disabled" alt="">
              <div v-else>失效</div>
            </div>
            <div class="detail">
              <div class="info">
                <div @click="goDetail(child)" class="img"><img :src="$fillImgPath(child.thumbUrl)" alt=""></div>
                <div class="text">
                  <div @click="goDetail(child)" class="multi-ellipsis title">{{child.skuName}}</div>
                  <div @click="goDetail(child)" v-if="(!child.invalidReason || child.invalidReason.indexOf(10)===-1)" class="ellipsis sku">{{child.skuAttr}}</div>
                  <div v-if="item.shopId !== '-1'&&child.notSettleCart" class="no-option">本商品不支持购物车下单</div>
                </div>
              </div>
              <div v-if="item.shopId !== '-1'&&child.activityFlag === 10&&(child.activityInfoList[0].activityType==='10'||child.activityInfoList[0].straightDownType==='20')&&child.activityInfoList[0].pageActivityStatus===20" class="activity">
                <div>【{{child.activityInfoList[0].activityType==='10'?'秒杀':'限时直降'}}】</div>
                <div v-if="child.activityInfoList[0].isOver">距结束时间{{child.activityInfoList[0].strEnd}}</div>
                <CustomCountDown 
                  v-else
                  :time="{time: child.activityInfoList[0].seckillTime}"
                  :isBegin="true"
                  @finished="getData"
                >
                </CustomCountDown>
              </div>
            </div>
            <div v-if="item.shopId !== '-1'" class="price"><span v-if="child.inventoryFlag!=-1 && child.optional &&!child.reSelect">¥{{child.currentPrice}}</span></div>
            <div v-if="item.shopId !== '-1'" :class="['count', child.quantity===1 || child.quantity === child.inventory?'count-disabled':'']">
              <template v-if="child.inventoryFlag!=-1 && child.optional &&!child.reSelect">
                <InputNumber @on-change="handleQuantityChange(child)" @on-focus="inputFlag = true" @on-blur="inputFlag = false" v-model="child.quantity" controls-outside size="small" :active-change="false"></InputNumber>
                <div v-if="child.inventoryFlag === 10" class="inventory">库存紧张</div>
              </template>
              <div @click="goDetail(child)" v-if="child.reSelect" class="invalid">请重新选择规格</div>
            </div>
            <div v-if="item.shopId !== '-1'" class="total"><span v-if="child.inventoryFlag!=-1 && child.optional &&!child.reSelect">¥{{(Number(child.currentPrice)*child.quantity).toFixed(2)}}</span></div>
            <div class="action">
              <div @click="delGoods(1, child.id)">删除</div>
              <div @click="delGoods(2, child.id)" v-if="item.shopId !== '-1'">移入收藏夹</div>
              <div @click="goSimilar(child.skuId)" v-else>找相似</div>
            </div>
          </div>
        </div>
      </template>
      <Affix :offset-bottom="0">
        <div class="line line-summary">
          <div class="select"><img @click="chooseAll" :src="allChecked?checkboxIcon_checked:checkboxIcon" alt=""></div>
          <div class="detail">全选<span @click="beforeDel(1)">删除选中商品</span><span @click="beforeDel(2)">移入收藏夹</span></div>
          <div class="sum-count">已选择<span>{{selectedGoods.length}}</span>件商品</div>
          <div class="sum-price">
            <div>
              <span class="name">总价：</span>
              <span class="unit">¥</span>
              <span class="sale">{{summary}}</span>
            </div>
            <div>
              <span class="name">优惠券：</span>
              <span class="discount">-¥{{discount}}</span>
            </div>
          </div>
          <div @click="sumTotal" class="sum">去结算</div>
        </div>
      </Affix>
    </div>
    <empty v-if="cartList.length === 0&&(!busy)" @btn-click="goHome" icon="cart" tip="购物车空空的哦~，去看看心仪的商品吧~" btn="去逛逛>"></empty>
    <div v-if="cartList.length === 0&&busy" class="empty-loading">
      <img :src="require('../assets/imgs/cart/loading.gif')" alt="">
      <div class="txt">加载中...</div>
    </div>
    <recommend v-if="finished">
      <template slot="title">
        <div class="icon"><img src="../assets/imgs/cart/recommend.png" alt=""></div>
        <Divider>猜你喜欢</Divider>
        </template>
    </recommend>
    <confirm 
      v-model="isShow"
      @ok="confirmInfo[confirmType].ok()"
      @cancel="confirmInfo[confirmType].cancel()"
      :title="confirmInfo[confirmType].title"
      :des="confirmInfo[confirmType].des"
      :leftText="confirmInfo[confirmType].leftText"
      :rightText="confirmInfo[confirmType].rightText"
      :goodList="confirmInfo[confirmType].goodList"
      >
    </confirm>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  getCartList,
  getCartLoadList,
  getCartSummaryList,
  delCartList,
  collectCartList,
  checkInventory,
  updateCartInfo,
  checkSeckillCartList,
  checkSeckillResultCartList,
  getCartCouponList
} from '@/api/api'
import Empty from '../components/Empty.vue'
import Coupon from '../components/Coupon.vue'
import Address from '../components/Address.vue'
import Confirm from '../components/Confirm.vue'
import Recommend from '../components/Recommend.vue'
import CustomCountDown from './goods-detail/CustomCountDown.vue'
export default {
  name: 'Cart',
  data() {
    return {
      checkboxIcon_checked: require('@/assets/imgs/cart/checkbox-checked.png'), // 已选中
      checkboxIcon: require('@/assets/imgs/cart/checkbox.png'), // 未选中
      checkboxIcon_disabled: require('@/assets/imgs/cart/checkbox-disabled.png'), // 不可用
      cartShopList: [],
      cartGoodList: [],
      cartSize: 0, // 购物车数量
      finished: false, // 购物车列表是否请求完成
      busy: true,
      summary: '0.00',
      discount: '0.00',
      isShow: false,
      confirmType: 1,
      confirmInfo: {
        1: {
          title: '确定要删除吗',
          des: '你可以选择移入收藏夹，或删除商品',
          leftText: '移入收藏夹',
          rightText: '删除商品',
          goodList: [],
          ok: this.handleDel,
          cancel: this.handleFavorite,
        },
        2: {
          title: '是否添加到收藏夹',
          des: '移动后选中商品将不在购物车中显示',
          leftText: '再想想',
          rightText: '移入收藏夹',
          goodList: [],
          ok: this.handleFavorite,
          cancel: this.closeConfirm,
        },
        3: {
          title: '确定清空失效宝贝',
          des: '您可以选择再想想，或删除商品',
          leftText: '再想想',
          rightText: '删除',
          goodList: [],
          ok: this.handleDel,
          cancel: this.closeConfirm,
        },
        4: {
          title: '商品已下架',
          des: '以下商品已下架，继续购买将此订单中清除下架商品',
          leftText: '重新选择',
          rightText: '继续购买',
          goodList: [],
          ok: () => {
            this.closeConfirm();
            this.sumTotal();
          },
          cancel: () => {
            this.closeConfirm();
            this.getData();
          },
        },
        5: {
          title: '商品库存不足',
          des: '以下商品库存不足，继续购买将按剩余库存计算',
          leftText: '重新选择',
          rightText: '继续购买',
          goodList: [],
          ok: () => {
            this.closeConfirm();
            this.sumTotal();
          },
          cancel: () => {
            this.closeConfirm();
            this.getData();
          },
        },
        6: {
          title: '购物车快满了，建议清理失效商品和较早的商品。',
          leftText: '',
          rightText: '我知道了',
          goodList: [],
          ok: () => {
            this.closeConfirm();
          },
        },
      },
      confirmId: '',
      inputFlag: false,
    }
  },
  components:{
    Empty,
    Coupon,
    Address,
    Confirm,
    Recommend,
    CustomCountDown
  },
  computed: {
    ...mapState([
      'userInfo',
      'userAddress'
    ]),
    cartList() {
      return this.cartGoodList.reduce((list, currentValue) => {
        let baseShopId = currentValue.baseShopId
        if(list.length&&list[list.length-1].shopId === baseShopId) { // 是一个分组
          list[list.length-1].productList.push(currentValue)
        } else { // 新建一个分组
          let shopInfo = this.cartShopList.find(item => {
            return item.shopId === baseShopId
          })
          if(!shopInfo)
            shopInfo = {}
          this.$set(shopInfo, 'couponList', [{couponCommodityDetailDataVOList: (shopInfo.couponList && shopInfo.couponList[0].couponCommodityDetailDataVOList) || []}])
          shopInfo.productList = [currentValue]
          list.push(shopInfo)
        }
        return list
      }, [])
    },
    shopCheckedList() {
      return this.cartList.map(item => {
        // 排除只有失效商品和店铺里面只有optional为false，导致全选的情况
        return (item.shopId === '-1' && this.cartList[0].shopId !== '-1') || (item.productList.every(child => child.optional === false || child.checked) && item.productList.some(child => child.optional === true));
      })
    },
    allChecked() {
      return this.shopCheckedList.length && this.shopCheckedList.every(item => item);
    },
    selectedGoods() {
      let list = [];
      this.cartList.forEach((item) => {
        item.productList.forEach((child) => {
          if(child.checked) {
            list.push({
              skuId: child.skuId,
              shopId: child.shopId,
              baseShopId: child.baseShopId,
              quantity: child.quantity,
              id: child.id,
              cid: child.cid,
              productId: child.productId,
            });
          }
        })
      });
      return list;
    }
  },
  watch: {
    'selectedGoods.length': function() {
      this.getSummaryData();
    },
    // 第一次进入购物车要显示底部结算栏
    'cartList.length': function(val) {
      if(val) {
        this.$nextTick(() => {
          let event = new Event("resize")
          window.dispatchEvent(event)
        })
      }
    }
  },
  methods: {
    goConfirmOrder(isSeckill) {
      let list = [];
      this.cartList.forEach((item) => {
        let temp = [];
        item.productList.forEach((child) => {
          if(child.checked) {
            temp.push({
              productId: child.productId,
              skuId: child.skuId,
              quantity: child.quantity,
              skuName: child.skuName,
              skuAttr: child.skuAttr,
              productImg: child.thumbUrl,
              price: child.currentPrice,
              activityInfos: child.activityInfoList,
              thirdPlatformCid: child.cid
            });
          }
        })
        if(temp.length) {
          list.push({
            shopId: item.shopId,
            shopName: item.shopName,
            shopType: item.shopType,
            shopCouponId: -1, // 店铺优惠券
            products: temp,
          })
        }
      });
      this.$store.commit('SET_ORDER_INFO', {
        shops: list,
        shopCart: true,
        isSeckill: isSeckill
      });
      this.$router.push({name: 'Order'});
    },
    checkSeckillResult(item, token) {
      return new Promise((resolve, reject) => {
        checkSeckillResultCartList({
          userId: this.userInfo.userId,
          skuId: item.skuId,
          seckillActivityId: item.activityInfoList[0].activityId,
          seckillToken: token,
        }).then((res) => {
          if (res.result === 0) {
            resolve(res);
          } else {
            reject(res);
          }
        }).catch((err) => {
          reject(err);
        })
      })
    },
    checkSeckill(item) {
      return new Promise((resolve, reject) => {
        checkSeckillCartList({
          userId: this.userInfo.userId,
          skuId: item.skuId,
          seckillActivityId: item.activityInfoList[0].activityId,
          quantity: item.quantity
        }).then((res) => {
          if (res.result === 0) {
            this.checkSeckillResult(item, res.data.seckillToken).then((resP) => {
              item.activityInfoList[0].activityToken = res.data.seckillToken
              resolve(resP);
            }).catch((errP) => {
              this.$Message.info(errP.message);
              reject(errP);
            })
          } else {
            reject(res);
          }
        }).catch((err) => {
          reject(err);
        })
      })
    },
    sumTotal() {
      if(this.selectedGoods.length === 0) {
        this.$Message.info('您还没有选择宝贝哦~');
        return;
      }
      let count = this.selectedGoods.length;
      checkInventory({
        userId: this.userInfo.userId,
        userReceiveAddress: {
          provinceCode: this.userAddress.provinceCode,
          cityCode: this.userAddress.cityCode,
          countyCode: this.userAddress.countryCode,
          townCode: this.userAddress.townCode
        },
        queryList: this.selectedGoods,
        origin: 1,
      }).then((res) => {
        if (res.result === 0) {
          // 秒杀校验
          let seckillList = [];
          this.selectedGoods.forEach((element) => {
            this.cartList.forEach((item) => {
              item.productList.forEach((child) => {
                if(child.id === element.id && child.activityFlag === 10 && child.activityInfoList[0].activityType==='10' && child.activityInfoList[0].pageActivityStatus===20) {
                  seckillList.push(child);
                }
              })
            })
          });
          if(seckillList.length) {// 有秒杀
            Promise.all(seckillList.map(item => this.checkSeckill(item))).then((resP) => {
              // 去确认订单
              this.goConfirmOrder(true);
            }).catch((errP) => {
            })
          } else {// 无秒杀
            // 去确认订单
            this.goConfirmOrder(false);
          }
        } else {
          if(res.data.notOnSale?.length > 0 || res.data.inventoryNotEnough?.length > 0) {
            let list = res.data.inventoryNotEnough.map(item => item.skuId);
            let inventoryList = res.data.inventoryNotEnough.map(item => item.inventory);
            let type = 5;
            if(res.data.notOnSale.length) {
              list = res.data.notOnSale;
              type = 4;
            }
            if(count === list.length) {
              this.$Message.info(res.message);
              this.getData();
            } else {
              let goodList = [];
              this.cartList.forEach((item) => {
                item.productList.forEach((child) => {
                  if(list.includes(child.skuId)) {
                    if(type === 5) {
                      let num = inventoryList[list.findIndex(id => id === child.skuId)];
                      child.quantity = child.inventory = num
                      if(num < 1) {
                        child.checked = false;
                      }
                    } else {
                      child.checked = false;
                    }
                    goodList.push(child);
                  }
                })
              });
              this.confirmInfo[type].goodList = goodList;
              this.delGoods(type);
            }
          } else {
            // 刷新页面 超过组合限购商品列表未处理没ui
            if(res.data.seckillNotQualified?.length > 0 || res.data.seckillPersonalLimit?.length > 0) {
              this.getData();
            } else {
              if(res.result === 1498) {
                // 商品变为订金商品、商务合作商品或定金预售商品（购物车不支持下单的活动商品）
                this.getData();
              }
            }
          }
        }
      }).catch((err) => {
      })
    },
    handleQuantityChange(child) {
      // 在数据变化后要执行的某个操作，而这个操作需要使用随数据改变而改变的DOM结构的时候，这个操作都应该放进Vue.nextTick()的回调函数中
      let quantity = child.quantity
      if(this.inputFlag) {
        if(child.quantity === null) {
          quantity = 1;
          this.$nextTick(() => {
            child.quantity = 1;
          })
        } else if(child.quantity === 0) {
          this.$Message.info('不能再减少了哦~');
          quantity = 1;
          this.$nextTick(() => {
            child.quantity = 1;
          })
        } else if(child.quantity > child.inventory) {
          this.$Message.info('超过商品最大库存，已自动帮您调整~');
          quantity = child.inventory;
          this.$nextTick(() => {
            child.quantity = child.inventory;
          })
        }
      } else {
        if(child.quantity < 1) {
          this.$Message.info('不能再减少了哦~');
          this.$nextTick(() => {
            child.quantity = 1;
          })
          return;
        }
        if(child.quantity > child.inventory) {
          this.$Message.info('不能再增加了哦~');
          this.$nextTick(() => {
            child.quantity = child.inventory;
          })
          return;
        }
      }
      updateCartInfo({
        userId: this.userInfo.userId,
        shopId: child.shopId,
        skuId: child.skuId,
        productId: child.productId,
        quantity: quantity,
        id: child.id,
        cid: child.cid,
        shopType: child.shopType,
        shopName: child.shopName,
        thumbUrl: child.thumbUrl,
        skuName: child.skuName,
        skuAttr: child.skuAttr,
      }).then((res) => {
        if (res.result === 0) {
          if(this.selectedGoods.length)
            this.getSummaryData();
        } else {
        }
      }).catch((err) => {
      })
    },
    // 关闭弹窗
    closeConfirm() {
      this.isShow = false;
    },
    goSimilar(skuId) {
      this.$router.push({
        name: 'Similar',
        params: {
          skuId,
        }
      });
    },
    // 删除
    handleDel() {
      let list;
      if(this.confirmId === '') { // 批量
        list = this.selectedGoods.map(item => item.id);
      } else if(this.confirmId === 'clear') { // 清空
        list = this.cartList[this.cartList.length-1].productList.map(item => item.id);
      } else { // 单个
        list = [ this.confirmId ];
      }
      delCartList({
        userId: this.userInfo.userId,
        cartIdList: list
      }).then((res) => {
        if (res.result === 0) {
          this.$Message.success('删除成功');
          this.closeConfirm();
          this.getData();
        } else {
        }
      }).catch((err) => {
        
      })
    },
    // 移入收藏夹
    handleFavorite() {
      let list;
      if(this.confirmId === '') { // 批量
        list = this.selectedGoods.map(item => item.id);
      } else { // 单个
        list = [ this.confirmId ];
      }
      collectCartList({
        userId: this.userInfo.userId,
        cartIdList: list
      }).then((res) => {
        if (res.result === 0) {
          this.$Message.success('移入收藏夹成功');
          this.closeConfirm();
          this.getData();
        } else {
        }
      }).catch((err) => {
      })
    },
    delGoods(type, id) {
      this.isShow = true;
      this.confirmType = type;
      this.confirmId = id;
    },
    beforeDel(type) {
      if(this.selectedGoods.length === 0) {
        this.$Message.info('您还没有选中商品');
        return;
      }
      this.delGoods(type, '');
    },
    chooseAll() {
      const checked = this.allChecked;
      this.cartList.forEach((item) => {
        if(item.shopId !== '-1') {
          item.productList.forEach((child) => {
            if(child.optional) {
              child.checked = !checked;
            }
          })
        }
      });
    },
    chooseShop(index) {
      const item = this.cartList[index];
      const checked = this.shopCheckedList[index];
      item.productList.forEach(child => {
        if(child.optional) {
          child.checked = !checked;
        }
      })
    },
    chooseGood(index,childIndex) {
      const item = this.cartList[index].productList[childIndex];
      if(item.optional) {
        item.checked = !item.checked;
      }
    },
    goHome() {
      this.$router.push({ name: 'Home' });
    },
    goDetail(item) {
      this.$router.push({
        name: 'GoodsDetail',
        query: {
          productNo: item.productId,
          skuNo: item.skuId,
        }
      });
    },
    format(child) {
      // 格式化秒杀直降倒计时时间
      if(child.activityFlag === 10 && child.activityInfoList && child.activityInfoList[0]) {
        const nowTime = new Date(child.activityInfoList[0].serverTime.replace(/-/g, '/'));
        const endTime = new Date(child.activityInfoList[0].endTime.replace(/-/g, '/'));
        child.activityInfoList[0].seckillTime = endTime.getTime() - nowTime.getTime();
        child.activityInfoList[0].isOver = child.activityInfoList[0].seckillTime > 24*60*60*1000;
        // 格式化结束时间
        let nowArr = child.activityInfoList[0].serverTime.split('-');
        let endArr = child.activityInfoList[0].endTime.split('-');
        let str = '';
        if(nowArr[0] !== endArr[0]){
          str +=  endArr[0]+'年';
        }
        str +=  endArr[1]+'月';
        let endDay = endArr[2].split(' ');
        str +=  endDay[0]+'号 ';
        let time = endDay[1].split(':');
        str +=  time[0]+':'+time[1];
        child.activityInfoList[0].strEnd = str;
      }
    },
    getCouponData(item) {
      getCartCouponList({
        userId: this.userInfo.userId,
        shopId: item.shopId
      }).then((res) => {
        if (res.result === 0) {
          item.couponList.splice(0, 1, {couponCommodityDetailDataVOList: res.data.result});
        } else {
        }
      }).catch((err) => {
      })
    },
    getSummaryData() {
      if(this.selectedGoods.length === 0) {
        this.summary = '0.00';
        this.discount = '0.00';
        return;
      }
      getCartSummaryList({
        userId: this.userInfo.userId,
        productList: this.selectedGoods,
      }).then((res) => {
        if (res.result === 0) {
          this.summary = res.data.summary;
          this.discount = res.data.discount;
        } else {
        }
      }).catch((err) => {
      })
    },
    getGoodData() {
      if(this.finished) return;
      this.busy = true;
      let baseShopId = '', addCartTime = ''
      if(this.cartList.length) {
        let lastShopInfo = this.cartList[this.cartList.length -1]
        baseShopId = lastShopInfo.shopId
        addCartTime = lastShopInfo.addCartTime
      }
      getCartLoadList({
        skuIds: this.cartGoodList.map((item) => item.skuId),
        baseShopId: baseShopId,
        addCartTime: addCartTime,
        userId: this.userInfo.userId,
        userReceiveAddress: {
          provinceCode: this.userAddress.provinceCode,
          cityCode: this.userAddress.cityCode,
          countyCode: this.userAddress.countryCode,
          townCode: this.userAddress.townCode
        }
      }).then((res) => {
        if (res.result === 0) {
          res.data.list.forEach((child) => {
            if(child.baseShopId !== '-1' && child.optional && (this.$route.params.id === child.skuId || this.allChecked)) {
              child.checked = true;
            } else {
              child.checked = false;
            }
            this.format(child);
          })
          this.cartGoodList = this.cartGoodList.concat(res.data.list);
          this.finished = !res.data.nextPage;
          this.cartSize = res.data.size;
          this.$emit('cart', res.data.size);
          if(res.data.size > 90 && !sessionStorage.getItem('closeCartFull')) {
            this.delGoods(6);
            sessionStorage.setItem('closeCartFull', 'closed')
          }
          this.busy = false;
        } else {
          if(this.cartGoodList.length === 0) {
            // 初始化报错
            this.finished = true;
          } else {
            // 加载更多报错
            this.busy = false;
          }
        }
      }).catch((err) => {
        if(this.cartGoodList.length === 0) {
          // 初始化报错
          this.finished = true;
        } else {
          // 加载更多报错
          this.busy = false;
        }
      })
    },
    getShopData() {
      getCartList({
        userId: this.userInfo.userId,
        userReceiveAddress: {
          provinceCode: this.userAddress.provinceCode,
          cityCode: this.userAddress.cityCode,
          countyCode: this.userAddress.countryCode,
          townCode: this.userAddress.townCode
        }
      }).then((res) => {
        if (res.result === 0) {
          this.cartShopList = res.data;
          this.getGoodData();
        } else {
          this.finished = true;
        }
      }).catch((err) => {
        this.finished = true;
      })
    },
    getData() {
      this.busy = true
      this.finished = false
      this.cartGoodList = []
      this.getShopData();
    }
  },
}
</script>

<style lang="less" scoped>
.cart {
  .address-line {
    padding: 16px 0 4px 39px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .num {
      font-size: 16px;
      line-height: 19px;
      color: #cc0000;
      font-weight: bold;
    }
    .address {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #333333;
    }
  }
  .line {
    display: flex;
    font-size: 16px;
    color: #333333;
    .select {
      width: 93px;
      text-align: center;
      line-height: 14px;
      img {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
    .detail {
      flex: 1;
      overflow: hidden;
      span {
        margin-left: 74px;
      }
    }
    .price {
      width: 120px;
      text-align: center;
    }
    .count {
      width: 98px;
      text-align: center;
    }
    .total {
      width: 203px;
      text-align: center;
    }
    .action {
      width: 93px;
    }
  }
  .line-header {
    height: 60px;
    background-color: rgba(204,0,0,0.10);
    align-items: center;
  }
  .line-shop {
    margin-top: 24px;
    height: 60px;
    background-color: transparent;
    align-items: center;
    font-size: 17px;
    .detail {
      overflow: visible;
      display: flex;
      align-items: center;
      > .shop-title {
        width: 463px;
        font-size: 14px;
        color: #333333;
        font-weight: 600;
      }
      > .shop-coupon {
        position: relative;
        top: 3px;
      }
    }
  }
  .line-shop-useless {
    margin-top: 24px;
    height: 60px;
    background-color: transparent;
    padding-bottom: 4px;
    align-items: flex-end;
    border-bottom: 1px solid rgba(153,153,153,0.20);
    .detail {
      font-size: 14px;
      line-height: 20px;
      color: #333333;
      text-align: center;
    }
    .action {
      line-height: 20px;
      font-size: 14px;
      color: #999999;
      cursor: pointer;
    }
    .action:hover {
      color: #CC0000;
    }
  }
  .line-header + .line-shop, .line-header + .line-shop-useless{
    margin-top: 0;
  }
  .good-wrap {
    box-shadow: 0px -2px 0px 0px #999999; 
    border: 1px solid rgba(153,153,153,0.20);
    border-top: none;
    border-bottom: none;
  }
  .good-wrap-useless {
    background: rgba(153,153,153,0.10);
    border: 1px solid rgba(153,153,153,0.20);
  }
  .line-good {
    height: 160px;
    padding-top: 19px;
    border-bottom: 1px solid rgba(153,153,153,0.20);
    .select {
      margin-top: -19px;
      align-self: center;
    }
    .detail {
      .info {
        display: flex;
        .img {
          width: 100px;
          height: 100px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            cursor: pointer;
          }
        }
        .text {
          margin-left: 7px;
          width: 280px;
          font-size: 14px;
          color: #333333;
          .title {
            line-height: 20px;
            cursor: pointer;
          }
          .sku {
            cursor: pointer;
            margin-top: 15px;
            line-height: 16px;
          }
          .no-option {
            font-size: 14px;
            line-height: 16px;
            color: #cc0000;
          }
        }
      }
      .activity {
        margin-top: 10px;
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 14px;
        color: #cc0000;
      }
    }
    .price {
      font-size: 14px;
      color: #333333;
    }
    .count {
      .inventory {
        line-height: 20px;
        font-size: 14px;
        color: #cc0000;
      }
      .full {
        color: #999999;
      }
      .invalid {
        line-height: 20px;
        font-size: 14px;
        color: #0085ff;
      }
    }
    .total {
      line-height: 17px;
      font-size: 14px;
      font-weight: bold;
    }
    .action {
      line-height: 20px;
      font-size: 14px;
      cursor: pointer;
      div:first-child {
        color: #999999;
      }
      div:last-child {
        color: #0085ff;
      }
      div:first-child:hover, div:last-child:hover {
        color: #CC0000;
      }
    }
  }
  .line-good-useless {
    height: 132px;
    padding-top: 20px;
    .select {
      margin-top: -20px;
      align-self: center;
      div {
        display: inline-block;
        background: #999999;
        padding: 4px 6px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
      }
    }
    .detail {
      .info {
        display: flex;
        .img {
          width: 100px;
          height: 100px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
        .text {
          margin-left: 7px;
          width: 280px;
          font-size: 14px;
          color: #333333;
          .title {
            line-height: 20px;
          }
          .sku {
            margin-top: 15px;
            line-height: 16px;
          }
        }
      }
      .activity {
        margin-top: 10px;
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 14px;
        color: #cc0000;
      }
    }
    .action {
      line-height: 20px;
      font-size: 14px;
      color: #333333;
      cursor: pointer;
      div:last-child {
        margin-top: 20px;
      }
      div:first-child:hover, div:last-child:hover {
        color: #0085ff;
      }
    }
  }
  .line-summary {
    background-color: rgb(242, 242, 242);
    margin-top: 28px;
    height: 60px;
    border: 1px solid rgba(153,153,153,0.20);
    align-items: center;
    .detail {
      flex: 1;
      overflow: hidden;
      font-size: 14px;
      line-height: 20px;
      color: #333333;
      span:first-child {
        margin-left: 72px;
        cursor: pointer;
      }
      span:last-child {
        margin-left: 17px;
        cursor: pointer;
      }
      span:first-child:hover, span:last-child:hover {
        color: #CC0000;
      }
    }
    .sum-count {
      width: 160px;
      font-size: 14px;
      line-height: 20px;
      color: rgba(51,51,51,0.60);
      span {
        padding: 0 4px;
        color: #cc0000;
      }
    }
    .sum-price {
      width: 233px;
      font-size: 14px;
      line-height: 20px;
      color: #333333;
      span {
        vertical-align: middle;
      }
      .name {
        display: inline-block;
        width: 56px;
        text-align: right;
      }
      .unit {
        color: #cc0000;
      }
      .sale {
        color: #cc0000;
        font-size: 18px;
      }
      .discount {
        color: #ef2f2f;
      }
    }
    .sum {
      width: 101px;
      height: 100%;
      background: #cc0000;
      text-align: center;
      line-height: 60px;
      font-size: 24px;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .empty-loading {
    padding-top: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 230px;
      height: 230px;
    }
    .txt {
      margin-top: 85px;
      font-size: 18px;
      color: #999999;
    }
  }
}
</style>

<style lang="less">
.cart {
  .line {
    .count {
      .ivu-input-number.ivu-input-number-small.ivu-input-number-controls-outside {
        width: 100px;
        border-radius: 0;
        border: 1px solid rgba(153,153,153,0.20);
        background-color: transparent;
        .ivu-input-number-controls-outside-btn {
          background: rgba(153,153,153,0.40);
          color: #333333;
        }
        .ivu-input-number-controls-outside-down {
          border-right: none;
        }
        .ivu-input-number-controls-outside-up {
          border-left: none;
        }
        .ivu-input-number-input-wrap {
          .ivu-input-number-input {
            text-align: center;
            color: #000000;
          }
        }
      }
    }
    .count-disabled {
      .ivu-input-number.ivu-input-number-small.ivu-input-number-controls-outside {
        .ivu-input-number-controls-outside-btn {
          background: rgba(153,153,153,0.20);
          color: #b6b6b6;
        }
      }
    }
  }
}
</style>