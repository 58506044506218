<template>
  <div class="pay-timeout">
    <div class="container">
      <Breadcrumb />
      <div class="content">
        <img :src="require('@/assets/imgs/pay/timeout.png')" alt="" />
        <p class="tip">订单正在处理中</p>
        <button
          :class="isActive ? 'active' : ''"
          @mouseenter="isActive = true"
          @mouseleave="isActive = false"
          @click="$router.push('/user/newOrder')"
        >
          查看订单详情
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "./components/Breadcrumb.vue";
export default {
  name: "PayTimeout",
  components: {
    Breadcrumb,
  },
  data() {
    return {
      isActive: false,
    };
  },
};
</script>

<style lang="less" scoped>
.pay-timeout {
  width: 1400px;
  margin: auto;
  height: 100%;
  background-color: #fff;
  padding: 50px 0 166px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 84px;
  img {
    height: 170px;
  }
  .tip {
    font-size: 20px;
    color: #666;
    margin: 35px 0;
  }
  button {
    font-size: 20px;
    color: #666;
    padding: 20px 60px;
    border-radius: 6px;
    border: 2px solid #666;
  }
  .active {
    color: #fff;
    border: 2px solid #cc0000;
    background-color: #cc0000;
  }
}
</style>