<template>
  <!-- 地址选择组件 -->
  <div class="address-wrap">
    <div class="choose-detail" 
      @mouseenter="showAddressPopup = true" 
      @mouseleave="showAddressPopup = false"
    >
      <span class="choose-detail-item">{{currentAddress.fullAddress}}</span>
      <Icon type="ios-arrow-down" color="#999" size="18"/>
    </div>
    
    <div :class="['address-popup',ivuIconFlag?'user-list':'']" 
      v-show="showAddressPopup" 
      @mouseenter="addressPopupMouseEnter"
      @mouseleave="addressPopupMouseLeave"
    >
      <div class="address-content" v-if="userAddressList.length > 0">
        <div class="title" @click="ivuIconFlag = !ivuIconFlag">
          <h3>常用地址</h3>
          <Icon :type="`ios-arrow-${ivuIconFlag?'up':'down'}`" color="#999" size="20"/>
        </div>
        <ul class="li-list-address" v-if="layoutUserAddressList.length > 0">
          <li :class="['li-item',currentAddress.id === item.id?'active':'']" v-for="(item,index) in layoutUserAddressList" :key="item.id" @click="addressListClick(item,index)">
            <span class="name">{{item.contactPerson}}</span>
            <span class="full-address" >{{item.fullAddress}}</span>
          </li>
        </ul>
      </div>
      <div class="address-content">
        <div class="title new-address" v-if="userAddressList.length > 0" @click="clickNewAddressHeader">
          <h3>选择新地址</h3>
          <Icon :type="`ios-arrow-${ivuIconFlag?'down':'up'}`" color="#999" size="20"/>
        </div>
        <div v-show="showNewAddress" class="new-address-wrap">
          <ul class="list-new-address">
            <li :class="['li-item',index === tabActiveIndex?'active':'']" v-for="(item,index) in tabs" :key="index" @click="handleTabClick(item,index)">
              <span>{{item.addressName}}</span>
              <Icon type="ios-arrow-down" color="#999" size="20"/>
            </li>
          </ul>
          <div class="new-address-panel">
            <span :class="{'active':panelActiveIndex === index}" v-for="(item,index) in addressPanelList" :key="item.id" @click="chooseItemAddress(item)">{{item.addressName}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import { mapState } from 'vuex';
import{getUserAddressList,queryAddress} from '@/api/api'

export default {
  name: '',
  components:{
    
  },
  props:{
    // popup定位位置 -默认false->left定位
    positionRight:Boolean,

    //切换地址触发函数
    changeAddressCallBack:Function,

  },
  data () {
   return {
     showAddressPopup:false,
     currentAddress:{fullAddress:'选择配送区域'}, //当前选择的地址
     userAddressList:[], //用户常用地址列表
     ivuIconFlag:false, // 地址展开标识
     showNewAddress:true, //展示新地址选择
     tabs:[], //新地址tab
     tabActiveIndex:0, //tab选中激活索引
     panelActiveIndex:-1, //面板地址选中激活索引
     addressPanelList:[], //面板地址列表
     addressFlag:false,
     panelFlag:false,
     addressPopupFlag:false,
     cachePanelActiveIndex:-1 // 缓存面板索引
   }
  },
  computed: {
    ...mapState(['userAddress', 'userInfo']),
    layoutUserAddressList() {
      let list = [];
      if(this.sortedAddressList.length) {
        if(this.ivuIconFlag) {
          list = this.sortedAddressList;
        } else {
          list.push(this.sortedAddressList[0]);
        }
      }
      return list;
    },
    sortedAddressList() {
      let list = this.userAddressList.map(item => item); // 复制一份;
      if(list.length && this.currentAddress.id) {
        let index = list.findIndex(item => item.id === this.currentAddress.id);
        let temp = list.splice(index, 1);
        list = temp.concat(list);
      }
      return list;
    },
  },
  watch:{
    showAddressPopup:{ 
      handler(val){
        if(!val) return
        // 定位popup弹框位置
        const popupDom = document.querySelector('.address-popup')
        popupDom.style[this.positionRight?'right':'left'] = 0
      },
      immediate:true
    },
    ivuIconFlag:{
      handler(val){
        this.showNewAddress = !val
      }
    },
    userAddressList(val){
      const storeAddress = Object.keys(this.userAddress)
      if(storeAddress.length > 0) {
        let addressActiveIndex = val.findIndex(item => item.id === this.userAddress.id)
        if(!this.userAddress.id) {
          return
        } else if(addressActiveIndex !== -1) {
          this.currentAddress = this.userAddress
          this.$emit('changeAddressCallBack')
          return
        }
      }
      // 没有选择过地址时
      const defaultAddress = val.find(item => item.isDefault === 1)
      // 有默认地址取默认地址,否则取列表第一条,最后默认显示-'选择配送区域'
      const initAddress = defaultAddress?defaultAddress:val.length?val[0]:{}
      this.currentAddress = initAddress
      this.$store.commit('SET_USER_ADDRESS',initAddress)
      this.$emit('changeAddressCallBack')
    },
    currentAddress:{
      handler(val){
        if(this.addressFlag) return
        if(val.provinceCode){
          const {provinceName,provinceCode,cityName,cityCode,countryName,countryCode,townName,townCode} = val
          // 选择过的地址至少会有三级地址
          const province = {addressName:provinceName,addressCode:provinceCode,parentCode:0}
          const city = {addressName:cityName,addressCode:cityCode,parentCode:provinceCode}
          const country = {addressName:countryName,addressCode:countryCode,parentCode:cityCode}
          const town = {addressName:townName,addressCode:townCode,parentCode:countryCode}
          this.tabs = [province,city,country]
          if(town.addressName && town.addressCode){
            this.tabs.push(town)
          }
          this.tabActiveIndex = this.tabs.length - 1
          const currentTab = this.tabs[this.tabActiveIndex]
          this.queryAddressFn(currentTab.parentCode)
        }else{
          const storeDataLength = Object.keys(this.userAddress).length
          if(storeDataLength > 0) return
          this.tabs.push({addressName:'请选择',addressCode:''})
          this.queryAddressFn(0)
        }
      },
    }
  },
  created () {
    this.initCheckArea(),
    this.getUserAddressListFn()
  },

  methods:{
    // 初始化缓存的地址
    initCheckArea(){
      const storeDataLength = Object.keys(this.userAddress).length
      if(storeDataLength <= 0 || this.userAddress.id) return
      // 选择过地址
      console.log(this.userAddress,'缓存的地址');
      this.currentAddress = this.userAddress
      this.$emit('changeAddressCallBack')
    },

    // 获取用户地址列表
    async getUserAddressListFn(){
      let result = 0, data = [] // 没登录相当于用户地址列表为空
      if (this.userInfo.userId) {
        let listData = await getUserAddressList({userId: this.userInfo.userId})
        result = listData.result
        data = listData.data
      }
      if(result !== 0 || data.length <= 0){
        if(this.userAddress.id) {
          this.$store.commit('SET_USER_ADDRESS',{})
        }
        if(Object.keys(this.userAddress).length <= 0 || this.userAddress.id) {
          // 触发currentAddress监听函数
          this.currentAddress = Object.assign({},this.currentAddress)
          this.$emit('changeAddressCallBack')
        }
        return
      }else{
        this.userAddressList = data
      }
    },

    //用户地址列表点击
    addressListClick(item,index){
      this.addressFlag = false
      this.currentAddress = item
      // 配送区域校验
      this.$store.commit('SET_USER_ADDRESS',item)
      this.$emit('changeAddressCallBack')
      this.showAddressPopup = false
    },

    // 地址tab点击
    handleTabClick(item,index){
      this.panelFlag = false
      if(this.tabActiveIndex === index) return
      this.tabActiveIndex = index
      const code = item.addressCode?item.parentCode:this.tabs[index-1].addressCode
      this.queryAddressFn(code)
    },

    //面板选择具体的地址
    chooseItemAddress(item){
      const len = this.tabs.length
      this.panelFlag = true
      if(this.tabActiveIndex === len-1){
        if(len <= 3 ){
          this.tabs.splice(len-1,0,item)
          this.tabActiveIndex += 1
          this.queryAddressFn(item.addressCode)
        }else{
          // 最后一级地址
          this.showAddressPopup = false
          this.tabs.splice(this.tabActiveIndex,1,item)
          const currentTab = this.tabs[this.tabActiveIndex]
          this.panelActiveIndex = currentTab.addressCode?this.addressPanelList.findIndex(item => item.addressCode === currentTab.addressCode):this.addressPanelList.length-1
          if(this.cachePanelActiveIndex === this.panelActiveIndex) return
          this.formatAddress()
        }
        
      }else{
        this.tabs.splice(this.tabActiveIndex,Infinity,item)
        this.tabs.push({addressName:'请选择',addressCode:''})
        this.tabActiveIndex += 1
        this.queryAddressFn(item.addressCode)
      }      
    },

    //请求级联地址
    async queryAddressFn(code){
      const {result,data} = await queryAddress({parentCode:code})
      if(result === 0){
        const len = this.tabs.length
        const currentTab = this.tabs[this.tabActiveIndex]
        if(len >= 4 && this.tabActiveIndex === len-1){
          this.addressPanelList = [...data,{addressName:'其他',addressCode:''}]
          this.panelActiveIndex = currentTab.addressCode?data.findIndex(item => item.addressCode === currentTab.addressCode):this.panelFlag?-1:this.addressPanelList.length-1
          
        }else{
          this.addressPanelList = data
          this.panelActiveIndex = data.findIndex(item => item.addressCode === currentTab.addressCode)
        }
        this.cachePanelActiveIndex = this.panelActiveIndex
      }
    },

    // 选择最后一级地址-格式化地址
    formatAddress(){
      const nowTabs = this.tabs
      const lastTab = nowTabs[nowTabs.length -1]
      const newTabs = lastTab.addressCode?nowTabs:nowTabs.slice(0,nowTabs.length -1)

      const address = newTabs.reduce((pre,cur) => {
        return pre + ' ' + cur.addressName
      },'')
      
      const fullAddress = address.replace(/(^\s*)|(\s*$)/g, '')
      const lastNewTab = newTabs[newTabs.length -1]
      this.addressFlag = true
      const data = {...lastNewTab,fullAddress}
      this.currentAddress = data
      const fillData = this.fillAddress()
      this.cachePanelActiveIndex = this.panelActiveIndex
      console.log({...fillData,fullAddress},'最后一级地址');
      this.$store.commit('SET_USER_ADDRESS',{...fillData,fullAddress})
      this.$emit('changeAddressCallBack')
    },

    //地址数据组装
    fillAddress(){
      const obj = {}
      this.tabs.map((item,index) => {
        const {addressName,addressCode} = item
        switch (index) {
          case 0:
            obj.provinceName = addressName
            obj.provinceCode = addressCode
            break;
          case 1:
            obj.cityName = addressName
            obj.cityCode = addressCode
            break;
          case 2:
            obj.countryName = addressName
            obj.countryCode = addressCode
            break;
          case 3:
            obj.townName = addressName
            obj.townCode = addressCode
            break;
          default:
            break;
        }
      })
      return obj
    },
    clickNewAddressHeader(){
      this.ivuIconFlag && (this.addressPopupFlag = true)
      this.ivuIconFlag = !this.ivuIconFlag
      
    },
    addressPopupMouseEnter(){
      this.showAddressPopup = true
      this.addressPopupFlag = false
    },
    addressPopupMouseLeave(){
      if(this.addressPopupFlag) return
      this.showAddressPopup = false
    }
  }
}
</script>
 
<style lang="less" scoped>
  .address-wrap{
    position: relative;
  }
  .choose-detail{
    display: flex;
    align-items: center;
    height: 24px;
    border: 1px solid #999;
    color: #333;
    cursor: pointer;
    box-sizing: content-box;
    &-item{
      margin-left: 5px;
      display: inline-block;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .address-popup{
    position: absolute;
    top: 24px;
    width: 500px;
    border: 1px solid #999;
    background-color: #fff;
    padding: 0 10px;
    z-index: 9999;
    &.user-list{
      padding-bottom: 20px;
    }
  }

  .address-content{
    /deep/.ivu-icon{
      cursor: pointer;
    }
    .title{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 15px 0 10px;
      h3{
        font-size: #333;
        font-weight: bold;
      }
      &.new-address{
        margin-bottom: 0;
      }
    }
    
    .li-list-address{
      border-bottom: 1px solid #999;
      padding-right: 20px;
      .li-item{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
        &:hover{
          color: red;
        }
        &.active{
          color: red;
        }
        span{
          display: block;
          word-break: break-all;
        }
        .name{
          flex-basis: 80px;
        }
        .full-address{
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .new-address-wrap{
      padding-top: 10px;
      .list-new-address{
        display: flex;
        color: #0085FF;
        border-bottom: 2px solid red;
        width: 90%;
        height: 24px;
        box-sizing: content-box;
        .li-item{
          height: 24px;
          padding: 0 5px;
          border: 1px solid #999;
          border-bottom: none;
          line-height: 24px;
          margin-right: 5px;
          cursor: pointer;
          &.active{
            border: 2px solid red;
            border-bottom: none;
            background-color: #fff;
            height: 26px;
          }
          /deep/.ivu-icon{
            vertical-align: middle;
          }
        }
      }
      .new-address-panel{
        margin-top: 10px;
        display: grid;
        grid-template-columns: auto auto auto auto;
        color: #0085FF;
        span{
          margin-bottom: 10px;
          cursor: pointer;
          &:hover{
            color: red;
          }
          &.active{
            color: red;
          }
        }
      }
    }
    
  }
</style>