<template>
  <div class="layout-wrap">
    <div v-if="$slots.default" :class="['layout-title',className]">
      <slot/>
      <span class="text">{{isBegin?'即将结束':'即将开始'}}</span>
    </div>
    <div v-else class="layout-name">距离结束还剩</div>
    <div class="layout-time">
      <span :class="['days',className]" v-show="timeObj.days">{{timeObj.days}}天</span>
      <span :class="['block',className]">{{timeObj.hours}}</span>
      <i :class="['colon',className]">:</i>
      <span :class="['block',className]">{{timeObj.minutes}}</span>
      <i :class="['colon',className]">:</i>
      <span :class="['block',className]">{{timeObj.seconds}}</span>
    </div>
  </div>
</template>

<script>

import {formatTime} from '@/utils/common'

export default ({
  name: 'CustomCountDown',

  props: {
    // 时间戳
    time: {
      type: Object,
      default: () => {
        return {time: 0}
      }
    },

    // 倒计时结束触发函数
    finished:Function,

    // 活动是否开始 -默认为预告主题色
    isBegin:{
      type:Boolean,
      default:false
    },

    isColorFixed: {
      type:Boolean,
      default:false
    }
    
  },
  data(){
    return {
      cacheTime:0,
      timer:null,
      timeNum: this.time.time,
      timeObj:{}  //初始化显示倒计时时间
    }
  },

  computed: {
    className(){
      // this.$slots.default：区分出购物车
      // this.isColorFixed：区分首页和商详，首页颜色固定，商详根据活动状态显示不一样的颜色
      return this.$slots.default?this.isColorFixed?'':this.isBegin?'begin':'':'begin-no-default'
    }
  },

  mounted () {
    this.countDown();
  },

  methods: {
    countDown(){
      this.cacheTime = Date.now();
      this.getTime(this.timeNum);
    },

    getTime(time){
      this.timer && clearTimeout(this.timer);
      if (time <= 0) {
        clearTimeout(this.timer)
        this.$emit('finished')
        return;
      }
      const data = formatTime(time)
      this.timeObj = {...data}
      this.timer = setTimeout(() => {
        const now = Date.now();
        const diffTime = Math.floor((now - this.cacheTime) / 1000);
        const step = diffTime > 1 ? diffTime : 1; // 有的浏览器页面退到后台的时候不会计时，对比时间差，大于1s的重置倒计时
        this.cacheTime = now;
        this.getTime(time - step * 1000);
      }, 1000);
    }
  },
  watch: {
    time: {
      handler: function (newValue, oldValue) {
        this.countDown()
      },
      deep: true // 必须设置为 true 才能监听对象内部属性的变化
    }
  },
  beforeDestroy () {
    clearTimeout(this.timer)
  }
})
</script>

<style  lang="less" scoped>

  .layout-wrap{
    display: flex;
    align-items: center;
  }

  .layout-title{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 105px;
    height: 24px;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 600;
    background-color: #ef2f2f;
    margin-right: 8px;
    &.begin{
      color: #ef2f2f;
      background-color: #fff;
    }
    .text{
      margin-left: 3px;
      letter-spacing: 1px;
    }
  }

  .layout-name {
    font-size: 12px;
    line-height: 14px;
    color: #cc0000;
}

  .layout-time{
    font-weight: 600;
  }

 .days{
    color: #ef2f2f;
    font-size: 15px;
    margin-right: 3px;
    vertical-align: middle;
    font-weight: 800;
    &.begin{
      color: #fff
    }
  }

  .block {
    display: inline-block;
    width: 34px;
    height: 20px;
    border-radius: 20px;
    line-height: 20px;
    background-color: #ef2f2f;
    font-size: 14px;
    text-align: center;
    &.begin{
      color: #ef2f2f;
      background-color: #fff;
    }
    &.begin-no-default{
      width: 20px;
      color: #cc0000;
      font-size: 12px;
      border-radius: 0px;
      background-color: transparent;
      border: 1px solid #cc0000;
    }
  }

  .colon {
    display: inline-block;
    color: #ef2f2f;;
    margin: 0 4px;
    &.begin{
      color: #fff;
    }
    &.begin-no-default{
      color: #cc0000;
    }
  }

</style>
