<template>
  <div class="panel forget-password">
    <div class="container content">
      <div class="form">
        <Form :model="forgetForm">
          <Form-item :error="error.phone">
            <Input v-model.trim="forgetForm.phone" @on-blur="handlePhoneBlur" placeholder="手机号/用户名/邮箱"/>
          </Form-item>
          <Form-item :error="error.code">
            <div class="line">
              <Input v-model.trim="forgetForm.code" placeholder="输入验证码"/>
              <Button @click="sendCode" :disabled="(!forgetForm.phone) || (!!error.phone) || second>0">{{second?'重新获取('+second+'s)': btnText}}</Button>
            </div>
          </Form-item>
          <Form-item :error="error.password">
            <Input v-model.trim="forgetForm.password" @on-blur="handlePasswordBlur" type="password" placeholder="8-16位密码，区分大小写" clearable password />
          </Form-item>
          <Form-item :error="error.password_repeat">
            <Input v-model.trim="forgetForm.password_repeat" @on-blur="handlePasswordRepeatBlur" type="password" placeholder="确认密码" clearable password />
          </Form-item>
          <Button @click="submit" :disabled="(!forgetForm.phone) || (!!error.phone) || (!forgetForm.code) || (!!error.code) || (!forgetForm.password) || (!!error.password) || (!forgetForm.password_repeat) || (!!error.password_repeat)" class="btn" type="primary" long>确定</Button>
        </Form>
      </div>
      <div id="captcha"></div>
    </div>
  </div>
</template>

<script>
import {
  getCode,
  updateDefaultPsd,
  checkLogin
} from '@/api/api'
import { rasEncrypt, checkLoginConfig } from '@/utils/common'
export default {
  name: 'ForgetPassword',
  data() {
    return {
      forgetForm: {
        phone: '',
        code: '',
        password: '',
        password_repeat: '',
      },
      error: {
        phone: '',
        code: '',
        password: '',
        password_repeat: '',
      },
      second: 0,
      btnText: '获取验证码',
      captchaIns:null, //网易网盾插件实例
    }
  },
  methods: {
    handlePasswordRepeatBlur() {
      if(this.forgetForm.password === this.forgetForm.password_repeat){
        this.error.password_repeat = '';
      }else{
        this.error.password_repeat = '两次输入密码不一致';
        return;
      }
    },
    handlePasswordBlur() {
      const regPWD =/^(?=.*[0-9].*)(?=.*[a-zA-Z].*)(?=[^_].*)[\w.@$!%*#\-~?&^].{8,16}$/g ;
      if(regPWD.test(this.forgetForm.password)){
        this.error.password = '';
      }else{
        this.error.password = '6-20位字符，由不含空格、字母、符号两个以上组合';
        return;
      }
    },
    handlePhoneBlur() {
      const regPhone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if(regPhone.test(this.forgetForm.phone)){
        this.error.phone = '';
      }else{
        this.error.phone = '手机号码格式不正确';
        return;
      }
    },
    sendCode() {
      getCode({
        mobileNumber: this.forgetForm.phone,
        messageFlag: 'PC_MALL_FORGET_PWD'
      }).then((res) => {
        if (res.result === 0) {
          this.$Message.success('短信验证码发送成功');
          this.btnText = '重新获取';
          this.second = 60;
          let timer = setInterval(() => {
            this.second -= 1;
            if(this.second === 0) {
              clearInterval(timer);
            }
          }, 1000)
        } else if (res.result === 2231) {
          this.captchaIns.popUp();
        } else {
        }
      }).catch((err) => {
      })
    },
    submit() {
      this.handlePhoneBlur();
      this.handlePasswordBlur();
      this.handlePasswordRepeatBlur();
      updateDefaultPsd({
        mobile: this.forgetForm.phone,
        smsCode: this.forgetForm.code,
        pwd: rasEncrypt(this.forgetForm.password),
      }).then((res) => {
        if (res.result === 0) {
          this.$Message.success('密码设置成功!');
          // 去登陆页
          this.$router.push({ path: '/login' });
        } else {
        }
      }).catch((err) => {
      })
    }
  },
  mounted() {
    // 网易网盾配置
    checkLoginConfig({
      init: (instance) => {
        // 初始化成功后得到验证实例instance，可以调用实例的方法
        this.captchaIns = instance
      },
      afterCheck: (val) => {
        // 验证成功后，调用close方法关闭弹框（enableClose为true时调用）
        this.captchaIns.close()
        checkLogin({
          captchaValidateValue: val.validate,
          mobileNumber: this.forgetForm.phone,
        }).then((res) => {
          if(res.result === 0){
            this.sendCode() //发送验证码
          }
        })
      },
    })
  }
}
</script>

<style lang="less" scoped>
.forget-password {
  .content {
    padding: 60px 0;
    display: flex;
    justify-content: center;
    .form {
      width: 320px;
      .btn {
        color: white;
        margin-top: 16px;
        background: #EF2F2F;
        border-color: #EF2F2F;
      }
      .btn[disabled] {
        background: rgba(239,47,47,0.60);
        border-color: rgba(239,47,47,0.60);
      }
      .line {
        display: flex;
        button {
          color: #EF2F2F;
          margin-top: 0;
          margin-left: 7px;
        }
        button:hover {
          border-color: #dcdee2;
        }
        button:focus {
          box-shadow: none;
        }
      }
    }
    /deep/.ivu-form-item-error-tip {
      font-size: 12px;
    }
  }
}
</style>