// 举例：
// 获取轮播(营销)图片
// export const loadCarouselItems = ({ commit }) => {
//   return new Promise((resolve, reject) => {
//     const data = {
//       carouselItems: [
//         'static/img/nav/1.jpg',
//         'static/img/nav/2.jpg',
//         'static/img/nav/3.jpg',
//         'static/img/nav/4.jpg',
//         'static/img/nav/5.jpg'
//       ],
//       activity: [
//         'static/img/nav/nav_showimg1.jpg',
//         'static/img/nav/nav_showimg2.jpg'
//       ]
//     };
//     commit('SET_CAROUSELITEMS_INFO', data);
//   });
// };
import {getGoodsDetail,getCouponList } from '@/api/api'

// 请求获得商品详细信息
export const loadGoodsInfo = async ({ commit }, params) => {
  commit('SET_LOAD_STATUS', true);
  params.appType = "web" // web端必传 "web"
  const { result, data } = await getGoodsDetail(params)
  
  if (result === 0) {
    commit('SET_GOODS_DETAIL', data);
    commit('SET_LOAD_STATUS', false);

    //  获取当前sku信息
    const skuInfos = data.skuInfoList?.find(e => e.skuNo === params.skuNo)
    commit('SET_CURRENT_SKU_INFOS', skuInfos);
    
    //获取优惠券 -依赖此接口返回的cid
    const { productNo, skuNo: skuId, shopId, userId, activeCouponFlag } = params
    //活动刷新不需重新请求优惠券
    if(activeCouponFlag) return 
    const query = { productNo, skuId, shopId, userId, cid: data.cid }
    const { result, data: { records}}  = await getCouponList(query)
    if (result === 0) {
      commit('SET_COUPON_LIST', records);
    }
  } else {
    commit('SET_LOAD_STATUS', false);
  }
};