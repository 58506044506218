// 订单列表条件
const conditions1 = (item) => {
  const type = item.itemInfoList?.[0]?.activityType
  const text = {
    30: () => {
      return item.itemInfoList?.[0]?.orderItemState === 14 ? '等买家付尾款' : '等买家付订金'
    },
    110: () => {
      return item.itemInfoList?.[0]?.orderItemState === 14 ? '等买家付尾款' : '等买家付定金'
    }
  }
  if (!text[type]) {
    return '等买家付款'
  }
  return text[type]()
}
// 订单详情
const conditions2 = (item) => {
  const type = item.orderItems?.[0]?.activityType
  const text = {
    30: () => {
      return item.orderItems?.[0]?.state === 1? '待支付订金' : '待付尾款' 
    },
    110: () => {
      return item.orderItems?.[0]?.state === 1? '待支付定金' : '待付尾款'
    }
  }
  if (!text[type]) {
    return '待支付'
  }
  return text[type]()
}
//订单状态
export const statusText = (state, type, item) => {
  let text = '';
  switch (state) {
    case 1:
      text = type === 'title' ? conditions1(item) : conditions2(item)
      break;
    case 2:
      text = type === 'title' ? '等商家发货' : '待发货'
      break;
    case 3:
      text = type === 'title' ? '待收货' : '待收货'
      break;
    case 4:
      text = type === 'title' ? '待评价' : '待评价'
      break;
    case 5:
      text = type === 'title' ? '交易完成' : '交易完成'
      break;
    case 6:
      text = type === 'title' ? '交易关闭' : '交易关闭'    //已取消
      break;
    case 7:
      text = type === 'title' ? '交易关闭' : '交易关闭'    //已关闭
      break;
    case 18:
      text = type === 'title' ? '交易关闭' : '交易关闭'    //超时未支付
      break;
    case 60:
      text = type === 'title' ? '部分发货' : '待发货'
      break;
    default:
      break
  }
  return text;
}

// 订单行状态
export const afterSaleText = (state) => {
  let text = '';
  switch (+state) {
    case 2:
      text = "退款"; //待发货
      break;
    case 3:
    case 4:
    case 5:
      text = "退换"; //待收货 待安装 待评价
      break;
    case 6:
      text = "申请售后"; //已完成
      break;
    default:
      break;
  }
  return text;
}

//退款退货单状态
export const afterSaleRefund = (state, type,afterSaleStatus) => {
  let text = '';
  switch (+state) {
    case 1:
      text = type === 'refund' ? "退款中" : "退货中"  //退款or退货
      break;
    case 2:         //退款or退货
      text = type === 'refund' ? "拒绝退款" : "拒绝退货"
      break;
    case 3:
      text = "退货中"
      break;
    case 4:
      text = "退货中"
      break;
    case 5:
      text = "退款关闭"
      break;
    case 6:
      text = "已退款"
      break;
    case 7:
      text = "退款中"
      break;
    case 8:
      text = "退款失败"
      break;
    case 13:
      text = "退款中"
      break;
    case 14:
      text = "申请售后"
      break;
    default:
      break;
  }
  return text;
}

//换货单状态
export const afterSaleGoods = (state) => {
  let text = '';
  switch (+state) {
    case 1:
    case 2:
    case 9:
      text = "换货中"
      break;
    case 3:
      text = "拒绝换货"
      break;
    case 4:
      text = "已换货"
      break;
    case 5:
      text = "换货关闭"
      break;
    case 14:
      text = "申请售后"
      break;
    default:
      break;
  }
  return text;
}
