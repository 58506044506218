import { post } from './request'

/* 账号密码登录 */
export const accountLogin = (params) => post('/mallv2/user/login/pc_psw', params)

/* 忘记密码 */
export const updateDefaultPsd = (params) => post('/mallv2/user/forget/pc_mall_update', params)

/* 验证码登录 */
export const codeLogin = (params) => post('/mallv2/user/login/pc_sms', params)

/* 获取短信验证码 */
export const getCode = (params) => post('/mallv2/login/verification_account/msg_send', params)

/* 客服中心 - 转换token */
export const getOnlineServiceToken = (params) => post('/mallv2/login/verification_account/exchange_sub_token', params)

/* 网易网盾校验 */
export const checkLogin = (params) => post('/mallv2/login/behavior_captcha/check_login', params)

/* 通过关键词名称查询联想词-用户端 */
export const getKeywordAssociateList = (params) => post('/mallv2/eoc/keyword/list_associate_word_by_name', params)

// 通过关键字搜索商品/mallv2/search/key_word_search
// TODO: 先注释PC端的搜索接口，改用h5端用的接口
// export const getKeywordSearchList = (params) => post('/mallv2/search/pc_key_word_search', params)
export const getKeywordSearchList = (params) => post('/mallv2/search/key_word_search_v212', params)
// 筛选接口-条件查询
export const getAssociatedSearch = (params) => post('/mallv2/search/associated_search_pc', params)
// 筛选接口-条件查询-筛商品
export const getFilterSearch = (params) => post('/mallv2/search/filter_search_pc', params)
// 通过关键词名称查询信息: 根据词条类型选用不同接口
export const getSlogansListWordByName = (params) => post('/mallv2/facade/search/slogans_list_word_by_name', params)

// 通过优惠券搜索商品
export const getCouponSearchList = (params) => post('/mallv2/search/coupon_search_v212', params)

// 通过商品类别搜索商品mallv2/search/classify_search_v212
export const getClassifySearchList = (params) => post('mallv2/search/classify_search_v212', params)

// 商祥 - 商品详情
export const getGoodsDetail = (params) => post('/mallv2/classify/user_center/commodity_detail_v220', params)
// 商祥 - 加入收藏
export const addCollect = (params) => post('/mallv2/user/my_favorite/insert', params)
// 商祥 - 移除收藏
export const removeCollect = (params) => post('/mallv2/user/my_favorite/remove', params)
// 商祥 - 优惠券查询
export const getCouponList = (params) => post('/mallv2/classify/user_center/item_get_coupon_list', params)
// 商祥 - 领取优惠券
export const getShopCoupon = (params) => post('/mallv2/classify/user_center/get_coupon', params)
// 商祥 - 服务
export const goodsService = (params) => post('/mallv2/classify/user_center/commodity_detail/item_service', params)
// 商祥 - 用户评价
export const getEvaluates = (params) => post('/mallv2/classify/user_center/commodity_detail/comment_list', params)
// 商祥 - 好评率
export const getGoodRate = (params) => post('/mallv2/classify/user_center/commodity_praise_rate', params)
// 商祥 - 加入购物车
export const addCart = (params) => post('/mallv2/cart/add_v3', params)
// 商祥 - 秒杀下单获取token
export const doSeckill = (params) => post('/mallv2/activity/seckill_order/do_seckill', params)
// 商祥 - 秒杀下单token-结果获取
export const getSeckillTokenResult = (params) => post('/mallv2/activity/seckill_order/get_seckill_result', params)
// 商祥 - 校验商品是否发生变化
export const checkGoodStatus = (params) => post('/mallv2/cart/check_activity_status_v219', params)
// 商祥 - 用户地址列表
export const getUserAddressList = (params) => post('/mallv2/user/user_address/query_user_address_list', params)
// 商祥 - 配送区域校验
export const checkArea = (params) => post('/mallv2/classify/user_center/check_distribution_area', params)
// 商祥 - 地址列表级联查询
export const queryAddress = (params) => post('/mallv2/classify/seller_center/mall_address/query_address_list', params)


// 首页预览
export const getHomeManageData = (params) => post('/mallv2/facade/non_commodity/query_manage_data', params)
// 首页 - 轮播图查询
// export const getHomeBannerList = (params) => post('/mallv2/facade/index/select_flow_banner', params)
// 首页 - 分类一级
export const getHomeCategoryMainList = (params) => post('/mallv2/classify/user_center/category/menu_list_tab', params)
// 首页 - 分类二级
export const getHomeCategorySubList = (params) => post('/mallv2/classify/user_center/category/tab_section', params)
// 首页 - Metro图
// export const getHomeMetroList = (params) => post('/mallv2/facade/index/select_metro', params)
// 首页 - 专题广告位
export const getHomeThemeAdList = (params) => post('/mallv2/facade/index/select_special_ad_v3', params)
// 首页 - 推荐
export const getHomeRecommendList = (params) => post('/mallv2/facade/index/select_home_recommend_v212', params)
// 首页 - 推荐翻页
export const getHomeNextRecommendList = (params) => post('/mallv2/facade/index/home_recommend/query_page_v212', params)
// 首页 - Floating
// export const getHomeFloatingInfo = (params) => post('/mallv2/facade/index/select_home_floating', params)

// 购物车 - 购物车数量
export const getCartNum = (params) => post('/mallv2/cart/cart_size', params)
// 购物车 - 购物车列表
export const getCartList = (params) => post('/mallv2/cart/shop_list', params)
// 购物车 - 购物车加载列表
export const getCartLoadList = (params) => post('/mallv2/cart/list_v3', params)
// 购物车 - 购物车商品合计
export const getCartSummaryList = (params) => post('/mallv2/cart/summary_v3', params)
// 购物车 - 从购物车移除商品
export const delCartList = (params) => post('/mallv2/cart/remove', params)
// 购物车 - 移至收藏夹
export const collectCartList = (params) => post('/mallv2/cart/batch_move_to_myFavorite', params)
// 购物车 - 修改购物车中的商品信息
export const updateCartInfo = (params) => post('/mallv2/cart/modify_v3', params)
// 购物车 - 店铺优惠券列表
export const getCartCouponList = (params) => post('/mallv2/coupon/discount_coupon_list', params)
// 购物车 - 秒杀校验_获取token
export const checkSeckillCartList = (params) => post('/mallv2/activity/seckill_order/do_seckill', params)
// 购物车 - 秒杀校验_token结果获取
export const checkSeckillResultCartList = (params) => post('/mallv2/activity/seckill_order/get_seckill_result', params)
// 购物车 - 推荐商品--关联商品查询
export const getRecommendGoodList = (params) => post('/mallv2/search/recommend_associate', params)
// 购物车 - 推荐商品--不关联商品查询
export const getRecommendList = (params) => post('/mallv2/search/recommend_un_associate', params)

// 注册
export const regist = (params) => post('/mallv2/user/regist/pc_add', params)
// 个人中心 - 收藏列表
export const getFavoriteList = (params) => post('/mallv2/user/my_favorite/list', params)
// 个人中心 - 取消收藏
export const deleteFavorite = (params) => post('/mallv2/user/my_favorite/remove', params)
// 个人中心-地址列表
export const getAddressList = (params) => post('/mallv2/user/user_address/query_user_address_list_page', params)
// 个人中心-删除地址
export const deleteAddress = (params) => post('/mallv2/user/user_address/delete_user_address', params)
// 个人中心-设置地址获取用户信息
export const getUserDetail = (params) => post('/mallv2/user/user_address/get_user_address_detail', params)
// 个人中心-设置默认地址 
export const setAddress = (params) => post('mallv2/user/user_address/set_default', params)
// 个人中心-获取城市信息
export const getCityList = (params) => post('/mallv2/classify/seller_center/mall_address/query_address_list', params)
//个人中心-修改或新增收货地址
export const addOrUpdateAddress = (params) => post('/mallv2/user/user_address/save_or_update_user_address', params)
//个人中心-优惠券类型和数量
export const getCouponCount = (params) => post('/mallv2/user/coupon/count_coupon_type_and_status', params)
//个人中心-获取明珠卡数量
export const getCardCount = (params) => post('/mallv2/user/pearl_card/valid_list', params)
//个人中心-获取不可用明珠卡数量
export const getunusableCardCount = (params) => post('/mallv2/user/pearl_card/invalid_list', params)
//个人中心-获取明珠卡消费记录
export const getHistory = (params) => post('/mallv2/user/pearl_card/consume_history', params)
//个人中心-绑定明珠卡
export const bindCard = (params) => post('/mallv2/user/pearl_card/bind', params)
//个人中心-获取优惠券列表
export const getQueryCouponList = (params) => post('/mallv2/user/coupon/query_user_coupon_list', params)
//个人中心-订单数量
export const getOrderCount = (params) => post('/mallv2/user/functions_count/count', params)
//个人中心-订单列表
export const getNewOrder = (params) => post('/mallv2/order/user_center/query_order_list', params)
//个人中心-安装信息
export const getInstallInfo = (params) => post('/mallv2/order/query_installation', params)
//个人中心-删除订单
export const getDeletOrder = (params) => post('/mallv2/order/order_detail/delete_order', params)
//个人中心-确认收货
export const receiptOrder = (params) => post('/mallv2/order/order_detail/confirm_order_receive', params)
//个人中心-提醒收货
export const remindOrder = (params) => post('/mallv2/order/order_detail/inform_send_goods', params)
//个人中心-查询发票抬头
export const getInvoiceCenterList = (params) => post('/mallv2/user/invoice/query_invoice', params)
//发票中心-校验图形验证码-（通过后查纳税人识别号）
export const captchaCheck = (params) => post('/mallv2/order/invoice/captcha_check', params)
//个人中心-新增修改发票
export const editInvoiceCenter = (params) => post('/mallv2/user/invoice/save_update_invoice', params)
//个人中心-删除发票抬头
export const delInvoiceCenter = (params) => post('/mallv2/user/invoice/deleteInvoice', params)
//个人中心-查询公司纳税人识别号
export const queryTaxCode = (params) => post('/mallv2/order/invoice/query_tax_code', params)
//个人中心-个人信息
export const queryUserInfo = (params) => post('/mallv2/user/user_account/query_base_info', params)
// 个人信息修改接口
export const modifyUserInfo = (params) => post('/mallv2/user/user_account/edit_base_info', params)
// 个人中心修改绑定手机 
export const checkCaptchaPhone = (params) => post('/mallv2/user/usercenter/pc_change_mobile', params)
//个人中心-校验验证码
// export const getCaptcha = (params) => post('/mallv2/user/user_account/check_captcha', params)
export const getCaptcha = (params) => post('/mallv2/user/usercenter/pc_change_mobile/old_verify', params)
// 个人中心修改密码 
export const modifyUserPassword = (params) => post('/mallv2/user/usercenter/pc_update_pwd', params)
//订单-查询订单详情
export const queryOrderDetail = (params) => post('/mallv2/order/order_detail/query', params)
//订单-取消订单
export const cancelOrder = (params) => post('/mallv2/order/order_detail/cancel_order', params)
//订单-多个订单一起支付
export const discountInfo = (params) => post('/mallv2/order/order_detail/share_discount_info', params)
//订单-单个订单线下转账(对公转账)
export const offlinePay = (params) => post('/mallv2/order/query_offline_pay', params)
//订单-物流详情
export const queryTrackInfo = (params) => post('/mallv2/order/order_delivery/query_delivery_detail', params)
//订单-快递列表
export const queryDeliveryList = (params) => post('/mallv2/order/order_detail/query_logistics', params)

//确认订单 - 结算校验库存
export const checkInventory = (params) => post('mallv2/cart/settlement_check_inventory_v3', params)

//确认订单 - 生成确认订单
// export const getOrderInfo = (params) => post('/mallv2/order/generate_confirm_order', params)
export const getOrderInfo = (params) => post('/mallv2/order_check/order/confirm_order', params) // 订单重构新接口
//确认订单 - 配送区域校验
export const checkOrderAddress = (params) => post('/mallv2/order/check_distribution_area', params)
//确认订单 - 查询订单发票信息
export const getOrderInvoiceInfo = (params) => post('/mallv2/order/invoice/query_order_invoice', params)
//确认订单 - 查询最新发票信息
export const getNewInvoiceInfo = (params) => post('/mallv2/order/invoice/obtain_last_invoice_info', params)
//确认订单 - 修改换票申请换票
export const updateInvoice = (params) => post('/mallv2/order/invoice/change_invoice', params)
//确认订单 - 校验下单发票入参
export const checkInvoice = (params) => post('/mallv2/order/check_invoice_param', params)
//确认订单 - 提交订单
export const submitOrder = (params) => post('/mallv2/order_check/order/commit_order', params)

// 支付 - 扫码支付
export const getQrCodePayInfo = (params) => post('/mallv2/pay/gree/scan_qr_code', params)
// 支付 - 支付结果
export const getNotifyMessage = (params) => post('/mallv2/pay/record/notify_message', params)
// 支付 - 银企直连
export const queryAccountBookNo = (params) => post('/mallv2/pay/account/order/bind_account_book_no', params)
// 支付 - 金额查询
export const queryPayAmount = (params) => post('/mallv2/order/query_pay_amount', params)
// 帮助中心 - 一级列表
export const getHelpCenterList = (params) => post('/mallv2/eoc/help_center/one_page_list', params)
// 隐私政策
export const getPrivacyAgreementList = (params) => post('/mallv2/eoc/help_center/detail', params)
// 隐私声明 
export const getQueryPrivacyStatement = (params) => post('mallv2/eoc/help_center/query_privacy_statement',params)
// 历史隐私声明共用列表接口 
export const getHelpHistoryList = (params) => post('mallv2/eoc/help_center_history/list',params)
// 历史隐私声明共用详情接口 
export const getHistoryDetail = (params) => post('mallv2/eoc/help_center_history/detail',params)
// 用户协议
export const getUserPrivacyProtocol = () => post('mallv2/eoc/help_center/query_user_agreement')
// 搜索栏广告词
export const getSearchSlogans = (params) => post('/mallv2/search/slogans/list', params)
// 个人中心-删除优惠券
export const deleteCouponList = (params) => post('/mallv2/user/coupon/delete_user_coupon', params)
// PC微信登录
export const weChatAuthorization = (params) => post('/mallv2/login/verification_we_chat/pc_mall/authorization', params)
// pc商城-微信绑定已有账号通过账号密码
export const wechatBindByPsw = (params) => post('/mallv2/login/verification_we_chat/pc_mall/wechat_bind_by_psw', params)
// pc商城-微信绑定已有账号通过短信验证
export const wechatBindBySms = (params) => post('/mallv2/login/verification_we_chat/pc_mall/wechat_bind_by_sms', params)
// pc商城-注册新账号并绑定微信
export const wechatBindRegiste = (params) => post('/mallv2/login/verification_we_chat/pc_mall/wechat_bind_registe', params)
// 根据token自动登录
export const getUserInfoByToken = (params) => post('/mallv2/login/verification_account/get_user_info_by_token', params)
// 校验手机是否存在
export const checkMobileExist = (params) => post('/mallv2/user/forget/check_mobile_exist', params)

// 新增浏览
export const insertBrowHistory = (params) => post('/mallv2/user/brow_history/insert', params)
// 浏览列表
export const getBrowHistoryList = (params) => post('/mallv2/user/brow_history/contrast_list', params)
// 批量查询商品详情接口
export const commodityContrastList = (params) => post('/mallv2/classify/user_center/commodity_contrast_list', params)
// 查询商品状态
export const querySkuInfo = (params) => post('/mallv2/classify/user_center/query_sku_Info', params)

// 银联支付
export const unionPay = (params) => post('/mallv2/pay/gree/mobile/unionPay', params)

// 农行支付
export const lybankPay = (params) => post('/mallv2/pay/abcBank/payRequest', params)

// 农行优惠文案
export const getProgressPromote = (params) => post('/mallv2/pay/promotion/get_in_progress_promotion', params)

// 农行优惠文案
export const abcBankSwitch = (params) => post('/mallv2/pay/abcBank/switch', params)

