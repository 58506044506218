<template>
  <div class="layout-wrap">
    <div v-if="$slots.default" :class="['layout-title',className]">
      <slot/>
      <span class="text">{{isBegin?'即将结束':'即将开始'}}</span>
    </div>
    <div v-else class="layout-name">剩</div>
    <div class="layout-time">
      <span :class="['days',className]" v-show="timeObj.days">{{timeObj.days}}天</span>
      <span :class="['block',className]">{{timeObj.hours}}</span>
      <i :class="['colon',className]">时</i>
      <span :class="['block',className]">{{timeObj.minutes}}</span>
      <i :class="['colon',className]">分</i>
      <span :class="['block',className]">{{timeObj.seconds}}</span>
      <i :class="['colon',className]">秒</i>
    </div>
  </div>
</template>

<script>

import {formatTime} from '@/utils/common'

export default ({
  name: 'CustomCountDown',

  props: {
    // 时间戳
    time: {
      type: Number,
      required: true,
      default:0
    },

    // 倒计时结束触发函数
    finished:Function,

    // 活动是否开始 -默认为预告主题色
    isBegin:{
      type:Boolean,
      default:false
    }
    
  },
  data(){
    return {
      cacheTime:0,
      timer:null,
      timeNum:this.time,
      timeObj:{}  //初始化显示倒计时时间
    }
  },

  computed: {
    className(){
      return this.$slots.default?this.isBegin?'begin':'':'begin-no-default'
    }
  },

  mounted () {
    this.countDown();
  },

  methods: {
    countDown(){
      this.cacheTime = Date.now();
      this.getTime(this.timeNum);
    },

    getTime(time){
      this.timer && clearTimeout(this.timer);
      if (time < 0) {
        this.$emit('finished')
        return;
      }
      const data = formatTime(time)
      this.timeObj = {...data}
      this.timer = setTimeout(() => {
        const now = Date.now();
        const diffTime = Math.floor((now - this.cacheTime) / 1000);
        const step = diffTime > 1 ? diffTime : 1; // 有的浏览器页面退到后台的时候不会计时，对比时间差，大于1s的重置倒计时
        this.cacheTime = now;
        this.getTime(time - step * 1000);
      }, 1000);
    }
  },

  beforeDestroy () {
    clearTimeout(this.timer)
  }
})
</script>

<style  lang="less" scoped>

  .layout-wrap{
    display: flex;
    align-items: center;
  }

  .layout-title{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 105px;
    height: 24px;
    border-radius: 24px;
    font-size: 14px;
    margin-right: 8px;
    &.begin{
      color: #999999;
      background-color: #fff;
    }
    .text{
      margin-left: 3px;
      letter-spacing: 1px;
    }
  }

  .layout-name {
    padding-bottom: 4px;
    padding-right: 5px;
    font-size: 14px;
    line-height: 14px;
    color: #999999;
}

  .layout-time{
    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
    font-weight: 400;
  }

 .days{
    color: #999999;
    font-size: 14px;
    margin-right: 3px;
    // vertical-align: middle;
    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
    font-weight: 400;
    &.begin{
      color: #fff
    }
  }

  .block {
    display: inline-block;
    width: 34px;
    height: 20px;
    border-radius: 20px;
    line-height: 20px;
    font-size: 14px;
    text-align: center;
    &.begin{
      color: #999999;
      background-color: #fff;
    }
    &.begin-no-default{
      width: 20px;
      color: #999999;
      font-size: 14px;
      border-radius: 0px;
    }
  }

  .colon {
    display: inline-block;
    color: #999999;
    margin: 0 4px;
    &.begin{
      color: #fff;
    }
    &.begin-no-default{
     color: #999999;
    }
  }

</style>
