import { JSEncrypt } from 'jsencrypt'
import config from '@/config'

const dayjs = require('dayjs')


const publicKey = 
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCDSsM7GPsZzpWg8mX3St6inIUg3dYk9xQCxXm3OrmT8oIZDDDFNqxOcQFHw+G2y9oXY77csOV9ZmjIPCtjGcr3ax0qyQfqltplYmmq4MgxejU4nS9cGeOHMV3Z9ubhmQnLxhIzcidWwFWMLAp1AwjUD72LYaG+YSkV/qwXl9uDIQIDAQAB'

// ras加密，登录密码加密方式
export const rasEncrypt = (input) => {
  const encrypt = new JSEncrypt()
  encrypt.setPublicKey(publicKey)
  const password = encrypt.encrypt(input)
  return `LOGINPASSWORD${encodeURIComponent(password)}`
}


// 对接网易网盾
export const checkLoginConfig = (param) => {
  const { afterCheck, init } = param
  /* eslint-disable no-undef */
  return initNECaptcha(
    {
      element: '#captcha',
      captchaId: 'eb3a774eced3448aa16996fe1c3ed20e',
      mode: 'popup',
      width: '320px',
      enableClose: true, // 由业务方控制验证码弹框关闭
      // feedbackEnable: false, // 业务方关闭反馈入口
      onClose: () => {
        // 弹出关闭结束后将会触发该函数
      },
      onVerify: (err, val) => {
        if (!err) {
          afterCheck(val)
        }
      },
    },
    (instance) => {
      // 初始化成功后得到验证实例instance，可以调用实例的方法
      init(instance)
    },
    (err) => {
      console.log(err)
      // 初始化失败后触发该函数，err对象描述当前错误信息
    }
  )
}

// 临时把 CDN 替换成源站方案
export const preFixUrl = (url) => {
  let reg = new RegExp('gelimalloss.gree.com', "g");
  let reg1 = new RegExp('gelimall.oss-cn-shenzhen.aliyuncs.com', "g");
  url = url?.replace(reg, 'malloss.gree.com')
	url = url?.replace(reg1, 'malloss.gree.com')
  return url || ''
}

// 图片路径补全
export const fillImgPath = (url) => {
  if (!url) return ''
  url = url?.replace('gelimalloss.gree.com', 'malloss.gree.com')
	url = url?.replace('gelimall.oss-cn-shenzhen.aliyuncs.com', 'malloss.gree.com')
  if (['gelimalloss.gree.com', 'gelimall.oss-cn-shenzhen.aliyuncs.com'].includes(url)) {
    return preFixUrl(url) || ''
  }
  return url.includes('http') ? url : config.PIC_URL + url
}

//时间戳格式化 天-时-分-秒
export const formatTime = (time) => {
  const days = parseInt(time / (1000 * 60 * 60 * 24));
  const hours = parseInt((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = parseInt((time % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = (time % (1000 * 60)) / 1000;
  const obj = {
    days,
    hours:String(hours).padStart(2,'0'),
    minutes:String(minutes).padStart(2,'0'),
    seconds:String(seconds).padStart(2,'0')
  }
  return obj
}

// 日期格式化-天 YYYY.MM.DD
export const formatDateDay = (val) => dayjs(val).format('YYYY-MM-DD')

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

/**
 * URL参数替换不跳转
 * @param name 参数名
 * @param value 参数值
*/
export const changeURLStatic = (name, value) => {
  const { href } = window.location
  const reg = new RegExp(`([?|&]${name}=)[^&]*`)
  let url = ''
  if (!value) return
  if (href.match(reg)) {
    url = href.replace(reg, `$1${value}`); // 正则替换
    window.history.replaceState(Object.assign({}, history.state, {
      current: history.state?.current?.replace(reg, `$1${value}`)
    }), null, url) // 替换地址
  }
}

/**
 * 防抖函数
 * @param fn 执行函数
 * @param time 间隔时间
*/
export const debounce = (fn, time) => {
  let timer = null
  return function (...args) {
    if(timer) clearTimeout(timer)
    timer = setTimeout(() => {
      this[fn](args)
    }, time);
  }
}