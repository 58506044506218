<template>
  <div>
    <div :class="['card', isGray?'card-gray':'']">
      <div class="info">
        <img :src="isGray?require('../assets/imgs/discounts/gree-logo-card-gray.png'):require('../assets/imgs/discounts/logo 1@2x.png')" alt="">
        <div class="txt">
          <div class="detail">
            <div>{{ 'NO.' + info.cardId }}</div>
            <div class="money">面额：{{ info.denomination }}元</div>
          </div>
          <div>{{ info.startTime + '~' + info.endTime }}</div>
        </div>
      </div>
      <div class="left-money">
        <div class="txt">
          余额：¥<span :class="cardId === info.cardId?'':'txt-price'">{{ info.balance }}</span>
        </div>
        <div v-if="cardId === info.cardId" class="money-input">
          本次使用¥
          <Input @click.stop.native="" @on-enter="moneyValidate" @on-blur="moneyValidate" @on-change="moneyChange" :value="money" size="large"  style="width: 102px" number class="card-input"/>
          <Button @click.stop="autoDiscount" type="primary" class="btn">最高抵扣</Button>
        </div>
      </div>
      <div class="rule">
        <span v-if="info.description">{{ info.description }}，</span>
        {{ info.couponUsingRange == 1 ? '全部商品可用' :'部分商品可用'}}
        <img @click.stop="openDialog" :src="require('../assets/imgs/discounts/card-rule-tip.png')" alt="">
      </div>
    </div>
    <confirm 
      v-model="isShowConfirm"
      @ok="isShowConfirm = false"
      title="卡说明"
      :des="info.couponExplain"
      leftText=""
      rightText="我知道了"
      :goodList="[]"
      >
    </confirm>
  </div>
</template>

<script>
import Confirm from './Confirm.vue'
export default {
  name: 'Card',
  props: {
    // 信息
    info: {
      type: Object,
      default:() => {}
    },
    // 是否置灰
    isGray: {
      type: Boolean,
      default: false
    },
    money: {
      type: String,
      default: ''
    },
    cardId: {
      type: String,
      default: ''
    }
  },
  components:{
    Confirm,
  },
  data() {
    return {
      isShowConfirm: false,
    }
  },
  methods: {
    openDialog() {
      if(this.info.couponExplain) {
        this.isShowConfirm = true
      }
    },
    moneyChange(event) {
      // 在数据变化后要执行的某个操作，而这个操作需要使用随数据改变而改变的DOM结构的时候，这个操作都应该放进Vue.nextTick()的回调函数中
      this.$nextTick(() => {
        event.target.value = event.target.value.replace(/[^\d.]/g, '')
      })
    },
    moneyValidate(event) {
      let newVal = Number(event.target.value)
      let money = Number(this.money)

      if(((!newVal) && newVal !== 0) || newVal === money) {
        // 输入的值不是数字或输入的值没变
        event.target.value = this.money
        return
      }
      if(newVal === 0) {
        this.$Message.info('输入数字必须大于0');
        event.target.value = this.money
        return
      }
      if(newVal > Number(this.info.currentMaxCardAmount)) {
        this.$Message.info('超过最高抵扣金额，已自动帮您调整');
        event.target.value = this.info.currentMaxCardAmount
      }
      this.$emit('changeCardMoney', event.target.value)
    },
    autoDiscount() {
      if(Number(this.money) === Number(this.info.currentMaxCardAmount)) {
        this.$Message.info('已经是最高抵扣金额了');
        return
      }
      this.$emit('changeCardMoney', this.info.currentMaxCardAmount)
    },
  }
}
</script>

<style lang="less" scoped>
.card {
  width: 368px;
  height: 208px;
  padding: 16px 16px 16px 24px;
  background:url('~@/assets/imgs/discounts/card-red.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
  .info {
    width: 100%;
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 26px;
    }
    .txt {
      margin-left: 8px;
      flex: 1;
      font-size: 14px;
      line-height: 18px;
      .detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .money {
          font-size: 14px;
        }
      }
    }
  }
  .left-money {
    .txt {
      line-height: 20px;
      font-size: 14px;
      text-align: center;
    }
    .txt-price {
      line-height: 30px;
      font-size: 30px;
    }
    .money-input {
      font-size: 16px;
      .btn {
        margin-left: 8px;
        width: 72px;
        height: 24px;
        background:url('~@/assets/imgs/discounts/card-btn-bg.png');
        background-size: 100% 100%;
        padding: 0;
        font-size: 14px;
        color: #CC0000;
        box-shadow: none;
        border: none;
      }
    }
  }
  .rule {
    font-size: 12px;
    img {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  }
}
.card-gray {
  background:url('~@/assets/imgs/discounts/card-gray.png');
  background-size: 100% 100%;
}
</style>

<style lang="less">
.card {
  .left-money {
    .money-input {
      .card-input {
        input {
          background-color: transparent;
          border-top: none;
          border-left: none;
          border-right: none;
          border-radius: 0;
          font-size: 32px;
          color: white;
        }
      }
    }
  }
}
</style>