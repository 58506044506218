<template>
  <Breadcrumb>
    <BreadcrumbItem to="/">首页</BreadcrumbItem>
    <BreadcrumbItem to="/user/userInfo">个人中心</BreadcrumbItem>
    <BreadcrumbItem>确认订单</BreadcrumbItem>
  </Breadcrumb>
</template>

<script>
export default {};
</script>

<style>
</style>