<template>
  <div class="user-property">
    <Tabs @on-click="changeTabs" value="coupon" :animated='false'>
      <TabPane :label="couponTitle" name="coupon">
        <div class="tabs">
          <!-- <Select @on-change="handleChange" v-model="userStatus" style="width:144px;">
            <Option v-for="item in statuList" :value="item.label" :key="item.value">{{ item.value }}（{{item.number}}）</Option>
          </Select>
          <Select @on-change="handleType"  v-model="activeType" style="width:144px;margin-left:32px;">
            <Option v-for="item in activeList" :value="item.label" :key="item.value">{{ item.value }}（{{item.number}}）</Option>
          </Select> -->
          <div class="left-wrapper">
            <span :class="{active: userStatus === 0 }" @click="handleChange(0)">未使用</span>
            <span :class="{active: userStatus === 1 }" @click="handleChange(1)">已使用</span>
            <span :class="{active: userStatus === 2 }" @click="handleChange(2)">已过期</span>
          </div>
          <div class="right-wrapper" v-if="userStatus === 2">
            <Checkbox
              :model-value="checkedAll"
              v-if="isCheckbox"
              @on-change="handleCheckAll">{{ checkedAll ? "取消全选": "全选"}}
            </Checkbox>
            <span class="del" v-if="isCheckbox" @click="delItem">删除</span>
            <button class="btn" @click="toggleCheck">{{isCheckbox ? "完成": "管理"}}</button>
          </div>
        </div>
        <div class="preferential-card">
          <CheckboxGroup v-model="checkboxs" @on-change="checkAllGroupChange">
          <div v-for="(child,index) in couponList" :key="index" class="item-conpon-wrapper">
            <div style="line-height: 35px;">{{child.couponGroupName}}</div>
            <ul>
                  <template v-for="(item,childIndex) in child.couponUserDataVOList">
                    <li class="preferential-ticket" :class="{'expired':userStatus ===2||userStatus ===1}" :key="index+'-'+childIndex">
                    <span v-if="item.couponUsingRange ===1" class="typeOfTag1" :class="{'typeOfTag':userStatus ===1||userStatus ===2}">平台通用</span>
                    <span v-if="item.couponUsingRange ===3" class="typeOfTag2" :class="{'typeOfTag':userStatus ===1||userStatus ===2}">部分品类</span>
                    <span v-if="item.couponUsingRange ===4 || item.couponUsingRange ===5" class="typeOfTag3" :class="{'typeOfTag':userStatus ===1||userStatus ===2}">部分商品</span>
                    <span v-if="item.couponUsingRange ===2" class="typeOfTag4" :class="{'typeOfTag':userStatus ===1||userStatus ===2}">店铺通用</span>
                    <div class="card">
                        <div v-if="item.couponType ===4 ||item.couponType ===1||item.couponType ===2" class="card-price">
                        <i class="card-price-mark">￥</i>
                        <i>{{item.couponAmount}}</i>
                        </div>
                        <div v-if="item.couponType ===3" class="card-price">
                        <i>{{item.meetPrice}}</i>
                        <i>折</i>
                        </div>
                        <div v-if="item.couponType ===4 ||item.couponType ===1||item.couponType ===2" class="card-rule">{{item.description}}</div>
                    </div>
                    <div class="card-describe">
                        {{item.couponName}}
                    </div>
                    <div class="active">
                        <div style=" position: relative;">
                        <span class="active-describe">{{item.endTimeDesc}}</span>
                        <span v-if="item.couponType === 3 && item.couponAmount !== '0'" class="active-describe">最高可抵扣{{item.couponAmount}}元</span>
                        <div class="active-time1" style="margin:6px 0; padding: 0">{{child.couponGroupName}}</div>
                        <div v-if="userStatus ===0 && item.unusedState !== 2" class="activeBtn" @click="draw(item, child.shopId ? child.shopId : false)">立即使用</div>
                        </div>
                    </div>
                    <div class="imgTags">
                        <!-- 未开始 -->
                        <img v-if="item.unusedState === 2" src="../../assets/imgs/discounts/Group 360@2x.png" alt="">
                        <!-- 已使用 -->
                        <img v-if="userStatus ===1" src="../../assets/imgs/discounts/Group 426@2x.png" alt="">
                        <!-- 已过期 -->
                        <img v-if="userStatus ===2" src="../../assets/imgs/discounts/Group 368@2x.png" alt="">
                    </div>
                    <Checkbox :label="item.couponCodes" v-if="isCheckbox" class="check-box"></Checkbox>
                    </li>
                  </template>
                  
              </ul>
          </div>
          <!-- <img src="../../assets/imgs/checked.png" alt=""> -->
          </CheckboxGroup>
          <div v-if="couponList.length ===0" class="no-userCoupon">
            <img src="../../assets/imgs/discounts/wuyouhuiquan@2x.png" alt="">
            <div class="userCoupon-desc">账户中无优惠券</div>
          </div>  
        </div>
      </TabPane>
      <!-- 明珠卡 -->
      <TabPane :label="cardTitle" name="card">
        <div class="pearl-tabs">
            <div class="card-tips">
              <Tooltip theme="light">
                <img src="../../assets/imgs/discounts/Group 316@2x.png" alt="">
                 <div slot="content">
                  <p>以下原因导致明珠卡不可用:</p>
                  <p>1、明珠卡不在有效期内;</p>
                  <p>2、结算商品中有部分不支持用于明珠卡</p>
                  <p>3、结算金额未满足使用门槛</p>
                 </div>
              </Tooltip>
            </div>
            <span style="margin-right:40px;"
              :class="{ 'actived-color': selected === 1 }"
              @click="changeCard(1)"
            >
              可用
            </span>
            <span
              :class="{ 'actived-color': selected === 2 }"
              @click="changeCard(2)"
            >
              不可用
            </span>
        </div>
        <div class="pearl-bind">
          <div class="bind-input">
            <Input v-model="tiedCard" :maxlength="12" placeholder="请输入明珠卡密码，可联系发卡人获取" style="width: 272px" @on-blur="blurTiedCard" :class="[wrongText ? 'input-wrong': 'input-normal']"/>
          </div>
          <span class="wrong-tips">{{wrongText}}</span>
          <span @click="getBindCard" class="bind-card">绑卡</span>
        </div>
        <div class="card-wrapper">
            <!-- 消费记录弹窗 -->
            <div v-show="showPop" class="showPop">
             <div class="popTop">
               <span>卡号：</span>
               <span style="margin-right:8px;">{{cardId}}</span>
               <img v-clipboard:copy="cardId"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError" src="../../assets/imgs/discounts/fuzhi1@2x.png" alt="">
               <span style="position: absolute;right:14px;" @click="closePop">
                  <img style="width:24px;height:24px;" src="../../assets/imgs/discounts/close@2x.png" alt="">
               </span>
             </div>
             <div v-if="historyTotal ===0" class="no-accises">
                 <img src="../../assets/imgs/discounts/xiaofei@2x.png" alt="">
                 <div class="accises-desc">这张卡还没有消费哦~</div>
             </div>
              <!-- 消费记录 -->
              <Scroll height='506'>
                <!-- <div class="history-list"> -->
                <ul style="min-height: 506px;" v-infinite-scroll="getHistoryList" infinite-scroll-disabled="busy" infinite-scroll-immediate-check="false">
                  <li class="shop-history" v-for="(item,index) in historyList" :key="index">
                    <span style="color: #666666;">订单：{{item.orderId}}</span>
                    <span style="color: #cc0000;">{{ item.changeType === 1 ? '-' : '+'
                    }}{{ item.balanceChange }} 元</span>
                    <span style="color: #666666;">余：{{item.afterBalance}} 元</span>
                    <span style="color: #666666;">{{ item.createTime | formatMin }}</span>
                  </li>
                </ul>
                <!-- </div> -->
              </Scroll>
            </div>
          <ul>
              <!-- 明珠卡 -->
            <li v-for="(item,index) in cardList" :key="index" class="card-item" :class="{'card-item2':selected === 2}">
              <span class="card-tag">
                <img v-if="item.status ===1" src="../../assets/imgs/discounts/yiyongwan@2x.png" alt="">
                <img v-else-if="item.status ===2" src="../../assets/imgs/discounts/yiguoqi@2x.png" alt="">
                <img v-else-if="item.status ===3" src="../../assets/imgs/discounts/weishengxiao@2x.png" alt="">
                <img v-else-if="item.status ===4" src="../../assets/imgs/discounts/yizhongzhi@2x.png" alt="">
              </span>
              <div class="top">
                <div class="top-left">
                  <img src="../../assets/imgs/discounts/logo 1@2x.png" alt="">
                  <div class="card-number">
                    <p>NO.{{item.cardId}}</p>
                    <p> {{ item.couponStartDate | formatTimes }}~{{
                        item.couponEndDate | formatTimes
                      }}
                    </p>
                  </div>
                </div>
                <span class="card-value">面额:{{item.couponAmount}}元</span>
              </div>
              <div class="card-content">
                <span>余额：</span>
                <span>¥</span>
                <span>{{item.balance}}</span>
              </div>
              <div class="consumeBtn"><span @click="showconsumedilog(item.cardId)" style="cursor: pointer;">消费记录 ></span></div>
              <div class="bottom-tip">
                <span>{{item.desc}}</span>
                <span style="line-height: initial;" v-show="item.couponUsingRange ===1">全部商品可用</span>
                <span style="line-height: initial;"  v-if="
                        item.couponUsingRange === 2 ||
                        item.couponUsingRange === 3 ||
                        item.couponUsingRange === 4 ||
                        item.couponUsingRange === 5
                      ">部分商品可用</span>
                <Tooltip max-width="200" placement="top" :content="item.couponExplain">
                  <span style="margin-left:4px;"><Icon type="ios-alert-outline" size="14" /></span>
                </Tooltip>
                <!-- <span style="margin-left:4px;"><Icon type="ios-alert-outline" size="14" /></span> -->
              </div>
            </li>
            
          </ul>
          <div v-if="cardList.length === 0" class="no-userCard">
            <img src="../../assets/imgs/discounts/Group 125@2x.png" alt="">
            <div class="userCard-desc">没有可使用明珠卡</div>
          </div>  
        </div>
      </TabPane>
    </Tabs>
     <Page @on-change="changePage" v-if="showPage" :total="cardTotal" page-size=4 />
  </div>
</template>

<script>
import{getCouponCount,getCardCount,getQueryCouponList,getunusableCardCount,getHistory,bindCard,deleteCouponList} from '@/api/api'
// let moment = require('moment')
import dayjs from 'dayjs'
export default {
  name:'MyCoupon',
  data(){
    return{
      tabsName:'',
      showPage:false,
      // 筛选条件
      availableType:0,
      // createType:'',
      couponTitle:'优惠券（0）',
      cardTitle:'明珠卡（0）',
      couponList:[],  //优惠券数量
      cardList:[],    //明珠卡数量
      statuList: [
        {
          value: '未使用',
          label: 0,
          number:0
        },
         {
          value: '已使用',
          label: 1,
          number:0
        },
        {
          value: '已过期',
          label: 2,
          number:0
        },
      ],
      userStatus: 0 ,
      activeList:[
        {
          value: '全部',
          label: 3,
          number:0
        },
        {
          value: '平台活动',
          label: 0,
          number:0
        },
        {
          value: '平台优惠',
          label: 1,
          number:0
        },
        {
          value: '店铺优惠',
          label: 2,
          number:0
        },
      ],
      // activeType:3,
    //   明珠卡数据源
    selected:1,
    tiedCard:'', 
    showPop:false,
    cardId:'',
    historyList:[],
    pageIndex:0,
    historyTotal:'',
    busy: false,
    pages:'',
    current:0,
    cardTotal:0,
    cardPageIndex:1,
    showTabs:true,
    isCheckbox: false,
    checkedAll: false,
    checkboxs: [], //复选框组
    wrongText: ''
    }
    
  },
  created() {
    this.getCouponNumbers()
    this.getCardNumber()
    this.getUserCouponList()
  },

  watch:{
    selected:{
      handler(val){
        //   debugger
        this.cardPageIndex = 1
        if(val ===1){
          this.getCardNumber()
        }else{
          this.getUnusableCardNumber()   
        }
      }
    }  
  },

 filters: {
    formatTimes(val) {
      return dayjs(val).format('YYYY.MM.DD')
    },
    formatMin(val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm')
    },
  },

  methods:{
    //   tabs切换
    changeTabs(name){
      this.tabsName = this.tabsName
      if(name === 'coupon') {
        this.showPage = false
      }else{
        this.showPage = true
        this.cardPageIndex = 1
        this.selected === 1 ? this.getCardNumber() : this.getUnusableCardNumber()   
      }
    },
    toggleCheck () {
      this.isCheckbox = !this.isCheckbox
      this.checkedAll = false
      this.checkboxs = []
    },
    checkAllGroupChange (e) {
      this.checkboxs = e
    },
    handleCheckAll () {
      this.checkedAll = !this.checkedAll
      if (this.checkedAll) {
        this.checkboxs = []
        this.couponList.forEach(item => {
           item.couponUserDataVOList.forEach(i => this.checkboxs.push(i.couponCodes))
        })
      } else {
         this.checkboxs = []
      }
    },
    // 删除
    delItem () {
      if (this.checkboxs.length) {
        let delList = []
        this.checkboxs.forEach(item => {
          if(item instanceof Array) {
            delList.push(...item)
          } else {
            delList.push(item)
          }
        })
      deleteCouponList({
        userId:this.$store.state.userInfo.userId.toString(),
        couponIds: delList
      }).then((res) =>{
        if (res.result === 0) {
          this.$Message.info("删除成功")
          this.getUserCouponList()
          this.isCheckbox = false
          this.checkboxs = []
          this.checkedAll = false
        }
      })
      } else {
        this.$Message.info("您还没有选择优惠券")
      }
    },
    changePage(page) {
      this.cardPageIndex = page
      if(this.selected ===1 ){
        this.getCardNumber()
      }else{
        this.getUnusableCardNumber() 
      }
    },
    //  优惠券类型和数量
    getCouponNumbers(){
      getCouponCount({
        userId:this.$store.state.userInfo.userId.toString()
      }).then((res) =>{
        this.couponTitle = '优惠券（'+res.data.totalTypeCount+'）'
        // 使用优惠券状态数量
        this.statuList[0].number = res.data.unusedCouponCount  //未使用数量
        this.statuList[1].number = res.data.usedCouponCount    //已使用数量
        this.statuList[2].number = res.data. expiredCouponCount  //已过期数量
        //  优惠券类型数量
        this.activeList[0].number = res.data.totalTypeCount  //全部类型数量
        this.activeList[1].number = res.data.platformActivityCount  //平台活动类型数量
        this.activeList[2].number = res.data.platformCouponCount  //平台优惠类型数量
        this.activeList[3].number = res.data.shopCouponCount  //店铺优惠类型数量
      })
    },
    //明珠卡绑定
    getBindCard() {
      if(!this.blurTiedCard()) return
      bindCard({
        cardPassword: this.tiedCard,
        userId: this.$store.state.userInfo.userId
      }).then((res) =>{
          if (res.result === 0) {
            let bindResult = res.data.bindResult
            switch (bindResult) {
              case 1:
                this.$Message.error('该卡密不存在请重新输入');
                break
              case 2:
                this.$Message.error('绑卡成功, 但该卡已过期或停用');
                this.selected = 2
                break
              case 3:
                this.$Message.error('该卡密已被使用');
                break
              case 4:
                this.$Message.success('绑卡成功')
                this.selected === 2 ?  this.selected = 1 : this.getCardNumber()
                break
              case 5:
                this.$Message.success('绑卡成功, 该卡需等到有效期才能使用')
                this.selected = 2
                break
            }
          } else {
            // this.$Message.success(res.message)
          }
      })
    },
     blurTiedCard () {
       let val = this.tiedCard
       const reg = /^[0-9a-zA-Z]{1,12}$/
       if (val === '') {
         this.wrongText = '请输入明珠卡密码'
         return false
       } else if (!reg.test(val)){
         this.wrongText = '请输入1-12位数字或字母'
         return false
       } else {
          this.wrongText =  ''
          return true
       }
     },
    //获取明珠卡消费记录
    getHistoryList(){
        this.pageIndex++;
        if(this.pages === this.current){
            return
        }
        this.busy = true;
      getHistory({
        cardId: this.cardId,
        pageIndex: this.pageIndex,
        pageSize: 10,
        userId: this.$store.state.userInfo.userId
      }).then(res =>{
        this.historyList = res.data.records
        this.historyTotal = res.data.total
        this.pages = res.data.pages
        this.current = res.data.current
        this.busy = false;
      })
    },
    onCopy() {
      this.$Message.success('复制成功');
    },
    onError() {
    this.$Message.error('复制失败');
    },

    // 获取可用明珠卡数量
    getCardNumber(){
      getCardCount({
        pageIndex: this.cardPageIndex,
        pageSize: 4,
        userId: this.$store.state.userInfo.userId
      }).then((res) =>{
        this.cardTitle = '明珠卡（'+res.data.total+'）'
        this.cardTotal = res.data.total
        this.cardList = res.data.records
      })
    },
    // 获取不可用明珠卡数量
    getUnusableCardNumber(){
      getunusableCardCount({
        pageIndex: this.cardPageIndex,
        pageSize: 4,
        userId: this.$store.state.userInfo.userId
      }).then((res) => {
        this.cardList = res.data.records 
        this.cardTotal = res.data.total
      })
    },

    //获取优惠券列表
    getUserCouponList(){
      getQueryCouponList({
        availableType: this.availableType,
        // createType:this.createType,
        userId: this.$store.state.userInfo.userId,
      }).then((res)=>{
        this.couponList = res.data;
      })
    },
    // 筛选条件
    handleChange(val) {
      this.userStatus = val
      this.availableType = this.userStatus
      this.getUserCouponList()
    },
    changeCard(val) {
      this.selected = val
    },
    // 打开消费记录弹窗
    showconsumedilog(val) {
      this.showPop = true
      this.cardId =  val
      this.getHistoryList()
    },
    // 关闭消费记录弹窗
    closePop() {
      this.showPop = false
      this.pages = ''
      this.current = 0
      this.pageIndex = 0
    },
    // 去使用
    draw (item, classShopId) {
      let data
      if (item.costAllocation === 1) {
        //平台分摊
        switch (item.couponUsingRange) {
          case 1:
            data = {
              costAllocation: 1,
              shopId: '2000001394',
              type: 'coupon',
              couponUsingRange: 1,
            }
            break
          case 2:
            data = {
              costAllocation: 1,
              shopId: item.usingRangeInfoVOs
                .map(id => id.couponUsingId)
                .join(','),
              type: 'coupon',
              couponUsingRange: 2,
            }
            break
          case 3:
            data = {
              costAllocation: 1,
              shopId: '2000001394',
              categoryId: item.usingRangeInfoVOs
                .map(id => id.couponUsingId)
                .join(','),
              type: 'coupon',
              couponUsingRange: 3,
            }
            break
          case 4:
            data = {
              costAllocation: 1,
              skuId: item.usingRangeInfoVOs
                .map(id => id.couponUsingId)
                .join(','),
              type: 'coupon',
              couponUsingRange: 4,
            }
            break
          case 5:
            data = {
              costAllocation: 1,
              type: 'coupon',
              couponUsingRange:5,
              shopId: '2000001394',
            }
            break
          default:
            break
        }
      } else if (item.costAllocation === 2) {
        //店铺分摊
        // let idList = item.usingRangeInfoVOs;
        switch (item.couponUsingRange) {
          case 2: // 店铺通用,shopId直接传该店铺id
            data = {
              costAllocation: 2, // 店铺通用
              shopId: classShopId,
              type: 'coupon',
              couponUsingRange: 2,
            }
            break
          case 3:
            data = {
              shopId: classShopId,
              costAllocation: 2,
              categoryId: item.usingRangeInfoVOs
                .map(id => id.couponUsingId)
                .join(','),
              type: 'coupon',
              couponUsingRange: 3,
            }
            break
          case 4:
            data = {
              shopId: classShopId,
              costAllocation: 2,
              skuId: item.usingRangeInfoVOs
                .map(id => id.couponUsingId)
                .join(','),
              type: 'coupon',
              couponUsingRange: 4,
            }
            break
          case 5:
            data = {
              costAllocation: 2,
              type: 'coupon',
              couponUsingRange:5,
              shopId: classShopId
            }
            break
          default:
            break
        }
      }
      sessionStorage.setItem('couponData', JSON.stringify(data))
      this.$router.push({
        path: '/coupon-goods-list',
        query: {
          shopId: '2000001394',
          type: 'coupon'
        },
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .user-property{
    width: 100%;
    height: 100%;
    background: #fff;
    padding-top: 24px;
    padding-left: 48px;
    padding-bottom: 16px;
    .tabs {
      position: relative;
      display: flex;
      justify-content: space-between;
      .left-wrapper {
        font-size: 16px;
        color:#333333;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
      }
      span {
        margin-right: 28px;
        cursor: pointer;
      }
      .active {
        color: #CC0000;
        padding-bottom:5px;
        border-bottom: 1px solid #CC0000;
      }
      .right-wrapper {
        position: absolute;
        top:0px;
        right:75px;
        .btn {
          width: 60px;
          height: 30px;
          background-color: #E00000;
          color: #FFFFFF;
          font-size: 14px;
          border-radius: 4px;
          margin-right: 65px;
          cursor: pointer;
        }
        .del {
          color: #E00000;
          margin: 0 30px 0 20px;
        }
        /deep/.ivu-checkbox-inner {
          display: none !important;
        }
      }
    }
    .preferential-card{
      margin-top: 32px;
      .item-conpon-wrapper {
        display: block;
        overflow: hidden;
      }
      .preferential-ticket{
        width: 192px;
        height: 264px;
        background-image: url('../../assets/imgs/discounts/Group 531.png');
        float: left;
        margin-right: 16px;
        margin-bottom: 30px;
        position: relative;
        span{
          width: 64px;
          height: 24px;
          font-size: 14px;
          font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
          font-weight: 400;
          text-align: CENTER;
          line-height: 24px;
          color: #ffffff;
        }
        .typeOfTag1{
          display: inline-block;
          background-image: url('../../assets/imgs/discounts/Group 485.png');
        }
        .typeOfTag2{
          display: inline-block;
          background-image: url('../../assets/imgs/discounts/Group 482.png');
        }
        .typeOfTag3{
          display: inline-block;
          background-image: url('../../assets/imgs/discounts/Group 496.png');
        }
        .typeOfTag4{
          display: inline-block;
          background-image: url('../../assets/imgs/discounts/Group 487.png');
          color: #f53d2f;
        }
        .typeOfTag{
           background-image: url('../../assets/imgs/discounts/Group 487.png');
           color: #adadad;
        }
        .card{
          margin-top: 5px;
          height: 47px;
          font-size: 18px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          font-weight: 400;
          color: #ffffff;
          line-height: 21px;
          .card-price{
            text-align: center;
            font-size: 30px;
            .card-price-mark {
              font-size: 18px;
              font-family: Source Han Sans CN;
            }
          }
          .card-rule{
            text-align: center;
            font-size: 16px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          }
        }
        .card-describe{
          width: 120px;
          height: 34px;
          margin: 0 auto;
          margin-top: 9px;
          font-size: 12px;
          font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: #ffffff;
          line-height: 17px;
          letter-spacing: 0px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .active{
          margin-top: 32px;
          margin-left: 8px;
          color: #000;
          .active-describe{
            display: inline-block;
            width: 100%;
            font-size: 12px;
            font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #444444;
          }
          .active-time1{
            font-size: 12px;
            font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #444444;
            padding-left: 5px;
          }
          .active-time2{
            font-size: 12px;
            font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
            font-weight: 400;
            text-align: center;
            color: #444444;
          }
        }
        .check-box {
          position: absolute;
          left: 50%;
          bottom: -25px;
          /deep/.ivu-checkbox+span {
            display: none;
          }
          /deep/.ivu-checkbox-inner {
            width: 20px;
            height: 20px;
            border-radius: 10px;
          }
          /deep/.ivu-checkbox-checked .ivu-checkbox-inner:after {
            width: 7px;
            height: 12px;
            color: #E00000;
          }
          /deep/.ivu-checkbox-checked .ivu-checkbox-inner {
            border-color: #E00000;
            // background-color: #FFF;
            background-color: #E00000;
          }
        }
      }
      .expired{
         background-image: url('../../assets/imgs/discounts/Group 511.png');
      }
      .activeBtn{
          width: 64px;
          height: 20px;
          opacity: 1;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          //   bottom: 8px;
          top: 85px;
          border: 1px solid #ff6e59;
          border-radius: 11px;
          font-size: 12px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: center;
          color: #ff6e59;
          line-height: 18px;
          cursor: pointer;
        }
        .activeBtn:hover{
            background: #FF6E59;
            color: #ffffff;
          }
       .imgTags{
         width: 48px;
         height: 48px;
         position: absolute;
         right: 8px;
         bottom: 8px;
         img{
          width: 100%;
          height: 100%;
         }
       }
    }
  }
//   明珠卡樣式
.pearl-tabs {
    background-color: #ffffff;
    font-size: 12px;
    font-family: PingFang SC Medium, PingFang SC Medium-Medium;
    font-weight: 500;
    color: #666;
    margin-bottom: 32px;
    position: relative;
    .card-tips{
      position: absolute;
      left: 205px;
      top: -8px;
      img{
        width: 16px;
        height: 16px;
      }
    }
    span{
      display: inline-block;
      width: 88px;
      height: 32px;
      opacity: 1;
      border: 1px solid #cc0000;
      border-radius: 3px;
      font-size: 16px;
      font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
      font-weight: 400;
      text-align: center;
      color: #cc0000;
      line-height: 32px;
      cursor: pointer;
    }
    .actived-color {
      width: 88px;
      height: 32px;
      font-size: 16px;
      font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
      font-weight: 400;
      text-align: center;
      line-height: 32px;
      color: #ffffff;
      opacity: 1;
      background: #cc0000;
      border-radius: 2px;
      box-shadow: 0px 2px 8px 0px rgba(204,0,0,0.20); 
      }
  }
  .pearl-bind{
    width: 100%;
    position: relative;
    .bind-input{
      display: inline-block;
      .input-wrong {
        border: 1px solid #cc0000;
        border-radius: 5px;
      }
      .input-normal {
         border: 1px solid #dcdee2;
         border-radius: 5px;
      }
     /deep/ .ivu-input {
        border: none;
      }
     /deep/ .ivu-input:focus {
       border-color: #cc0000;
       box-shadow: 0 0 0 1px #cc0000;
      }
    }
    .bind-card{
      width: 88px;
      height: 32px;
      display: inline-block;
      margin-left: 16px;
      font-size: 16px;
      font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
      font-weight: 400;
      text-align: center;
      line-height: 32px;
      color: #ffffff;
      opacity: 1;
      background: #cc0000;
      border-radius: 2px;
      box-shadow: 0px 2px 8px 0px rgba(204,0,0,0.20);
      cursor: pointer; 
    }
    .wrong-tips {
      position: absolute;
      top: 40px;
      left: 0;
      color: red;
    }
  }
  .card-item{
    width: 368px;
    height: 208px;
    float: left;
    margin-right: 44px;
    margin-bottom: 64px;
    opacity: 1;
    background-image: url('../../assets/imgs/discounts/Group 319@2x.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    .card-tag{
      display: inline-block;
      position: absolute;
      width: 128px;
      height: 128px;
      right: 0;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .top{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 15px;
      padding: 0 20px 0 24px;
      .top-left{
        display: flex;
        align-items: center;
        img{
          width: 24px;
          height: 26px;
          margin-right: 8px;
        }
        .card-number{
          >p:nth-child(1) {
            line-height: 17px;
          }
          >p:nth-child(2) {
            line-height: 20px;
          }
        }
        p{
         margin-top: 1px;
         font-size: 14px;
         font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
         font-weight: 400;
         text-align: LEFT;
         color: #ffffff;
        }

     }
     .card-value{
       font-size: 14px;
       font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
       font-weight: 400;
       text-align: LEFT;
       color: #ffffff;
       }
    }
    .card-content{
      display: flex;
      justify-content: center;
      margin: 28px 0;
      span{
        font-size: 32px;
        font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #ffffff;
      }
      :first-child{
        margin-right: 7px;
        font-size: 14px;
        align-self: center;
        margin-top: 5px;
      }
    }
    .consumeBtn{
      padding-right: 31px;
      font-size: 12px;
      font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
      font-weight: 400;
      text-align: right;
      color: #ffffff;
    //   cursor: pointer;
    //   line-height: 140px;
    }
    .bottom-tip{
      display: flex;
      margin-top: 14px;
      font-size: 12px;
      font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff; 
      padding: 0 31px 0 25px;
      :first-child{
        flex: 1;
        line-height: initial;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 1;
      } 
    }
    
    }
    .card-item2{
       background-image: url('../../assets/imgs/discounts/dibu@2x.png');
    }
    .card-wrapper {
      margin-top: 40px;
    }
     .showPop{
      width: 864px; 
      height: 560px;
      background: #ebebeb;
      position: absolute;
      z-index: 999;
    //   overflow: scroll;
      padding-bottom: 26px;
      .popTop{
          margin-top: 16px;
          margin-left: 8px;
          position: relative;
        span{
          font-size: 14px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #cc0000;
        }
        img{
          width: 16px;
          height: 16px;
          vertical-align: middle;
        }
      }
      .history-list{
        height:506px; 
        overflow-y: auto;
      }
      .no-accises{
        width: 160px;
        height: 185px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        img{
          width: 100%;
          height: 100%;
        }
        .accises-desc{
          font-size: 16px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #999999;
          letter-spacing: 0px;
        }
       }
       .shop-history{
         width: 836px;
         height: 48px;
         background: #ffffff;
         border: 1px solid rgba(229,229,229,0.80);
         border-radius: 11px;
         margin: 0 auto;
         margin-top: 24px;
         display: flex;
         justify-content: flex-start;
         align-items: center;
         padding:0 24px;
         span{
           font-size: 14px;
           font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
           font-weight: 400;
           text-align: LEFT;
        //    color: #666666;
           letter-spacing: 0px;
         }
         > span:nth-child(1){
          flex:4;
         }
         > span:nth-child(2){
          flex:3;
         }
         > span:nth-child(3){
          flex:3
         }
         > span:nth-child(4){
          flex:2;
          text-align: right;
         }
       }
    //    :last-child{
    //      margin-bottom: 20px;
    //    }
    }
    .no-userCard{
      margin-top: 105px;
      text-align: center;
      img{
        width: 200px;
        // height: 350px;
      }
      .userCard-desc{
        font-size: 18px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #999999;
      }
    }
    .no-userCoupon{
      margin-top: 105px;
      text-align: center;
      img{
        width: 200px;
        // height: 350px;
      }
      .userCoupon-desc{
        font-size: 18px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #999999;
      }
    }
    /deep/.ivu-tooltip-inner{
      width: 270px;
      min-width: 265px;
    }
    /deep/.ivu-page{
      text-align: center;
    }
    /deep/.ivu-tabs-tabpane{
      height:auto;
    }
    /deep/.ivu-tabs-nav .ivu-tabs-tab{
      font-size: 20px;
      font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
      margin-bottom: 19px;
      padding:0 16px;
    }
    /deep/.ivu-tabs-nav .ivu-tabs-tab-active{
      color: #cc0000 !important;
    }
    /deep/.ivu-tabs-nav .ivu-tabs-tab:hover{
      color: #cc0000;
    }
    /deep/.ivu-tabs-ink-bar{
      background-color: #cc0000;
    }
   /deep/ .ivu-checkbox-checked .ivu-checkbox-inner {
     background: url(../../assets/imgs/checked.png)  no-repeat;
     background-size: cover;
     background-color: transparent !important;
     border-color: transparent !important;
     z-index: 99;
   }
   /deep/.ivu-checkbox-inner:after {
     display: none;
   }
   /deep/.ivu-checkbox-focus {
     box-shadow: 0 0 0 0;
   }
</style>