<template>
  <Modal
    v-model="isShow"
    :title="form.id?'编辑地址':'新建地址'"
    :footer-hide="true"
    :closable="false"
    class-name="address-edit"
    width="700">
    <Form ref="dataForm" :model="form" :rules="rules" :label-width="93">
      <FormItem label="所在地区：" prop="address">
        <FormItem prop="provinceCode" style="display: inline-block;">
          <Select :value="form.provinceCode" @on-change="handleProvinceChange" label-in-value style="width:137px">
            <Option v-for="item in provinceList" :value="item.addressCode" :key="item.addressCode">{{ item.addressName }}</Option>
          </Select>
        </FormItem>
        <FormItem prop="cityCode" style="display: inline-block;">
          <Select :value="form.cityCode" @on-change="handleCityChange" label-in-value class="gap" style="width:137px">
            <Option v-for="item in cityList" :value="item.addressCode" :key="item.addressCode">{{ item.addressName }}</Option>
          </Select>
        </FormItem>
        <FormItem prop="countryCode" style="display: inline-block;">
          <Select :value="form.countryCode" @on-change="handleCountryChange" label-in-value class="gap" style="width:137px">
            <Option v-for="item in countryList" :value="item.addressCode" :key="item.addressCode">{{ item.addressName }}</Option>
          </Select>
        </FormItem>
        <FormItem prop="townCode" style="display: inline-block;">
          <Select :value="form.townCode" @on-change="handleTownChange" label-in-value class="gap" style="width:137px">
            <Option v-for="item in townList" :value="item.addressCode" :key="item.addressCode">{{ item.addressName }}</Option>
          </Select>
        </FormItem>
      </FormItem>
      <FormItem label="详细地址：" prop="detailAddress">
        <Input v-model="form.detailAddress" type="textarea" :autosize="{minRows: 2,maxRows: 5}" placeholder="详细地址，街道、门牌号等"></Input>
      </FormItem>  
      <FormItem label="收货人：" prop="contactPerson">
        <Input v-model="form.contactPerson" placeholder="" style="width: 169px"></Input>
      </FormItem>  
      <FormItem label="手机号：" prop="contactPhone">
        <Input v-model="form.contactPhone" :maxlength="11" placeholder="" style="width: 169px"></Input>
      </FormItem>  
      <FormItem label="地址标签：">
        <Input v-model="form.tagName" placeholder="" style="width: 169px"></Input>
        <div class="example">
          <span class="title">建议填写常用别名</span>
          <span @click="toggleTag(1)" :class="['item', form.tagId === 1?'item-active':'']">家</span>
          <span @click="toggleTag(2)" :class="['item', form.tagId === 2?'item-active':'']">公司</span>
          <span @click="toggleTag(3)" :class="['item', form.tagId === 3?'item-active':'']">学校</span>
        </div>
      </FormItem>
      <FormItem>
        <Checkbox v-model="form.isDefault">设为默认</Checkbox>
      </FormItem>
      <div class="submit">
        <Button @click="submit" type="primary" class="sure" size="large">确定</Button>
        <Button @click="isShow = false" type="dashed" class="cancle" style="margin-left: 24px" size="large">取消</Button>
      </div>
    </Form>
  </Modal>
</template>

<script>
import { mapState } from 'vuex'
import {
  queryAddress,
  getUserDetail,
  addOrUpdateAddress
} from '@/api/api'
export default {
  name: 'AddressEdit',
  props: {
    value: Boolean,
    id: String,
  },
  data() {
    const validateTrue = (rule, value, callback) => {
      callback();
    };
    const validateAddress = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请选择所在地区'));
      } else {
        callback();
      }
    };
    return {
      provinceList: [],
      cityList: [],
      countryList: [],
      townList: [],   
      form: {
        id: undefined,
        provinceCode: '',
        provinceName: '',
        cityCode: '',
        cityName: '',
        countryCode: '',
        countryName: '',
        townCode: '',
        townName: '',
        detailAddress: '',
        contactPerson: '',
        contactPhone: '',
        tagId: 4,
        tagName: '',
        isDefault: false,
      },
      rules: {
        address: [{ required: true, validator: validateTrue }],
        provinceCode: [{ validator: validateAddress, trigger: 'change' }],
        cityCode: [{ validator: validateAddress, trigger: 'change' }],
        countryCode: [{ validator: validateAddress, trigger: 'change' }],
        townCode: [{ validator: validateAddress, trigger: 'change' }],
        detailAddress: [{ required: true, message: '详细地址不能为空', trigger: 'blur' },],
        contactPerson: [{ required: true, message: '收货人不能为空', trigger: 'blur' }],
        contactPhone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { type: 'string', pattern: /^1[3456789]\d{9}$/, message: '请输入正确手机号码', trigger: 'blur' },
        ],
      }
    }
  },
  computed: {
    ...mapState([
      'userInfo',
    ]),
    isShow: {
      get: function() {
        return this.value;
      },
      set: function(val) {
        this.$emit('input', val);
      },
    }
  },
  watch: {
    value(newValue) {
      if(newValue) {
        if(this.id) {
          this.getData()
        }else {
          this.$refs['dataForm'].resetFields()
          this.form = {
            id: undefined,
            provinceCode: '',
            provinceName: '',
            cityCode: '',
            cityName: '',
            countryCode: '',
            countryName: '',
            townCode: '',
            townName: '',
            detailAddress: '',
            contactPerson: '',
            contactPhone: '',
            tagId: 4,
            tagName: '',
            isDefault: false,
          }
        }
      }
    },
    'form.provinceCode': {
      handler: function(newVal, oldVal){
        if(newVal === '') {
          this.cityList = []
        } else {
          this.getAddressData(newVal, 'cityList')
        }
      }
    },
    'form.cityCode': {
      handler: function(newVal, oldVal){
        if(newVal === '') {
          this.countryList = []
        } else {
          this.getAddressData(newVal, 'countryList')
        }
      }
    },
    'form.countryCode': {
      handler: function(newVal, oldVal){
        if(newVal === '') {
          this.townList = []
        } else {
          this.getAddressData(newVal, 'townList')
        }
      }
    },
  },
  created() {
    this.getAddressData(0, 'provinceList')
  },
  methods: {
    submit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          addOrUpdateAddress({
            ...this.form,
            address: this.form.provinceName + this.form.cityName + this.form.countryName + this.form.townName,
            fullAddress: this.form.provinceName + this.form.cityName + this.form.countryName + this.form.townName + this.form.detailAddress,
            isDefault: this.form.isDefault?1:2,
          }).then((res) => {
            if (res.result === 0) {
              this.$Message.success(this.form.id?'修改成功':'保存成功');
              this.isShow = false
              this.$emit('init')
            } else {
            }
          }).catch((err) => {
          })
        }
      })
    },
    toggleTag(type) {
      if(this.form.tagId === type) {
        this.form.tagId = 4
      } else {
        this.form.tagId = type
        this.form.tagName = ''
      }
    },
    handleTownChange(val) {
      if(val) {
        this.form.townCode = val.value
        this.form.townName = val.label
      }
    },
    handleCountryChange(val) {
      if(val) {
        this.form.townCode = ''
        this.form.countryCode = val.value
        this.form.countryName = val.label
      }
    },
    handleCityChange(val) {
      if(val) {
        this.form.townCode = ''
        this.form.countryCode = ''
        this.form.cityCode = val.value
        this.form.cityName = val.label
      }
    },
    handleProvinceChange(val) {
      if(val) {
        this.form.townCode = ''
        this.form.countryCode = ''
        this.form.cityCode = ''
        this.form.provinceCode = val.value
        this.form.provinceName = val.label
      }
    },
    getAddressData(code, dataKey) {
      queryAddress({
        parentCode: code,
      }).then((res) => {
        if (res.result === 0) {
          this[dataKey] = res.data;
        } else {
        }
      }).catch((err) => {
      })
    },
    getData() {
      getUserDetail({
        userId: this.userInfo.userId,
        id: this.id,
      }).then((res) => {
        if (res.result === 0) {
          this.$refs['dataForm'].resetFields()
          this.form = {
            id: this.id,
            provinceCode: res.data.provinceCode,
            provinceName: res.data.provinceName,
            cityCode: res.data.cityCode,
            cityName: res.data.cityName,
            countryCode: res.data.countryCode,
            countryName: res.data.countryName,
            townCode: res.data.townCode,
            townName: res.data.townName,
            detailAddress: res.data.detailAddress,
            contactPerson: res.data.contactPerson,
            contactPhone: res.data.contactPhone,
            tagId: Number(res.data.tagId),
            tagName: res.data.tagName,
            isDefault: res.data.isDefault === 1?true:false,
          }
        } else {
        }
      }).catch((err) => {
      })
    },
  }
}
</script>

<style lang="less" scoped>
.address-edit {
  .gap {
    margin-left: 9px;
  }
  .example {
    display: inline-block;
    margin-left: 15px;
    .title {
      margin-right: 11px;
      font-size: 14px;
      color: #999999;
    }
    .item {
      display: inline-block;
      margin-right: 14px;
      width: 48px;
      height: 22px;
      text-align: center;
      line-height: 20px;
      border: 1px solid #999999;
      border-radius: 11px;
      font-size: 12px;
      color: #333333;
      cursor: pointer;
    }
    .item-active {
      border: none;
      background-color: #0085FF;
      line-height: 22px;
      color: white;
    }
  }
  .submit {
    text-align: center;
    .sure {
      width: 132px;
      background-color: #CC0000;
      border: none;
      font-size: 18px;
      color: white;
    }
    .cancle {
      width: 132px;
      border: 1px solid #999999;
      font-size: 18px;
      color: #999999;
    }
  }
}
</style>