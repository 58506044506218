<template>
  <div class="user-favorite">
    <div class="favorite-main">
      <div v-if="favoriteList.length!==0" class="myFavorite">我的收藏({{page.total}})</div>
      <ul>
        <li class="favorite-list" v-for="(item,index) in favoriteList" :key="index">
          <div class="listContent-main">
            <div class="favorite-left">
              <img :src="$fillImgPath(item.thumbUrl)" alt="">
              <div class="favorite-state" v-if="!item.inStore || !item.downAndUpStatus || item.isSwiper">
                <p class="favorite-stateDesc" v-if="item.downAndUpStatus==false">已下架</p>
                <p class="favorite-stateDesc" v-else-if="item.inStore==false">无货</p>
              </div>
            </div>
            <div class="favorite-right">
              <div class="product-description">{{item.skuName}}</div>
              <div class="favorite-btn">
                <span @click="deleteCollection(item)" style="margin-right:16px">取消收藏</span>
                <span @click="getDetail(item)">查看商品</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div v-if="favoriteList.length===0" class="noCollection">
         <img src="@/assets/imgs/Favorite_@2x.png" alt="">
         <div class="noFavorite">您暂时没有收藏的商品哦~</div>
      </div>
      <Page v-if="favoriteList.length!==0" :total="page.total" :current="page.current" :page-size="page.size" prev-text="上一页" next-text="下一页" @on-change="handleChange" />
    </div>
  </div>
</template>

<script>
import{getFavoriteList,deleteFavorite} from '@/api/api'

export default {
  name: 'UserFavorite',
  data() {
    return{
     favoriteList:[],
     page:{
      current:1,
      size:10,
      total:0
      },
      ids:[]
    }
  },
  created() {
    this.getPersonCollectionList()
  },
  methods:{
    // 分页
    handleChange(page) {
      this.page.current = page
      this.getPersonCollectionList()
    },
    // 获取个人收藏列表
    getPersonCollectionList(){
      getFavoriteList({
      pageIndex: this.page.current,
      pageSize: this.page.size,
      userId: this.$store.state.userInfo.userId
    }).then((res) =>{
      this.page.total = res.data.total
      this.favoriteList = res.data.records
    })
    },
    // 取消收藏
    deleteCollection(val) {
      this.ids = []
      this.ids.push(val.id)
      deleteFavorite({
        ids:this.ids,
        userId:this.$store.state.userInfo.userId
      }).then((res) =>{
        if(res.result ===0) {
          this.$Message.success(res.message)
          this.getPersonCollectionList()
        }else{
           this.$Message.error({
             content: '操作失败'
           })
        }
      })
    },

    // 查看商品详情
    getDetail(item) {
      const route = {
        name:'GoodsDetail',
        query:{
          productNo: item.itemId,
          skuNo: item.skuId,
          shopId: item.storeId
        }
      }
      if(this.$route.name === 'GoodsDetail')
        this.$router.replace(route);
      else
        this.$router.push(route);
    }
  },
}
</script>

<style lang="less" scoped>
.user-favorite {
  width: 100%;
  height: 100%;
  background: #fff;
  .favorite-main{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 48px;
    margin: 0 auto;
    margin-bottom: 16px;
    .myFavorite{
      font-size: 20px;
      font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #cc0000;
      letter-spacing: 0px;
      padding:28px 0 27px 16px;
    }
    .favorite-list{
        width: 100%;
        height: 192px;
        padding-top: 24px;
        border-top: 1px solid #e1e1e1;
        border-bottom: 1px solid #e1e1e1;
        .listContent-main{
        width: 100%;
        height: 144px;
        // background: red;
        // display: flex;
        .favorite-left{
          width: 144px;
          height: 144px;
          float: left;
          position: relative;
          .favorite-state{
            width: 144px;
            height: 144px;
            opacity: 1;
            background: rgba(51,51,51,0.50);
            position: absolute;
            top:0;
            .favorite-stateDesc{
              width:100%;
              position: absolute;
              bottom:10px;
              text-align:center;
              font-size: 20px;
              font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
              font-weight: 400;
              color:#fff;
            }
          }
          img{
            height: 100%;
            width: 100%;
          }
        }
        .favorite-right{
          margin-left: 24px;
          float: left;
          .product-description{
            width: 560px;
            font-size: 18px;
            font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #333333;
            letter-spacing: 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
          .favorite-btn{
            margin-top: 76px;
            margin-left: 440px;
            span{
              width: 106px;
              height: 32px;
              display: inline-block;
              opacity: 1;
              border: 1px solid #666666;
              border-radius: 5px;
              font-size: 18px;
              font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
              font-weight: 400;
              text-align: CENTER;
              color: #666666;
              letter-spacing: 0px;
              line-height: 32px;
              cursor: pointer;
            }
            :hover{
              color: #FFFFFF;
              background: #CC0000;
              border: none;
              }
          }
        }
        }
      }
    .noCollection{
      // text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      img{
        width: 200px;
        height: 200px;
      }
      .noFavorite{
        // margin-top: 43px;.
        text-align: center;
        font-size: 18px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #999999;
      }
    }
      ul>li:first-child{
          border-top: 1.5px solid #e1e1e1;
        }
      ul>li:last-child{
        border-bottom: 1.5px solid #e1e1e1;
      }
  }
}
/deep/ .ivu-page{
  margin-top: 99px;
  text-align: center;
}
</style>