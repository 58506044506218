<template>
  <div class="order-detail">
    <div class="detail-title">
      <Breadcrumb>
        <BreadcrumbItem to="/home">首页</BreadcrumbItem>
        <BreadcrumbItem to="/user/newOrder">我的订单</BreadcrumbItem>
        <BreadcrumbItem>订单详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="detail-top">
      <div class="detail-status">
        <div class="detail-order">
          <span>订单号：</span>
          <span style="color: #999999">{{ orderId }}</span>
        </div>
        <!-- 待支付 -->
        <template v-if="orderStatus === 1">
          <div class="detail-pay">
            <span>待支付</span>
            <!-- 剩6天23时05分57秒 -->
            <span v-if="showTime">
              <DetailCountDown :time="countDownTime" @finished="countDownFinished">
              </DetailCountDown>
            </span>
          </div>
          <div class="detail-payment">
            <!-- 定金预售订单，待付款及待付尾款状态隐藏去支付按钮 -->
            <span class="payment-go-pay" v-if="
              !(orderItems[0].activityType === 110) &&
              !(orderItems[0].activityType === 30) &&
              [1].includes(orderStatus)
            " @click="goToPay()">去付款</span>
            <span
              v-if="(orderStatus === 1 && [40].includes(payType)) 
							&& (tradeIn === 0 || tradeIn === 1 && [1,3].includes(proprietary)) 
							&& !depositPaymentW(orderItems)
							|| (depositPaymentD(orderItems) && [40].includes(payType))"
              @click="showPayDialog"
              class="detail-handle"
              style="margin-top: 16px"
            >
              转账信息
            </span>
            <span v-if="orderStatus === 1 && orderItems[0].state === 1" @click="showOrderDialog(1)" class="detail-handle" style="margin-top: 16px">取消订单</span>
          </div>
        </template>
        <!-- 待发货 -->
        <div v-else-if="[2, 60].includes(orderStatus)" class="detail-deliver">
          <img src="../../assets/imgs/orderDetail/waitdeliver@2x.png" alt="">
          <span>待发货</span>
          <span @click="remindDelivery" v-if="(orderStatus === 2 ||
            orderStatus === 60) &&
            addressStatus == 20 &&
            isShowRemindSend({
              serverCurrentTime,
              orderTime,
            })
          ">提醒发货</span>
        </div>
        <!-- 待收货 -->
        <div v-else-if="orderStatus === 3" class="detail-receiving">
          <img src="../../assets/imgs/orderDetail/waitReceiving@2x.png" alt="" />
          <span>待收货</span>
          <span @click="confirmReceipt">确认收货</span>
        </div>
        <!-- 待评价 -->
        <div v-else-if="orderStatus === 4" class="detail-receiving">
          <img src="../../assets/imgs/orderDetail/waitappraise@2x.png" alt="" />
          <span>待评价</span>
          <span @click="codeDialog = true">评价</span>
        </div>
        <!-- 交易完成 -->
        <div v-else-if="orderStatus === 5" class="detail-deliver">
          <img src="../../assets/imgs/orderDetail/complete@2x.png" alt="" />
          <span>交易完成</span>
        </div>
        <!-- 交易关闭-超时/取消/退款 -->
        <div v-else class="detail-deliver">
          <img src="../../assets/imgs/orderDetail/failure@2x.png" alt="" />
          <span>交易关闭</span>
        </div>
      </div>
      <div class="detail-steps">
        <div v-if="showWarn" class="detail-desc">
          <img class="detail-warnImg" src="../../assets/imgs/orderDetail/warn@2x.png" alt="" />
          <span class="detail-warn">格力董明珠店不会以任何理由要求您点击任何网站链接进行退款操作。</span>
          <img class="warn-closeImg" @click="showWarn = false" src="../../assets/imgs/orderDetail/close@2x.png"
            alt="" />
        </div>
        <div class="detail-stepStatus">
          <Steps :current="
            (orderStatus == 3 || orderStatus == 4)
              ? orderStatus - 1
              : (orderStatus== 60 ? 2:orderStatus)
          ">
            <Step content="提交订单"></Step>
            <Step v-if="[1, 2, 3, 4, 5, 60].includes(orderStatus)" content="付款成功"></Step>
            <Step v-if="[1, 2, 3, 4, 5, 60].includes(orderStatus)" content="等待收货"></Step>
            <Step v-if="[1, 2, 3, 4, 5, 60].includes(orderStatus)" content="用户评价"></Step>
            <Step v-if="[1, 2, 3, 4, 5, 60].includes(orderStatus)" content="交易完成"></Step>
            <Step v-if="orderStatus >= 6" >
              <template slot="content">
                  <span style="white-space:pre-wrap">{{closeOrderText() || '用户取消'}}</span>
              </template>
            </Step>
          </Steps>
        </div>
      </div>
    </div>
    <div class="detail-content">
      <div class="detail-shop">{{ shopName }}</div>
      <div class="shop-title">
        <div><span>商品信息</span></div>
        <div>单价</div>
        <div>数量</div>
        <div>操作</div>
      </div>
      <ul>
        <li v-for="(item, index) in orderItems" :key="index" class="detail-procuct">
          <GoodsItem 
            :item="item"
            :orderStatus="orderStatus"
            :tradeIn="tradeIn" 
            :selfTake="selfTake"  
            @codeDialog="codeDialog = true"
            @showCodeDialog="showCodeDialog"
            @distributionDetail="distributionDetail"  
            @handleOpenInstallInfo="handleOpenInstallInfo"
          >
          </GoodsItem>
          
          <div v-if="item.orderItems && item.orderItems.length > 0" class="attachment-btn" @click="isShowAttach = !isShowAttach">
            <span class="name">附件商品明细及物流</span>
            <i :class="{ 'bottom-arrow': !isShowAttach }"></i>
          </div>
          <!-- 附件 -->
          <template v-if="isShowAttach">
            <div v-for="(item, index) in item.orderItems" :key="index">
              <GoodsItem 
                :item="item" 
                goodsType="attach"
                :tradeIn="tradeIn"
                :orderStatus="orderStatus"  
                :selfTake="selfTake"
                @codeDialog="codeDialog = true"
                @showCodeDialog="showCodeDialog"
                @distributionDetail="distributionDetail"  
                @handleOpenInstallInfo="handleOpenInstallInfo"
              >
              </GoodsItem>
            </div>
          </template>
        </li>
      </ul>
      <div v-if="showDistribution" class="distribution">
        <div class="distribution-title">
          <span v-if="deliveryInfo.deliveryCompany"
            >{{ deliveryInfo.deliveryCompany }}
            <span class="delivery-no">{{ deliveryInfo.deliveryNo }}</span></span
          >
          <span v-if="!deliveryInfo.deliveryCompany">暂无物流信息</span>
          <img
            @click="showDistribution = false"
            src="../../assets/imgs/orderDetail/close@2x.png"
            alt=""
          />
        </div>
        <div class="distribution-content">
          <Timeline pending>
            <TimelineItem color="red" v-for="(item, indexs) in deliveryMsgList" :key="indexs">
              <div class="distribution-circle" slot="dot"></div>
              <div class="distribution-status">
                <div class="delivery-time">{{ item.time }}</div>
                <span class="delivery-message">{{ item.message }}</span>
              </div>
            </TimelineItem>
          </Timeline>
          <div class="no-delivery" v-if="noDelivery">
            <img width="150" height="150" src="../../assets/imgs/newOrder/noOrder@2x.png" />
            <p>暂无物流消息</p>
          </div>
        </div>
      </div>
    </div>
    <div class="detail-footer">
      <div class="order-info" :class="[orderStatus === 1 ? 'payFooter' : '']">
        <div class="order-title">订单信息</div>
        <div class="order-desc">
          <div>
            <span>订单编号：</span>
            <span>{{ orderId }}</span>
            <span class="copyImg">
              <img v-clipboard:copy="orderId" v-clipboard:success="onCopy" v-clipboard:error="onError"
                src="../../assets/imgs/orderDetail/copy@2x.png" alt="" />
            </span>
          </div>
          <div>
            <span>下单时间：</span>
            <span>{{ orderTime }}</span>
          </div>
          <div v-if=" !selfTake && [3,4,5,60].includes(orderStatus)">
            <span>发货时间：</span>
            <span>{{sendOutTime}}</span>
          </div>
          <div v-if="proprietary == 1">
            <span>预约发货日期：</span>
            <span style="margin-left: 23px">{{ planDeliveryDate.split(' ')[0] }}</span>
          </div>
          <div v-if="([2, 3, 4, 5, 60].includes(orderStatus) || (orderStatus == 1 && orderItems[0].state == 14))">
            <span>支付方式：</span>
            <span>{{ payTypeFormat[payType] + (payTypeFormat[finalPayType]?`、${payTypeFormat[finalPayType]}`:'') }}</span>
          </div>
          <div v-if="([2, 3, 4, 5, 60].includes(orderStatus) || (orderStatus == 1 && orderItems[0].state == 14))">
            <span>付款时间：</span>
            <span>{{ payTime }}</span>
          </div>
          <div>
            <span>发票信息：</span>
            <span>{{ invoiceDesc }}</span>
            <span @click="codeDialog = true" v-if="orderStatus === 1 && proprietary === 1"
              class="detail-invoice">修改</span>
            <span @click="codeDialog = true" v-if="([2,3,4,60].includes(orderStatus) && proprietary === 1) || orderStatus === 5"
              class="detail-invoice">查看</span>
          </div>
          <div class="order-remarkInfo" style="display: flex">
            <span>订单备注：</span>
            <span @mouseenter="showOrderMark = true" @mouseleave="showOrderMark = false" class="order-remark">{{ remark
            }}</span>
            <div v-show="showOrderMark && remark && remark !== '无'" class="detail-orderRemark">
              {{ remark }}
            </div>
          </div>
        </div>
      </div>
      
      <div class="consignee-info" :class="[orderStatus === 1 ? 'payFooter' : '']">
        <div v-if="!selfTake" class="consignee-title">收货人信息</div>
        <div v-else class="consignee-title">门店信息</div>
        <div class="consignee-content">
          <div class="consignee-left">
            <span v-if="!selfTake">收货人：</span>
            <span v-if="!selfTake">地址：</span>
            <span v-if="selfTake">自提门店：</span>
            <span v-if="selfTake">门店地址：</span>
          </div>
          <div class="consignee-right" v-if="addressStatus != 10">
            <span>
              <span>{{ !selfTake === true ? name : selfTakeShopName }}</span>
              <!-- 部分发货、待收货、待评价、已完成、已关闭、已取消等订单状态，不展示修改按钮 -->
              <span v-if="[1, 2, 8].includes(orderStatus) && !selfTake" @click="showCodeDialog"
                style="margin-left:16px;color:#0085FF;cursor: pointer;">修改</span>
            </span>
            <span>{{ address }}</span>
          </div>
          <div class="consignee-right" v-if="[1, 2].includes(orderStatus) && addressStatus == 10">
            <span>
              <span></span>
              <span @click="showCodeDialog" style="margin-left: 16px; color: #0085ff; cursor: pointer">去设置</span>
            </span>
            <span></span>
          </div>
        </div>
        <div class="consignee-flex"><span>联系电话：</span><span v-if="addressStatus != 10">{{ encodeNum(phone, selfTake) }}</span></div>
      </div>
      <div class="pay-info" :class="[orderStatus === 1 ? 'payFooter' : '']">
        <div class="pay-title">订单合计</div>
        <div v-if="false" class="pay-title">支付信息</div>
        <div class="pay-content">
          <div class="pay-left">
            <span>商品总额：</span>
            <span>运费：</span>
            <span v-if="isShowCoupon(storeCouponAmount)">店铺优惠：</span>
            <span v-if="isShowCoupon(platformCouponAmount)">平台优惠：</span>
            <span v-if="isShowCoupon(recognitionCodeAmount)">优惠码：</span>
            <span v-if="isShowCoupon(cardAmount)">格力明珠卡（提货凭证）：</span>
            <span v-if="orderStatus==6 && orderItems[0].state == 7 || orderStatus == 18">需支付：</span>
            <span v-else>{{ paymentText(orderItems) }}</span>
          </div>
          <div class="pay-right">
            <span>{{ totalAmount }}{{ balanceDiscount ? `(尾款已优惠${balanceDiscount}元)` : '' }}</span>
            <span>+{{ freightAmount }}</span>
            <span v-if="isShowCoupon(storeCouponAmount)">-{{ storeCouponAmount }}</span>
            <span v-if="isShowCoupon(platformCouponAmount)">-{{ platformCouponAmount }}</span>
            <span v-if="isShowCoupon(recognitionCodeAmount)">-{{ recognitionCodeAmount }}</span>
            <span v-if="isShowCoupon(cardAmount)">-{{ cardAmount }}</span>
            <span style="color: #cc0000;">￥{{ payAmount }}</span>
          </div>
        </div>
      </div>
    </div>
     <!-- 确认收货弹窗 -->
     <Modal v-model="deleteDialog" :closable="false" width="500">
           <p slot="header" style="height:40px;color:#f60;text-align:center">
               <img src="../../assets/imgs/newOrder/Frame 348@2x.png" style="width:40px;height:40px;">
           </p>
           <div style="text-align:center; font-size: 18px; font-weight: 400;">
               <span >{{dialogMsg}}</span>
           </div>
           <div slot="footer">
              <span @click="cancel" class="cancel">再想想</span>
              <span @click="confirm()" class="confirm">确定</span>
           </div>
        </Modal>
    <!-- 二维码弹窗 -->
    <Modal v-model="codeDialog" :footer-hide="true" width="500">
      <div class="codeDialog">
        <img src="../../assets/imgs/newOrder/erweima.jpg" alt="" />
        <span>请打开微信扫一扫 进行下一步操作吧</span>
      </div>
    </Modal>
    <!-- 单个订单线下支付 -->
    <Modal v-model="offlinePayDialog" :footer-hide="true" width="600">
      <div class="offlinePayDialog">
        <div class="payment-instructions">
          尊敬的客户：您的订单应付金额为
          <span style="color: #cc0000">{{ paymentPrice }}</span>
          元，请在5天内转账至如下账户，并备注您的订单号：
          <span style="color: #cc0000">{{ parentOrderId }}</span>
        </div>
        <div class="payment-information">
          <div class="information-left">
            <span>开户行名称：</span>
            <span>收款账号：</span>
            <span>账户名：</span>
          </div>
          <div class="information-right">
            <span>{{ bankName }}</span>
            <span>{{ account }}</span>
            <span>{{ accountName }}</span>
          </div>
        </div>
        <div class="warm-tip">
          温馨提示：线下付款到账周期为3个工作日，请您合理安排付款时间。
        </div>
      </div>
    </Modal>
    <!-- 多订单转账 -->
    <Modal v-model="payDialog" :closable="false" width="600" footer-hide>
      <div class="multi-order-container">
        <div slot="header" class="payDialogTitle">
          <img class="pic" src="../../assets/imgs/newOrder/Frame 348@2x.png" style="width: 40px; height: 40px" />
          <span class="payDec">以下订单将一起{{ multiShopDialogType === 1 ? "取消" : "付款" }}</span>
          <span class="tips">同时提交生成的订单需要一起{{ multiShopDialogType === 1 ? "取消" : "付款" }}</span>
        </div>
        <div v-for="(item, index) in discountList" :key="index" class="payDialogContent">
          <div class="contentShop">
            <div class="shopName">{{ item.shopName }}</div>
            <div class="price-wrap">
              <span class="txt">共{{ item.quantity }}件，计</span>
              <span class="price">￥{{ item.payAmount }}</span>
            </div>
          </div>
          <div class="productItem">
            <img v-for="(img, idx) in item.products" :key="idx" :src="$fillImgPath(img.thumbUrl)" alt="" />
          </div>
        </div>
        <div class="footer">
          <span @click="cancelPay" class="multi-order-cancel">取消</span>
          <span @click="goPay" class="multi-order-confirm">
            {{ multiShopDialogType === 1 ? "确认" : "付款" }}
          </span>
        </div>
      </div>
    </Modal>
    <!-- 取消订单 -->
    <Modal v-model="cancelOrderDialog" :closable="false" width="500">
      <p slot="header" style="height: 40px; color: #f60; text-align: center">
        <img src="../../assets/imgs/newOrder/Frame 348@2x.png" style="width: 40px; height: 40px" />
      </p>
      <div style="text-align: center; font-size: 18px; font-weight: 400">
        <span>订单取消后将无法恢复，其中使用的优惠券和积分等将会返还。</span>
      </div>
      <div slot="footer">
        <span @click="cancelOrderBtn" class="cancel">再想想</span>
        <span @click="cancelOrderConfirm" class="confirm">确定</span>
      </div>
    </Modal>
    <!-- 安装信息 -->
    <Modal v-model="installInfoDialog" class="installInfo-popup" width="500">
      <div class="title">
        安装信息
      </div>
      <div class="item">
        <span>安装状态:</span>
        <span style="color:#EF2F2F;">{{ `${setInstallStatus || '-'}` }}</span>
      </div>
      <div class="item">
        <span>安装师傅:</span>
        <span>{{ `${installInfoData.workerName || '-'}` }}</span>
      </div>
      <div class="item">
        <span>预约安装时间:</span>
        <span>{{ `${installInfoData.appointmentStart || '-'}` }}</span>
      </div>
      <div class="item">
        <span>网点联系电话:</span>
        <span style="color:#EF2F2F;">{{ `${installInfoData.serviceMobile || '-'}` }}</span>
      </div>
      <div slot="footer" @click="installInfoDialog = false">
        <span class="cancel">知道了</span>
      </div>
    </Modal>
    <!-- 快递列表 -->
    <Modal v-model="deliveryDialog" class="delivery-list-popup" width="500" :footer-hide="true">
      <div class="title">
        快递列表
      </div>
      <ul>
        <li v-for="(item, index) in expressDeliveries" :key="index">
          <div
            class="item"
            @click="toExpressDeliveryDetail(item)"
          >
            <div>
              <div class="name c-ellipsis">
                {{ item.expressCompany }}:{{ item.expressNo }}
              </div>
            </div>
            <div>
              <div class="goods-img">
                <img :src="$fillImgPath(item.productImageUrl)"/>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </Modal>
  </div>
</template>

<script>
import {
  queryOrderDetail,
  receiptOrder,
  remindOrder,
  cancelOrder,
  queryAccountBookNo,
  queryTrackInfo,
  queryDeliveryList,
  discountInfo,
  getInstallInfo
} from "@/api/api";
import dayjs from "dayjs";
import DetailCountDown from "./DetailCountDown.vue";
import GoodsItem from './goodsItem.vue'
// 把格式化的日期格式变成毫秒
export function convertDateStringToNumber(str) {
  if (!str) {
    return Date.now();
  }
  return new Date(str.replace(/-/g, "/")).getTime();
}
export default {
  components: {
    DetailCountDown,
    GoodsItem
  },
  data() {
    return {
      countDownTime: 0,
      showWarn: true,
      showOrderMark: false,
      codeDialog: false,
      showTime: true, //倒计时展示
      showDistribution: false, // 查看物流物流状态(已揽收/运输中/派件中/已签收)
      noDelivery: true,
      deliveryMsgList: [{}, {}],
      deliveryInfo: {},
      subOrderCount: 0, // 判断是否有多个订单状态
      parentOrderId: "", // 父订单ID
      offlinePayDialog: false,  //  线下支付转账
      payDialog: false,  //多个订单线下支付
      cancelOrderDialog: false,  //取消订单
      orderId: '', //订单ID
      orderStatus: 0, //订单(步骤条)状态
      shopName: '',  //店铺名称
      orderItems: [], // 产品
      orderTime: '', // 下单时间
      planDeliveryDate: '', // 预约送货时间
      immediateDelivery: '立即发货',
      proprietary: 0, // 店铺类型
      remark: '', // 订单备注
      selfTake: false, // 是否自提
      name: '', // 收货人
      address: '', // 收货地址/自提
      phone: '',
      selfTakeShopName: '', // 自提门店
      selfTakeShopPhone: '', // 自提电话
      totalAmount: '', // 商品总额
      freightAmount: '', // 运费
      storeCouponAmount: '', // 店铺优惠
      platformCouponAmount: '', // 平台优惠
      recognitionCodeAmount: '', // 优惠码优惠
      invoiceDesc: '',//发票信息
      cardAmount: '',//明珠卡优惠金额
      payAmount: '', // 支付金额
      payTime: '', // 付款时间
      payType: '', // 支付方式
      finalPayType: "",
      paymentPrice: '', //线下支付转账金额
      bankName: '', //银行名称
      account: '', // 银行账号
      accountName: '', // 账户名
      deadLineDate: '',  //订单过期时间
      discountList: [],  //多个订单
      multiShopDialogType: 0, // 多店铺弹窗类型 1:取消订单 2:去付款 3:线下转账
      balanceDiscount: 0, //定金预售尾款优惠金额
      addressStatus: 0,
      twentyFour: 86400000, //24小时转换为毫秒数
      serverCurrentTime: '',
      orderTime: '',
      sendOutTime:'',//发货时间
      payTypeFormat: {
        10: '支付宝支付',
        20: '微信支付',
        30: '银联支付',
        40: '线下支付',
        50: '银联卡支付',
        60: '花呗分期',
        70: '农行支付',
        80: '信用卡分期',
        100: '云闪付'
      },
      deleteDialog:false,
      dialogMsg:'',
      installInfoDialog: false,
      installInfoData: {},
      deliveryDialog: false,
      expressDeliveries: {},
      isShowAttach: false,
    }
  },
  computed: {
    isShowRemindSend() {
      return function isShowRemindSend(detailData) {
        // 判断提醒发货展示时间下单24小时后
        return this.isShowRemindDeliveryBtn(
          detailData.serverCurrentTime,
          detailData.orderTime
        );
      };
    },
    setInstallStatus() {
      // 状态(500-服务人员接单,501-服务人员预约,600-已安装,550-作废)
			const message = {
				500: '服务人员接单',
				501: '服务人员预约',
				550: '作废',
				600: '已安装'
			}
			return message[this.installInfoData.status]
    },
    // 订金预购付尾款阶段
    depositPaymentW() {
      return (orderItems) => {
				return orderItems[0]?.activityType === 30 && orderItems[0].state === 14
			}
    },
    // 订金预购付订金阶段
		depositPaymentD() {
			return (orderItems) => {
				return orderItems[0]?.activityType === 30 && orderItems[0].state === 1
			}
		},
  },
  created() {
    this.getOrderDetail();
    // this.getCountDownTimeFn()
  },
  methods: {
    //判断交易关闭状态下的显示文案
    closeOrderText(){
      let text = ''
      if(this.orderItems.length){
         if(this.orderStatus == 6 &&this.orderItems[0].state == 7){
          text = '用户取消'
         }else if( this.orderStatus ==7 && this.orderItems[0].state == 8){
          text = this.orderItems[0].afterSaleId ? '整单申请\n退货退款' : '超时未支付'
         }
      }
      return text
    },
    //判断价格是否显示
    isShowCoupon(str) {
      return parseFloat(str) > 0 && !isNaN(parseFloat(str))
    },
    //跳转商品详情
    toGoodsDetail(item) { // 商详页面
      this.$router.push({
        path: '/goodsDetail',
        query: {
          productNo: item.spuId,
          skuNo: item.skuId
        },
      })
    },
    encodeNum(num, selfTake) {
      return selfTake ? num : (num.substr(0, 3) + "****" + num.substr(7, 11));
    },
    // 显示提醒发货按钮
    isShowRemindDeliveryBtn(serveTime, orderTime) {
      const current = Date.parse(serveTime.replace(/-/gi, "/")); //返回时间戳
      const order = Date.parse(orderTime.replace(/-/gi, "/"));

      if (current - order > this.twentyFour) {
        return true;
      }
      return false;
    },
    // 倒计时完成
    countDownFinished() {
      console.log('22332')
      this.getOrderDetail();
      this.showTime = false;
    },

    showCodeDialog() {
      this.codeDialog = true;
    },
    //订单-单个订单线下转账(对公转账)
    getOfflinePay() {
      queryAccountBookNo({
        parentOrderId: this.parentOrderId,
      }).then((res) => {
        this.paymentPrice = res.data.paymentPrice;
        this.bankName = res.data.bankName;
        this.account = res.data.account;
        this.accountName = res.data.accountName;
      });
    },
    // 多个订单支付
    goPay() {
      const multiShopDialogType = this.multiShopDialogType
      if (multiShopDialogType === 1) {
        // 取消订单
        cancelOrder({
          parentOrderId: this.parentOrderId,
          orderId: this.orderId,
          userId: this.$store.state.userInfo.userId,
          reason: "不想买了",
        }).then((res) => {
          if (res.result === 0) {
            this.cancelOrderDialog = false;
            this.getOrderDetail();
          }
        });
        this.payDialog = false;
      } else if (multiShopDialogType === 3) {
        // 线下转账
        this.payDialog = false;
        this.getOfflinePay();
        this.offlinePayDialog = true;
      } else {
        // 去付款
        const query = {
          value: this.payAmount,
          orderId: this.orderId,
          parentOrderId: this.parentOrderId,
          exist: false,
          mutipOrder: 1,
        };
        this.$router.push({
          path: "/payOrder",
          query,
        });
      }
    },
    cancelPay() {
      this.payDialog = false;
    },
    //取消订单
    async showOrderDialog() {
      const resDiscount = await this.getDiscountInfo({ orderId: this.orderId });
      const {
        result,
        message,
        data: { records, exist },
      } = resDiscount;

      if (result === 0) {
        this.discountList = records || [];

        if (exist) {
          // 多个订单支付弹窗逻辑
          this.multiShopDialogType = 1
          this.payDialog = true;
        } else {
          this.cancelOrderDialog = true;
        }
      }
    },
    cancelOrderBtn() {
      this.cancelOrderDialog = false;
    },
    async cancelOrderConfirm() {
      // 以下订单一起取消
      cancelOrder({
        parentOrderId: this.parentOrderId,
        orderId: this.orderId,
        userId: this.$store.state.userInfo.userId,
        reason: "不想买了",
      }).then((res) => {
        if (res.result === 0) {
          this.cancelOrderDialog = false;
          this.getOrderDetail();
        }
      });
    },
    // 获取订单详情
    getOrderDetail() {
      queryOrderDetail({
        orderId: this.$route.query.orderId,
        userId: this.$store.state.userInfo.userId,
      }).then((res) => {
        const { data } = res
        if (res.result === 0) {
          this.orderId = data.orderId
          this.orderStatus = data.state
          this.shopName = data.shopName
          this.orderItems = data.orderItems
          this.orderTime = data.orderTime
          this.planDeliveryDate = data.planDeliveryDate
            ? data.planDeliveryDate
            : this.immediateDelivery
          this.proprietary = data.selfShop
          this.remark = data.remark || '无'
          this.selfTake = data.selfTake
          this.name = data.name
          this.address = data.address
          this.addressStatus = data.addressStatus
          this.phone = data.phone
          this.invoiceDesc = data.invoiceDesc
          this.selfTakeShopName = data.selfTakeShopName
          this.selfTakeShopPhone = data.selfTakeShopPhone
          this.totalAmount = data.totalAmount
          this.freightAmount = data.freightAmount
          this.storeCouponAmount = data.storeCouponAmount
          this.platformCouponAmount = data.platformCouponAmount
          this.recognitionCodeAmount = data.recognitionCodeAmount
          this.cardAmount = data.cardAmount
          this.sendOutTime = data.sendOutTime
          if (this.orderItems.length && this.orderItems[0].activityType == 30 && this.orderItems[0].state == 14) {
            this.payAmount = this.orderItems[0].finalAmount
          } else {
            this.payAmount = data.payAmount
          }
          this.payTime = data.payTime
          this.payType = data.payType
          this.finalPayType = data.finalPayType
          this.subOrderCount = data.subOrderCount
          this.parentOrderId = data.parentOrderId
          this.deadLineDate = data.deadLineDate
          this.serverCurrentTime = data.serverCurrentTime
          this.orderTime = data.orderTime
          // this.countDownTime = dayjs(data.deadLineDate).valueOf() -  dayjs(data.serverCurrentTime).valueOf()
          this.tradeIn = data.tradeIn
          this.countDownTime =
            convertDateStringToNumber(
              this.orderItems?.[0]?.state === 14 &&
                this.isShowPayFinal(data.payFinalPaymentStartTime)
                ? data.payFinalPaymentEndTime
                : data.deadLineDate
            ) - convertDateStringToNumber(data.serverCurrentTime);
          console.log("this.countDownTime", this.countDownTime);

          this.balanceDiscount =
            this.orderItems?.[0]?.depositPreSaleAmount || 0;
        }
      });
    },
    // //计算付尾款时间
    isShowPayFinal(payTime) {
      if (!payTime) {
        return false;
      } else {
        //尾款开始支付时间小于当前时间
        let payTimeStart = new Date(payTime).getTime();
        let nowTime = new Date().getTime();
        return payTimeStart < nowTime;
      }
    },
    queryTrackInfoFn(val) {
      const params = {
        orderItemId: val.orderItemId,
        orderId: this.$route.query.orderId,
        userId: this.$store.state.userInfo.userId
      }
      if (val.expressNo) {
        params.expressNo = val.expressNo
      }
      queryTrackInfo(params).then((res) => {
        if (res.result === 0) {
          this.deliveryMsgList = res.data?.deliveryMessage;
          this.deliveryInfo = res.data;
          if (this.deliveryMsgList) {
            this.noDelivery = !this.deliveryMsgList.length;
          }
        }
      });
    },
    // 物流详情
    distributionDetail(val) {
      let logisticsLength = val?.logistics?.length || 1
      if (logisticsLength > 1) {
         this.deliveryDialog = true
        queryDeliveryList({
          orderItemId: val.orderItemId
        }).then((res) => {
          const { result, data } = res
          if (result === 0) {
            let array = data.logistics?.map(item => {
              return {
                ...item,
                productImageUrl: data.orderItem.picUrl,
                productName: data.orderItem.skuName,
                orderItemId: data.orderItem.id
              }
            })
            this.expressDeliveries = [...array]
            console.log('expressDeliveries', this.expressDeliveries)
          }
        });
        return
      }

      this.showDistribution = true;
      this.queryTrackInfoFn(val)
    },
    getInstallInfoFn(item) {
      getInstallInfo({
        orderItemId: item.orderItemId
      }).then((res) => {
        const { result, data} = res;
				if (result == 0) {
					this.installInfoData = data
				}
      })
    },
    handleOpenInstallInfo(item) {
      this.getInstallInfoFn(item)
      this.installInfoDialog = true
    },
    // 确认收货
    confirmReceipt() {
      let flag = false
      this.orderItems.forEach(item=>{
        if(item.afterSaleId){
          if (![5, 6, 8, 14].includes(item.afterSaleState) && !(item.afterSaleType === 2 && item.afterSaleState === 4)) {
            flag = true
          }
        }
      })
      if(flag) {
        this.$Message.warning({
          content:'商品正在售后,暂不能收货'
        })
        return;
      }
      this.dialogMsg = '请确认已经收到所有商品。'
      this.deleteDialog = true  
    },
    cancel() {
      this.deleteDialog = false
    },
    confirm(){
        // 确认收货
      receiptOrder({
          orderId:this.orderId,
          userId:this.$store.state.userInfo.userId
        }).then((res) => {
          if(res.result === 0){
            this.getOrderDetail();
        }
      })
      this.deleteDialog = false
    },
    // 提醒收货
    remindDelivery() {
      remindOrder({
        userId: this.$store.state.userInfo.userId,
        orderId: this.orderId,
      }).then((res) => {
        if (res.result === 0) {
          this.$Message.success({
            content: '已发送消息通知商家发货'
          })
        }
      });
    },
    //  转账信息
    async showPayDialog() {
      const res = await this.getDiscountInfo({ orderId: this.orderId });
      if (res.result === 0) {
        if (res.data.exist) {
          this.discountList = res.data.records || []
          this.payDialog = true
          this.multiShopDialogType = 3
        } else {
          this.getOfflinePay()
          this.offlinePayDialog = true
        }
      }
    },
    // 订单状态校验
    async orderStatusCheck() {
      const params = {
        userId: this.$store.state.userInfo.userId,
        orderId: this.orderId,
      }
      const res = await queryOrderDetail({ ...params })
      try {
        if (res.result === 0) {
          if(res.data.state !==1){
            this.$Message.info({
              content:'订单状态已更新'
            })
            setTimeout(()=>{
              this.$router.go(0);
            },1000)
            return false
          }else if (res.data.state ==1 && res.data.orderItems[0]?.activityType !=30 && (this.payAmount !== res.data.payAmount)){
            this.$Message.info({
              content:'订单金额已更新，请注意！'
            })
            return true
          }else if (res.data.state ==1 && res.data.orderItems[0]?.activityType ==30 && (this.payAmount !== res.data.orderItems[0]?.finalAmount)) {
            this.$Message.info({
              content:'订单金额已更新，请注意！'
            })
            return true
          } else {
            return true
          }
          
        } else {
          this.$Message.info({
            content: res.message
          })
          return false
        }
      } catch (e) {
        console.error(e)
        return false
      }
    },
    // 去支付
    async goToPay() {
      // 判断当前主订单下的订单状态是否为待付款
      if (!(await this.orderStatusCheck())) {
        return;
      }

      const resDiscount = await this.getDiscountInfo({ orderId: this.orderId });
      const {
        result,
        message,
        data: { records, exist },
      } = resDiscount;

      if (result === 0) {
        this.discountList = records || [];

        if (exist) {
          // 多个订单支付弹窗逻辑
          this.multiShopDialogType = 2
          this.cancelOrderIng = false;
          this.payDialog = true;
        } else {
          const query = {
            value: this.payAmount,
            orderId: this.orderId,
            parentOrderId: this.parentOrderId,
            exist: false,
            mutipOrder: 1,
          };
          this.$router.push({
            path: "/payOrder",
            query,
          });
        }
      }
    },
    // 多个订单
    getDiscountInfo(item) {
      return discountInfo({
        orderId: item.orderId,
        userId: this.$store.state.userInfo.userId,
      });
    },

    onCopy() {
      this.$Message.success("复制成功");
    },
    onError() {
      this.$Message.error("复制失败");
    },
    paymentText(item) {
      let defaultText = '实付款：'
      const type = item[0]?.activityType
      const state = item[0]?.state
      const textType = {
        // 订金预购
        30: () => {
          if (state == 1) {
            return '需支付订金'
          }
          if (state == 14) {
            return '需支付尾款'
          }
          return defaultText
        }
      }
      if (!textType[type]) {
        return defaultText
      }
      return textType[type]()
    },
    toExpressDeliveryDetail(val) {
      this.deliveryDialog = false
      this.showDistribution = true;
      this.queryTrackInfoFn(val)
    }
  },
};
</script>

<style lang="less" scoped>
//   删除弹窗样式
/deep/.ivu-modal-header{
  border-bottom: none;
}
/deep/.ivu-modal-footer{
  border-top: none;
}
/deep/.ivu-modal-footer{
  text-align: center;
  span {
    display: inline-block;
    width: 168px;
    height: 45px;
    border: 1px solid #999999;
    border-radius: 5px;
    font-size: 18px;
    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
    font-weight: 400;
    text-align: CENTER;
    color: #999999;
    line-height: 45px;
    cursor: pointer;
  }
  .cancel{
    margin-right: 12px;
    color: #ffffff;
    background: #cc0000;
    border:none
  }
  .confirm{
    margin-left: 12px;
    &:hover{
      color: #ffffff;
      background: #cc0000;
      border: 1px solid #fff;
    }
  }
}
.order-detail {
  width: 1200px;
  height: 100%;
  position: relative;
  margin: 0 auto;
  background: #F2F2F2;
  padding-bottom: 56px;

  //  overflow: hidden;
  .detail-title {
    //    overflow: hidden;
    margin: 48px 0;
  }

  .detail-top {
    overflow: hidden;

    .detail-status {
      width: 288px;
      height: 281px;
      display: flex;
      flex-direction: column;
      float: left;
      background: #ffffff;

      .detail-order {
        margin-left: 24px;
        margin-top: 24px;

        span {
          font-size: 16px;
          font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #333333;
        }
      }

      .detail-pay {
        margin-top: 39px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        >:first-child {
          font-size: 24px;
          font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #cc0000;
        }

        >:last-child {
          margin-top: 8px;
          font-size: 14px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #999999;
        }
      }

      .detail-payment {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .payment-go-pay {
          width: 90px;
          height: 38px;
          opacity: 1;
          border: 1px solid #cc0000;
          border-radius: 5px;
          font-size: 14px;
          font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: #cc0000;
          line-height: 38px;
          cursor: pointer;

          &:hover {
            background-color: #cc0000;
            color: #fff;
          }
        }

        .detail-handle {
          font-size: 12px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: #0085ff;
          line-height: 14px;
          cursor: pointer;
        }
      }
    }

    .detail-steps {
      width: 896px;
      height: 281px;
      position: relative;
      float: left;
      margin-left: 16px;
      background: #ffffff;

      .detail-desc {
        width: 448px;
        height: 30px;
        margin: 24px 0 0 24px;
        opacity: 1;
        background: rgba(246, 94, 94, 0.10);
        border-radius: 4px;
        line-height: 30px;

        img {
          width: 14px;
          height: 14px;
          vertical-align: middle;
          text-align: center;
        }

        .detail-warnImg {
          margin: 0 8px 0 16px;
        }

        .warn-closeImg {
          margin-left: 8px;
          cursor: pointer;
        }

        .detail-warn {
          font-size: 12px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #333333;
        }
      }

      .detail-stepStatus {
        width: 100%;
        position: absolute;
        top: 50%;
        padding: 0 54px;
        transform: translatey(-50%);
      }
    }

    .detail-deliver {
      margin-top: 47px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 64px;
        height: 64px;
      }

      >span:nth-child(2) {
        margin-top: 8px;
        font-size: 24px;
        font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #cc0000;
      }

      >span:nth-child(3) {
        font-size: 14px;
        font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #0085ff;
        cursor: pointer;
        margin-top: 10px
      }
    }

    .detail-receiving {
      margin-top: 47px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 64px;
        height: 64px;
      }

      >:nth-child(2) {
        margin-top: 8px;
        font-size: 24px;
        font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #cc0000;
      }

      >:last-child {
        width: 104px;
        height: 38px;
        margin-top: 24px;
        opacity: 1;
        border: 1px solid #cc0000;
        border-radius: 5px;
        font-size: 14px;
        font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #cc0000;
        line-height: 38px;
        cursor: pointer;

        &:hover {
          background-color: #cc0000;
          color: #fff;
        }
      }

      .detail-handle {
        font-size: 12px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #0085ff;
        line-height: 14px;
        cursor: pointer;
      }
    }
  }

  .detail-steps {
    width: 896px;
    height: 281px;
    position: relative;
    float: left;
    margin-left: 16px;
    background: #ffffff;

    .detail-desc {
      width: 448px;
      height: 30px;
      margin: 24px 0 0 24px;
      opacity: 1;
      background: rgba(246, 94, 94, 0.1);
      border-radius: 4px;
      line-height: 30px;

      img {
        width: 14px;
        height: 14px;
        vertical-align: middle;
        text-align: center;
      }

      .detail-warnImg {
        margin: 0 8px 0 16px;
      }

      .warn-closeImg {
        margin-left: 8px;
        cursor: pointer;
      }

      .detail-warn {
        font-size: 12px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
      }
    }

    .detail-stepStatus {
      width: 100%;
      position: absolute;
      top: 50%;
      padding: 0 54px;
      transform: translatey(-50%);
    }
  }

  .detail-deliver {
    margin-top: 47px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 64px;
      height: 64px;
    }

    > :last-child {
      margin-top: 8px;
      font-size: 24px;
      font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #cc0000;
    }
  }

  .detail-receiving {
    margin-top: 47px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 64px;
      height: 64px;
    }

    > :nth-child(2) {
      margin-top: 8px;
      font-size: 24px;
      font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #cc0000;
    }

    > :last-child {
      width: 104px;
      height: 38px;
      margin-top: 24px;
      opacity: 1;
      border: 1px solid #cc0000;
      border-radius: 5px;
      font-size: 14px;
      font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
      font-weight: 400;
      text-align: CENTER;
      color: #cc0000;
      line-height: 38px;
      cursor: pointer;

      &:hover {
        background-color: #cc0000;
        color: #fff;
      }
    }
  }
}

.detail-content {
  width: 1200px;
  // overflow: hidden;
  display: inline-block;
  margin-top: 16px;
  background: #ffffff;
  position: relative;

  .distribution {
    width: 400px;
    // height: 438px;
    position: absolute;
    right: 200px;
    top: 100px;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);

    .no-delivery {
      padding-top: 84px;
      padding-bottom: 110px;
      text-align: center;

      img {
        width: 150px;
        height: 150px;
      }

      p {
        margin-top: 16px;
        text-align: center;
        font-size: 16px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        color: #333333;
      }
    }

    .distribution-title {
      width: 100%;
      height: 49px;
      border-bottom: 1px solid rgba(153, 153, 153, 0.2);
      text-align: center;

      span {
        line-height: 49px;
        font-size: 16px;
        color: #333333;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;

        .delivery-no {
          font-size: 12px;
        }
      }

      img {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }

    .distribution-content {
      min-height: 100px;
      padding-top: 10px;
      padding-left: 26px;
    }

    .ivu-timeline {
      .ivu-timeline-item {
        padding-bottom: 0px;
        color: #999;

        .ivu-timeline-item-content {
          .distribution-status {
            padding-bottom: 10px;
            border-bottom: 1px solid rgba(153, 153, 153, 0.2);
          }
        }

        .distribution-circle {
          text-align: center;
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #c2c2c2;
        }

        .delivery-time {
          margin-bottom: 6px;
        }

        .delivery-message {
          font-size: 12px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #999;
          line-height: 20px;
        }

        &:first-of-type {
          .distribution-circle {
            background: #cc0000;
            width: 10px;
            height: 10px;
          }

          .delivery-message {
            color: #333333;
          }

          .delivery-time {
            color: #cc0000;
          }
        }

        &:last-of-type {
          .ivu-timeline-item-content {
            .distribution-status {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }

  .detail-shop {
    margin: 24px 0 0 24px;
    font-size: 16px;
    font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #333333;
  }

  .shop-title {
    display: flex;
    padding: 16px 24px;

    div {
      flex:1;
      font-size: 14px;
      font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
      font-weight: 400;
      text-align: center;
      color: #333333;
    }

    > :nth-child(1) {
      flex:2;
      >span {
        margin-left: 100px;
      }

    }

    // > :nth-child(2) {
    //   background-color: green;

    // }

    // > :nth-child(3) {
    //   background-color: yellow;

    // }

    // > :nth-child(4) {
    //   margin-left: 192px;
    // }
  }

  .detail-procuct {
    padding: 16px 24px;
    border-top: 1px solid rgba(153, 153, 153, 0.2);
    .attachment-btn {
      margin: 12px auto 24px;
      width: 146px;
      padding-left: 8px;
      line-height: 26px;
      border: 1px solid #eaeaea;
      border-radius: 5px;
      
      cursor: pointer;
      .name {
        color: #333333;
        font-size: 12px;
        font-weight: 400;
      }
      
      i {
        display: inline-block;
        margin-left: 6px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6px 6px;
        border-color: transparent transparent #333;
        position: relative;
        &::after {
          content: '';
          border-style: solid;
          border-width: 0 6px 6px;
          border-color: transparent transparent #fff;
          position: absolute;
          top: 2px;
          left: -6px;
        }
      }
      .bottom-arrow {
        border-top: 6px solid #333;
        border-bottom: 0;
      }
      .bottom-arrow::after {
        border-top: 6px solid #fff;
        border-bottom: 0;
        margin-top: -9px;
      }
    }
  }
}

.detail-footer {
  display: flex;
  margin-top: 16px;

  .order-info {
    display: flex;
    flex-direction: column;
    width: 389px;
    height: 367px;
    float: left;
    opacity: 1;
    background: #ffffff;
    padding:24px;
    

    .order-title {
      font-size: 16px;
      font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
    }

    .order-desc {
      // display: flex;
      margin-top: 24px;

      div {
        display: flex;
        margin-top: 16px;

        > :first-child {
          font-size: 14px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #333333;
          line-height: 20px;
          white-space: nowrap;
        }

        > :nth-child(2) {
          font-size: 14px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #333333;
          line-height: 20px;
          margin-left: 52px;
        }
      }

      > :first-child {
        margin-top: 0px;
      }

      .order-remark {
        width: 219px;
        height: 60px;
        letter-spacing: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }

      .detail-invoice {
        min-width: 40px;
        margin-left: 10px;
        font-size: 14px;
        font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
        font-weight: 400;
        line-height: 20px;
        text-align: LEFT;
        color: #0085ff;
        cursor: pointer;
      }

      .copyImg {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-left: 8px;
        vertical-align: middle;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .order-remarkInfo {
        position: relative;
        .detail-orderRemark {
          position: absolute;
          left: 245px;
          top: 60px;
          width: 320px;
          opacity: 1;
          font-size: 14px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #333333;
          line-height: 20px;
          padding: 5px;
          border: 1px solid #ccc;
          background-color: #f9f9fa;
        }
      }
    }
  }

  .consignee-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 390px;
    height: 367px;
    padding-top: 24px;
    padding-left: 24px;
    float: left;
    margin: 0 16px;
    opacity: 1;
    background: #ffffff;

    .consignee-title {
      font-size: 16px;
      font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
    }

    .consignee-content {
      display: flex;
      margin-top: 24px;

      .consignee-left {
        display: flex;
        flex-direction: column;

        span {
          font-size: 14px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #333333;
          line-height: 20px;
          min-width: 80px;
        }
        >span:nth-child(2){
          margin: 16px 0;
        }

        .order-desc {
          // display: flex;
          margin-top: 24px;

          div {
            margin-top: 16px;

            >:first-child {
              font-size: 14px;
              font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #333333;
              line-height: 20px;
            }

            >:nth-child(2) {
              font-size: 14px;
              font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #333333;
              line-height: 20px;
              margin-left: 52px;
            }
          }

          >:first-child {
            margin-top: 0px;
          }

          .order-remark {
            width: 219px;
            height: 60px;
            letter-spacing: 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }

          .detail-invoice {
            margin-left: 16px;
            font-size: 14px;
            font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #0085ff;
            cursor: pointer;
          }

          .copyImg {
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-left: 8px;
            vertical-align: middle;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .consignee-right {
        display: flex;
        flex-direction: column;

        span {
          font-size: 14px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #333333;
          line-height: 20px;
        }

        > :nth-child(2) {
          margin: 16px 0;
          width: 248px;
        }
      }
    }

    .consignee-flex {
      display: flex;
      font-size: 14px;
      font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
      line-height: 20px;
      >span:nth-child(1){
        min-width: 80px;
      }
    }
  }

  .pay-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 389px;
    height: 367px;
    padding-top: 24px;
    padding-left: 24px;
    float: left;
    opacity: 1;
    background: #ffffff;

    .pay-title {
      font-size: 16px;
      font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
    }

    .pay-content {
      display: flex;

      .pay-left {
        display: flex;
        flex-direction: column;

        span {
          margin-top: 16px;
          font-size: 14px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #333333;
          line-height: 20px;
        }

        > :first-child {
          margin-top: 24px;

          .consignee-left {
            display: flex;
            flex-direction: column;

            span {
              font-size: 14px;
              font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #333333;
              line-height: 20px;
              min-width: 80px;
            }

            >:nth-child(2) {
              margin: 16px 0;
            }
          }

          .consignee-right {
            display: flex;
            flex-direction: column;

            span {
              font-size: 14px;
              font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #333333;
              line-height: 20px;
            }

            >:nth-child(2) {
              margin: 16px 0;
              width: 248px;
            }
          }
        }

        .consignee-flex {
          display: flex;

          >span:nth-child(1) {
            min-width: 80px;
          }

          span {
            font-size: 14px;
            font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #333333;
            line-height: 20px;
          }
        }
      }

      .pay-right {
        display: flex;
        flex-direction: column;

        span {
          margin-top: 16px;
          margin-left: 24px;
          font-size: 14px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #333333;
          line-height: 20px;
        }

        > :first-child {
          margin-top: 24px;
        }
      }
    }
  }

}

.codeDialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.offlinePayDialog {
  display: flex;
  flex-direction: column;

  .payment-instructions {
    width: 552px;
    height: 52px;
    margin-top: 20px;
    font-size: 18px;
    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
    font-weight: 400;
    text-align: CENTER;
    color: #333333;
    line-height: 26px;
  }

  .payment-information {
    display: flex;
    width: 552px;
    height: 112px;
    margin-top: 24px;
    padding: 16px 0 0 16px;
    opacity: 1;
    border: 1px solid rgba(153, 153, 153, 0.6);

    .information-left {
      display: flex;
      flex-direction: column;

      span {
        font-size: 14px;
        font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
      }

      > :nth-child(2) {
        margin: 10px 0;
      }
    }

    .information-right {
      display: flex;
      flex-direction: column;
      margin-left: 24px;

      span {
        font-size: 14px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
      }

      > :nth-child(2) {
        margin: 10px 0;
      }
    }
  }

  .warm-tip {
    margin-top: 8px;
    padding-bottom: 16px;
    font-size: 12px;
    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #999999;
  }
}

//  多个订单线下支付弹窗样式
.multi-order-container {
  padding: 24px 8px 32px;
  line-height: 1;

  .payDialogTitle {
    // height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #f60;
    text-align: center;
    .pic {
      width: 40px;
      height: 40px;
    }
    .payDec {
      margin-top: 20px;
      font-size: 18px;
      font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
      font-weight: 400;
      color: #333333;
    }
    .tips {
      font-size: 12px;
      font-family: FZLanTingHei-R-GBK, FZLanTingHei-R-GBK-Regular;
      font-weight: 400;
      color: #666666;
      margin-top: 6px;
      margin-bottom: 8px;
    }
  }

  .payDialogContent {
    // width: 552px;
    // height: 136px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin-bottom: 16px;
    border: 1px solid rgba(153, 153, 153, 0.6);

    .contentShop {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .shopName {
        font-size: 14px;
        font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
        text-align: LEFT;
        color: #333333;
      }

      .price-wrap {
        .txt {
          font-size: 14px;
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
          font-weight: 400;
          color: #333333;
        }
        .price {
          font-size: 14px;
          font-family: Source Han Sans CN, Source Han Sans CN-Bold;
          font-weight: 700;
          color: #cc0000;
        }
      }
    }

    .productItem {
      width: 70px;
      height: 70px;
      display: flex;
      margin-top: 16px;

      img {
        width: 70px;
        height: 70px;
        margin-left: 16px;
      }

      img:first-child {
        margin-left: 0;
      }
    }

  }

  .footer {
    display: flex;
    justify-content: center;
    padding-top: 32px;
    .multi-order-confirm,
    .multi-order-cancel {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 132px;
      height: 45px;
      border-radius: 5px;
      font-size: 18px;
      font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
      cursor: pointer;
    }
    .multi-order-cancel {
      background-color: #fff;
      border: 1px solid #999999;
      color: #999999;
    }
    .multi-order-confirm {
      background-color: #cc0000;
      border: 1px solid #cc0000;
      color: #ffffff;
      margin-left: 26px;
    }
  }
}

.cancel {
  width: 132px;
  height: 45px;
  display: inline-block;
  line-height: 45px;
  margin-right: 12px;
  color: #ffffff;
  background: #cc0000;
  border-radius: 5px;
  cursor: pointer;
}

.confirm {
  width: 132px;
  height: 45px;
  display: inline-block;
  line-height: 45px;
  margin-left: 12px;
  border: 1px solid #999999;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    color: #ffffff;
    background: #cc0000;
  }
}

.installInfo-popup {
  .title {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
  }
  .item {
    > span:first-child {
      display: inline-block;
      width: 100px;
    }
  }
}
.delivery-list-popup {
  /deep/.ivu-modal-content {
    background: rgb(245, 244, 244);
  }
  
  .title {
    color: #333;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  ul li {
    margin-bottom: 10px;
    padding: 15px;
    background: #fff;
    .name {
      margin-bottom: 5px;
    }
    .c-ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .goods-img {
    img {
      width: 90px;
      height: 90px;
    }
  }
}
/deep/.ivu-modal-footer {
  text-align: center;
}

/deep/.ivu-steps-horizontal .ivu-steps-content {
  padding-left: 0px;
}

//    /deep/.ivu-steps-head-inner>span{
//      display: none;
//    }
/deep/.ivu-steps-item.ivu-steps-status-process .ivu-steps-head-inner {
  border-color: #f65e5e;
  background-color: #f65e5e;
}

/deep/.ivu-steps-item.ivu-steps-status-finish .ivu-steps-head-inner {
  border-color: #f65e5e;
}

/deep/.ivu-steps-item.ivu-steps-status-finish .ivu-steps-head-inner span,
.ivu-steps-item.ivu-steps-status-finish .ivu-steps-head-inner>.ivu-steps-icon {
  color: #f65e5e;
}

/deep/.ivu-steps-item.ivu-steps-status-finish .ivu-steps-tail>i:after {
  color: #f65e5e;
}

/deep/.ivu-steps-item.ivu-steps-status-finish .ivu-steps-tail>i:after {
  background-color: #f65e5e;
}

/deep/.ivu-steps-item.ivu-steps-status-process .ivu-steps-head-inner span,
.ivu-steps-item.ivu-steps-status-process .ivu-steps-head-inner>.ivu-steps-icon {
  display: none;
}

/deep/.ivu-steps-item.ivu-steps-status-wait .ivu-steps-head-inner span,
.ivu-steps-item.ivu-steps-status-wait .ivu-steps-head-inner>.ivu-steps-icon {
  display: none;
}

/deep/.ivu-steps-item.ivu-steps-status-wait .ivu-steps-head-inner {
  background-color: #c4c4c4;
}

/deep/.ivu-steps-item.ivu-steps-status-process .ivu-steps-content {
  font-size: 20px;
  font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
}

/deep/.ivu-steps-item.ivu-steps-status-wait .ivu-steps-content {
  font-size: 20px;
  font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
  font-weight: 400;
  text-align: left;
  color: #999999;
}

/deep/.ivu-steps .ivu-steps-head {
  margin-left: 25px;
}

/deep/.ivu-steps-horizontal .ivu-steps-item:not(:first-child) .ivu-steps-head {
  padding-left: 35px;
}

/deep/.ivu-steps .ivu-steps-tail {
  left: 45px;
}
/deep/ .ivu-steps .ivu-steps-tail>i {
  left: 20px
}

/deep/.ivu-steps-item.ivu-steps-status-finish .ivu-steps-content {
  font-size: 20px;
  font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
  font-weight: 400;
  text-align: left;
  color: #999999;
}
</style>
